import { ValueTyping } from '../../../common/components/ExpressionField/Expression';

export type DataScope = 'user' | 'page' | 'module' | 'formation' | 'data' | 'quiz';
export const readonlyScopes: string[] = ['user'];
export type DynamicData = Record<DataScope, Record<string, ValueTyping> | undefined>;
export type IntelisenseType = 'var' | 'rovar' | 'func';

export type ParameterType = 'Nombre' | 'Chaine' | 'Date' | 'Booléen' | 'Expression';

export type Intelisense = Record<string, IntelisenseInfo>;
export interface IntelisenseParameterInfo {
    name: string;
    type: ParameterType;
    description: string;
}
export interface IntelisenseInfo {
    type: IntelisenseType;
    fullName: string;
    description?: string | undefined;
    parameters?: IntelisenseParameterInfo[];
    items?: Intelisense | undefined;
}
export interface DynamicDataState {
    data: DynamicData;
    intelisense: Intelisense;
    autoRefreshIntelisense: boolean;
}

export const initialState: DynamicDataState = {
    data: {
        user: undefined,
        page: undefined,
        module: undefined,
        formation: undefined,
        data: undefined,
        quiz: undefined,
    },
    intelisense: {},
    autoRefreshIntelisense: false,
};
