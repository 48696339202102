import { FC } from 'react';
import {
    IPageElement,
    IPageElementAudio,
    IPageElementButton,
    IPageElementCheckField,
    IPageElementCustomComponent,
    IPageElementDatetimeField,
    IPageElementImage,
    IPageElementLabel,
    IPageElementLink,
    IPageElementNumberField,
    IPageElementPDF,
    IPageElementQuestionnary,
    IPageElementSeparator,
    IPageElementTextField,
    IPageElementVideo,
    PageElementContainerStyle,
    PageElementType,
} from '../../../Services/SakuraApiClient';
import { ElementAudioView } from './Elements/Audio/ElementAudioView';
import { ElementButtonView } from './Elements/Button/ElementButtonView';
import { ElementCustomComponent } from './Elements/CustomComponent/ElementCustomComponent';
import { ElementImageView } from './Elements/Image/ElementImageView';
import { ElementCheckField } from './Elements/Input/ElementCheckField';
import { ElementDatetimeField } from './Elements/Input/ElementDatetimeField';
import { ElementNumberField } from './Elements/Input/ElementNumberField';
import { ElementTextField } from './Elements/Input/ElementTextField';
import { ElementLabelView } from './Elements/Label/ElementLabelView';
import { ElementLinkView } from './Elements/Link/ElementLinkView';
import { ElementPdfViewerView } from './Elements/PdfViewer/ElementPdfViewerView';
import { ElementQuestionnaryView } from './Elements/Questionnary/ElementQuestionnaryView';
import { ElementRichTextView } from './Elements/RichText/ElementRichTextView';
import { IPageElementRichTextExtended } from './Elements/RichText/IPageElementRichTextExtended';
import { ElementSeparatorView } from './Elements/Separator/ElementSeparatorView';
import { ElementVideoView } from './Elements/Video/ElementVideoView';

export interface PageElementBaseProps {
    data: IPageElement;
    type: PageElementType;
    parentContainerStyle?: PageElementContainerStyle;
}

export const PageElementBase: FC<PageElementBaseProps> = (props: PageElementBaseProps) => {
    const { type, data, parentContainerStyle } = props;

    switch (type) {
        case PageElementType.CheckField:
            return <ElementCheckField data={data as IPageElementCheckField} />;
        case PageElementType.TextField:
            return <ElementTextField data={data as IPageElementTextField} />;
        case PageElementType.NumberField:
            return <ElementNumberField data={data as IPageElementNumberField} />;
        case PageElementType.DatetimeField:
            return <ElementDatetimeField data={data as IPageElementDatetimeField} />;
        case PageElementType.Label:
            return <ElementLabelView data={data as IPageElementLabel} />;
        case PageElementType.Image:
            return <ElementImageView data={data as IPageElementImage} />;
        case PageElementType.Audio:
            return <ElementAudioView data={data as IPageElementAudio} />;
        case PageElementType.Video:
            return <ElementVideoView data={data as IPageElementVideo} />;
        case PageElementType.Button:
            return <ElementButtonView data={data as IPageElementButton} />;
        case PageElementType.RichText:
            return <ElementRichTextView data={data as IPageElementRichTextExtended} />;
        case PageElementType.Link:
            return <ElementLinkView data={data as IPageElementLink} />;
        case PageElementType.Questionnary:
            return <ElementQuestionnaryView data={data as IPageElementQuestionnary} />;
        case PageElementType.PDF:
            return <ElementPdfViewerView data={data as IPageElementPDF} />;
        case PageElementType.Separator:
            return <ElementSeparatorView data={data as IPageElementSeparator} direction={parentContainerStyle?.direction} />;
        case PageElementType.CustomComponent:
            return <ElementCustomComponent data={data as IPageElementCustomComponent} />;
        default:
            return <></>;
    }
};
