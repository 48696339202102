import { DraftStyleMap } from 'draft-js';
import { IColorPalette } from '../../Services/SakuraApiClient';
import { idToCssVar } from './common';

export function duplicatePalette(palette: IColorPalette) {
    let entries = palette.entries ? [...palette.entries] : undefined;
    if (entries) {
        entries = entries.map((e) => {
            return {
                colors: e.colors ? [...e.colors] : undefined,
                entryName: e.entryName,
            };
        });
    }
    return { entries };
}
export function loadPalette(palette: IColorPalette) {
    // Get the root element
    let entryIndex = 0;
    (palette.entries ?? []).forEach((entry) => {
        let colorIndex = 0;
        (entry.colors ?? []).forEach((color) => {
            const value = color.split('|')[0];
            document.body.style.setProperty(`--C${entryIndex}-${colorIndex}`, value);
            colorIndex++;
        });
        entryIndex++;
    });
}
export function createDraftJsPalette(palette: IColorPalette, suffix: 'b' | 'f') {
    let entryIndex = 0;
    const entries: Record<string, React.CSSProperties> = {};
    palette.entries?.forEach((e) => {
        let colorIndex = 0;
        e.colors?.forEach((c) => {
            const id = `--C${entryIndex}-${colorIndex}`;
            colorIndex++;
            if (suffix === 'f') {
                entries[`${id}-${suffix}`] = {
                    color: idToCssVar(id),
                };
            } else {
                entries[`${id}-${suffix}`] = {
                    background: idToCssVar(id),
                };
            }
        });
        entryIndex++;
    });
    return entries;
}
export function draftJsDeclarePalette(palette: IColorPalette, entries: DraftStyleMap) {
    let entryIndex = 0;
    palette.entries?.forEach((e) => {
        let colorIndex = 0;
        e.colors?.forEach((c) => {
            const id = `--C${entryIndex}-${colorIndex}`;
            colorIndex++;
            entries[id] = {
                color: idToCssVar(id),
            };
        });
        entryIndex++;
    });
}
