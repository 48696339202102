import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { IResource, QuestionnaryKind, QuestionTypeEnum } from '../../../../Services/SakuraApiClient';

export function questionnaryValidateModel(model: DeepPartial<IResource>): string[] | undefined {
    const questionnary = model.data?.content?.questionnary;
    const errors: string[] = [];

    if (questionnary) {
        if (questionnary.questions) {
            questionnary.questions.forEach((element) => {
                if (element.type === QuestionTypeEnum.Choice || element.type === QuestionTypeEnum.MultiChoice) {
                    if (!element.answers || element.answers.length <= 1) {
                        errors.push(`A la question ${element.id} specifier au moins deux réponses possibles.`);
                    }
                }
                if (questionnary.kind === QuestionnaryKind.Exam) {
                    if (element.type === QuestionTypeEnum.YesNo) {
                        if (element.no?.isCorrect === element.yes?.isCorrect) {
                            errors.push(`A la question ${element.id} une reponse doit étre correcte et l'autre non.`);
                        }
                    }
                    if (element.type === QuestionTypeEnum.Choice || element.type === QuestionTypeEnum.MultiChoice) {
                        if (element.answers) {
                            if (element.answers.filter((a) => a.isCorrect).length === 0) {
                                errors.push(`A la question ${element.id} specifier au moins 1 réponse marqué comme correcte.`);
                            }
                        }
                    }
                    if (element.type === QuestionTypeEnum.Choice) {
                        if (element.answers) {
                            if (element.answers.filter((a) => a.isCorrect).length !== 1) {
                                errors.push(`A la question ${element.id} specifier spécifier une seule reponse correcte.`);
                            }
                        }
                    }
                }
            });
        }
    }
    return errors;
}
