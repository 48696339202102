// Internal function for creating a toString-based type tester.
function tagTester(name: string) {
    return function (obj: unknown) {
        return toString.call(obj) === '[object ' + name + ']';
    };
}
// Modern feature detection.
const supportsArrayBuffer = typeof ArrayBuffer !== 'undefined';

const nativeIsArray = Array.isArray,
    nativeKeys = Object.keys,
    nativeCreate = Object.create,
    nativeIsView = supportsArrayBuffer && ArrayBuffer.isView;
// Is a given value an array?
// Delegates to ECMA5's native Array.isArray
export const isArray = nativeIsArray || tagTester('Array');

// Is a given variable an object?
export function isObject(obj: unknown) {
    const type = typeof obj;
    return type === 'function' || (type === 'object' && !!obj);
}

// Add some isType methods.

export const isArguments = tagTester('Arguments');
export const isFunction = tagTester('Function');
export const isString = tagTester('String');
export const isNumber = tagTester('Number');
export const isDate = tagTester('Date');
export const isRegExp = tagTester('RegExp');
export const isError = tagTester('Error');
export const isSymbol = tagTester('Symbol');
export const isMap = tagTester('Map');
export const isWeakMap = tagTester('WeakMap');
export const isSet = tagTester('Set');
export const isWeakSet = tagTester('WeakSet');
export const isArrayBuffer = tagTester('ArrayBuffer');
export const isDataView = tagTester('DataView');
