import { FC } from 'react';
import { DataListEdit } from '../../../common/components/DataListItem/DataListEdit';

export interface UserVariablesEditProps {
    variables: string[];
    onChange: (variables: string[]) => void;
}
export const UserVariablesEdit: FC<UserVariablesEditProps> = (props: UserVariablesEditProps) => {
    const { variables, onChange } = props;
    return (
        <DataListEdit
            titleAdd={'Ajouter une variable'}
            label={'List des variables dynamique des comptes utilisateur'}
            question={'Quel est le nom de la variable'}
            iconName={'Variable2'}
            data={variables}
            onChange={onChange}
        />
    );
};
