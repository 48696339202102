export function parseBoolean(val: string | undefined) {
    if (val) {
        if (val.toLowerCase() === 'yes' || val === 'oui') {
            return true;
        }
        if (val.toLowerCase() === 'no' || val === 'non') {
            return false;
        }
    }
    return undefined;
}

export function booleanAsString(val: boolean | undefined, isEnglish: boolean) {
    if (val !== undefined) {
        if (val) {
            return isEnglish ? 'yes' : 'oui';
        }
        return isEnglish ? 'no' : 'non';
    }
    return undefined;
}
