import { useMemo } from 'react';
import { dynamicDataResolver } from '../../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { DynamicData } from '../../../../Redux/Reducers/DynamiqueData/state';
import { Expression } from '../Expression';

export function useBracketResolver(value: string | undefined, data: DynamicData) {
    return useMemo(() => {
        if (value) {
            return Expression.resolveExpressionsInBrackets(value, dynamicDataResolver).newContent;
        }
        return undefined;
    }, [value, data]);
}
