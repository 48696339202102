import { Label, Slider } from '@fluentui/react';
import { FC } from 'react';
import { ColorPalettePicker } from '../../../../../common/components/Color/ColorPalettePicker';
import { CssBoxShadow } from '../../../../../common/components/Css/CssBoxShadow';
import { CssRectInput } from '../../../../../common/components/Css/CssRectInput';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { IPageElementSeparator } from '../../../../../Services/SakuraApiClient';

export interface SeparatorElementPanelProps {
    data: IPageElementSeparator;
    onChangeData: (data: DeepPartial<IPageElementSeparator>) => void;
}

export const SeparatorElementPanel: FC<SeparatorElementPanelProps> = (props: SeparatorElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Label>{'Couleur'}</Label>
            <ColorPalettePicker
                type='b'
                border
                idAsCssVariable
                colorId={data.color}
                onChange={(colorId) => {
                    onChangeData({ color: colorId });
                }}
            />
            <Slider
                label='Epaisseur'
                max={100}
                min={1}
                value={data.weight}
                showValue
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(weight) => {
                    onChangeData({ weight });
                }}
            />
            <Slider
                label='Marge'
                max={100}
                min={0}
                value={data.margin}
                showValue
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(margin) => {
                    onChangeData({ margin });
                }}
            />
            <Slider
                label='Padding'
                max={100}
                min={0}
                value={data.padding}
                showValue
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(padding) => {
                    onChangeData({ padding });
                }}
            />
            <CssRectInput label='Bordure arrondi' cornerMode title='Bordure arrondi' value={data.borderRadius} onChange={(val) => onChangeData({ borderRadius: val })} />
            <CssBoxShadow label='Box Shadow' value={data.boxShadow} onChangeValue={(val) => onChangeData({ boxShadow: val })} />
        </>
    );
};
