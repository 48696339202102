import { IObjectWithKey, Selection } from '@fluentui/react';
import { useRef } from 'react';

export function useGridSelection<T>(getKey: (item: T) => string | number, onSelect: ((item: T | undefined) => void) | undefined) {
    const createSelection = () => {
        return new Selection({
            onSelectionChanged: () => {
                const selected = gridSelection.current.getSelection();
                if (onSelect) {
                    if (selected.length > 0) {
                        onSelect(selected[0] as T);
                    } else {
                        onSelect(undefined);
                    }
                }
            },
            getKey: (item: IObjectWithKey) => getKey(item as T),
        });
    };
    const gridSelection = useRef(createSelection());
    return gridSelection;
}
