import { useAppDispatch } from '../../Redux/hook';
import { ServiceCreator } from '../../Services/ServiceCreator';
import { setLoading, setMessage } from '../../Redux/Reducers/System/reducer';
import { SakuraApiClient } from '../../Services/SakuraApiClient';
import { DependencyList, useEffect } from 'react';
import { AppDispatch } from '../../Redux/store';
import { MessageBarType } from '@fluentui/react';
import { displayError } from '../../Services/displayError';
import axios, { CancelToken } from 'axios';
import { useStrictMode } from '../..';

export interface ApiCallOption {
    contextId?: string;
    anonymous?: boolean;
    cancelToken?: CancelToken;
}
export async function performApiCall<T>(apiCall: (client: SakuraApiClient, cancelToken?: CancelToken) => Promise<T | undefined>, dispatch: AppDispatch, option?: ApiCallOption) {
    const contextId = option?.contextId ?? 'default';
    const anonymous = option?.anonymous ?? false;
    const perform = async () => {
        dispatch(setLoading({ contextId, loading: { isLoading: true } }));

        try {
            const client = ServiceCreator.SakuraApiClient(anonymous);
            return await apiCall(client, option?.cancelToken);
        } catch (ex: unknown) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((ex as any)?.message !== 'canceled') {
                dispatch(
                    setMessage({
                        contextId,
                        message: { Severity: MessageBarType.error, text: displayError(ex) },
                    }),
                );
            }
        } finally {
            dispatch(setLoading({ contextId, loading: undefined }));
        }
        return undefined;
    };
    return await perform();
}

export function useApiCall<T>(apiCall: (client: SakuraApiClient, cancelToken?: CancelToken) => Promise<T>, deps?: DependencyList, option?: Omit<ApiCallOption, 'cancelToken'>) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const source = axios.CancelToken.source();
        performApiCall(apiCall, dispatch, { ...option, cancelToken: source.token });
        return () => {
            if (!useStrictMode) {
                source.cancel();
            } else {
                console.log('Should have sent a throw cancel api call');
            }
        };
    }, deps);
}
