import { FC } from 'react';
import { BadgeLookup } from '../../Redux/Reducers/DataSet/ApiCall/Badge';
import { storageEndpointsSelector } from '../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../Redux/hook';
import { IBadge } from '../../Services/SakuraApiClient';
import { ST } from '../../common/Hooks/StorageResolver';
import { BadgeViewer } from '../components/Badge/BadgeViewer';
import { DatasetLookupField } from './DatasetLookupField';

export interface BadgeLookupFieldProps {
    selectedBadge: IBadge | undefined;
    onSelectBadge: (badge: IBadge | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
}
export const BadgeLookupField: FC<BadgeLookupFieldProps> = (props: BadgeLookupFieldProps) => {
    const { label, selectedBadge, onSelectBadge, readOnly, required, contextId } = props;
    const storage = useAppSelector(storageEndpointsSelector);

    return (
        <DatasetLookupField
            label={label}
            apiCall={BadgeLookup}
            accessKeyProp={(i) => i.id.toString()}
            accessTextProp={(i) => i.name ?? ''}
            selectedItem={selectedBadge}
            onSelectItem={(item) => onSelectBadge(item)}
            readOnly={readOnly}
            required={required}
            contextId={contextId}
            onRenderLabel={(item: IBadge) => {
                return item ? <BadgeViewer width={24} height={24} url={ST(storage, item.imageBlobPath)} editable={false} type={item.badgeType} graphics={item.badgeSetting.graphics} /> : <></>;
            }}
            onRenderItem={(item: IBadge) => {
                return item ? (
                    <div className='DivFlexHorizontalCenter'>
                        <BadgeViewer width={40} height={40} url={ST(storage, item.imageBlobPath)} type={item.badgeType} graphics={item.badgeSetting.graphics} />
                        <span>{item.name}</span>
                    </div>
                ) : (
                    <></>
                );
            }}
        />
    );
};
