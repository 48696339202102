import { useMemo } from 'react';
import { dynamicDataResolver } from '../../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { DynamicData } from '../../../../Redux/Reducers/DynamiqueData/state';
import { Expression } from '../Expression';

export function useExpressionCondition(fieldValue: string | undefined, data: DynamicData) {
    return useMemo(() => {
        if (fieldValue) {
            const exp = new Expression(fieldValue, dynamicDataResolver);
            return exp.evalCondition();
        }
        return true;
    }, [fieldValue, data]);
}
