import { IResourceConsumerPackage, IUserMenuEntry, MenuEntryType } from '../../../Services/SakuraApiClient';
import { MenuEntry } from '../../../common/Layout/LayoutNavigation';

export interface UserRoute {
    routePath: string;
    type: MenuEntryType;
    pageId?: number;
    page: IResourceConsumerPackage | undefined;
    resourceId: number | undefined;
}

export function buildUserMenuRoutes(userMenu: IUserMenuEntry[]) {
    const routes: UserRoute[] = [];
    const firstRoute: string[] = [];
    userMenu.forEach((e) => buildUserMenuChildRoutes(undefined, routes, e, firstRoute));
    return { routes, firstRoute: firstRoute.length === 1 ? firstRoute[0] : undefined };
}
function buildUserMenuChildRoutes(routePath: string | undefined, routes: UserRoute[], entry: IUserMenuEntry, firstRoute: string[]) {
    const entryRoutePath = routePath ? `${routePath}/${entry.key}` : entry.key ?? '';

    if (entry.type === MenuEntryType.Page || entry.type === MenuEntryType.ViewResource) {
        routes.push({
            routePath: entryRoutePath,
            pageId: entry.pageId,
            page: entry.page,
            type: entry.type,
            resourceId: entry.resourceId,
        });
    }
    if (entry.children && entry.children.length > 0) {
        entry.children.forEach((c) => {
            buildUserMenuChildRoutes(entryRoutePath, routes, c, firstRoute);
        });
    } else if (entry.type === MenuEntryType.Page || entry.type === MenuEntryType.BuildinPage || entry.type === MenuEntryType.ViewResource) {
        if (firstRoute.length === 0) {
            firstRoute.push(entryRoutePath);
        }
    }
    return;
}

export function buildUserMenu(userMenu: IUserMenuEntry[]) {
    return userMenu.map((e) => buildUserMenuChild(undefined, e));
}
function buildUserMenuChild(routePath: string | undefined, entry: IUserMenuEntry) {
    const entryPath = routePath ? `${routePath}/${entry.key}` : `/${entry.key}` ?? '';
    const result: MenuEntry = {
        key: entry.key ?? '',
        title: entry.title ?? '[Title Not Configured]',
        disabledBreadCrumb: true,
        externalUrl: entry.type === MenuEntryType.Url ? entry.externalUrl : undefined,
        svgPath: entry.svgPath,
        icon: entry.icon,
        path: entry.children === undefined || entry.children.length === 0 ? entryPath : undefined,
    };

    if (entry.children) {
        result.children = [];

        entry.children.forEach((c) => {
            if (result.children) {
                result.children.push(buildUserMenuChild(entryPath, c));
            }
        });
    }
    return result;
}
