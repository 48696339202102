/* ----------*
* Modifiers
------------*/

import { EditorState, Modifier, SelectionState } from 'draft-js';
import { TriggerRange } from './IntelisensePopup';
import { IntelisenseInfo } from '../../../../Redux/Reducers/DynamiqueData/state';
import { hasChildProperty } from '../../../../Redux/Reducers/DynamiqueData/helpers';

export const addIntelisenseItem = (editorState: EditorState, content: IntelisenseInfo, triggerRange: TriggerRange) => {
    const { start, end } = triggerRange; // getInsertRange(prefix, editorState);
    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const selection = currentSelection.merge({
        anchorOffset: start,
        focusOffset: end,
    });

    let text = content.fullName;
    let refresh = false;
    if (hasChildProperty(content) && content.type !== 'func') {
        text = text + '.';
        refresh = true;
    } else if (content.type === 'func') {
        text = text + '(';
        refresh = true;
    }
    const newContentState = Modifier.replaceText(contentState, selection, `${text}`, undefined, undefined);

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    return { editorState: EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()), refresh };
};

export function addBracketExpression(editorState: EditorState) {
    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    if (currentSelection.getStartOffset() === currentSelection.getEndOffset() && currentSelection.getStartKey() === currentSelection.getEndKey()) {
        const newContentState = Modifier.replaceText(contentState, currentSelection, `{{}}`, undefined, undefined);

        const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
        let newSelectionState = newContentState.getSelectionAfter();
        newSelectionState = newSelectionState.merge({
            focusOffset: newSelectionState.getFocusOffset() - 2,
            anchorOffset: newSelectionState.getAnchorOffset() - 2,
        });
        return EditorState.forceSelection(newEditorState, newSelectionState);
    }
    return undefined;

    //  const contentText = contentState.getPlainText();
    //  const currentBlockKey = currentSelection.getStartKey();
    //  const currentBlockIndex = editorState
    //      .getCurrentContent()
    //      .getBlockMap()
    //      .keySeq()
    //      .findIndex((k) => k === currentBlockKey);
    //  const blockOffset = calculatePreviousBlockLength(currentBlockIndex, contentText);
    //  const startFullIndex = blockOffset + start;
    //  const endFullIndex = blockOffset + end;
    //  if (startFullIndex >= 2 && contentText[startFullIndex - 1] === '{' && contentText[startFullIndex - 2] === '{') {
    //      start = start - 2;
    //  }
    //  if (endFullIndex < contentText.length - 1 && contentText[endFullIndex] === '}' && contentText[endFullIndex + 1] === '}') {
    //      end = end + 2;
    //  }
}
