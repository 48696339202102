import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const queryEmailTemplates = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.getEmailTemplate(newState.pageIndex, newState.pageSize, cancelToken);
};

const queryContactList = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.getContactLists(newState.pageIndex, newState.pageSize, cancelToken);
};

const queryContactAttributes = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.getContactAttributes(newState.pageIndex, newState.pageSize, cancelToken);
};

export const EmailTemplateLookup = new ApiCall('EmailTemplateLookup', queryEmailTemplates);
export const ContactListLookup = new ApiCall('ContactListLookup', queryContactList);
export const ContactAttributesLookup = new ApiCall('ContactAttributesLookup', queryContactAttributes);
