import { ContentState } from 'draft-js';
import React, { FC, PropsWithChildren } from 'react';
import './syntaxHighlight.scss';

export interface SyntaxHighlightProps {
    color: string;
    bold?: boolean;
    contentState: ContentState;
    entityKey: string;
    start: number;
    end: number;
    decoratedText: string;
}
export const SyntaxHighlight: FC<PropsWithChildren<SyntaxHighlightProps>> = (props: PropsWithChildren<SyntaxHighlightProps>) => {
    const { color, bold, children } = props;

    return (
        <span className='syntaxHighlight' style={{ color, fontWeight: bold ? 'bold' : undefined }}>
            {children}
        </span>
    );
};
