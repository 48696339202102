import { FC } from 'react';
import { ShareButton } from '../../../../SocialNetworkLinks/ShareButton';
import { SocialNetworkNameShareable } from '../../../../SocialNetworkLinks/type';

export interface ShareButtonComponentProps {
    target: SocialNetworkNameShareable | undefined;
    url: string | undefined;
    hashTag?: string | undefined;
    content?: string | undefined;
    subject?: string | undefined;
    media?: string | undefined;
    editMode?: boolean;
}
export const ShareButtonComponent: FC<ShareButtonComponentProps> = (props: ShareButtonComponentProps) => {
    const { target, url, hashTag, editMode, content, subject, media } = props;

    if (!url || url === '' || !target) {
        return <span>{'Spécifié une URL.'}</span>;
    }
    const button = <ShareButton target={target} url={url} hashTag={hashTag} content={content} subject={subject} media={media} />;
    return button !== null ? editMode ? <div style={{ pointerEvents: 'none' }}>{button}</div> : button : <span> TODO</span>;
};
