import { Icon } from '@fluentui/react';
import { FC } from 'react';
import { EmailShareButton } from 'react-share';
import { SharedButtonProps } from './type';

export const EmailSB: FC<SharedButtonProps> = (props: SharedButtonProps) => {
    const { url, content, subject } = props;

    return url ? (
        <EmailShareButton url={url} subject={subject} body={content}>
            <div className='email_share_b' title='Partager par email'>
                <div className='email_icon_div'>
                    <Icon iconName={'MailSolid'} style={{ color: 'white' }} />
                </div>

                <span>{'Partager'}</span>
            </div>
        </EmailShareButton>
    ) : null;
};
