import { INavMenuEntry } from '../../../../common/components/NavMenu';
import { IAccountDataResource, IResourceHierarchyView } from '../../../../Services/SakuraApiClient';

interface ItemUserData {
    resourcePath: string;
    data: IAccountDataResource | undefined;
}
export interface FormationUserData {
    userDataIndex: Record<string, number>;
    userDatas: ItemUserData[];
}
export function buildFormationUserData(userData: Record<string, IAccountDataResource | undefined>): FormationUserData {
    const userDatas: ItemUserData[] = [];
    const userDataIndex: Record<string, number> = {};
    const keys = Object.keys(userData);
    for (let i = 0; i < keys.length; i++) {
        userDataIndex[keys[i]] = i;
        userDatas.push({ resourcePath: keys[i], data: userData[keys[i]] });
    }
    return { userDataIndex, userDatas };
}

export function updateMenuItem(
    menu: INavMenuEntry<IResourceHierarchyView>[],
    resourcePath: string,
    update: (item: INavMenuEntry<IResourceHierarchyView>) => INavMenuEntry<IResourceHierarchyView>,
): INavMenuEntry<IResourceHierarchyView>[] {
    const matchIndex = menu.findIndex((item) => item.data.resourcePath === resourcePath);
    if (matchIndex !== -1) {
        const result = [...menu];
        result[matchIndex] = update(result[matchIndex]);
        return result;
    }
    const matchChildIndex = menu.findIndex((item) => resourcePath.startsWith(item.data.resourcePath!));

    if (matchChildIndex !== -1) {
        const result = [...menu];
        result[matchChildIndex] = { ...result[matchChildIndex], children: updateMenuItem(result[matchChildIndex].children!, resourcePath, update) };
        return result;
    }

    return menu;
}

export function updateMenuItemToOpenRequired(menu: INavMenuEntry<IResourceHierarchyView>[], resourcePath: string): INavMenuEntry<IResourceHierarchyView>[] {
    const matchIndex = menu.findIndex((item) => resourcePath.startsWith(item.data.resourcePath!));
    if (matchIndex !== -1) {
        const item = menu[matchIndex];
        if (!item.isExpanded && item.children && item.children.length > 0 && item.data.resourcePath !== resourcePath) {
            const result = [...menu];
            result[matchIndex] = { ...result[matchIndex], isExpanded: true, children: updateMenuItemToOpenRequired(result[matchIndex].children!, resourcePath) };
            return result;
        }
    }
    return menu;
}
