import { FC, ReactNode, useState } from 'react';
import { setColorPalette, setConfiguredLinks, setFontStyles } from './Redux/Reducers/System/reducer';
import { useAppDispatch } from './Redux/hook';
import { useApiCall } from './common/Hooks/useApiCall';

export interface InitialiazerPublicLinksProps {
    children: ReactNode | undefined;
}
export const InitialiazerPublicLinks: FC<InitialiazerPublicLinksProps> = (props: InitialiazerPublicLinksProps) => {
    const { children } = props;
    const dispatch = useAppDispatch();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    useApiCall(async (client, cancelToken) => {
        const settings = await client.getPublicSettings(cancelToken);
        dispatch(setConfiguredLinks({ links: settings.links }));
        if (settings?.colorPalette) {
            dispatch(setColorPalette({ colors: settings.colorPalette }));
        }
        if (settings?.fontStyles) {
            dispatch(setFontStyles({ fontStyles: settings.fontStyles }));
        }
        setIsLoaded(true);
    });
    return <>{isLoaded ? children : null}</>;
};
