import React, { FC, useContext, useMemo } from 'react';
import { IImageEffect, ResourceTypeEnum } from '../../../Services/SakuraApiClient';
import { ResourceImageViewer } from '../ImageViewer/ResourceImageViewer';
import { ResourcePackageDataContext } from './ResourcePackageContextProvider';
import { DeepPartial } from '../../helpers/ObjectAndArray';

export interface ResourceViewerProps {
    resourceId: number | undefined;
    effect?: DeepPartial<IImageEffect>;
    selected?: boolean;
    onSelect?: (selected: boolean) => void;
    style?: React.CSSProperties | undefined;
}
export const ResourceViewer: FC<ResourceViewerProps> = (props: ResourceViewerProps) => {
    const { resourceId, effect, selected, onSelect, style } = props;
    const context = useContext(ResourcePackageDataContext);

    const resource = useMemo(() => {
        if (resourceId !== undefined && context.resourcePackages) {
            return context.resourcePackages[resourceId.toString()];
        }
        return undefined;
    }, [resourceId, context.resourcePackages]);

    return (
        <>
            {resource ? (
                <>
                    {resource.type === ResourceTypeEnum.Image && resource.blobPath ? (
                        <ResourceImageViewer style={style} blobPath={resource.blobPath} effect={effect} selected={selected} onSelect={onSelect}></ResourceImageViewer>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};
