import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { IPageElement, IPageElementContainer, PageElement, PageElementContainerStyle, PageElementType } from '../../../Services/SakuraApiClient';
import { generateUniqueId } from '../../../common/helpers/uniqueId';
import { CustomComponentName } from '../Page/Elements/CustomComponent/type';
import { PageElementEditionBase } from '../Page/PageElementEditionBase';
import { EditorDataContext, IEditorDataContextProvider } from './EditorContext/EditorDataContextProvider';
import { DropPosition, ElementSelector } from './ElementSelector';
export interface PageElementProps {
    data: PageElement;
    parentContainerId?: string;
    orientation?: 'Vertical' | 'Horizontal';
    noDrag?: boolean;
    parentContainerStyle?: PageElementContainerStyle;
    onChangeData?: (data: PageElement) => void;
    onDropItem?: (position: DropPosition, elementType: PageElementType, elementSubType: CustomComponentName | undefined) => void;
    onDropLibraryItem?: (position: DropPosition, element: IPageElementContainer) => void;
    onMoveItem?: (position: DropPosition, element: PageElement) => void;
    onRemoveItem?: (immediate: boolean, position?: DropPosition) => void;
    onCopyPaste?: (itemCopied: IPageElement, to: IPageElement) => void;
}

export const PageElementEdition: FC<PageElementProps> = (props: PageElementProps) => {
    const { data, onChangeData, onDropItem, onDropLibraryItem, onMoveItem, onRemoveItem, orientation, parentContainerId, noDrag, parentContainerStyle, onCopyPaste } = props;
    const context = useContext(EditorDataContext);
    const uiComponentIdRef = useRef<string>(generateUniqueId('PI_'));
    const dataRef = useRef<PageElement>(data);

    const onDataChange = useCallback(
        (data: PageElement) => {
            dataRef.current = data;
            if (onChangeData) {
                onChangeData(data);
            }
        },
        [onChangeData],
    );
    useEffect(() => {
        dataRef.current = data;
    }, [data]);
    const select = useCallback(
        (currentContext: IEditorDataContextProvider) => {
            currentContext.select(
                uiComponentIdRef.current,
                dataRef.current,
                (newData) => {
                    if (onChangeData) {
                        onChangeData(newData);
                        return newData;
                    }
                    return data;
                },
                () => {
                    if (onRemoveItem) {
                        onRemoveItem(true);
                    }
                },
            );
        },
        [onChangeData, onRemoveItem],
    );
    useEffect(() => {
        if (context.planSelection) {
            if (data === context.planSelection) {
                select(context);
            }
        } else {
            if (uiComponentIdRef.current === context.selectedUIComponentId) {
                select(context);
            }
        }
    }, [data, context.selectedUIComponentId, context.planSelection]);

    const isSelected = useMemo(() => {
        return context.selectedUIComponentId === uiComponentIdRef.current;
    }, [context.selectedUIComponentId]);
    return (
        <ElementSelector
            parentContainerId={parentContainerId}
            orientation={orientation}
            isSelected={isSelected}
            data={data}
            noDrag={noDrag}
            onSelect={() => {
                select(context);
            }}
            onDropItem={onDropItem}
            onDropLibraryItem={onDropLibraryItem}
            onMoveItem={onMoveItem}
            onRemoveItem={onRemoveItem}
            parentContainerStyle={parentContainerStyle}
            onCopyPaste={onCopyPaste}
        >
            <PageElementEditionBase data={data} type={data.type} isSelected={isSelected} onChangeData={onDataChange} parentContainerStyle={parentContainerStyle} />
        </ElementSelector>
    );
};
