import { IFunction } from '../IFunction';
import { Value } from '../../Value';
import { IntelisenseInfo } from '../../../../../../Redux/Reducers/DynamiqueData/state';

export class IfFunction implements IFunction {
    public get name(): string {
        return 'si';
    }
    public get intelisenseInfo(): IntelisenseInfo {
        return {
            type: 'func',
            fullName: this.name,
            description: 'Vérifie la condition en premier parametre. Si elle renvoi vrai alors la fonction renvoi resultat vrai sinon elle renvoi resultat faux.',
            parameters: [
                {
                    name: 'condition',
                    type: 'Booléen',
                    description: 'Condition a evaluer',
                },
                {
                    name: 'Resultat vrai',
                    type: 'Expression',
                    description: 'le resultat de cette expression est renvoi si la condition est vrai',
                },
                {
                    name: 'Resultat faux',
                    type: 'Expression',
                    description: 'le resultat de cette expression est renvoi si la condition est fausse',
                },
            ],
        };
    }
    public checkArguments(parameters: Value[]): string | null {
        if (parameters.length !== 3) {
            return 'Cette fonction prend 3 arguments';
        }
        return null;
    }

    public invoke(parameters: Value[]): Value {
        let condition = parameters[0];
        const trueValue = parameters[1];
        const falseValue = parameters[2];

        condition = Value.Transtype(condition, 'boolean');
        if (condition.valueObject !== null && typeof condition.valueObject === 'boolean' && condition.valueObject === true) {
            return trueValue;
        }
        return falseValue;
    }
}
