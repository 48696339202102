import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listSocialNetworkLink(
        newState.filters['name'] as string | undefined,
        newState.filters['link'] as string | undefined,
        newState.filters['shortner'] as string | undefined,
        newState.filters['comment'] as string | undefined,
        newState.pageIndex,
        newState.pageSize,
        cancelToken,
    );
};
const queryTracking = async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const linkId = apiCall.parameters ? (apiCall.parameters['linkId'] as number) : (0 as number);
    return await client.getSocialNetworkAccessById(linkId, newState.pageIndex, newState.pageSize, cancelToken);
};
export const SocialNetworkLinksGrid = new ApiCall('SocialNetworkLinksGrid', query);
export const SocialNetworkLinksTrackingGrid = new ApiCall('SocialNetworkLinksTrackingGrid', queryTracking);
