import { FC, useCallback, useMemo, useState } from 'react';
import { DeepPartial, DeepPartialWithArrayFunc, FormError } from '../../../../common/Hooks/Form/useForm';
import { MenuHierarchy } from './MenuHierarchy';
import { IMenuSetting, MenuSettingEntry } from './menuSetting';

import { MenuItemDetail } from './MenuItemDetail';
import './MenutSetting.scss';
import { MenuHierarchyAction, formMenuHierarchySlicer } from './formMenuHierarchySlicer';
import { getItemByPath } from './utils';
export interface MenuSettingEditProps {
    errors: FormError<IMenuSetting | undefined>;
    data: DeepPartial<IMenuSetting>;
    onChange: (data: DeepPartialWithArrayFunc<IMenuSetting>) => void;
}

export const MenuSettingEdit: FC<MenuSettingEditProps> = (props: MenuSettingEditProps) => {
    const { errors, data, onChange } = props;

    const [selectedId, setSelectedId] = useState<string>();
    const updateForm = useCallback(
        (action: MenuHierarchyAction) => {
            const { newState } = formMenuHierarchySlicer(data, action);
            onChange(newState);
            return undefined;
        },
        [data],
    );
    const [selectedItem, selectedItemError] = useMemo(() => {
        if (selectedId) {
            const selectedItem = getItemByPath(selectedId, data as MenuSettingEntry, errors as FormError<MenuSettingEntry>);
            if (selectedItem) {
                return selectedItem;
            }
        }
        return [undefined, undefined];
    }, [data, selectedId, errors]);
    return (
        <div className='MenuEdit'>
            <MenuHierarchy
                selectedId={selectedId}
                errors={errors as FormError<MenuSettingEntry>}
                onSelect={(id) => setSelectedId(id)}
                entries={data?.children as MenuSettingEntry[]}
                updateForm={updateForm}
            />
            <MenuItemDetail data={selectedItem} error={selectedItemError} updateForm={updateForm} />
        </div>
    );
};
