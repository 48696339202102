import { ProductTileGrid, ProductTileWhishlistGrid } from './ApiCall/Product';
import { ResourceTileFavoriteGrid, ResourceTileGrid } from './ApiCall/Resource';

export function refreshAllResourceTiles() {
    ResourceTileGrid.refresh();
    ResourceTileFavoriteGrid.refresh();
}
export function refreshAllProductTiles() {
    ProductTileGrid.refresh();
    ProductTileWhishlistGrid.refresh();
}
