import React, { useState } from 'react';
import { CustomDialog } from '../CustomDialog';
import { DefaultButton, DialogFooter, IIconProps, PrimaryButton, TextField } from '@fluentui/react';

export interface IAskDialogProps {
    id: string;
    title: string;
    question?: string;
    icon?: IIconProps;
    defaultAnswer?: string;
    show: boolean;
    onClose: (answer: string | undefined) => void;
}

export function AskDialog(props: IAskDialogProps) {
    const { title, question, id, show, onClose, icon, defaultAnswer } = props;
    const [answer, setAnswer] = useState<string | undefined>(defaultAnswer);
    return (
        <CustomDialog
            id={id}
            hidden={!show}
            subText={question}
            title={title}
            icon={icon}
            onDismiss={() => {
                onClose(undefined);
            }}
        >
            <>
                <TextField value={answer} onChange={(_, txt) => setAnswer(txt)} />
                <DialogFooter>
                    <PrimaryButton onClick={() => onClose(answer)} text={'Ok'} />
                    <DefaultButton onClick={() => onClose(undefined)} text={'Annuler'} />
                </DialogFooter>
            </>
        </CustomDialog>
    );
}
