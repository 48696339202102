import { ContentBlock, DraftBlockType, DraftInlineStyle, DraftStyleMap } from 'draft-js';

export function defaultCustomStyleFn(style: DraftInlineStyle, contentBlock: ContentBlock): React.CSSProperties {
    const type = contentBlock.getType();

    if (type === 'TXTALIGNLEFT') {
        return {
            backgroundColor: 'blue',
            textAlign: 'left',
        };
    }
    if (type === 'TXTALIGNCENTER') {
        return {
            backgroundColor: 'green',
            textAlign: 'center',
        };
    }
    if (type === 'TXTALIGNRIGHT') {
        return {
            backgroundColor: 'red',
            textAlign: 'right',
        };
    }
    return {};
}
