import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import './exapander.scss';

export interface ExpanderProps {
    direction: 'vertical' | 'horizontal';
    targetDiv: React.MutableRefObject<HTMLDivElement | null>;
    min: number;
    max: number;
}

export const Expander: FC<ExpanderProps> = (props: ExpanderProps) => {
    const { direction, targetDiv, min, max } = props;
    const [isResizing, setIsResizing] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const divRef = useRef(null);

    const handleMouseUp = () => {
        setIsResizing(false); // Arrête le redimensionnement quand on relâche le bouton de la souris
    };
    // Effet pour l'ajout et le retrait des écouteurs de mouvement de la souris
    useEffect(() => {
        const handleMouseMove: (this: Window, ev: MouseEvent) => unknown = (e) => {
            if (isResizing && targetDiv.current) {
                if (direction === 'vertical') {
                    const newWidth = startPosition + (e.clientX - targetDiv.current.offsetLeft - startPosition);

                    if (newWidth >= min && newWidth <= max) {
                        targetDiv.current.style.width = `${newWidth}px`;
                    }
                } else if (direction === 'horizontal') {
                    const newHeight = startPosition + (e.clientY - targetDiv.current.offsetTop - startPosition);
                    if (newHeight >= min && newHeight <= max) {
                        targetDiv.current.style.height = `${newHeight}px`;
                    }
                }
            }
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, startPosition, direction, targetDiv]);

    // Commence le redimensionnement lors du clic
    const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setIsResizing(true);

        if (direction === 'vertical') {
            setStartPosition(e.clientX); // Position de départ X pour le redimensionnement horizontal
        } else if (direction === 'horizontal') {
            setStartPosition(e.clientY); // Position de départ Y pour le redimensionnement vertical
        }
    };

    return (
        <div ref={divRef} className={`expander ${direction}`} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            <div className={clsx('tongue', isResizing ? 'active' : '')}>
                <Icon iconName={direction === 'vertical' ? 'GripperBarVertical' : 'GripperBarHorizontal'} />
            </div>
        </div>
    );
};
