import React, { FC } from 'react';
import { EnumVideoProvider, IResource, IWorkflow, Resource, ResourceContent, ResourceData, ResourceDataVideo, ResourceTypeEnum, Workflow } from '../../../../Services/SakuraApiClient';
import { Container } from '../../../../common/Hooks/StorageResolver';
import { ResourceFileDetails } from '../ResourceFileDetails';
import { useForm } from '../../../../common/Hooks/Form';
import * as validators from '../../../../common/Hooks/Form/validators';
import { useAppDispatch } from '../../../../Redux/hook';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { GetResourceTypeEntityTitle, GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { ChoiceGroup, IChoiceGroupOption, Separator, TextField, Toggle } from '@fluentui/react';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { WorkflowEdit } from '../../Workflow/WorkflowEdit';

export interface ResourceWorkflowDetailsProps {
    resource?: IResource;
    show: boolean;

    onClose: (resource: IResource | undefined) => void;
}
const getNewResource = () => {
    const newResource = new Resource();
    newResource.init({ enabled: true });
    newResource.data = new ResourceData();
    newResource.data.content = new ResourceContent();
    newResource.data.content.workflow = new Workflow();
    return newResource;
};

export const ResourceWorkflowDetails: FC<ResourceWorkflowDetailsProps> = (props: ResourceWorkflowDetailsProps) => {
    const { resource, show, onClose } = props;

    const contextId = `EditWorkflow`;
    const form = useForm<IResource>(
        {
            initialState: resource ?? getNewResource(),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        resource !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        if (res === undefined) {
            form.rollback();
        }
        onClose(res);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state) {
                        const data = new Resource();
                        data.init(form.state);
                        data.resourceType = ResourceTypeEnum.Workflow;

                        let savedResource;
                        if (resource) {
                            savedResource = await client.updateResource(resource.id, data);
                        } else {
                            data.enabled = true;
                            data.reusable = false;
                            savedResource = await client.createResource(data);
                        }
                        form.commit();
                        close(savedResource);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Workflow) }}
            mode={resource ? 'Edit' : 'Create'}
            title={GetResourceTypeEntityTitle(ResourceTypeEnum.Workflow, resource ? 'edit' : 'new')}
            subText=''
            show={show}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Category' value={form.state.category ?? ''} onChange={(_, v) => form.update({ category: v })} />
                <TextField label='Description' value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                <Separator />
                <WorkflowEdit
                    direction='vertical'
                    defaultOpenState
                    workflow={form.state.data?.content?.workflow as IWorkflow}
                    onChange={(workflow) => form.update({ data: { content: { workflow } } })}
                />
                <Separator />
                {resource && <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.enabled} onChange={(_, val) => form.update({ enabled: val })} />}
            </div>
        </EditDialog>
    );
};
