import React, { FC, useState } from 'react';
import { Label, PrimaryButton } from '@fluentui/react';
import { IWorkflow } from '../../../../Services/SakuraApiClient';
import { SimpleDialog } from '../../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { WorkflowEdit } from '../../Workflow/WorkflowEdit';

export interface WorkflowConfiguratorProps {
    workflow: IWorkflow | undefined;
    onChangeWorkflow: (workflow: IWorkflow | undefined) => void;
}

export const WorkflowConfigurator: FC<WorkflowConfiguratorProps> = (props: WorkflowConfiguratorProps) => {
    const { workflow, onChangeWorkflow } = props;
    const [showWorkflow, setShowWorkflow] = useState<boolean>(false);

    return (
        <>
            <div>
                <Label>{`Actions (${workflow?.actions?.length ?? 0})`}</Label>
                <PrimaryButton text='Configurer' iconProps={{ iconName: 'Settings' }} onClick={() => setShowWorkflow(true)} />
            </div>
            <SimpleDialog
                id={'workflowDialog'}
                title={'Configurer'}
                show={showWorkflow}
                onClose={function (): void {
                    setShowWorkflow(false);
                }}
                enableFlyingPopup
            >
                <WorkflowEdit direction='vertical' defaultOpenState workflow={workflow} onChange={onChangeWorkflow} />
            </SimpleDialog>
        </>
    );
};
