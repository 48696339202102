import { Label } from '@fluentui/react';
import React, { createContext, FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { LoadingSpinner } from '../loadingSpinner';

import './ComboBoxItemPanel.scss';
import { SearchComboBoxState } from './Reducer';
import Pagination, { OnNavigate } from '../CustomDetailList/Pagination';
import { PaginationSetting } from '../CustomDetailList/ItemHelper';
import { ErrorPanel } from './ErrorPanel';
import { NoRecordPanel, NoRecordPanelProps } from './NoRecordPanel';
import clsx from 'clsx';

export type SearchComboBoxMode = 'FrequentlyUsed' | 'Search';

export interface ComboBoxItemPanelProps {
    onNavigate: OnNavigate;
    mode: SearchComboBoxMode;
    isLoading: boolean;
    viewMode: 'List' | 'Grid';
    nbItems: number;
    totalItems?: number;
    searchText: string;
    url?: string;
    errorMessage?: string;
    paginationSetting?: PaginationSetting;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchComboBoxState?: SearchComboBoxState<any>;
    showFrequentlyUsedLink?: boolean;
    showCannotFindPanel?: boolean;
    customLabelResource?: string;
    onShowFrequentlyUsedLink?: () => void;
    onchangeFilter?: () => void;
    customNoRecordPanel?: (props: NoRecordPanelProps) => JSX.Element;
    onCreateNewItem?: () => void;
}

export interface ItemPanelDataContextData<TItem> {
    mode: SearchComboBoxMode;
    isLoading: boolean;
    nbItems: number;
    totalItems?: number;
    searchText: string;
    url?: string;
    paginationSetting?: PaginationSetting;
    searchComboBoxState?: SearchComboBoxState<TItem>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ItemPanelDataContext = createContext<ItemPanelDataContextData<any>>({
    url: '',
    isLoading: false,
    nbItems: 0,
    totalItems: 0,
    searchText: '',
    mode: 'Search',
});
export const ComboBoxItemPanel: FC<PropsWithChildren<ComboBoxItemPanelProps>> = (props: PropsWithChildren<ComboBoxItemPanelProps>) => {
    const { children, onNavigate, customLabelResource, onCreateNewItem } = props;
    const memorizePosition = useRef<number>();
    const {
        isLoading,
        viewMode,
        nbItems,
        totalItems,
        mode,
        url,
        searchComboBoxState,
        paginationSetting,
        onShowFrequentlyUsedLink,
        customNoRecordPanel,
        showFrequentlyUsedLink,
        errorMessage,
        onchangeFilter,
        showCannotFindPanel,
    } = props; // useContext(ItemPanelDataContext);

    useLayoutEffect(() => {
        const prefixElements = document.getElementsByClassName('searchComboBoxPrefix');
        if (prefixElements.length > 0) {
            const prefixElement = prefixElements[0];
            if (prefixElement) {
                setTimeout(() => {
                    const prefixWidth = prefixElement.clientWidth;
                    const elements = document.getElementsByClassName('ms-ComboBox-callout');
                    const divElement = elements[0] as HTMLDivElement;
                    if (divElement) {
                        const currentRight = Number.parseFloat(divElement.style.right.replace('px', ''));
                        if (memorizePosition.current !== currentRight) {
                            memorizePosition.current = Number.parseFloat((currentRight + prefixWidth).toFixed(3));

                            divElement.style.right = `${memorizePosition.current}px`;
                        }
                    }
                }, 1);
            }
        }
    });

    const pageInfo =
        paginationSetting?.currentPage != null && paginationSetting?.totalPage != null
            ? {
                  ...searchComboBoxState?.pageInfo,
                  currentPage: paginationSetting.currentPage,
                  totalPage: paginationSetting.totalPage,
                  paginationSetting: searchComboBoxState?.pageInfo ? searchComboBoxState.pageInfo.paginationSetting : paginationSetting,
              }
            : searchComboBoxState?.pageInfo;

    const NoRecordComponent = customNoRecordPanel ?? NoRecordPanel;

    return (
        <div className={clsx('comboBoxItemPanel', viewMode === 'Grid' ? 'gridMode' : '')}>
            {isLoading && nbItems === 0 ? (
                <div className='loading'>
                    <LoadingSpinner messageLabel='Recherche en cours...' />
                </div>
            ) : (
                <>
                    {nbItems === 0 ? (
                        <>
                            {(errorMessage ?? '') !== '' ? (
                                <ErrorPanel errorMessage={errorMessage} onchangeFilter={onchangeFilter} />
                            ) : (
                                <NoRecordComponent
                                    url={url ?? ''}
                                    showCannotFindPanel={showCannotFindPanel}
                                    onShowFrequentlyUsedLink={onShowFrequentlyUsedLink}
                                    showFrequentlyUsedLink={showFrequentlyUsedLink}
                                    onCreateNewItem={onCreateNewItem}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <div className='comboBoxPanelHeader'>
                                {mode === 'Search' ? (
                                    <>
                                        {pageInfo && pageInfo.totalPage > 0 ? (
                                            <Label className='topResult'>{`Total ${totalItems}`}</Label>
                                        ) : (
                                            <>
                                                <Label className='topResult'>{`Total ${totalItems}`}</Label>
                                                {totalItems !== nbItems && <Label>{`Total ${totalItems}`}</Label>}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Label className='topResult'>Récemment Utilisé</Label>
                                )}
                            </div>
                            {children}
                            {pageInfo && mode === 'Search' && pageInfo.totalPage > 0 && (
                                <Pagination
                                    itemsPerPage={pageInfo.paginationSetting.nbItemPerPage}
                                    currentPageIndex={pageInfo.currentPage}
                                    totalPageCount={pageInfo.totalPage}
                                    labels={{
                                        resultPerPage: undefined,
                                        totalRecord: undefined,
                                    }}
                                    totalRecordCount={totalItems}
                                    nbPagesShown={pageInfo.paginationSetting.nbPageShown}
                                    onNavigate={onNavigate}
                                />
                            )}
                        </>
                    )}
                    {isLoading && (
                        <div className='ItemPanelLoadingPanel'>
                            <div className='ItemPanelLoadingItem'>
                                <LoadingSpinner messageLabel={'Recherche en cours...'} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
