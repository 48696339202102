import { DraftHandleValue, Editor, EditorCommand, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { FC, useMemo, useRef, useState } from 'react';
import { RichTextEditorCommandPanel } from './Panel/RichTextEditorCommandPanel';
import { createCustomStyleMap } from './customStyleMap';

import clsx from 'clsx';
import { Intelisense } from '../../../Redux/Reducers/DynamiqueData/state';
import { colorsPaletteSelector, fontStylesSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IntelisenseData, IntelisensePopup } from './Intelisense/IntelisensePopup';
import { BracketExpressionCmd, CmdArrowDown, CmdArrowUp, CmdEnter, CmdTab, CommitSelectionCmd, IntelisenseCmd, keyCmdIntelisense } from './Intelisense/KeyCmdIntelisense';
import { addBracketExpression, addIntelisenseItem } from './Intelisense/Modifiers';
import { checkInsideExpressionBracket, checkInsideFunction, filterEntries, getCaretCoordinates, getSelectionContext, getTriggerRange } from './Intelisense/utils';
import { defaultCustomStyleFn } from './customBlockType';

export interface RichTextEditorProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    showPanel: boolean;
    showFocus: boolean;
    textAlignment?: 'left' | 'center' | 'right';
    onChangeTextAlignment?: (textAlignment?: 'left' | 'center' | 'right') => void;
    onEscape?(e: React.KeyboardEvent<unknown>): void;
    onTab?(e: React.KeyboardEvent<unknown>): void;
    onBlur?(e: React.SyntheticEvent<unknown>): void;
    onFocus?(e: React.SyntheticEvent<unknown>): void;
    handleKeyCommand?(command: EditorCommand, editorState: EditorState, eventTimeStamp: number): DraftHandleValue;
    intelisenseDataSource?: Intelisense;
    enabledBracketExpression?: boolean;
    disableReadOnlyData?: boolean;
    singleLine?: boolean;
    readonly?: boolean;
}

export const RichTextEditor: FC<RichTextEditorProps> = (props: RichTextEditorProps) => {
    const {
        editorState,
        onChangeState,
        showPanel,
        textAlignment,
        onChangeTextAlignment,
        onBlur,
        onFocus,
        handleKeyCommand,
        intelisenseDataSource,
        enabledBracketExpression,
        disableReadOnlyData,
        singleLine,
        showFocus,
        readonly,
    } = props;
    const colors = useAppSelector(colorsPaletteSelector);
    const fontStyles = useAppSelector(fontStylesSelector);
    const customStyleMap = useMemo(() => {
        return createCustomStyleMap(colors, fontStyles);
    }, [colors]);
    const editorRef = useRef<Editor>(null);
    const [intelisenseData, setIntelisenseData] = useState<IntelisenseData>();
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const insideExpression = useRef<boolean>(enabledBracketExpression ? false : true);

    const onChangeTriggerIntelisense = (ed: EditorState, shouldTrigger: boolean) => {
        const selectionContext = getSelectionContext(ed);
        if (enabledBracketExpression) {
            const isInsideExpression = checkInsideExpressionBracket(selectionContext);
            insideExpression.current = isInsideExpression;
        }
        if (insideExpression.current && intelisenseDataSource) {
            const triggerRange = getTriggerRange(selectionContext, shouldTrigger);
            if (triggerRange) {
                setIntelisenseData({
                    position: getCaretCoordinates(),
                    searchText: triggerRange.text,
                    triggerRange,
                    selectedIndex: 0,
                });
                return;
            }

            const func = checkInsideFunction(selectionContext, intelisenseDataSource);
            if (func) {
                setIntelisenseData({
                    position: getCaretCoordinates(),
                    searchText: func.triggerRange.text,
                    triggerRange: func.triggerRange,
                    selectedIndex: 0,
                    functionInfo: func.info,
                });
                return;
            }

            if (!triggerRange) {
                setIntelisenseData(undefined);
            }
        } else if (intelisenseData) {
            setIntelisenseData(undefined);
        }
    };

    const intelisenseEntries = useMemo(() => {
        if (intelisenseDataSource) {
            const result = filterEntries(intelisenseDataSource, intelisenseData?.searchText);

            if (result.results) {
                if (result.localFilter) {
                    const filtredResult = Object.entries(result.results)
                        .filter((e) => e[0].startsWith(result.localFilter ?? '') && (!disableReadOnlyData || e[1].type === 'var'))
                        .map((i) => {
                            return { name: i[0], info: i[1] };
                        });
                    if (filtredResult.length === 1) {
                        if (filtredResult[0].name === result.localFilter) {
                            return []; //When perfect match return empty list
                        }
                    }
                    return filtredResult;
                } else {
                    return Object.entries(result.results)
                        .filter((e) => !disableReadOnlyData || e[1].type === 'var')
                        .map((i) => {
                            return { name: i[0], info: i[1] };
                        });
                }
            }
        }
        return undefined;
    }, [intelisenseDataSource, intelisenseData?.searchText, disableReadOnlyData]);

    const addIntelisenteItemToText = (ed: EditorState, index: number | undefined = undefined) => {
        const selectedIndex = index !== undefined ? index : intelisenseData?.selectedIndex;
        if (intelisenseEntries && intelisenseData && selectedIndex !== undefined) {
            if (selectedIndex >= 0 && selectedIndex < intelisenseEntries.length) {
                setIntelisenseData(undefined);
                const { editorState: newState, refresh } = addIntelisenseItem(ed, intelisenseEntries[selectedIndex].info, intelisenseData.triggerRange);
                if (refresh) {
                    onChangeTriggerIntelisense(newState, false);
                }
                onChangeState(newState);
            }
            return true;
        }
        return false;
    };

    return (
        <div className={clsx('richEditor', showFocus ? 'showFocus' : '', isFocus && showFocus ? 'focus' : '')}>
            {showPanel ? (
                <RichTextEditorCommandPanel
                    refEditor={editorRef}
                    editorState={editorState}
                    onChangeState={onChangeState}
                    textAlignment={textAlignment}
                    onChangeTextAlignment={
                        onChangeTextAlignment
                            ? onChangeTextAlignment
                            : () => {
                                  return;
                              }
                    }
                />
            ) : (
                <></>
            )}
            <div
                draggable={true}
                onDragStart={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                }}
                onKeyUp={(ev) => {
                    if (ev.key === 'Delete') {
                        ev.stopPropagation();
                    }
                }}
            >
                <Editor
                    ref={editorRef}
                    readOnly={readonly}
                    customStyleMap={customStyleMap}
                    customStyleFn={defaultCustomStyleFn}
                    editorState={editorState}
                    textAlignment={textAlignment}
                    onChange={(ed: EditorState) => {
                        if (intelisenseDataSource) {
                            onChangeTriggerIntelisense(ed, false);
                        }
                        onChangeState(ed);
                    }}
                    keyBindingFn={(e) =>
                        keyCmdIntelisense(
                            e as React.KeyboardEvent<Record<string, unknown>>,
                            intelisenseData !== undefined && intelisenseEntries !== undefined && intelisenseEntries.length > 0,
                            (intelisenseEntries &&
                                intelisenseData &&
                                intelisenseData?.selectedIndex !== undefined &&
                                intelisenseData?.selectedIndex >= 0 &&
                                intelisenseData?.selectedIndex < intelisenseEntries.length) ||
                                singleLine === true,
                            enabledBracketExpression,
                        )
                    }
                    onBlur={(ev) => {
                        if (onBlur) onBlur(ev);
                        setIsFocus(false);
                    }}
                    onFocus={(ev) => {
                        if (onFocus) onFocus(ev);
                        setIsFocus(true);
                    }}
                    // onTab={(ev) => {
                    //     if (addIntelisenteItemToText(editorState)) {
                    //         ev.preventDefault();
                    //         ev.stopPropagation();
                    //         return;
                    //     }
                    //     if (onTab) {
                    //         onTab(ev);
                    //     }
                    // }}
                    // handleReturn={(ev, ed) => {
                    //     if (handleReturn) {
                    //         return handleReturn(ev, ed);
                    //     }
                    //     if (addIntelisenteItemToText(ed)) {
                    //         return 'handled';
                    //     }
                    //     return 'not-handled';
                    // }}
                    handleKeyCommand={(command, ed, eventTimeStamp) => {
                        if (intelisenseDataSource) {
                            if (insideExpression.current && command === IntelisenseCmd) {
                                onChangeTriggerIntelisense(ed, true);
                                return 'handled';
                            }
                            if (command === BracketExpressionCmd) {
                                const newState = addBracketExpression(ed);
                                if (newState) {
                                    insideExpression.current = true;
                                    onChangeState(newState);
                                }
                                return 'handled';
                            }
                            if (command === CommitSelectionCmd) {
                                if (addIntelisenteItemToText(ed)) {
                                    return 'handled';
                                }
                            }

                            if (command === CmdTab || command === CmdEnter) {
                                if (addIntelisenteItemToText(ed)) {
                                    return 'handled';
                                }
                            }
                            if (command === CmdArrowDown) {
                                if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                                    const max = intelisenseEntries.length;
                                    let selectedIndex = (intelisenseData?.selectedIndex ?? 0) + 1;
                                    if (selectedIndex > max - 1) {
                                        selectedIndex = max - 1;
                                    }
                                    setIntelisenseData({ ...intelisenseData, selectedIndex });
                                }
                            }
                            if (command === CmdArrowUp) {
                                if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                                    let selectedIndex = (intelisenseData?.selectedIndex ?? 0) - 1;
                                    if (selectedIndex < 0) {
                                        selectedIndex = 0;
                                    }
                                    setIntelisenseData({ ...intelisenseData, selectedIndex });
                                }
                            }
                        }

                        if (handleKeyCommand) {
                            return handleKeyCommand(command, ed, eventTimeStamp);
                        }
                        return 'not-handled';
                    }}
                    // onDownArrow={(ev) => {
                    //     if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                    //         ev.stopPropagation();
                    //         ev.preventDefault();
                    //         const max = intelisenseEntries.length;
                    //         let selectedIndex = (intelisenseData?.selectedIndex ?? 0) + 1;
                    //         if (selectedIndex > max - 1) {
                    //             selectedIndex = max - 1;
                    //         }
                    //         setIntelisenseData({ ...intelisenseData, selectedIndex });
                    //     }
                    // }}
                    // onUpArrow={(ev) => {
                    //     if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                    //         ev.stopPropagation();
                    //         ev.preventDefault();
                    //         let selectedIndex = (intelisenseData?.selectedIndex ?? 0) - 1;
                    //         if (selectedIndex < 0) {
                    //             selectedIndex = 0;
                    //         }
                    //         setIntelisenseData({ ...intelisenseData, selectedIndex });
                    //     }
                    // }}
                    // onEscape={onEscape}
                />
                {intelisenseEntries && intelisenseData ? (
                    <IntelisensePopup
                        dataSource={intelisenseEntries}
                        intelisenseData={intelisenseData}
                        onSelect={(index) => {
                            addIntelisenteItemToText(editorState, index);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};
