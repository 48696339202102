import { Link } from '@fluentui/react';
import { FC, useEffect } from 'react';
import { Accordion } from '../../common/components/Accordion';
import { FilterType } from '../../common/components/CustomDetailList/FilteredHeaderColumn';
import { Panel } from '../../common/components/Panel/Panel';
import { INavigationState, useNav } from '../../common/Hooks/useNav';
import { QuestionnaryResponsesGrid } from '../../Redux/Reducers/DataSet/ApiCall/QuestionnaryResponses';
import { screenPaths } from '../../Routing/ScreenPaths';
import { IQuestionnaryResultItem, QuestionnaryKind, ResourceTypeEnum } from '../../Services/SakuraApiClient';
import { getQuestionnaryKind } from '../Admin/Resources/Questionnary/QuestionnaryKind';
import { DatasetGrid } from '../Common/DatasetGrid';
import { QuestionnaryDetailsResponseView } from './QuestionnaryDetailsResponseView';
import './QuestionnaryResponseListView.scss';

interface NavState {
    refresh?: boolean;
}

export const QuestionnaryResponseListView: FC = () => {
    const nav = useNav();

    const navState = nav.current.state as INavigationState<NavState>;
    useEffect(() => {
        if (navState?.state?.refresh) {
            QuestionnaryResponsesGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const columns = [
        {
            key: 'questionnary',
            name: 'Questionnaire',
            fieldName: 'questionnary',
            minWidth: 250,
            maxWidth: 250,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'questionnary',
                        displayName: 'Questionnaire',
                    },
                ],
            },
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return (
                    <Link
                        onClick={() => {
                            nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Questionnary, item.questionnaryResourceId));
                        }}
                    >
                        {item.questionnaryResource?.name}
                    </Link>
                );
            },
        },
        {
            key: 'Compte',
            name: 'Compte',
            fieldName: 'accountName',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'accountName',
                        displayName: 'Compte',
                    },
                ],
            },
            onRender: (item: IQuestionnaryResultItem) => {
                return item.account?.accountId ? (
                    <div className='DivFlexVertical'>
                        <span>{item.account.email}</span>
                        <span>{`${item.account.lastname} ${item.account.lastname}`}</span>
                    </div>
                ) : (
                    <span>{'Anonyme'}</span>
                );
            },
        },
        {
            key: 'ContactBrevo',
            name: 'Contact Brevo',
            fieldName: 'ContactBrevo',
            minWidth: 90,
            maxWidth: 90,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return item.account?.brevoId ? (
                    <Link target='_blank' href={`https://app.brevo.com/contact/index/${item.account.brevoId}`}>{`Contact ${item.account.brevoId}`}</Link>
                ) : (
                    <span>{'--'}</span>
                );
            },
        },
        //
        {
            key: 'Type',
            name: 'Type',
            fieldName: 'Kind',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{getQuestionnaryKind(item.questionnaryResource.data.content.questionnary.kind)}</span>;
            },
        },
        {
            key: 'score',
            name: 'Score',
            fieldName: 'score',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{item.questionnaryResource.data.content.questionnary.kind === QuestionnaryKind.Exam ? `${item.score}%` : '-'}</span>;
            },
        },
        {
            key: 'category',
            name: 'Category',
            fieldName: 'category',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{item.questionnaryResource.data.content.questionnary.kind === QuestionnaryKind.Survey ? item.category : '-'}</span>;
            },
        },
        {
            key: 'startTime',
            name: 'Commencer',
            fieldName: 'startTime',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{item.startTime?.toLocaleDateString() ?? '-'}</span>;
            },
        },
        {
            key: 'endTime',
            name: 'Terminer',
            fieldName: 'endTime',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{item.endTime?.toLocaleDateString() ?? '-'}</span>;
            },
        },
        {
            key: 'elapsedTimeInSec',
            name: 'Temps passé',
            fieldName: 'elapsedTimeInSec',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return <span>{`${item.elapsedTimeInSec} sec`}</span>;
            },
        },
        {
            key: 'view',
            name: '',
            fieldName: 'view',
            minWidth: 50,
            maxWidth: 50,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IQuestionnaryResultItem) => {
                return (
                    <Link
                        onClick={() => {
                            nav.navigate(screenPaths.resourceView(ResourceTypeEnum.Questionnary, item.questionnaryResourceId));
                        }}
                    >
                        {'View'}
                    </Link>
                );
            },
        },
    ];
    return (
        <Panel
            title='Résulat de questionnaires'
            icon='quiz'
            commands={[
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        QuestionnaryResponsesGrid.refresh();
                    },
                },
            ]}
        >
            <div className='questionnaryDataGrid'>
                <DatasetGrid
                    datasetName={'QuestionnaryResponsesGrid'}
                    columns={columns}
                    rowClassname={'questionnaryRow'}
                    onRenderSubPanel={(item) => {
                        return (
                            <>
                                <div className='questionnarySubPanel'>
                                    <Accordion title='Voir les Réponses' lightMode>
                                        <QuestionnaryDetailsResponseView data={item as IQuestionnaryResultItem} />
                                    </Accordion>
                                </div>
                                <div className='questionnarySubPanelSeparator'></div>
                            </>
                        );
                    }}
                />
            </div>
        </Panel>
    );
};
