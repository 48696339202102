import { FC } from 'react';
import { IShareInfoData } from '../../../Services/SakuraApiClient';
import { ShareButton } from './ShareButton';
import './SocialNetworkShareBlock.scss';

export interface SocialNetworkShareBlockProps {
    title: string;
    message: string;
    url: string;
    share: IShareInfoData;
}
export const SocialNetworkShareBlock: FC<SocialNetworkShareBlockProps> = (props: SocialNetworkShareBlockProps) => {
    const { title, message, share, url } = props;
    return (
        <div className='SocialNetworkShareBlock'>
            <span>{title}</span>
            <p>{message}</p>
            <div className='shares'>
                <ShareButton target='facebook' url={url} hashTag={share.hashTag} />
                <ShareButton target='whatsapp' url={url} />
                <ShareButton target='email' url={url} content={share.emailContent} subject={share.emailObject} />
            </div>
        </div>
    );
};
