import { Label, TextField, Toggle } from '@fluentui/react';
import { FC, useCallback, useState } from 'react';
import { IResourceHierarchy, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { emptyAsUndefined, isNullOrEmpty } from '../../../../common/helpers/string';
import { SectionSettingLookup } from '../../System/SectionSetting/SectionSettingLookup';
import { WorkflowConfigurator } from '../Questionnary/WorkflowConfigurator';
import { PropertyUpdate, ResourceHierarchyAction } from './FormResourceHierarchySlicer';

export interface FormationEditDetailsProps {
    formationItem: IResourceHierarchy | undefined;
    updateForm: (action: ResourceHierarchyAction) => void;
}
export const FormationEditDetails: FC<FormationEditDetailsProps> = (props: FormationEditDetailsProps) => {
    const { formationItem, updateForm } = props;
    const [nameErr, setNameErr] = useState<string | undefined>(undefined);
    const updateProperties = useCallback(
        (propertyChanged: PropertyUpdate) => {
            if (formationItem && formationItem.resourcePath) {
                updateForm({
                    type: 'updateProperties',
                    payload: {
                        resourcePath: formationItem.resourcePath,
                        propertyChanged,
                    },
                });
            }
        },
        [formationItem, updateForm],
    );
    return (
        <Panel title='Propriétés de la formation' icon={GetResourceTypeIcon(ResourceTypeEnum.Formation)}>
            <div className='formationDetail'>
                <div>
                    <TextField
                        label='Nom'
                        required
                        errorMessage={nameErr}
                        value={formationItem?.resource.name ?? ''}
                        onChange={(_, v) => {
                            if (isNullOrEmpty(v)) {
                                setNameErr('Le nom est obligatoire');
                            } else {
                                setNameErr(undefined);
                            }

                            updateProperties({ resourcePart: { name: v } });
                        }}
                    />

                    <TextField
                        label='Titre'
                        value={formationItem?.resource.data?.content?.formation?.title ?? ''}
                        onChange={(_, v) => updateProperties({ formation: { title: emptyAsUndefined(v) } })}
                    />
                    <ResourceLookupField
                        label='Vignette de la formation'
                        type='ResourceImageContentLookup'
                        selectedResourceId={formationItem?.resource.data?.content?.formation?.pictureId}
                        onSelectResource={(resource) => {
                            if (resource) {
                                updateProperties({ formation: { pictureId: resource.id } });
                            } else {
                                updateProperties({ formation: { pictureId: undefined } });
                            }
                        }}
                    />
                </div>
                <div>
                    <Toggle
                        label='Afficher le nom/titre de la formation'
                        onText='Oui'
                        offText='Non'
                        checked={formationItem?.resource.data?.content?.formation?.showTitle ?? false}
                        onChange={(_, checked) => updateProperties({ formation: { showTitle: checked ?? undefined } })}
                    />
                </div>
                <FormGroup title='Workflows' icon={GetResourceTypeIcon(ResourceTypeEnum.Workflow)}>
                    <Label>{'Executé au démarrage de la formation'}</Label>
                    <WorkflowConfigurator workflow={formationItem?.resource.data?.workflowOnStart} onChangeWorkflow={(workflowOnStart) => updateProperties({ resourceData: { workflowOnStart } })} />
                    <Label>{'Executé A la fin de la formation'}</Label>
                    <WorkflowConfigurator
                        workflow={formationItem?.resource.data?.workflowOnCompleted}
                        onChangeWorkflow={(workflowOnCompleted) => updateProperties({ resourceData: { workflowOnCompleted } })}
                    />
                </FormGroup>
                <FormGroup title='Information de la tuile' icon='PreviewLink'>
                    <TextField required label='Nom' value={formationItem?.resource.uxData?.name ?? ''} onChange={(_, name) => updateProperties({ uxData: { name } })} />
                    <SectionSettingLookup required label='Section' value={formationItem?.resource.uxData?.section ?? ''} onChange={(section) => updateProperties({ uxData: { section } })} />
                    <TextField label='Description' value={formationItem?.resource.uxData?.description ?? ''} onChange={(_, description) => updateProperties({ uxData: { description } })} />
                    <ResourceLookupField
                        type='ResourceImageVignetteLookup'
                        label='Vignette'
                        selectedResourceId={formationItem?.resource.thumbnailResourceId}
                        onSelectResource={(resource) => updateProperties({ resourcePart: { thumbnailResourceId: resource?.id } })}
                    />
                </FormGroup>
            </div>
        </Panel>
    );
};
