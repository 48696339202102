import { Editor, EditorState } from 'draft-js';
import React, { FC, useMemo } from 'react';
import { fontStylesSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../Redux/hook';
import { SelectableIcon } from '../../Icons/SelectableIcon';
import { clearPresetFontStyle, getEditorPresetStyle, setEditorStyle } from './utils';

export interface RichEditStyleButtonProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    style: string;
    iconName: string;
    title: string;
    refEditor: React.RefObject<Editor>;
}

export const RichEditStyleButton: FC<RichEditStyleButtonProps> = (props: RichEditStyleButtonProps) => {
    const { editorState, onChangeState, style, iconName, title, refEditor } = props;

    const fontStyles = useAppSelector(fontStylesSelector);
    const isSelected = useMemo(() => {
        let inlineStyle = editorState.getCurrentInlineStyle().contains(style);
        const presetStyle = getEditorPresetStyle(editorState, fontStyles);
        if (presetStyle) {
            switch (style) {
                case 'BOLD':
                    inlineStyle = presetStyle.fontProperties?.weight === '700';
                    break;
                case 'ITALIC':
                    inlineStyle = presetStyle.fontProperties?.style?.toLocaleLowerCase() === 'italic';
                    break;
                case 'UNDERLINE':
                    inlineStyle = presetStyle.underline ?? false;
                    break;
                case 'STRIKE':
                    inlineStyle = presetStyle.strike ?? false;
                    break;
            }
        }
        return inlineStyle;
    }, [style, editorState]);

    return (
        <SelectableIcon
            iconName={iconName}
            title={title}
            selected={isSelected}
            onChange={() => {
                let newState = editorState;
                newState = clearPresetFontStyle(refEditor.current, newState, fontStyles);
                newState = setEditorStyle(refEditor.current, newState, style, style, style, isSelected);
                onChangeState(newState);
            }}
        />
    );
};
