import { FC } from 'react';
import { IResourceConsumerPackage, ResourceTypeEnum } from '../../../Services/SakuraApiClient';
import { AudioView } from '../Audio/AudioView';
import { FormationView } from '../Formation/FormationView';
import { ResourcePageView } from '../Page/ResourcePageView';
import { QuestionnaryUI } from '../Questionnary/QuestionnaryUI';
import { VideoView } from '../Video/VideoView';

export interface ResourceViewProps {
    resourceConsumerPackage: IResourceConsumerPackage;
}
export const ResourceView: FC<ResourceViewProps> = (props: ResourceViewProps) => {
    const { resourceConsumerPackage } = props;

    switch (resourceConsumerPackage.resource.resourceType) {
        case ResourceTypeEnum.Audio:
            return <AudioView resource={resourceConsumerPackage.resource} accountResourceData={resourceConsumerPackage.accountResourceData} />;
        case ResourceTypeEnum.Video:
            return <VideoView resource={resourceConsumerPackage.resource} accountResourceData={resourceConsumerPackage.accountResourceData} />;
        case ResourceTypeEnum.Formation:
            return (
                <FormationView
                    resource={resourceConsumerPackage.resource}
                    accountResourceData={resourceConsumerPackage.accountResourceData}
                    resourcePackage={resourceConsumerPackage.resourcePackage}
                    formationHierarchyView={resourceConsumerPackage.formationContent}
                />
            );
        case ResourceTypeEnum.Page:
            return <ResourcePageView resourceConsumerPackage={resourceConsumerPackage} />;
        case ResourceTypeEnum.Questionnary:
            return (
                <QuestionnaryUI
                    questionnary={resourceConsumerPackage.questionnary}
                    resourceId={resourceConsumerPackage.resource.id}
                    accountDataResource={resourceConsumerPackage.accountResourceData}
                    enablePersitance
                    showResult={{ value: 'true', protected: 'true' }}
                    onClosed={() => {}}
                />
            );
    }
    return <span>{'Invalid resource'}</span>;
};
