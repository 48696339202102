import { Dialog, DialogType, Icon, IIconProps, ProgressIndicator } from '@fluentui/react';
import React, { FC, PropsWithChildren } from 'react';
import { useLoadingSelector } from '../../../Redux/Reducers/System/reducer';
import { ErrorMessageBar } from '../ErrorMessageBar/ErrorMessageBar';

import './customDialog.scss';

export interface CustomDialogProps {
    id: string;
    icon?: IIconProps;
    title: string;
    subText?: string;
    hidden: boolean;
    isBlocking?: boolean;
    onDismiss: () => void;
    enableFlyingPopup?: boolean;
}
const modelProps = {
    isBlocking: false,
    containerClassName: 'custom-dialog',
    styles: { main: { maxWidth: 550, padding: 0 } },
};

export const CustomDialog: FC<PropsWithChildren<CustomDialogProps>> = (props: PropsWithChildren<CustomDialogProps>) => {
    const { title, subText, hidden, onDismiss, id, children, isBlocking, icon, enableFlyingPopup } = props;
    const loadingIndicator = useLoadingSelector(id);

    return (
        <Dialog
            hidden={hidden}
            onDismiss={onDismiss}
            dialogContentProps={{ type: DialogType.normal, showCloseButton: false }}
            modalProps={{ ...modelProps, isBlocking, className: enableFlyingPopup ? 'sakuraDialog' : undefined }}
        >
            <div className='customDialogHeader'>
                <ErrorMessageBar contextId={id} />
                {loadingIndicator?.isLoading ? <ProgressIndicator label={loadingIndicator?.loadingText} /> : <div style={{ height: 16 }} />}
            </div>
            <div className='customDialogHeaderPart'>
                {icon && <Icon {...icon} />}
                <span className='customDialogTitle'>{title}</span>
            </div>
            <div className='customDialogContentPart'>
                <span className='subText'>{subText}</span>
                {children}
            </div>
        </Dialog>
    );
};
