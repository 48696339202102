import { Icon, Separator } from '@fluentui/react';
import React, { CSSProperties, FC, useContext, useMemo } from 'react';
import { EnumBlockDirection, IPageElementSeparator } from '../../../../../Services/SakuraApiClient';
import './separator.scss';
import clsx from 'clsx';

export interface ElementSeparatorViewProps {
    data: IPageElementSeparator;
    direction?: EnumBlockDirection;
    editMode?: boolean;
}

export const ElementSeparatorView: FC<ElementSeparatorViewProps> = (props: ElementSeparatorViewProps) => {
    const { data, direction, editMode } = props;

    const vertical = useMemo(() => direction === EnumBlockDirection.Row, [direction]);

    const marginPercentage = useMemo(() => {
        return data.margin ? `${data.margin / 2}${vertical ? 'px' : '%'}` : undefined;
    }, [data.margin, vertical]);
    const style = useMemo((): React.CSSProperties => {
        return {
            background: data.color,
            height: vertical ? undefined : data.weight,
            width: vertical ? data.weight : undefined,
            borderRadius: data.borderRadius,
            marginLeft: vertical ? undefined : marginPercentage,
            marginRight: vertical ? undefined : marginPercentage,
            marginTop: vertical ? marginPercentage : undefined,
            marginBottom: vertical ? marginPercentage : undefined,
            boxShadow: data.boxShadow,
        };
    }, [data, vertical]);
    const size = data.weight + (data.padding ? data.padding * 2 : 0);
    return (
        <div className={clsx('separator', vertical ? 'vertical' : 'horizontal')} style={{ width: vertical ? size : undefined, height: vertical ? undefined : size, flex: editMode ? 1 : undefined }}>
            <div style={style} />
        </div>
    );
};
