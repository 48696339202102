import { FC } from 'react';
import { IPageElementCustomComponent } from '../../../../../Services/SakuraApiClient';
import { ShareButtonComponent } from './ShareButton';
import { SocialNetwork } from './SocialNetwork';
import { ThumbnailSection } from './ThumbnailsSection';
import { CustomComponentName } from './type';

export interface ElementCustomComponentProps {
    data: IPageElementCustomComponent;
    editMode?: boolean;
}

export const ElementCustomComponent: FC<ElementCustomComponentProps> = (props: ElementCustomComponentProps) => {
    const { data, editMode } = props;

    if (data.name) {
        switch (data.name as CustomComponentName) {
            case 'ShareButton': {
                //return <SocialNetworkShareBlock objectName={'ce cahier'} url={'https://coach.sakura-coaching.fr/67_idees_de_business_en_ligne'} />;
                return <ShareButtonComponent {...data.props} editMode={editMode} />;
            }
            case 'SocialNetwork': {
                return <SocialNetwork {...data.props} />;
            }
            case 'ThumbnailsSection':
                return <ThumbnailSection {...data.props} disableActions={editMode} />;
            default:
                return (
                    <>
                        <span>{`unsupported function ${data.name}`}</span>
                    </>
                );
        }
    }
    return <></>;
};
