import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { ContactAttributesLookup } from '../../Redux/Reducers/DataSet/ApiCall/Brevo';
import { useAppDispatch } from '../../Redux/hook';
import { IGetAttributesAttributes } from '../../Services/SakuraApiClient';
import { performApiCall } from '../Hooks/useApiCall';
import { DatasetLookupField } from './DatasetLookupField';

export interface ContactAttributeLookupFieldProps {
    contactAttributeName: string | undefined;
    onSelectContactAttribute: (contactList: IGetAttributesAttributes | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
    errorMessage?: string;
}
export const ContactAttributeLookupField: FC<ContactAttributeLookupFieldProps> = (props: ContactAttributeLookupFieldProps) => {
    const { label, contactAttributeName, onSelectContactAttribute, readOnly, required, contextId, errorMessage } = props;
    const [contactAttribute, setContactAttribute] = useState<IGetAttributesAttributes>();

    const appDispatch = useAppDispatch();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (contactAttributeName) {
            const attribute = ContactAttributesLookup.lastResult?.result?.find((r) => r.name === contactAttribute);
            if (attribute == undefined) {
                performApiCall(
                    async (client, cancelToken) => {
                        if (contactAttributeName) {
                            const attrs = await client.getContactAttributes(0, 200, cancelToken);
                            setContactAttribute((attrs.result ?? []).filter((s) => s.name === contactAttributeName)[0]);
                        }
                    },
                    appDispatch,
                    {
                        contextId: 'Default',
                        cancelToken: source.token,
                    },
                );
            } else {
                setContactAttribute(attribute);
            }
        } else {
            setContactAttribute(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [contactAttributeName]);
    return (
        <DatasetLookupField
            label={label}
            errorMessage={errorMessage}
            apiCall={ContactAttributesLookup}
            accessKeyProp={(i) => (i.name ?? '').toString()}
            accessTextProp={(i) => i.name ?? ''}
            selectedItem={contactAttribute}
            onSelectItem={(item) => onSelectContactAttribute(item)}
            readOnly={readOnly}
            required={required}
            contextId={contextId}
            disableTextSearch={true}
        />
    );
};
