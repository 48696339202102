import React, { FC } from 'react';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { Container } from '../../../../common/Hooks/StorageResolver';
import { ResourceFileDetails } from '../ResourceFileDetails';
import { v4 as uuidv4 } from 'uuid';

export interface ResourceDocumentDetailsProps {
    resource?: IResource;
    show: boolean;
    category?: string;
    onClose: (resource: IResource | undefined) => void;
}

export const ResourceDocumentDetails: FC<ResourceDocumentDetailsProps> = (props: ResourceDocumentDetailsProps) => {
    const { resource, show, onClose, category } = props;

    return (
        <ResourceFileDetails
            blobnameResolver={(_, filename) => `/documents/${uuidv4()}_${filename}`}
            container={Container.Private}
            contentDisposition='inline'
            onClose={onClose}
            resource={resource}
            category={category}
            show={show}
            showThumbnail
            type={ResourceTypeEnum.Document}
            showOptionEnabled
        />
    );
};
