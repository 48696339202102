import { IColorCellProps } from '@fluentui/react';
import clsx from 'clsx';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import './SwatchColorPicker.scss';

export interface ISwatchColorPickerPropsProps {
    selectedId?: string;
    onChange?: (event: React.FormEvent<HTMLDivElement>, id: string | undefined, color: string | undefined) => void;
    colorCells: IColorCellProps[];
    disableGradiantColorSelection?: boolean;
}

export const SwatchColorPicker: FC<ISwatchColorPickerPropsProps> = (props: ISwatchColorPickerPropsProps) => {
    const { colorCells, onChange, selectedId, disableGradiantColorSelection } = props;
    return (
        <div className='swatchColorPicker'>
            {colorCells
                .filter((c) => !disableGradiantColorSelection || c.color.startsWith('#'))
                .map((c) => {
                    return (
                        <div
                            key={c.id}
                            className={clsx('colorItem', c.id === selectedId ? 'selected' : '')}
                            title={c.label}
                            style={{ background: c.color }}
                            onClick={(ev) => {
                                if (onChange) {
                                    onChange(ev, c.id, c.color);
                                }
                            }}
                        />
                    );
                })}
        </div>
    );
};
