import { FC, useCallback, useMemo, useState } from 'react';
import { Account, AccountCreationPayload, AccountUpdateByAdminPayload, IAccount, RoleEnum } from '../../../Services/SakuraApiClient';

import { Dropdown, IDropdownOption, Separator, TextField, Toggle } from '@fluentui/react';
import { useAppDispatch } from '../../../Redux/hook';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { required, useForm } from '../../../common/Hooks/Form';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useNav } from '../../../common/Hooks/useNav';
import { DatetimePicker } from '../../../common/components/DatetimePicker/DatetimePicker';
import { FormGroup } from '../../../common/components/FormGroup/FormGroup';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import { Panel, PanelCommand } from '../../../common/components/Panel/Panel';

import { useNavigationEntity } from '../../../common/Hooks/useNavigationEntity';
import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { LinkExtended } from '../../../common/components/LinkExtended/LinkExtended';

export const rolesLabels: Record<string, string> = {
    Admin: 'Administrateur Global',
    OrganisationAdmin: "Administrateur d'organisation",
    PremiumMember: 'Membre Premium',
    Member: 'Membre',
    Prospect: 'Prospect',
};

const roles: IDropdownOption[] = [
    {
        key: RoleEnum.Admin,
        text: rolesLabels[RoleEnum.Admin.toString()],
    },
    {
        key: RoleEnum.OrganisationAdmin,
        text: rolesLabels[RoleEnum.OrganisationAdmin.toString()],
    },
    {
        key: RoleEnum.PremiumMember,
        text: rolesLabels[RoleEnum.PremiumMember.toString()],
    },
    {
        key: RoleEnum.Member,
        text: rolesLabels[RoleEnum.Member.toString()],
    },
];
const getNewAccount = () => {
    const newResource = new Account();
    newResource.init({ enabled: true });
    return newResource;
};
export const AccountDetail: FC = () => {
    const [id, account] = useNavigationEntity((id, client) => client.getAccount(id as string));
    return id && !account ? <></> : <AccountDetailContent account={account}></AccountDetailContent>;
};
interface AccountDetailContentProps {
    account: IAccount | undefined;
}
export const AccountDetailContent: FC<AccountDetailContentProps> = (props: AccountDetailContentProps) => {
    const { account } = props;
    const nav = useNav();
    const appDispatch = useAppDispatch();
    const createMode = account == undefined;
    const [connectionLink, setConnectionLink] = useState<string>();
    const form = useForm<IAccount>(
        {
            initialState: account ?? getNewAccount(),
            validators: {
                fields: {
                    displayName: { displayName: 'Le nom affichage', validators: [required] },
                    firstname: { displayName: 'Le prénom', validators: [required] },
                    lastname: { displayName: 'Le nom', validators: [required] },
                    signInEmail: { displayName: "L'identifiant de connexion", validators: [required] },
                },
            },
        },
        account !== undefined,
    );
    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.accountList)) {
                nav.navigate(screenPaths.accountList, refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );
    const onSave = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client) => {
                if (account) {
                    const dataUpdated = new AccountUpdateByAdminPayload();
                    dataUpdated.init(form.state);
                    await client.updateAccount(account.oid ?? '', dataUpdated);
                    form.commit();
                    nav.navigate(screenPaths.accountList, { refresh: true });
                } else {
                    const data = new AccountCreationPayload();
                    data.init(form.state);
                    const response = await client.createAccount(data);
                    setConnectionLink(response.firstConnexionLink);
                    form.commit();
                }
            }, appDispatch);
        }
    };
    const accountCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: account ? 'Sauvegarder' : 'Créer',
                icon: 'Save',
                onClick: () => {
                    onSave();
                },
            },
        ];
    }, [form, onSave]);

    return (
        <>
            <Panel
                isContentModified={form.isModified}
                revertContentModification={form.rollback}
                backButtonCommand={() => (nav.from ? goBack(false) : nav.navigate(screenPaths.accountList))}
                title='Compte'
                icon='Contact'
                commands={accountCommand}
            >
                <FormGroup isCollapsable title={'Informations'} defaultOpenState={true}>
                    <div className='SakuraPanelsGrid3'>
                        <TextField
                            disabled={!createMode}
                            label='Nom'
                            errorMessage={form.fieldErrors.lastname}
                            required={createMode}
                            value={form.state.lastname ?? ''}
                            onChange={(_, v) => form.update({ lastname: v })}
                        />
                        <TextField
                            disabled={!createMode}
                            label='Prénom'
                            errorMessage={form.fieldErrors.firstname}
                            required={createMode}
                            value={form.state.firstname ?? ''}
                            onChange={(_, v) => form.update({ firstname: v })}
                        />
                        <TextField
                            disabled={!createMode}
                            label="Nom d'affichage"
                            errorMessage={form.fieldErrors.displayName}
                            required={createMode}
                            value={form.state.displayName ?? ''}
                            onChange={(_, v) => form.update({ displayName: v })}
                        />
                        <TextField
                            disabled={!createMode}
                            label='Email de connexion'
                            errorMessage={form.fieldErrors.signInEmail}
                            required={createMode}
                            value={form.state.signInEmail ?? ''}
                            onChange={(_, v) => form.update({ signInEmail: v })}
                        />
                        <Dropdown label='Rôle' required options={roles} selectedKey={form.state.userRole} onChange={(_, option) => form.update({ userRole: option?.key as RoleEnum })}></Dropdown>
                        <Toggle label='Etat' onText='Actif' offText='Désactivé' checked={form.state.enabled} onChange={(_, checked) => form.update({ enabled: checked })}></Toggle>
                        <DatetimePicker label='Expiration du compte le' value={form.state.expiredOn} onSelectDate={(v) => form.update({ expiredOn: v })} />
                    </div>
                </FormGroup>
                <Separator />
                <FormGroup isCollapsable defaultOpenState={true} title={'Autres (lecture seule)'}>
                    <div className='SakuraPanelsGrid3'>
                        <TextField readOnly iconProps={{ iconName: 'Mail' }} label='Email de contact' value={form.state.contactEmail ?? ''} />
                        <TextField
                            readOnly
                            onRenderSuffix={(_, df) => {
                                return <img src='/images/sn/facebook.svg' alt='FaceBook logo' height={24} />;
                            }}
                            styles={{ suffix: { padding: '0 5px', background: 'none' } }}
                            label='Compte FaceBook'
                            value={form.state.facebookId ?? ''}
                        />
                        <TextField readOnly label='Mon Pseudo' value={form.state.pseudo ?? ''} />
                        <NumberField label='Pétale' value={form.state.sakuraCoin} readOnly />
                        <NumberField label='Diamant' value={form.state.sakuraDiamond} readOnly />
                        <NumberField label='Experience' value={form.state.experience} readOnly />
                        <NumberField label='Level' value={form.state.level} readOnly />
                        <NumberField label='Brevo Identifier' value={form.state.sendingBlueId} readOnly />
                        <Toggle label={'Email Black listé'} onText='Oui' offText='Non' checked={form.state.sendingBlueEmailBacklisted}></Toggle>
                        <TextField readOnly label='Canal de souscription' value={form.state.prospectSubscriptionContextEnum ?? ''} />
                    </div>
                </FormGroup>

                {connectionLink && (
                    <SimpleDialog
                        id='createAccount'
                        title='Création de compte'
                        icon={{ iconName: 'Contact' }}
                        show={connectionLink !== undefined}
                        onClose={() => {
                            setConnectionLink(undefined);
                            if (!nav.from) {
                                nav.navigate(screenPaths.accountList, { refresh: true });
                            } else if (nav.from) {
                                nav.goBack();
                            }
                        }}
                    >
                        <>
                            <span>Le compte a été créé.</span>
                            <Separator />
                            <LinkExtended label='Lien de premiere connexion' value={connectionLink} text='Cliquer pour se connecter' copyMessage='Le lien a été copier dans le presse-papiers' />
                        </>
                    </SimpleDialog>
                )}
            </Panel>
        </>
    );
};
