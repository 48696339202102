import { FC } from 'react';

import { EmailSB } from './ShareButtons/EmailSB';
import { FacebookSB } from './ShareButtons/FacebookSB';
import { MessengerSB } from './ShareButtons/MessengerSB';
import { PinterestSB } from './ShareButtons/PinterestSB';
import { WhatsAppSB } from './ShareButtons/WhatsAppSB';
import { XSB } from './ShareButtons/XSB';
import './shareButton.scss';
import { SocialNetworkNameShareable } from './type';

export interface ShareButtonProps {
    target: SocialNetworkNameShareable;
    url: string | undefined;
    hashTag?: string | undefined;
    content?: string | undefined;
    subject?: string | undefined;
    media?: string | undefined;
}
export const ShareButton: FC<ShareButtonProps> = (props: ShareButtonProps) => {
    const { target, url, hashTag, content, subject, media } = props;

    if (url) {
        switch (target) {
            case 'facebook':
                return <FacebookSB url={url} hashTag={hashTag} />;
            case 'pinterest':
                return <PinterestSB url={url} media={media} />;
            case 'whatsapp':
                return <WhatsAppSB url={url} />;
            case 'messenger':
                return <MessengerSB url={url} />;
            case 'x':
                return <XSB url={url} hashTag={hashTag} />;
            case 'email':
                return <EmailSB url={url} content={content} subject={subject} />;
            default:
        }
    }
    return null;
};
