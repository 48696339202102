import React, { FC } from 'react';

export interface SvgViewerProps {
    content: string;
    color?: string;
    width?: number;
    height?: number;
}
export const SvgViewer: FC<SvgViewerProps> = (props: SvgViewerProps) => {
    const { content, color, width, height } = props;
    return <svg data-src={content} style={{ fill: color, width, height }}></svg>;
};
