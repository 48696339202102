import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IResourceHierarchy } from '../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ToolBoxPanel } from '../Page/ToolBoxPanel';
import { ResourceHierarchyAction } from './FormResourceHierarchySlicer';
import { TableOfContentItem } from './TableOfContentItem';

export interface TableOfContentProps {
    resourceHierachy: DeepPartial<IResourceHierarchy>;
    updateForm: (action: ResourceHierarchyAction) => string | undefined;
    selectionPath: string | undefined;
    onSelect: (resourcePath: string | undefined) => void;
}
export const TableOfContent: FC<TableOfContentProps> = (props: TableOfContentProps) => {
    const { resourceHierachy, updateForm, selectionPath, onSelect } = props;

    return (
        <ToolBoxPanel title='Contenu' icon='CustomList' width={275}>
            <DndProvider backend={HTML5Backend}>
                <div className='tableOfContent'>
                    {resourceHierachy ? (
                        <TableOfContentItem root key={resourceHierachy.resourcePath} resourceItem={resourceHierachy} updateForm={updateForm} selectionPath={selectionPath} onSelect={onSelect} />
                    ) : (
                        <>Vide</>
                    )}
                </div>
            </DndProvider>
        </ToolBoxPanel>
    );
};
