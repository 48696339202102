import { CancelToken } from 'axios';
import { RoleEnum, SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.getAccounts(
        newState.pageIndex,
        newState.pageSize,
        newState.filters['signInEmail'] as string | undefined,
        newState.filters['lastname'] as string | undefined,
        newState.filters['userRole'] as RoleEnum | undefined,
        cancelToken,
    );
};
export const AccountGrid = new ApiCall('AccountGrid', query);
