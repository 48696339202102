import { FC, useState } from 'react';
import { IProductTile } from '../../../../../../../Services/SakuraApiClient';

import { ProductTileGrid, ProductTileWhishlistGrid } from '../../../../../../../Redux/Reducers/DataSet/ApiCall/Product';
import { useAppDispatch } from '../../../../../../../Redux/hook';
import { performApiCall } from '../../../../../../../common/Hooks/useApiCall';
import { CircleButton } from '../../../../../../../common/components/CircleButton';
import './ThumbnailItemDetails.scss';
export interface ThumbnailItemResourceProps {
    data: IProductTile;
    onOpen: () => Promise<void>;
}
export const ThumbnailItemProduct: FC<ThumbnailItemResourceProps> = (props: ThumbnailItemResourceProps) => {
    const { data, onOpen } = props;
    const appDispatch = useAppDispatch();
    const [isLoadingOperationList, setIsLoadingOperationList] = useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

    return (
        <>
            <div className='commandBar'>
                <CircleButton
                    toolTip={'Acheter'}
                    iconName={'ShoppingCart'}
                    size='medium'
                    isLoading={isLoadingAction}
                    onClick={async () => {
                        setIsLoadingAction(true);
                        await onOpen();
                        setIsLoadingAction(false);
                    }}
                />
                {data.inList ? (
                    <CircleButton
                        toolTip={'Retirer de ma liste de souhaits'}
                        iconName={'Cancel'}
                        size='medium'
                        isLoading={isLoadingOperationList}
                        onClick={() => {
                            setIsLoadingOperationList(true);
                            performApiCall(async (client, ct) => {
                                try {
                                    await client.deleteProductFromWhishList(data.id, ct);
                                    ProductTileGrid.PatchData<IProductTile>((item: IProductTile) => {
                                        if (item.id === data.id) {
                                            return { ...item, inList: false };
                                        }
                                        return item;
                                    });
                                    ProductTileWhishlistGrid.refresh();
                                } finally {
                                    setIsLoadingOperationList(false);
                                }
                            }, appDispatch);
                        }}
                    />
                ) : (
                    <CircleButton
                        toolTip={'Ajouter à ma liste de souhaits'}
                        iconName={'Add'}
                        size='medium'
                        isLoading={isLoadingOperationList}
                        onClick={() => {
                            setIsLoadingOperationList(true);
                            performApiCall(async (client, ct) => {
                                try {
                                    await client.addProductToWhishList(data.id, ct);
                                    ProductTileGrid.PatchData<IProductTile>((item) => {
                                        if (item.id === data.id) {
                                            return { ...item, inList: true };
                                        }
                                        return item;
                                    });
                                    ProductTileWhishlistGrid.refresh();
                                } finally {
                                    setIsLoadingOperationList(false);
                                }
                            }, appDispatch);
                        }}
                    />
                )}
            </div>

            <h3>{data.uxData?.name ?? data.name ?? ''}</h3>
            <span>{data.uxData?.description ?? ''}</span>
        </>
    );
};
