import { ActionButton, Dropdown, MessageBar, MessageBarType, Pivot, PivotItem, Separator, TextField } from '@fluentui/react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import {
    Answer,
    IAnswer,
    ICategoryPoint,
    IQuestion,
    IResource,
    IResourceData,
    IResourceDataQuestionnary,
    Question,
    QuestionTypeEnum,
    QuestionnaryKind,
    Resource,
    ResourceContent,
    ResourceData,
    ResourceDataQuestionnary,
    ResourceTypeEnum,
} from '../../../../Services/SakuraApiClient';
import { useForm } from '../../../../common/Hooks/Form';
import { DeepPartial, DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import * as validators from '../../../../common/Hooks/Form/validators';
import { performApiCall, useApiCall } from '../../../../common/Hooks/useApiCall';
import { useNav } from '../../../../common/Hooks/useNav';
import { useNavigationEntity } from '../../../../common/Hooks/useNavigationEntity';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel, PanelCommand } from '../../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { QuestionnaryDataContext, QuestionnaryDataContextProvider } from './DataContext/QuestionnaryDataContextProvider';
import { QuestionEdit, shuffleAnswer } from './QuestionEdit';
import { QuestionnaryKinds } from './QuestionnaryKind';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getVariablesNames } from '../../../../Redux/Reducers/DynamiqueData/helpers';
import { loadScope, useIntelisenseWritableDataOfScope } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { setMessage, setPopupMessage } from '../../../../Redux/Reducers/System/reducer';
import { PopupMessage } from '../../../../Redux/Reducers/System/state';
import { useRefLink } from '../../../../common/Hooks/useRefLink';
import { ContactAttributeLookupField } from '../../../../common/LookupFields/ContactAttributeLookupField';
import { ContactListLookupField } from '../../../../common/LookupFields/ContactListLookupField';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { PivotItemDraggable } from '../../../../common/components/PivotItemDraggable';
import { scopeBuilder } from '../../../Common/Page/hook/ScopeBuilder/scopeBuilder';
import { useLoadScopeDynamicData } from '../../../Common/Page/hook/useLoadScopeDynamicData';
import { Categories } from './Categories';
import { QuestionnaryGenerateLink } from './QuestionnaryGenerateLink';
import { QuestionnaryTester } from './QuestionnaryTester';
import { questionnaryValidateModel } from './QuestionnaryValidateModel';
import { ResourceQuestionnaryInfoDialog } from './ResourceQuestionnaryInfoDialog';
import { Score } from './Score';

const getNewQuestionnary = () => {
    const newResource = new Resource();
    newResource.init({ enabled: true, resourceType: ResourceTypeEnum.Questionnary });
    newResource.name = 'Questionnaire';
    newResource.reusable = false;
    newResource.data = new ResourceData();
    newResource.data.content = new ResourceContent();
    newResource.data.content.questionnary = new ResourceDataQuestionnary();
    newResource.data.content.questionnary.kind = QuestionnaryKind.Exam;
    return newResource;
};
export const ResourceQuestionnaryDetails: FC = () => {
    const [id, resource] = useNavigationEntity((id, client) => client.getResource(id as number));
    return id && !resource ? (
        <></>
    ) : (
        <QuestionnaryDataContextProvider initialContext={{ categories: resource?.data?.content?.questionnary?.categoriesDesc?.map((c) => ({ name: c.name ?? 'sans nom', id: c.id ?? '' })) ?? [] }}>
            <QuestionnaryDetailContent resource={resource}></QuestionnaryDetailContent>
        </QuestionnaryDataContextProvider>
    );
};
interface QuestionnaryDetailContentProps {
    resource: IResource | undefined;
}

function setModel(data: DeepPartialWithArrayFunc<IResourceDataQuestionnary>) {
    return { data: { content: { questionnary: data } } };
}
function getModel(data: DeepPartial<IResource>) {
    return data?.data?.content?.questionnary;
}
const confirmationSavePopup: PopupMessage = { icon: 'SkypeCircleCheck', title: 'Sauvegarde', subTitle: 'Questionnaire sauvegardé avec succés.', timeout: 1000 };

export const QuestionnaryDetailContent: FC<QuestionnaryDetailContentProps> = (props: QuestionnaryDetailContentProps) => {
    const { resource } = props;
    const nav = useNav();
    const appDispatch = useAppDispatch();
    const context = useContext(QuestionnaryDataContext);
    const [hasResponse, setHasResponse] = useState<boolean>(false);
    const [showGenerateLinkDialog, setShowGenerateLinkDialog] = useState<boolean>(false);
    const [showQuestionnaryTesterDialog, setShowQuestionnaryTesterDialog] = useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(resource?.data?.content?.questionnary?.questions ? resource?.data?.content?.questionnary?.questions[0].id : undefined);
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
        resource?.data?.content?.questionnary?.categoriesDesc ? resource?.data?.content?.questionnary?.categoriesDesc[0].id : undefined,
    );
    const [showEditInfo, setShowEditInfo] = useState<boolean>(false);
    useApiCall(async (client, cancelToken) => {
        if (resource?.id) {
            const val = await client.questionnaryHasResponse(resource.id, cancelToken);
            setHasResponse(val);
        }
    }, []);
    const form = useForm<IResource>(
        {
            initialState: resource ?? getNewQuestionnary(),
            validators: {
                fields: {
                    data: {
                        content: {
                            questionnary: {
                                examPassedThreshold: { displayName: 'Le seuil de réussite', validators: [validators.requiredIf((model) => model?.kind === QuestionnaryKind.Exam)] },
                                examMode: { displayName: 'Le type de notation ', validators: [validators.requiredIf((model) => model?.kind === QuestionnaryKind.Exam)] },
                                interviewCalendarLink: { displayName: 'Le lien de prise de rendez-vous ', validators: [validators.requiredIf((model) => model?.kind === QuestionnaryKind.Interview)] },
                                questions: {
                                    id: { displayName: "L'identifiant", validators: [validators.required] },
                                    text: { displayName: "L'intitulé", validators: [validators.required] },
                                    type: { displayName: 'Le type', validators: [validators.required] },
                                    answers: {
                                        _array: { validators: [] },
                                        text: { displayName: 'La réponse', validators: [validators.requiredIf((model) => model?.imageResourceId === undefined)] },
                                    },
                                },
                            },
                        },
                    },
                },

                global: questionnaryValidateModel,
            },
        },
        resource !== undefined,
        undefined,
        `questionnary_${resource ? resource.id : 'new'}`,
    );

    const formRef = useRefLink(form);
    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.resourceList(ResourceTypeEnum.Questionnary))) {
                nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Questionnary), refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );

    useEffect(() => {
        const message = form.globalErrors ? form.globalErrors.join('\n') : undefined;
        appDispatch(setMessage({ contextId: 'default', message: message ? { text: message, Severity: MessageBarType.blocked } : undefined }));
    }, [form.globalErrors]);

    const onSave = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client, ct) => {
                if (resource?.id) {
                    const resourceUpdated = new Resource();
                    resourceUpdated.init(form.state);
                    if (resourceUpdated.data.content.questionnary.customStartPageId !== undefined) {
                        resourceUpdated.data.content.questionnary.title = '';
                        resourceUpdated.data.content.questionnary.imageResourceId = undefined;
                        resourceUpdated.data.content.questionnary.subTitle = undefined;
                    }
                    await client.updateResource(resource?.id, resourceUpdated, ct);
                    form.commit();
                    // goBack(true);
                    appDispatch(setPopupMessage({ message: confirmationSavePopup }));
                } else {
                    const res = new Resource();
                    res.init(form.state);
                    if (res.data.content.questionnary.customStartPageId !== undefined) {
                        res.data.content.questionnary.title = '';
                        res.data.content.questionnary.imageResourceId = undefined;
                        res.data.content.questionnary.subTitle = undefined;
                    }
                    const response = await client.createResource(res);
                    form.commit();
                    //appDispatch(setPopupMessage({ message: confirmationSavePopup }));
                    goBack(true);
                }
            }, appDispatch);
        }
    };
    const resourceCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: resource ? 'Sauvegarder' : 'Créer',
                icon: 'Save',
                onClick: () => {
                    onSave();
                },
            },
            {
                text: 'Renommer',
                icon: 'Edit',
                onClick: () => {
                    setShowEditInfo(true);
                },
            },
            {
                text: 'Tester',
                icon: 'EntryView',
                disabled: !resource || form.isModified,
                onClick: () => {
                    setShowQuestionnaryTesterDialog(true);
                },
            },
            {
                text: "Générer un lien d'accés",
                icon: 'Link',
                disabled: !resource || form.isModified,
                onClick: () => {
                    setShowGenerateLinkDialog(true);
                },
            },
        ];
    }, [form, onSave]);
    const questionCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'CircleAddition',
                disabled: hasResponse,
                onClick: () => {
                    const model = getModel(form.state);
                    const question = new Question();
                    question.type = QuestionTypeEnum.YesNo;
                    question.id = `Q${(model?.questions ?? []).length + 1}`;
                    setSelectedQuestion(question.id);
                    const questions = ArrayOperation.add(model?.questions ?? [], question);
                    form.update(setModel({ questions }));
                },
            },
            {
                text: 'Dupliquer',
                icon: 'Copy',
                disabled: selectedQuestion == undefined || hasResponse,
                onClick: () => {
                    const model = getModel(form.state);
                    const question = new Question();
                    const questionSelected = (model?.questions ?? []).filter((q) => q.id === selectedQuestion)[0];
                    question.init(questionSelected);
                    question.id = `Q${(model?.questions ?? []).length + 1}`;
                    if (question.answers) {
                        question.answers = question.answers.map((a, index) => {
                            const newAnswer = new Answer();
                            newAnswer.init({ ...a, id: `${question.id}R${index}` });
                            return newAnswer;
                        });
                    }
                    setSelectedQuestion(question.id);
                    const questions = ArrayOperation.add(model?.questions ?? [], question);
                    form.update(setModel({ questions }));
                },
            },
            {
                text: 'Mélanger les réponses de toutes les questions',
                icon: 'PageListFilter',
                disabled: hasResponse,
                onClick: () => {
                    form.update(
                        setModel({
                            questions: (arrayQuestion) => {
                                const newArray = [...arrayQuestion];
                                newArray.forEach((q, index) => {
                                    if (q.type === QuestionTypeEnum.Choice || q.type === QuestionTypeEnum.MultiChoice) {
                                        const question = new Question();
                                        question.init({ ...q, answers: shuffleAnswer(q.answers as DeepPartialWithArrayFunc<IAnswer>[], q.id ?? '') });
                                        newArray[index] = question;
                                    }
                                });
                                return newArray;
                            },
                        }),
                    );
                },
            },
            {
                text: 'Effacer',
                icon: 'Delete',
                disabled: selectedQuestion == undefined || hasResponse,
                onClick: () => {
                    const model = getModel(form.state);

                    const questions = ArrayOperation.remove(model?.questions ?? [], (q) => q.id === selectedQuestion);
                    for (let i = 0; i < questions.length; i++) {
                        questions[i] = { ...questions[i], id: `Q${i + 1}` };
                    }
                    form.update(setModel({ questions: questions }));
                    //if (model?.questions?.length === 1) {
                    setSelectedQuestion(undefined);
                    //}
                },
            },
        ];
    }, [form, selectedQuestion, hasResponse]);
    const swapQuestion = useCallback(
        (currentIndex: number, targetIndex: number) => {
            form.update(
                setModel({
                    questions: (array) => {
                        const newArray = [...array];
                        const src = newArray[currentIndex];
                        const dst = newArray[targetIndex];
                        newArray[targetIndex] = src;
                        newArray[currentIndex] = dst;
                        src.id = `Q${targetIndex + 1}`;
                        dst.id = `Q${currentIndex + 1}`;
                        return newArray;
                    },
                }),
            );
        },
        [form],
    );

    const categoryCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'CircleAddition',
                disabled: hasResponse,
                onClick: () => {
                    const model = getModel(form.state);
                    const name = `category ${(model?.categoriesDesc?.length ?? 0) + 1}`;
                    const id = `C${(model?.categoriesDesc?.length ?? 0) + 1}`;
                    setSelectedCategory(id);
                    context.upsertCategory({ id, name });
                    form.update(
                        setModel({
                            categoriesDesc: (array) => {
                                return ArrayOperation.add(array, {
                                    name,
                                    id,
                                    descriptions: [
                                        {
                                            threshold: 0,
                                        },
                                    ],
                                });
                            },
                        }),
                    );
                },
            },
            {
                text: 'Dupliquer',
                icon: 'Copy',
                disabled: selectedCategory == undefined || hasResponse,
                onClick: () => {
                    const model = getModel(form.state);
                    const categorySelected = (model?.categoriesDesc ?? []).filter((c) => c.id === selectedCategory)[0];
                    if (categorySelected) {
                        const name = `category ${(model?.categoriesDesc?.length ?? 0) + 1}`;
                        const id = `C${(model?.categoriesDesc?.length ?? 0) + 1}`;
                        setSelectedCategory(id);
                        context.upsertCategory({ id, name });
                        form.update(
                            setModel({
                                categoriesDesc: (array) => {
                                    return ArrayOperation.add(array, {
                                        name,
                                        id,
                                        descriptions: categorySelected.descriptions ? [...categorySelected.descriptions] : [],
                                    });
                                },
                            }),
                        );
                    }
                },
            },
            {
                text: 'Effacer',
                icon: 'Delete',
                disabled: selectedCategory == undefined || hasResponse,
                onClick: () => {
                    form.update(
                        setModel({
                            categoriesDesc: (array) => {
                                return ArrayOperation.remove(array, (c) => c.id === selectedCategory);
                            },
                            questions: (questionArray) => {
                                return questionArray.map((q) => {
                                    const qModified: DeepPartial<IQuestion> = {};
                                    if (q.yes?.categories) {
                                        const categories = q.yes.categories as ICategoryPoint[];
                                        qModified.yes = { categories: categories.map((c) => (c.id == selectedCategory ? { id: undefined, points: undefined } : c)) };
                                    }
                                    if (q.no?.categories) {
                                        const categories = q.no.categories as ICategoryPoint[];
                                        qModified.no = { categories: categories.map((c) => (c.id == selectedCategory ? { id: undefined, points: undefined } : c)) };
                                    }
                                    if (q.answers) {
                                        const answerArray = q.answers as IAnswer[];
                                        qModified.answers = answerArray.map((answer) => {
                                            return {
                                                ...answer,
                                                categories: answer.categories ? answer.categories.map((c) => (c.id == selectedCategory ? { id: undefined, points: undefined } : c)) : undefined,
                                            };
                                        });
                                    }
                                    const modified = { ...q, ...qModified };
                                    return modified;
                                });
                            },
                        }),
                    );
                    if (selectedCategory) {
                        context.removeCategory(selectedCategory);
                    }
                    if (getModel(form.state)?.categoriesDesc?.length === 1) {
                        setSelectedCategory(undefined);
                    }
                },
            },
        ];
    }, [form, selectedCategory, setSelectedCategory, hasResponse]);
    const [showDeleteExistingResponses, setShowDeleteExistingResponses] = useState<boolean>();
    const deleteExistingResponses = () => {
        performApiCall(
            async (client, cancelToken) => {
                if (resource?.id) {
                    const deleted = await client.deleteQuestionnaryResponses(resource?.id);
                    if (deleted) {
                        setHasResponse(false);
                    }
                    setShowDeleteExistingResponses(false);
                }
            },
            appDispatch,
            { contextId: 'DeleteDialog' },
        );
    };
    useLoadScopeDynamicData('quiz', form.state.data as IResourceData, undefined, true);

    const intelisenseWritable = useIntelisenseWritableDataOfScope('quiz');
    useEffect(() => {
        if (intelisenseWritable) {
            const variableNames = getVariablesNames(intelisenseWritable, 'quiz.data.');
            formRef.current.update({ data: { variableNames } });
        }
    }, [intelisenseWritable]);

    useEffect(() => {
        const scope = 'quiz';
        const content = scopeBuilder[scope](form.state.data as IResourceData, undefined, true);
        appDispatch(
            loadScope({
                scope,
                content,
            }),
        );
    }, [form.state.data?.content?.questionnary?.questions]);
    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Panel
                    isContentModified={form.isModified}
                    revertContentModification={form.rollback}
                    backButtonCommand={() => (nav.from ? goBack(true) : nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Questionnary)))}
                    icon={GetResourceTypeIcon(ResourceTypeEnum.Questionnary)}
                    title={form.state.name ?? ''}
                    commands={resourceCommand}
                >
                    {showEditInfo ? (
                        <ResourceQuestionnaryInfoDialog
                            resource={form.state}
                            onChange={(change) => {
                                form.update(change);
                                setShowEditInfo(false);
                            }}
                            onClose={() => {
                                setShowEditInfo(false);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {hasResponse ? (
                        <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>
                            <div>
                                <span>{'Certaine fonctionnalitée ont été désactivé car ce questionnaire a déjà été répondu au moins une fois.'}</span>
                                <ActionButton
                                    style={{ height: 'unset' }}
                                    text='Supprimer les formulaires de réponses'
                                    iconProps={{ iconName: 'delete' }}
                                    onClick={() => {
                                        setShowDeleteExistingResponses(true);
                                    }}
                                />
                            </div>
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    <div className='SakuraPanelsGrid2'>
                        <Dropdown
                            label='Type'
                            errorMessage={form.fieldErrors.data?.content?.questionnary?.kind}
                            required
                            options={QuestionnaryKinds}
                            selectedKey={form.state.data?.content?.questionnary?.kind}
                            onChange={(_, v) => form.update(setModel({ kind: v?.key as QuestionnaryKind }))}
                        />
                        <ResourceLookupField
                            type='ResourcePageAutreLookup'
                            label={`Premiére page du questionnaire`}
                            selectedResourceId={form.state.data?.content?.questionnary?.customStartPageId}
                            onSelectResource={(resource) => form.update(setModel({ customStartPageId: resource?.id }))}
                        />

                        <div className='twoElement'>
                            <ContactAttributeLookupField
                                label={`Attribut contact (URL résultat)`}
                                contactAttributeName={form.state.data?.content?.questionnary?.contactAttributeResultLink}
                                onSelectContactAttribute={(contactAttributeResultLink) => form.update(setModel({ contactAttributeResultLink: contactAttributeResultLink?.name }))}
                            />
                            <ContactListLookupField
                                label={`Liste de diffusion`}
                                contactListId={form.state.data?.content?.questionnary?.contactListId}
                                onSelectContactList={(contactListId) => form.update(setModel({ contactListId: contactListId?.id }))}
                            />
                        </div>
                        <ExpressionField
                            label='Titre du questionnaire'
                            readonly={form.state.data?.content?.questionnary?.customStartPageId !== undefined}
                            Kind={ExpressionKind.TextWithExpression}
                            errorMessage={form.fieldErrors.data?.content?.questionnary?.title}
                            value={form.state.data?.content?.questionnary?.title ?? ''}
                            onChange={(v) => form.update(setModel({ title: v }))}
                        />

                        <div className='twoElementV'>
                            <ExpressionField
                                Kind={ExpressionKind.TextWithExpression}
                                label='Texte du bouton pour commencer le questionnaire'
                                errorMessage={form.fieldErrors.data?.content?.questionnary?.beginButtonText}
                                required
                                value={form.state.data?.content?.questionnary?.beginButtonText ?? ''}
                                onChange={(v) => form.update(setModel({ beginButtonText: v }))}
                            />
                            {form.state.data?.content?.questionnary?.kind === QuestionnaryKind.Interview ? (
                                <TextField
                                    label='Lien de prise de rendez-vous'
                                    errorMessage={form.fieldErrors.data?.content?.questionnary?.interviewCalendarLink}
                                    value={form.state.data?.content?.questionnary?.interviewCalendarLink}
                                    onChange={(_, interviewCalendarLink) => form.update(setModel({ interviewCalendarLink }))}
                                ></TextField>
                            ) : null}
                        </div>
                        <div className='twoElementV'>
                            <ResourceLookupField
                                readOnly={form.state.data?.content?.questionnary?.customStartPageId !== undefined}
                                type='ResourceImageContentLookup'
                                label={`Image sur la premiére page du questionnaire`}
                                selectedResourceId={form.state.data?.content?.questionnary?.imageResourceId}
                                onSelectResource={(resource) => form.update(setModel({ imageResourceId: resource?.id }))}
                            />
                            <ExpressionField
                                label='Texte explicatif sur la premiere page'
                                readonly={form.state.data?.content?.questionnary?.customStartPageId !== undefined}
                                Kind={ExpressionKind.TextWithExpression}
                                errorMessage={form.fieldErrors.data?.content?.questionnary?.subTitle}
                                multilines
                                minVisibleLines={4}
                                maxVisibleLines={4}
                                value={form.state.data?.content?.questionnary?.subTitle ?? ''}
                                onChange={(v) => form.update(setModel({ subTitle: v }))}
                            />
                        </div>
                    </div>
                    <Separator />
                    <FormGroup title={`Questions (${form.state.data?.content?.questionnary?.questions?.length ?? 0})`} isCollapsable commands={questionCommand}>
                        <Pivot
                            linkFormat='tabs'
                            selectedKey={selectedQuestion}
                            onLinkClick={(item) => {
                                setSelectedQuestion(item?.props.itemKey);
                            }}
                        >
                            {(form.state.data?.content?.questionnary?.questions ?? []).map((question, index) => {
                                const errorItem = form.fieldErrors.data?.content?.questionnary?.questions?.at(index);

                                return (
                                    <PivotItem
                                        key={`Q_${index}`}
                                        onRenderItemLink={(props, defaultRender) => {
                                            return defaultRender ? (
                                                <PivotItemDraggable disabled={hasResponse} index={index} onDrop={swapQuestion}>
                                                    {defaultRender(props)}
                                                </PivotItemDraggable>
                                            ) : (
                                                <></>
                                            );
                                        }}
                                        headerText={question.id}
                                        itemKey={question.id}
                                    >
                                        <QuestionEdit
                                            question={question}
                                            error={errorItem}
                                            hasResponse={hasResponse}
                                            onUpdate={(data) => {
                                                form.update(setModel({ questions: (questionsArray) => ArrayOperation.updateByIndex(questionsArray, index, data) }));
                                            }}
                                            questionnaryKind={form.state.data?.content?.questionnary?.kind ?? QuestionnaryKind.Exam}
                                        />
                                    </PivotItem>
                                );
                            })}
                        </Pivot>
                    </FormGroup>
                    <Separator />
                    {getModel(form.state)?.kind === QuestionnaryKind.Exam ? (
                        <>
                            <FormGroup title='Scoring' isCollapsable>
                                <Score form={form} hasResponse={hasResponse} />
                            </FormGroup>
                            <Separator />
                        </>
                    ) : (
                        <></>
                    )}
                    {getModel(form.state)?.kind === QuestionnaryKind.Survey ? (
                        <>
                            <FormGroup title='Categories' isCollapsable commands={categoryCommand}>
                                <Categories
                                    hasResponse={hasResponse}
                                    resultDescription={getModel(form.state)?.resultDescription}
                                    onChangeResultDescription={(resultDescription) => form.update(setModel({ resultDescription }))}
                                    showScoringDetails={getModel(form.state)?.showScoringDetails}
                                    onChangeShowScoringDetails={(showScoringDetails) => form.update(setModel({ showScoringDetails }))}
                                    selectedCategory={selectedCategory}
                                    onSelectCategory={(c) => {
                                        setSelectedCategory(c);
                                    }}
                                    items={getModel(form.state)?.categoriesDesc}
                                    onUpdate={(categoriesDesc) => {
                                        form.update(setModel({ categoriesDesc }));
                                    }}
                                ></Categories>
                            </FormGroup>
                            <Separator />
                        </>
                    ) : (
                        <></>
                    )}
                    {getModel(form.state)?.kind !== QuestionnaryKind.Interview ? (
                        <FormGroup title='Remerciement' isCollapsable>
                            <>
                                <div className='SakuraPanelsGrid2'>
                                    <ResourceLookupField
                                        type='ResourceImageContentLookup'
                                        label={`Image de remerciement`}
                                        selectedResourceId={form.state.data?.content?.questionnary?.thanksPage?.imageResourceId}
                                        onSelectResource={(imageResource) => form.update(setModel({ thanksPage: { imageResourceId: imageResource?.id } }))}
                                    />
                                    <ExpressionField
                                        label='Partage Titre'
                                        Kind={ExpressionKind.TextWithExpression}
                                        errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.shareTitle}
                                        minVisibleLines={1}
                                        maxVisibleLines={1}
                                        value={form.state.data?.content?.questionnary?.thanksPage?.shareTitle ?? ''}
                                        onChange={(shareTitle) => form.update(setModel({ thanksPage: { shareTitle } }))}
                                    />
                                    <ExpressionField
                                        label='Message de remerciement'
                                        Kind={ExpressionKind.TextWithExpression}
                                        errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.thanksMessage}
                                        multilines
                                        minVisibleLines={3}
                                        maxVisibleLines={3}
                                        value={form.state.data?.content?.questionnary?.thanksPage?.thanksMessage ?? ''}
                                        onChange={(thanksMessage) => form.update(setModel({ thanksPage: { thanksMessage } }))}
                                    />

                                    <ExpressionField
                                        label='Partage Message'
                                        Kind={ExpressionKind.TextWithExpression}
                                        errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.shareMessage}
                                        multilines
                                        minVisibleLines={3}
                                        maxVisibleLines={3}
                                        value={form.state.data?.content?.questionnary?.thanksPage?.shareMessage ?? ''}
                                        onChange={(shareMessage) => form.update(setModel({ thanksPage: { shareMessage } }))}
                                    />
                                </div>
                                <Separator />
                                <div className='SakuraPanelsGrid2'>
                                    <div>
                                        <TextField
                                            label="Objet de l'email (Partage via Email)"
                                            errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.share?.emailObject}
                                            value={form.state.data?.content?.questionnary?.thanksPage?.share?.emailObject}
                                            onChange={(_, emailObject) => form.update(setModel({ thanksPage: { share: { emailObject } } }))}
                                        ></TextField>
                                        <ExpressionField
                                            label="Contenu de l'email (Partage via Email)"
                                            Kind={ExpressionKind.TextWithExpression}
                                            errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.share?.emailContent}
                                            multilines
                                            minVisibleLines={5}
                                            maxVisibleLines={5}
                                            value={form.state.data?.content?.questionnary?.thanksPage?.share?.emailContent ?? ''}
                                            onChange={(emailContent) => form.update(setModel({ thanksPage: { share: { emailContent } } }))}
                                        />
                                    </div>
                                    <TextField
                                        label='HashTag (Partage via Facebook)'
                                        errorMessage={form.fieldErrors.data?.content?.questionnary?.thanksPage?.share?.hashTag}
                                        value={form.state.data?.content?.questionnary?.thanksPage?.share?.hashTag}
                                        onChange={(_, hashTag) => form.update(setModel({ thanksPage: { share: { hashTag } } }))}
                                    ></TextField>
                                </div>
                            </>
                        </FormGroup>
                    ) : null}
                </Panel>
            </DndProvider>
            <Separator />
            {showGenerateLinkDialog && resource && <QuestionnaryGenerateLink resource={resource} show={showGenerateLinkDialog} onClose={() => setShowGenerateLinkDialog(false)} />}
            {showDeleteExistingResponses && resource && (
                <ConfirmationDialog
                    title='Suppression des formulaires de réponse'
                    subText='Vos prospects ou membres ont déjà répondu à ce questionnaire.'
                    show={showDeleteExistingResponses}
                    icon={{ iconName: 'Delete' }}
                    id='DeleteDialog'
                    onReply={(reply) => {
                        if (reply) {
                            deleteExistingResponses();
                        } else {
                            setShowDeleteExistingResponses(false);
                        }
                    }}
                >
                    <>
                        <Separator />
                        <p>{'Êtes-vous sûr de vouloir supprimer les formulaires de réponse de ce questionnaire?'}</p>
                    </>
                </ConfirmationDialog>
            )}
            {showQuestionnaryTesterDialog && resource && (
                <QuestionnaryTester
                    questionnaryResourceId={resource.id}
                    onClose={() => {
                        setShowQuestionnaryTesterDialog(false);
                    }}
                />
            )}
        </>
    );
};
