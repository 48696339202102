import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { IStyle, TextField } from '@fluentui/react';
import { IPageElementTextField } from '../../../../../Services/SakuraApiClient';
import { useAppDispatch } from '../../../../../Redux/hook';
import { setVariable } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { Expression } from '../../../../../common/components/ExpressionField/Expression';
import { useVariableName } from '../../../../../common/components/ExpressionField/Hook/useVariableName';
import { textStyleBuilder, toIStyleContainer, toIStyleLabel } from '../Label/textStyleBuilder';

export interface ElementTextFieldProps {
    data: IPageElementTextField;
}

export const ElementTextField: FC<ElementTextFieldProps> = (props: ElementTextFieldProps) => {
    const { data } = props;
    const [txt, setTxt] = useState<string>();
    const dispatch = useAppDispatch();
    const assignTo = useVariableName(data.assignValueTo);
    const style = useMemo(() => {
        return textStyleBuilder(data.style);
    }, [data.style]);

    const labelStyleFU = useMemo(() => {
        const root = toIStyleLabel(style);
        return root;
    }, [style]);
    const containerStyleFU = useMemo<IStyle>(() => {
        return toIStyleContainer(style);
    }, [style]);
    return (
        <TextField
            label={data.label}
            value={txt}
            styles={{
                field: labelStyleFU,
                subComponentStyles: {
                    label: {
                        root: labelStyleFU,
                    },
                },
                root: containerStyleFU,
            }}
            required={data.mandatory}
            multiline={data.multiline}
            onChange={(_, val) => {
                setTxt(val);
                if (assignTo) {
                    dispatch(
                        setVariable({
                            name: assignTo,
                            value: val,
                        }),
                    );
                }
            }}
        />
    );
};
