import { Editor, EditorState } from 'draft-js';
import { toogleStyleOnEditorState } from '../state';
import { IFontStyleEntry, IFontStyles } from '../../../../Services/SakuraApiClient';
import { PresetStylePrefix, parseFontProperty } from '../../../styles/FontStyles';
import { getIdFromCssVar } from '../../../styles/common';

export const fontSizeStylePrefix = 'FS_';
export const fontStylePrefix = 'F_';

export function clearCustom(editor: Editor | null, state: EditorState): EditorState {
    let newState = state;
    newState = setEditorStyle(editor, newState, fontSizeStylePrefix);
    newState = setEditorStyle(editor, newState, fontStylePrefix);
    newState = setEditorStyle(editor, newState, '--C', 'f');
    newState = setEditorStyle(editor, newState, 'BOLD', 'BOLD', 'BOLD', true);
    newState = setEditorStyle(editor, newState, 'ITALIC', 'ITALIC', 'ITALIC', true);
    newState = setEditorStyle(editor, newState, 'UNDERLINE', 'UNDERLINE', 'UNDERLINE', true);
    newState = setEditorStyle(editor, newState, 'STRIKE', 'STRIKE', 'STRIKE', true);
    return newState;
}

export function clearPresetFontStyle(editor: Editor | null, state: EditorState, fontStyles: IFontStyles) {
    let newState = state;
    const activeStyles = state.getCurrentInlineStyle().filter((s) => s !== undefined && s.startsWith(PresetStylePrefix));
    let presetInfo: IFontStyleEntry | undefined;

    if (activeStyles && activeStyles.count() > 0) {
        const activeStyle = activeStyles.last();
        const entries = activeStyles.toArray();
        for (let i = 0; i < entries.length; i++) {
            newState = toogleStyleOnEditorState(editor, newState, entries[i]);
        }

        const presetName = activeStyle.substring(fontSizeStylePrefix.length).replaceAll('_', ' ');
        presetInfo = fontStyles.entries?.filter((e) => e.entryName === presetName)[0];
    }
    if (presetInfo) {
        const properties = parseFontProperty(presetInfo.font);

        if (properties.size) {
            newState = setEditorStyle(editor, newState, fontSizeStylePrefix, undefined, `${fontSizeStylePrefix}${properties.size}`);
        }
        if (properties.family) {
            newState = setEditorStyle(editor, newState, fontStylePrefix, undefined, `${fontStylePrefix}${properties.family.split(',')[0].replaceAll("'", '')}`);
        }
        if (presetInfo.color) {
            newState = setEditorStyle(editor, newState, '--C', undefined, `${getIdFromCssVar(presetInfo.color)}-f`);
        }

        newState = setEditorStyle(editor, newState, 'BOLD', 'BOLD', 'BOLD', properties.weight === undefined || properties.weight === '600');
        newState = setEditorStyle(editor, newState, 'ITALIC', 'ITALIC', 'ITALIC', properties.style === undefined || properties.style === 'normal');
        newState = setEditorStyle(editor, newState, 'UNDERLINE', 'UNDERLINE', 'UNDERLINE', !presetInfo.underline);
        newState = setEditorStyle(editor, newState, 'STRIKE', 'STRIKE', 'STRIKE', !presetInfo.strike);
    }
    return newState;
}

export function setEditorStyle(editor: Editor | null, editorState: EditorState, prefix: string, suffix?: string, newValue?: string, turnOff?: boolean) {
    let newState = editorState;

    const activeStyles = editorState.getCurrentInlineStyle().filter((s) => s !== undefined && s.startsWith(prefix) && (suffix === undefined || s.endsWith(suffix)));
    if (activeStyles) {
        const entries = activeStyles.toArray();
        for (let i = 0; i < entries.length; i++) {
            newState = toogleStyleOnEditorState(editor, newState, entries[i]);
        }
    }
    if (turnOff) {
        return newState;
    }
    if (newValue) {
        newState = toogleStyleOnEditorState(editor, newState, newValue);
    }
    return newState;
}

export function getEditorPresetStyle(editorState: EditorState, fontStyles: IFontStyles) {
    const value = editorState
        .getCurrentInlineStyle()
        .filter((s) => s !== undefined && s.startsWith(PresetStylePrefix))
        ?.last();
    if (value) {
        const presetName = value.substring(fontSizeStylePrefix.length).replaceAll('_', ' ');
        const style = fontStyles.entries?.filter((p) => p.entryName === presetName)[0];
        if (style) {
            return { ...style, fontProperties: parseFontProperty(style.font) };
        }
    }
    return undefined;
}
