import { Reducer } from 'react';
import { updateMenuItem, updateMenuItemToOpenRequired } from './helper';
import { IFormationContextState } from './IFormationContextState';

export type FormationContextAction =
    | { type: 'selectFormationItem'; payload: { path: string } }
    | { type: 'movePrev' }
    | { type: 'moveNext' }
    | { type: 'expandItem'; payload: { path: string; isExpanded: boolean } };

export type FormationContextReducerType = Reducer<IFormationContextState, FormationContextAction>;
export const FormationContextReducer = (state: IFormationContextState, action: FormationContextAction): IFormationContextState => {
    let newState = state;
    switch (action.type) {
        case 'selectFormationItem':
            {
                newState = { ...state, selectedContentPath: action.payload.path, menu: updateMenuItemToOpenRequired(state.menu, action.payload.path) };
            }
            break;
        case 'movePrev':
            {
                let index = 0;
                if (state.selectedContentPath) {
                    index = state.userData.userDataIndex[state.selectedContentPath];
                    if (index > 0) {
                        index--;
                    }
                }
                const selectedContentPath = state.userData.userDatas[index].resourcePath;
                newState = { ...state, selectedContentPath, menu: updateMenuItemToOpenRequired(state.menu, selectedContentPath) };
            }
            break;
        case 'moveNext':
            {
                let index = 0;
                if (state.selectedContentPath) {
                    index = state.userData.userDataIndex[state.selectedContentPath];
                    if (index < state.userData.userDatas.length - 1) {
                        index++;
                    }
                }
                const selectedContentPath = state.userData.userDatas[index].resourcePath;
                newState = { ...state, selectedContentPath, menu: updateMenuItemToOpenRequired(state.menu, selectedContentPath) };
            }
            break;
        case 'expandItem': {
            newState = {
                ...state,
                menu: updateMenuItem(state.menu, action.payload.path, (item) => {
                    return { ...item, isExpanded: action.payload.isExpanded };
                }),
            };
        }
    }
    if (state.serializationIdentifier) {
        localStorage.setItem(`ExecData_${state.serializationIdentifier}`, JSON.stringify(newState));
    }
    return newState;
};
