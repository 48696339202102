import React, { FC, useMemo, useState } from 'react';
import { IFontStyleEntry, IFontStyles } from '../../../Services/SakuraApiClient';
import { DeepPartial, DeepPartialWithArrayFunc } from '../../Hooks/Form/useForm';

import { FontStyleEntryItem } from './FontStyleEntryItem';
import { ArrayOperation } from '../../helpers/ObjectAndArray';

import './FontStyles.scss';
import { ActionButton, Label, MessageBarType, PrimaryButton } from '@fluentui/react';
import clsx from 'clsx';
import { AskDialog } from '../Dialog/AskDialog/AskDialog';
import { defaultFont } from '../../styles/FontStyles';
import { useAppDispatch } from '../../../Redux/hook';
import { clearContext, setMessage } from '../../../Redux/Reducers/System/reducer';

export interface FontStylesEditorProps {
    fontStyles: DeepPartial<IFontStyles>;
    onChange: (fontStyles: DeepPartial<IFontStyles> | undefined) => void;
}

export const FontStylesEditor: FC<FontStylesEditorProps> = (props: FontStylesEditorProps) => {
    const { fontStyles, onChange } = props;
    const [showNewStyle, setShowNewStyle] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const appDispatch = useAppDispatch();
    const selectedStyle = useMemo(() => {
        return fontStyles.entries?.filter((e) => e.entryName === selected)[0];
    }, [fontStyles, selected]);
    return (
        <div className='fontStyleEditor'>
            <div className='list'>
                <div>
                    <PrimaryButton
                        iconProps={{ iconName: 'CircleAddition' }}
                        onClick={() => {
                            setShowNewStyle(true);
                        }}
                    >
                        Ajouter un style
                    </PrimaryButton>
                </div>
                <div className='listContent'>
                    {fontStyles?.entries &&
                        fontStyles?.entries.map((e) => {
                            return (
                                <div
                                    key={e.entryName}
                                    className={clsx(e.entryName === selected ? 'selected' : '', 'listEntry')}
                                    onClick={() => {
                                        setSelected(e.entryName);
                                    }}
                                >
                                    <Label>{e.entryName}</Label>
                                    <ActionButton
                                        iconProps={{ iconName: 'Delete' }}
                                        onClick={() => {
                                            const copyFontStyles = { ...fontStyles };
                                            if (fontStyles.entries) {
                                                copyFontStyles.entries = ArrayOperation.remove(fontStyles.entries, (entry) => e.entryName === entry.entryName);
                                            }

                                            onChange(copyFontStyles);
                                        }}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className='details'>
                {selectedStyle ? (
                    <FontStyleEntryItem
                        key={selectedStyle.entryName}
                        entry={selectedStyle as IFontStyleEntry}
                        onChange={(entry) => {
                            const copyFontStyles = { ...fontStyles };
                            if (fontStyles.entries) {
                                copyFontStyles.entries = ArrayOperation.update(fontStyles.entries, (e) => e.entryName === entry?.entryName, entry);
                            } else {
                                copyFontStyles.entries = [];
                                copyFontStyles.entries.push(entry);
                            }

                            onChange(copyFontStyles);
                        }}
                    />
                ) : null}
            </div>
            {showNewStyle ? (
                <AskDialog
                    id='newStyleAskName'
                    show={showNewStyle}
                    onClose={(answer) => {
                        if (answer) {
                            const entries = fontStyles.entries ? [...fontStyles.entries] : [];
                            if (entries.findIndex((e) => e.entryName === answer) !== -1) {
                                appDispatch(setMessage({ message: { text: 'Nom de style déjà utilisé', Severity: MessageBarType.error }, contextId: 'newStyleAskName' }));
                                return;
                            }
                            appDispatch(clearContext({ contextId: 'newStyleAskName' }));
                            entries.push({ entryName: answer, font: `12px ${defaultFont}` });
                            onChange({ entries });
                            setSelected(answer);
                            setShowNewStyle(false);
                        } else {
                            setShowNewStyle(false);
                        }
                    }}
                    title='Ajouter un nouveau style'
                    icon={{ iconName: 'Font' }}
                    question='Quel est le nom du style?'
                />
            ) : null}
        </div>
    );
};
