import { FC, useState } from 'react';
import { ResourceFormationGrid, ResourceImageGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { clearContext } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { useNav } from '../../../../common/Hooks/useNav';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceDialog } from '../ResourceDialog';
import { ResourceList } from '../ResourceList';
import { ResourceUXDataChange } from '../ResourceUXDataChange';

export const ResourceFormationList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditUxData, setShowEditUxData] = useState<boolean>(false);
    const appDispatch = useAppDispatch();
    const nav = useNav();
    return (
        <Panel
            title='Liste des formations'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Formation)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowCreateDialog(true);
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Formation, selectedResource.id), undefined); //no state here
                        }
                    },
                },
                {
                    icon: 'PreviewLink',
                    text: 'Modifier la tuile',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditUxData(true);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceImageGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourceFormationGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                extraColumns={[]}
                onRowDoubleClick={(resource) => {
                    if (resource?.id) {
                        nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Formation, resource.id), undefined); //no state here
                    }
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Formation) }}
                show={showDeleteDialog}
                title='Suppression de la formation'
                subText='Etes-vous sur de vouloir supprimer la formation?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceFormationGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showCreateDialog ? (
                <ResourceDialog
                    type={ResourceTypeEnum.Formation}
                    show
                    showThumbnail
                    showOptionEnabled
                    onClose={(res) => {
                        if (res) {
                            ResourceFormationGrid.refresh();
                        }
                        setShowCreateDialog(false);
                    }}
                />
            ) : null}
            {showEditUxData && selectedResource ? (
                <ResourceUXDataChange
                    resource={selectedResource}
                    onClose={(res) => {
                        setShowEditUxData(false);
                        if (res) {
                            ResourceFormationGrid.refresh();
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
