import { BadgeTypeEnum } from '../../../Services/SakuraApiClient';

export function getBadgeTypeLabel(type: BadgeTypeEnum) {
    switch (type) {
        case BadgeTypeEnum.Normal:
            return 'Commun';
        case BadgeTypeEnum.Rare:
            return 'Rare';
        case BadgeTypeEnum.Epic:
            return 'Epique';
        case BadgeTypeEnum.Legendary:
            return 'Légendaire';
        case BadgeTypeEnum.Mythical:
            return 'Mythique';
    }
}
