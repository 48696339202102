import { CompositeDecorator, DraftDecorator } from 'draft-js';
import { SyntaxHighlight } from './SyntaxHighligthComponent';
import { intelisenseDecorator } from '../Intelisense/IntelisenseDecorator';

function matchRegex(regex: RegExp, text: string, callback: (start: number, end: number) => void) {
    let match;
    while ((match = regex.exec(text)) !== null) {
        const start = match.index;
        const end = start + match[0].length;
        callback(start, end);
    }
}
function createDecorator(regex: RegExp, props: object): DraftDecorator {
    return {
        strategy: (contentBlock, callback) => matchRegex(regex, contentBlock.getText(), callback),
        props,
        component: SyntaxHighlight,
    };
}
const bracketVariableDecorator = createDecorator(/\{\{|\}\}/g, { color: '#3C9646', bold: true });

const parenthesesDecorator = createDecorator(/(\(|\))/g, { bold: true });
const operatorDecorator = createDecorator(/\s+(=|!=|>=|<=|>|<|&&|\|\||or|ou|et|and)\s+/g, { color: 'blue', bold: true });
const variableDecorator = createDecorator(/\s*(\w+\.[\.\w|_]+)\s*/g, { color: '#6078f0' });

const litteralDecorator = createDecorator(/([\'][^\']*[\'])/g, { color: '#B75028' });
const litteralAlternatifDecorator = createDecorator(/([\"][^\"]*[\"])/g, { color: '#B75028' });

const booleanTrueDecorator = createDecorator(/\s+(oui|ok|yes|true)\s+/g, { color: 'green' });
const booleanFalseDecorator = createDecorator(/\s+(non|ko|no|false)\s+/g, { color: 'red' });

export const syntaxHighlightDecoratorTemplate = new CompositeDecorator([intelisenseDecorator, bracketVariableDecorator]);
export const syntaxHighlightDecoratorExpression = new CompositeDecorator([intelisenseDecorator, litteralDecorator, litteralAlternatifDecorator, parenthesesDecorator, variableDecorator]);
