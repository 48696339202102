import React, { FC, useEffect } from 'react';
import { IOverflowSetItemProps, ActionButton, IButtonStyles, PrimaryButton } from '@fluentui/react';

import palette from '../../styles/_variables.module.scss';
const colorDisabled = 'themeTertiary';

const commandButtonStyle: IButtonStyles = {
    root: {
        padding: 0,
        minWidth: 'unset',
        border: 0,
    },
    labelHovered: {
        textDecoration: 'underline',
    },
    rootHovered: {
        border: 0,
        background: 'transparent',
    },
    rootPressed: {
        background: 'transparent',
        border: 0,
        fontWeight: 800,
    },
    rootDisabled: {
        background: 'transparent',
        border: 0,
        color: `${palette[colorDisabled]}`,
    },
    labelDisabled: {
        color: `${palette[colorDisabled]}`,
    },
    iconDisabled: {
        color: `${palette[colorDisabled]}`,
    },
};

export const DefaultCommandButton: FC<IOverflowSetItemProps> = (props: IOverflowSetItemProps): JSX.Element => {
    return (
        // <ActionButton
        //     disabled={props.disabled}
        //     iconProps={{ iconName: props.icon }}
        //     role="action"
        //     onClick={props.onClick}
        //     href={props.href}
        //     menuProps={props.menuProps}
        //     menuAs={props.menuAs}
        //     allowDisabledFocus={props.allowDisabledFocus}
        // >
        //     {props.text}
        // </ActionButton>
        <PrimaryButton
            text={props.text}
            className={props.className}
            iconProps={props.icon ? { iconName: props.icon } : undefined}
            menuIconProps={props.menuIcon ? { iconName: props.menuIcon } : undefined}
            title={props.title}
            disabled={props.disabled}
            onClick={(ev) => {
                props.onClick();
                ev.stopPropagation();
            }}
            styles={commandButtonStyle}
        />
    );
};
