import { FC, useContext, useMemo } from 'react';
import { mobileViewInContextSelector, storageEndpointsSelector, useWindowWidth } from '../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../Redux/hook';
import { PageElementContainer, PageElementContainerStyle } from '../../../../../Services/SakuraApiClient';
import { ST } from '../../../../../common/Hooks/StorageResolver';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';
import { PageElementView } from '../../PageElementView';
import { styleBuilder } from './styleBuilder';

export interface ElementContainerViewProps {
    data: PageElementContainer;
    parentContainerStyle?: PageElementContainerStyle;
    rootContainer?: boolean;
}

export const ElementContainerView: FC<ElementContainerViewProps> = (props: ElementContainerViewProps) => {
    const { data, parentContainerStyle, rootContainer } = props;
    const [state] = useContext(ExecutionDataContext);
    const storage = useAppSelector(storageEndpointsSelector);
    const isMobile = useAppSelector(mobileViewInContextSelector);
    const windowWidth = useWindowWidth();
    const style = useMemo(() => {
        const result = styleBuilder(
            data.style,
            parentContainerStyle,
            data.style?.backgroundImageResourceId ? ST(storage, state.resources[data.style?.backgroundImageResourceId]?.blobPath, isMobile) : undefined,
            windowWidth,
        );
        if (rootContainer) {
            result.width = '100%';
        }
        return result;
    }, [data.style, parentContainerStyle, storage, state, windowWidth, rootContainer]);

    return (
        <div style={style}>
            {(data.items ?? []).map((item, index) => {
                return <PageElementView key={`item_${index}`} data={item} parentContainerStyle={data.style} />;
            })}
        </div>
    );
};
