import { FC } from 'react';
import { displayBadgeSelector } from '../../../Redux/Reducers/User/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { BadgeToast } from './BadgeToast';

import './BadgeToastLayout.scss';

export const BadgeToastLayout: FC = () => {
    const badgeToDisplay = useAppSelector(displayBadgeSelector);
    return <div className='BadgeToastLayout'>{badgeToDisplay.length > 0 && <BadgeToast badge={badgeToDisplay[0]} />}</div>;
};
