import React, { FC, useEffect, useState } from 'react';
import { IProduct, IResourceOfProduct, Product, ProductCreationModel, ProductTypeEnum, ProductUpdateModel, Resource, ResourceTypeEnum } from '../../../Services/SakuraApiClient';

import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../Redux/hook';
import { Dropdown, TextField, Toggle } from '@fluentui/react';

import { useForm } from '../../../common/Hooks/Form';
import { ProductTypes } from './ProductType';
import { ProductDetailForm } from './ProductDetailForm';
import { ProductDetailResourceList } from './ProductDetailResourceList';
import { saveProduct } from './saveProduct';
import * as validators from '../../../common/Hooks/Form/validators';

export interface ProductEditDialogProps {
    show: boolean;
    product?: IProduct | undefined;
    onClose: (product: IProduct | undefined) => void;
}
const getNewProduct = (): IProduct => {
    const newProduct = new Product();
    newProduct.init({ enabled: true });
    return newProduct;
};

export const ProductEditDialog: FC<ProductEditDialogProps> = (props: ProductEditDialogProps) => {
    const { show, onClose, product } = props;
    const contextId = `AddProduct`;

    const [resources, setResources] = useState<IResourceOfProduct[]>([]); //use in creation mode only
    const form = useForm<IProduct>(
        {
            initialState: product ?? getNewProduct(),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                    productType: { displayName: 'Le type', validators: [validators.required] },
                },
            },
        },
        product !== undefined,
        contextId,
    );

    const appDispatch = useAppDispatch();

    const close = (prd: IProduct | undefined) => {
        form.clearErrors();
        onClose(prd);
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: 'Product' }}
            mode={product ? 'Edit' : 'Create'}
            title={product ? 'Modifier le produit' : 'Créer un produit'}
            show={show}
            onSubmit={() => saveProduct(form, product, resources, appDispatch, close, contextId)}
            onClose={() => close(undefined)}
            subText={''}
        >
            <ProductDetailForm form={form} />
            <ProductDetailResourceList onResourcesChange={(r) => setResources(r)} resources={resources} createMode={product === undefined} product={product} />
        </EditDialog>
    );
};
