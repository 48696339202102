import { FC } from 'react';
import { DataListEdit } from '../../../../common/components/DataListItem/DataListEdit';

export interface SectionSettingEditProps {
    sections: string[];
    onChange: (sections: string[]) => void;
}
export const SectionSettingEdit: FC<SectionSettingEditProps> = (props: SectionSettingEditProps) => {
    const { sections, onChange } = props;
    return (
        <DataListEdit
            titleAdd={'Ajouter une section'}
            label={"List des sections pour l'affichage de vignette"}
            question={'Quel est le nom de la section'}
            iconName={'ThumbnailView'}
            data={sections}
            onChange={onChange}
        />
    );
};
