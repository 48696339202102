import { Icon, Pivot, PivotItem } from '@fluentui/react';
import React, { FC } from 'react';
import { Accordion } from '../../../../common/components/Accordion';
import { IResourceDataPage, PageElementType } from '../../../../Services/SakuraApiClient';
import { ElementToolBox } from './ElementToolBox';
import { PageHierachyMap } from './PageHierachyMap';
import { ToolBoxContent } from './ToolBoxContent';
import { ToolBoxPanel } from '../../../Admin/Resources/Page/ToolBoxPanel';

export interface PageEditorToolBoxProps {
    pageData: IResourceDataPage | undefined;
}
export const PageEditorToolBox: FC<PageEditorToolBoxProps> = (props: PageEditorToolBoxProps) => {
    const { pageData } = props;
    return (
        <ToolBoxPanel icon='Design' title='Conception' width={275}>
            <Pivot linkSize='large'>
                <PivotItem headerText='Elements' itemIcon='DeveloperTools'>
                    <ToolBoxContent />
                </PivotItem>
                {pageData ? (
                    <PivotItem headerText='Structure' itemIcon='BulletedTreeList'>
                        <PageHierachyMap data={pageData} />
                    </PivotItem>
                ) : (
                    <></>
                )}
            </Pivot>
        </ToolBoxPanel>
    );
};
