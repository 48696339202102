import React from 'react';
import { IOverflowSetItemProps, CommandBarButton, IRenderFunction, IButtonStyles } from '@fluentui/react';
import palette from '../../styles/_variables.module.scss';

const commandButtonStyle: IButtonStyles = {
    root: {
        padding: 0,
        background: 'transparent',
        minWidth: 'unset',
        border: 0,
    },
    menuIconHovered: {
        color: `${palette['white']}`,
        fontWeight: 800,
    },
    menuIconExpanded: {
        color: `${palette['white']}`,
    },
    menuIconExpandedHovered: {
        color: `${palette['white']}`,
        fontWeight: 800,
    },
    menuIconPressed: {
        color: `${palette['white']}`,
        fontWeight: 900,
    },
    menuIcon: {
        color: `${palette['white']}`,
    },
    labelHovered: {
        textDecoration: 'underline',
    },

    rootHovered: {
        border: 0,
        background: 'transparent',
    },
    rootExpandedHovered: {
        border: 0,
        background: 'transparent',
    },
    rootPressed: {
        background: 'transparent',
        border: 0,
        fontWeight: 800,
    },
    rootDisabled: {
        background: 'transparent',
        border: 0,
    },
};

export const renderOverflowButton: IRenderFunction<IOverflowSetItemProps[]> = (overflowItems: IOverflowSetItemProps[] | undefined): JSX.Element => {
    return (
        <CommandBarButton
            role='menuitem'
            menuIconProps={{ iconName: 'More' }}
            menuProps={{
                items: overflowItems ?? [],
            }}
            styles={commandButtonStyle}
        />
    );
};
