import React, { FC, PropsWithChildren, useEffect } from 'react';
import { AuthenticationService } from './AuthService';

export interface ISilentLoginCallbackProps {
    authService: AuthenticationService;
}
export const SilentLoginCallback: FC<PropsWithChildren<ISilentLoginCallbackProps>> = (props: PropsWithChildren<ISilentLoginCallbackProps>) => {
    const { authService, children } = props;
    useEffect(() => {
        authService.signinSilentCallback();
    }, []);

    return children ? <>{children}</> : <></>;
};
