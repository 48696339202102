import { ActionButton } from '@fluentui/react';
import React, { FC } from 'react';
import './NoRecordPanel.scss';

export interface NoRecordPanelProps {
    url?: string;
    showFrequentlyUsedLink?: boolean;
    onShowFrequentlyUsedLink?: () => void;
    showCannotFindPanel?: boolean;
    onCreateNewItem?: () => void;
}
export const NoRecordPanel: FC<NoRecordPanelProps> = (props: NoRecordPanelProps) => {
    const { url, showFrequentlyUsedLink, onShowFrequentlyUsedLink, showCannotFindPanel, onCreateNewItem } = props;
    return (
        <div className='noRecordPanel'>
            <span className='title'>Aucun résultat</span>
            {showCannotFindPanel && (
                <>
                    <span className='subtitle bold'>CannotFind</span>
                    <p>
                        <span className='subtitle'>CannotFindSubText</span>
                        {(url ?? '') !== '' && (
                            <>
                                <span className='subtitle'>Lien</span>
                                <a className='codeURL' href={url ?? '#'} rel='noopener noreferrer' target='_blank'>
                                    Create
                                </a>
                            </>
                        )}
                    </p>
                </>
            )}
            {onCreateNewItem ? <ActionButton iconProps={{ iconName: 'AddTo' }} text='Créer un nouvel élément' onClick={() => onCreateNewItem()} /> : null}
            {showFrequentlyUsedLink && (
                <ActionButton
                    className='actionButtonLink'
                    onClick={() => {
                        if (onShowFrequentlyUsedLink) {
                            onShowFrequentlyUsedLink();
                        }
                    }}
                >
                    Utilisé récemment
                </ActionButton>
            )}
        </div>
    );
};
