import { DefaultButton, DialogFooter, IIconProps, PrimaryButton } from '@fluentui/react';
import React, { FC, PropsWithChildren } from 'react';
import { useLoadingSelector } from '../../../../Redux/Reducers/System/reducer';
import { CustomDialog } from '../CustomDialog';

export interface ConfirmationDialogProps {
    id: string;
    icon?: IIconProps;
    title: string;
    subText: string;
    show: boolean;
    onReply: (reply: boolean) => void;
}
export const ConfirmationDialog: FC<PropsWithChildren<ConfirmationDialogProps>> = (props: PropsWithChildren<ConfirmationDialogProps>) => {
    const { title, subText, show, onReply, id, icon, children } = props;
    const loadingIndicator = useLoadingSelector(id);
    return (
        <CustomDialog id={id} hidden={!show} onDismiss={() => onReply(false)} subText={subText} title={title} icon={icon} isBlocking>
            {children}
            <DialogFooter>
                <PrimaryButton disabled={loadingIndicator?.isLoading} onClick={() => onReply(true)} text='Oui' />
                <DefaultButton disabled={loadingIndicator?.isLoading} onClick={() => onReply(false)} text='Non' />
            </DialogFooter>
        </CustomDialog>
    );
};
