import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { AddBadgeAction, SetBalanceAction, SetExperienceChangeAction, SetProfileAction } from './action';
import { initialState } from './state';

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        commitCoin: (state, action: PayloadAction<number>) => {
            if (state.userProfile) {
                state.acquiredCoin = undefined;
                state.userProfile.sakuraCoin = action.payload;
            }
        },
        commitDiamond: (state, action: PayloadAction<number>) => {
            if (state.userProfile) {
                state.acquiredDiamond = undefined;
                state.userProfile.sakuraDiamond = action.payload;
            }
        },
        setBalance: (state, action: PayloadAction<SetBalanceAction>) => {
            if (state.userProfile) {
                // if (action.payload.newBalanceCoin !== undefined && state.userProfile.sakuraCoin !== action.payload.newBalanceCoin) {
                //     state.userProfile.sakuraCoin = action.payload.newBalanceCoin;
                // }
                // if (action.payload.newBalanceDiamond !== undefined && state.userProfile.sakuraDiamond !== action.payload.newBalanceDiamond) {
                //     state.userProfile.sakuraDiamond = action.payload.newBalanceDiamond;
                // }
                if (action.payload.acquiredCoin !== undefined) {
                    state.acquiredCoin = { total: action.payload.newBalanceCoin ?? 0, variation: action.payload.acquiredCoin };
                }
                if (action.payload.acquiredDiamond !== undefined) {
                    state.acquiredDiamond = { total: action.payload.newBalanceDiamond ?? 0, variation: action.payload.acquiredDiamond };
                }
            }
        },

        setProfile: (state, action: PayloadAction<SetProfileAction>) => {
            state.userProfile = action.payload.profile;
            if (action.payload.menu !== undefined) {
                state.menu = action.payload.menu;
            }
            if (action.payload.organization !== undefined) {
                state.organization = action.payload.organization;
                state.rootPath = state.organization !== '' ? `/${state.organization}/app` : '/app';
            }
            if (action.payload.experienceRequiredToLevelUp !== undefined) {
                state.experienceRequiredToLevelUp = action.payload.experienceRequiredToLevelUp;
            }
        },
        setExperienceChangeAction: (state, action: PayloadAction<SetExperienceChangeAction>) => {
            if (state.userProfile) {
                if (action.payload.experience !== undefined && state.userProfile.experience !== action.payload.experience) {
                    state.userProfile.experience = action.payload.experience;
                }
                if (action.payload.level !== undefined && state.userProfile.level !== action.payload.level) {
                    state.userProfile.level = action.payload.level;
                }
                if (action.payload.experienceRequiredToLevelUp !== undefined) {
                    state.experienceRequiredToLevelUp = action.payload.experienceRequiredToLevelUp;
                }
                if (action.payload.hasLevelUp !== undefined) {
                    state.hasLevelUp = action.payload.hasLevelUp;
                }
                if (action.payload.experienceAcquired !== undefined) {
                    state.acquiredExperience = action.payload.experienceAcquired;
                }
            }
        },

        addBadgeToDisplay: (state, action: PayloadAction<AddBadgeAction>) => {
            state.displayBadge = [...state.displayBadge, { ...action.payload.badge }];
        },
        removeLastDisplayBadge: (state, action: PayloadAction) => {
            if (state.displayBadge.length > 0) {
                state.displayBadge = state.displayBadge.slice(1);
            }
        },
    },
});

//Export Actions
export const { setProfile, addBadgeToDisplay, removeLastDisplayBadge, setBalance, setExperienceChangeAction, commitCoin, commitDiamond } = userSlice.actions;
//Export Selector
export const userProfileSelector = (state: RootState) => state.user.userProfile;
export const userMenuSelector = (state: RootState) => state.user.menu;
export const userRoleSelector = (state: RootState) => state.user.userProfile?.userRole;

export const xpRequiredSelector = (state: RootState) => state.user.experienceRequiredToLevelUp;
export const acquiredCoinSelector = (state: RootState) => state.user.acquiredCoin;
export const acquiredDiamondSelector = (state: RootState) => state.user.acquiredDiamond;
export const userOrganizationSelector = (state: RootState) => state.user.organization;
export const useRootPathSelector = (state: RootState) => state.user.rootPath;

export const displayBadgeSelector = (state: RootState) => state.user.displayBadge;
//export Reducer
export default userSlice.reducer;
