//note that we don't use export default here
//create context here, use context in others

import React, { createContext, FC, PropsWithChildren, useMemo, useReducer } from 'react';
import { CategoryData, IQuestionnaryDataContextState } from './IQuestionnaryDataContextState';
import { QuestionnaryDataContextReducer, QuestionnaryDataContextReducerType } from './QuestionnaryDataContextReducer';

const emptyContext: IQuestionnaryDataContextState = { categories: [] };
//this DataContext will be shared by all the components
export const QuestionnaryDataContext = createContext<IQuestionnaryDataContextProvider>({
    categories: [],
    upsertCategory: () => {
        alert('Not Configured');
    },
    removeCategory: () => {
        alert('Not Configured');
    },
});

export interface IQuestionnaryDataContextProvider {
    categories: CategoryData[];
    upsertCategory: (category: CategoryData) => void;
    removeCategory: (categoryId: string) => void;
}

export interface QuestionnaryDataContextProviderProps {
    initialContext?: IQuestionnaryDataContextState;
}

export const QuestionnaryDataContextProvider: FC<PropsWithChildren<QuestionnaryDataContextProviderProps>> = (props: PropsWithChildren<QuestionnaryDataContextProviderProps>) => {
    const { children, initialContext } = props;
    const [state, dispatch] = useReducer<QuestionnaryDataContextReducerType, IQuestionnaryDataContextState>(
        QuestionnaryDataContextReducer,
        initialContext ?? emptyContext,
        () => initialContext ?? emptyContext,
    );

    const value = useMemo<IQuestionnaryDataContextProvider>(() => {
        return {
            categories: state.categories,
            upsertCategory: (category: CategoryData) => {
                dispatch({ type: 'upsertCategory', payload: { category } });
            },
            removeCategory: (categoryId) => {
                dispatch({ type: 'removeCategory', payload: { categoryId } });
            },
        };
    }, [state, dispatch]);
    return <QuestionnaryDataContext.Provider value={value}>{children}</QuestionnaryDataContext.Provider>;
};
