import { CancelToken } from 'axios';
import { ResourceTypeEnum, SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (
    apiCall: ApiCall,
    client: SakuraApiClient,
    newState: DataSetInfo,
    resourceType: ResourceTypeEnum,
    cancelToken?: CancelToken,
    specifiedCategory?: string,
    noData?: boolean,
    reusable?: boolean,
) => {
    return await client.listResources(
        newState.pageIndex,
        newState.pageSize,
        undefined,
        resourceType,
        reusable,
        specifiedCategory ?? (newState.filters['category'] as string),
        newState.filters['name'] as string,
        noData,
        cancelToken,
    );
};

export const ResourceDocumentGrid = new ApiCall('ResourceDocumentGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Document, ct));
export const ResourceImageGrid = new ApiCall('ResourceImageGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Image, ct));
export const ResourceVideoGrid = new ApiCall('ResourceVideoGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Video, ct));
export const ResourceAudioGrid = new ApiCall('ResourceAudioGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Audio, ct));
export const ResourceFormationGrid = new ApiCall('ResourceFormationGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Formation, ct, undefined, true));
export const ResourcePageGrid = new ApiCall('ResourcePageGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Page, ct, undefined, true));
export const ResourceQuestionnaryGrid = new ApiCall('ResourceQuestionnaryGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Questionnary, ct));
export const ResourceGameContentGrid = new ApiCall('ResourceGameContentGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.GameContent, ct));

export const ResourceDocumentLookup = new ApiCall('ResourceDocumentLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Document, ct));

export const ResourceImageLookup = new ApiCall('ResourceImageLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Image, ct, ''));
export const ResourceImagePubliciteLookup = new ApiCall('ResourceImagePubliciteLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Image, ct, 'Publicité'));
export const ResourceImageVignetteLookup = new ApiCall('ResourceImageVignetteLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Image, ct, 'Vignette'));
export const ResourceImageContentLookup = new ApiCall('ResourceImageContentLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Image, ct, 'Contenu'));

export const ResourceVideoLookup = new ApiCall('ResourceVideoLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Video, ct));
export const ResourceAudioLookup = new ApiCall('ResourceAudioLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Audio, ct));
export const ResourceFormationLookup = new ApiCall('ResourceFormationLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Formation, ct));
export const ResourcePageFormationLookup = new ApiCall('ResourcePageFormationLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Page, ct, 'Formation', undefined, true));
export const ResourcePageVenteLookup = new ApiCall('ResourcePageVenteLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Page, ct, 'Vente', undefined, undefined));
export const ResourcePageAutreLookup = new ApiCall('ResourcePageAutreLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Page, ct, 'Autre', undefined, undefined));
export const ResourceModuleLookup = new ApiCall('ResourceModuleLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Module, ct, undefined, undefined, true));

export const ResourceQuestionnaryLookup = new ApiCall('ResourceQuestionnaryLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Questionnary, ct));
export const ResourceGameContentLookup = new ApiCall('ResourceGameContentLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.GameContent, ct));

export const ResourcesByProduct = new ApiCall('ResourcesByProduct', async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const productId = newState.filters['productId'] as number;
    return await client.listResourcesOfProduct(productId, newState.pageIndex, newState.pageSize, undefined, newState.filters['category'] as string, newState.filters['name'] as string, cancelToken);
});

export const ResourceWorkflowGrid = new ApiCall('ResourceWorkflowGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Workflow, ct));
export const ResourceWorkflowLookup = new ApiCall('ResourceWorkflowLookup', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.Workflow, ct));

export const ResourcePageBlockGrid = new ApiCall('ResourcePageBlockGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.PageBlock, ct));

export const ResourcePageTemplateGrid = new ApiCall('ResourcePageTemplateGrid', (a, c, d, ct) => query(a, c, d, ResourceTypeEnum.PageTemplate, ct));

export const ResourcePublicLinkAccessGrid = new ApiCall('ResourcePublicLinkAccessGrid', async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const resourceId = apiCall.parameters ? (apiCall.parameters['resourceId'] as number) : (0 as number);
    const shortname = newState.filters['linkShortName'] as string | undefined;
    return await client.getResourcePublicAccessTrackingById(resourceId, newState.pageIndex, newState.pageSize, shortname, cancelToken);
});

export const ResourceTileGrid = new ApiCall('ResourceTileGrid', async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listResourceTileOwnByAccount(newState.pageIndex, newState.pageSize, cancelToken);
});

export const ResourceTileFavoriteGrid = new ApiCall('ResourceTileFavoriteGrid', async (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listResourceTileFavorite(newState.pageIndex, newState.pageSize, cancelToken);
});
