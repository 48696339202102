import { SearchBox } from '@fluentui/react';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { datasetCollections } from '../../../../Redux/Reducers/DataSet/DataSetName';
import { useDataSetSelector } from '../../../../Redux/Reducers/DataSet/reducer';
import { IResource } from '../../../../Services/SakuraApiClient';
import { PageBlockItem } from './PageBlockItem';

export const PageBlockLibrary: FC = () => {
    const dataset = useDataSetSelector('ResourcePageBlockGrid');
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const onfilter = (name: string | undefined) => {
        const perform = async () => {
            await datasetCollections['ResourcePageBlockGrid'].update({ pageIndex: 1 }, { mode: 'Full', change: { name } });
        };

        perform();
    };
    useEffect(() => {
        const source = axios.CancelToken.source();
        datasetCollections['ResourcePageBlockGrid'].init('default', { mode: 'Full', change: { name: undefined } }, undefined, source.token);
        return () => {
            console.log('throw cancel api call for dataset');
            source.cancel();
        };
    }, []);
    return (
        <div>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <SearchBox
                    placeholder='Rechercher'
                    underlined={true}
                    value={searchValue}
                    onChange={(_, val) => setSearchValue(val)}
                    onSearch={(val) => onfilter(val)}
                    onClear={() => {
                        onfilter(undefined);
                    }}
                />
            </div>
            <div className='toolBoxContent'>
                {dataset.data.map((item) => {
                    const resource = item as IResource;
                    return <PageBlockItem key={`itemLib${resource.id}`} item={resource} />;
                })}
            </div>
        </div>
    );
};
