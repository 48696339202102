import { DependencyList, useEffect } from 'react';
/* eslint-disable  @typescript-eslint/no-explicit-any */

export class WindowsActivationTracking {
    private onBlur: (() => void)[];
    private onFocus: (() => void)[];

    public constructor() {
        this.onBlur = [];
        this.onFocus = [];
        this.attachTracking();
    }
    private attachTracking() {
        const focus = () => this.onAppFocus();
        const blur = () => this.onAppBlur();
        /* Detect if the browser supports `addEventListener`
        Complies with DOM Event specification. */
        if (window.addEventListener) {
            // Handle window's `load` event.
            window.addEventListener('load', function () {
                // Wire up the `focus` and `blur` event handlers.
                window.addEventListener('focus', focus);
                window.addEventListener('blur', blur);
            });
        } else if ((window as unknown as any)?.attachEvent) {
            /* Detect if the browser supports `attachEvent`
            Only Internet Explorer browsers support that. */
            // Handle window's `load` event.
            const w = window as unknown as any;
            w.attachEvent('onload', function () {
                // Wire up the `focus` and `blur` event handlers.
                w.attachEvent('onfocus', focus);
                w.attachEvent('onblur', blur);
            });
        } else {
            /* If neither event handler function exists, then overwrite 
            the built-in event handers. With this technique any previous event
            handlers are lost. */
            // Handle window's `load` event.
            window.onload = function () {
                // Wire up the `focus` and `blur` event handlers.
                window.onfocus = focus;
                window.onblur = blur;
            };
        }
    }
    private onAppFocus() {
        this.onFocus.forEach((e) => e());
    }
    private onAppBlur() {
        this.onBlur.forEach((e) => e());
    }
    public registerFocusCallback(func: () => void) {
        this.onFocus.push(func);
    }
    public registerBlurCallback(func: () => void) {
        this.onBlur.push(func);
    }
    public unregisterFocusCallback(func: () => void) {
        this.onFocus = this.onFocus.filter((f) => f !== func);
    }
    public unregisterBlurCallback(func: () => void) {
        this.onBlur = this.onBlur.filter((f) => f !== func);
    }
}

const windowsActivationTrackingInstance = new WindowsActivationTracking();

export function useOnWindowsActivated(func: () => void, deps?: DependencyList) {
    useEffect(() => {
        windowsActivationTrackingInstance.registerFocusCallback(func);
        return () => {
            windowsActivationTrackingInstance.unregisterFocusCallback(func);
        };
    }, deps ?? []);
}
export function useOnWindowsDesactivated(func: () => void, deps?: DependencyList) {
    useEffect(() => {
        windowsActivationTrackingInstance.registerBlurCallback(func);
        return () => {
            windowsActivationTrackingInstance.unregisterBlurCallback(func);
        };
    }, deps ?? []);
}
