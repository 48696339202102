import { Dropdown, TextField, Toggle } from '@fluentui/react';
import { FC } from 'react';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { useForm } from '../../../../common/Hooks/Form';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import * as validators from '../../../../common/Hooks/Form/validators';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { PageCategories } from './PageCategories';

export interface ResourcePageInfoDialogProps {
    resource: DeepPartial<IResource>;
    onChange: (resource: DeepPartial<IResource>) => void;
    onClose: () => void;
}
export const ResourcePageInfoDialog: FC<ResourcePageInfoDialogProps> = (props: ResourcePageInfoDialogProps) => {
    const { resource, onChange, onClose } = props;

    const form = useForm<IResource>(
        {
            initialState: resource,
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        resource !== undefined,
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onChange(form.initialState);
        onClose();
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            form.commit();
            onChange({
                name: form.state.name,
                enabled: form.state.enabled,
                description: form.state.description,
                category: form.state.category,
                reusable: form.state.reusable,
            });
        }
    };
    return (
        <EditDialog
            id={'Informations'}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Page) }}
            mode={resource ? 'Edit' : 'Create'}
            title={'Informations'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />

                <Dropdown label='Category' options={PageCategories} selectedKey={form.state.category} onChange={(_, v) => form.update({ category: v?.key?.toString() })}></Dropdown>
                <TextField label='Description' value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                <div className='Grid2'>
                    <Toggle
                        label='Partageable'
                        title='Une page partageable peut étre référencé dans different module de formation.'
                        offText='Non'
                        onText='Oui'
                        checked={form.state.reusable}
                        onChange={(_, val) => form.update({ reusable: val })}
                    />
                    <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.enabled} onChange={(_, val) => form.update({ enabled: val })} />
                </div>
            </div>
        </EditDialog>
    );
};
