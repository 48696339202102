import * as React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import './LoadingSpinner.scss';
import { PropsWithChildren } from 'react';

export interface LoadingSpinnerProps {
    messageLabel: string;
}

const LoadingSpinner: React.FC<PropsWithChildren<LoadingSpinnerProps>> = (props: PropsWithChildren<LoadingSpinnerProps>) => {
    const { messageLabel, children } = props;
    return (
        <div>
            {children}
            <Spinner size={SpinnerSize.large} ariaLive='assertive' label={messageLabel} />
        </div>
    );
};
export default LoadingSpinner;
