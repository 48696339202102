import { FC } from 'react';
import { storageEndpointsSelector } from '../../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../../Redux/hook';
import { ST } from '../../../../../../common/Hooks/StorageResolver';
import { ImageViewer } from '../../../../../../common/components/ImageViewer/ImageViewer';

export interface ThumbnailImageProps {
    width?: number;
    height?: number;
    thumbnailPath: string | undefined;
    isMobileView?: boolean;
}
export const ThumbnailImage: FC<ThumbnailImageProps> = (props: ThumbnailImageProps) => {
    const { width, height, thumbnailPath, isMobileView } = props;
    const storage = useAppSelector(storageEndpointsSelector);
    // const size = useMemo(() => {
    //     if (width === undefined && height === undefined) {
    //         return getTileSize(isMobileView);
    //     }
    //     return { width, height };
    // }, [width, height, isMobileView]);
    return (
        <>
            {thumbnailPath ? (
                <ImageViewer url={ST(storage, thumbnailPath, isMobileView)} width={width ? `${width}px` : undefined} height={height ? `${height}px` : undefined} />
            ) : (
                <ImageViewer url={`/images/tile_no_visual${isMobileView ? '_m' : ''}.png`} width={width ? `${width}px` : undefined} height={height ? `${height}px` : undefined} />
            )}
        </>
    );
};
