import { FC } from 'react';

import { IQuestionnary, IQuestionnaryResult } from '../../../../../Services/SakuraApiClient';
import { QuestionResult } from './QuestionResult';

export interface ShowAnswersDetailsProps {
    result: IQuestionnaryResult;
    questionnary: IQuestionnary;
}

export const ShowAnswersDetails: FC<ShowAnswersDetailsProps> = (props: ShowAnswersDetailsProps) => {
    const { result, questionnary } = props;

    return (
        <>
            <span>{'Vos réponses au questionnaire'}</span>
            <div className='resultDetails'>
                {questionnary.questions?.map((question) => {
                    return <QuestionResult key={`qr_${question.id}`} question={question} answers={result.details?.filter((d) => d.questionId === question.id) ?? []} />;
                })}
            </div>
        </>
    );
};
