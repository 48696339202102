export const topTypes = [
    'LongHairBigHair',
    'LongHairBob',
    'LongHairBun',
    'LongHairCurly',
    'LongHairCurvy',
    'LongHairDreads',
    'LongHairFrida',
    'LongHairFro',
    'LongHairFroBand',
    'LongHairNotTooLong',
    'LongHairShavedSides',
    'LongHairMiaWallace',
    'LongHairStraight',
    'LongHairStraight2',
    'LongHairStraightStrand',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides',
    'ShortHairTheCaesar',
    'ShortHairTheCaesarSidePart',
    'NoHair',
    'Eyepatch',
    'Hat',
    'Hijab',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
];

export const mouthTypes = ['Concerned', 'Default', 'Disbelief', 'Eating', 'Grimace', 'Sad', 'ScreamOpen', 'Serious', 'Smile', 'Tongue', 'Twinkle', 'Vomit'];

export const eyeTypes = ['Close', 'Cry', 'Default', 'Dizzy', 'EyeRoll', 'Happy', 'Hearts', 'Side', 'Squint', 'Surprised', 'Wink', 'WinkWacky'];

export const eyeBrowTypes = [
    'Angry',
    'AngryNatural',
    'Default',
    'DefaultNatural',
    'FlatNatural',
    'RaisedExcited',
    'RaisedExcitedNatural',
    'SadConcerned',
    'SadConcernedNatural',
    'UnibrowNatural',
    'UpDown',
    'UpDownNatural',
];

export const facialHairTypes = ['Blank', 'BeardMedium', 'BeardLight', 'BeardMajestic', 'MoustacheFancy', 'MoustacheMagnum'];

export const accessoriesTypes = ['Blank', 'Kurt', 'Prescription01', 'Prescription02', 'Round', 'Sunglasses', 'Wayfarers'];

export const clothTypes = [
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    //   'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck',
    'ShirtVNeck',
];

export interface ColorEntry {
    name: string;
    value: string;
}
export const skinColors = [
    {
        name: 'Tanned',
        value: '#FD9841',
    },
    {
        name: 'Yellow',
        value: '#F8D25C',
    },
    {
        name: 'Pale',
        value: '#FFDBB4',
    },
    {
        name: 'Light',
        value: '#EDB98A',
    },
    {
        name: 'Brown',
        value: '#D08B5B',
    },
    {
        name: 'DarkBrown',
        value: '#AE5D29',
    },
    {
        name: 'Black',
        value: '#614335',
    },
];

export const hairColors = [
    {
        name: 'Auburn',
        value: '#A55728',
    },
    {
        name: 'Black',
        value: '#2C1B18',
    },
    {
        name: 'Blonde',
        value: '#B58143',
    },
    {
        name: 'BlondeGolden',
        value: '#D6B370',
    },
    {
        name: 'Brown',
        value: '#724133',
    },
    {
        name: 'BrownDark',
        value: '#4A312C',
    },
    {
        name: 'PastelPink',
        value: '#F59797',
    },
    /*    {
        name: 'Blue',
        value: '#000fdb'
    },
  */ {
        name: 'Platinum',
        value: '#ECDCBF',
    },
    {
        name: 'Red',
        value: '#C93305',
    },
    {
        name: 'SilverGray',
        value: '#E8E1E1',
    },
];

export const facialHairColors = [
    {
        name: 'Auburn',
        value: '#A55728',
    },
    {
        name: 'Black',
        value: '#2C1B18',
    },
    {
        name: 'Blonde',
        value: '#B58143',
    },
    {
        name: 'BlondeGolden',
        value: '#D6B370',
    },
    {
        name: 'Brown',
        value: '#724133',
    },
    {
        name: 'BrownDark',
        value: '#4A312C',
    },
    {
        name: 'Platinum',
        value: '#ECDCBF',
    },
    {
        name: 'Red',
        value: '#C93305',
    },
    {
        name: 'SilverGray',
        value: '#E8E1E1',
    },
];
export const clotheColors = [
    {
        name: 'Black',
        value: '#262E33',
    },
    {
        name: 'Blue01',
        value: '#65C9FF',
    },
    {
        name: 'Blue02',
        value: '#5199E4',
    },
    {
        name: 'Blue03',
        value: '#25557C',
    },
    {
        name: 'Gray01',
        value: '#E6E6E6',
    },
    {
        name: 'Gray02',
        value: '#929598',
    },
    {
        name: 'Heather',
        value: '#3C4F5C',
    },
    {
        name: 'PastelBlue',
        value: '#B1E2FF',
    },
    {
        name: 'PastelGreen',
        value: '#A7FFC4',
    },
    {
        name: 'PastelOrange',
        value: '#FFDEB5',
    },
    {
        name: 'PastelRed',
        value: '#FFAFB9',
    },
    {
        name: 'PastelYellow',
        value: '#FFFFB1',
    },
    {
        name: 'Pink',
        value: '#FF488E',
    },
    {
        name: 'Red',
        value: '#FF5C5C',
    },
    {
        name: 'White',
        value: '#FFFFFF',
    },
];
