import { Icon, Label } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { PageElementType } from '../../../../Services/SakuraApiClient';
import { CustomComponentName } from '../../Page/Elements/CustomComponent/type';

export interface ElementToolBoxProps {
    elementType: PageElementType;
    elementSubType?: CustomComponentName | undefined;
}

export function getVisualOfElementType(elementType: PageElementType | undefined, elementSubType?: CustomComponentName | undefined) {
    switch (elementType) {
        case PageElementType.Audio:
            return ['Volume3', 'Audio'];
        case PageElementType.Button:
            return ['TabletMode', 'Bouton'];
        case PageElementType.Container:
            return ['LargeGrid', 'Conteneur'];
        case PageElementType.Label:
            return ['InsertTextBox', 'Texte'];
        case PageElementType.RichText:
            return ['FabricTextHighlight', 'Texte enrichi'];
        case PageElementType.TextField:
            return ['TextField', 'Texte'];
        case PageElementType.DatetimeField:
            return ['DateTime', 'Date'];
        case PageElementType.NumberField:
            return ['NumberField', 'Nombre'];
        case PageElementType.CheckField:
            return ['CheckboxComposite', 'Case à cocher'];
        case PageElementType.Image:
            return ['Photo2', 'Image'];
        case PageElementType.Link:
            return ['Link', 'Lien'];
        case PageElementType.Video:
            return ['Video', 'Video'];
        case PageElementType.Questionnary:
            return ['Questionnaire', 'Questionnaire'];
        case PageElementType.PDF:
            return ['PDF', 'Visualiseur PDF'];
        case PageElementType.Separator:
            return ['Separator', 'Separateur'];

        case PageElementType.CustomComponent:
            if (elementSubType) {
                switch (elementSubType) {
                    case 'ThumbnailsSection':
                        return ['ThumbnailView', 'Section de Vignettes'];
                    case 'SocialNetwork':
                        return ['GlobeFavorite', 'Réseaux sociaux'];
                    case 'ShareButton':
                        return ['Share', 'Partage sur les réseaux'];
                    default:
                }
            }
            return ['WebComponents', 'Custom Component'];
    }
    return ['', ''];
}
export const ElementToolBox: FC<ElementToolBoxProps> = (props: ElementToolBoxProps) => {
    const { elementType, elementSubType } = props;
    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: 'ELEMENT_TYPE',
        item: { elementType, elementSubType },
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const [icon, text] = useMemo(() => getVisualOfElementType(elementType, elementSubType), [elementType, elementSubType]);
    return (
        <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className='elementToolBox' ref={drag}>
                <Icon iconName={icon} />
                <Label>{text}</Label>
            </div>
        </div>
    );
};
