import { Pivot, PivotItem, TextField } from '@fluentui/react';
import React, { FC, useContext } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ResourceLookupField } from '../../../../../common/LookupFields/ResourceLookupField';
import { IPageElementImage } from '../../../../../Services/SakuraApiClient';
import { ExecutionDataContext } from '../../../Page/ExecutionContext/ExecutionDataContextProvider';
import { CssCommonField } from '../Common/CssCommonField';
import { ImageEffectPanel } from './ImageEffectPanel';
import { DimensionsInput } from '../../../../../common/components/Css/DimensionsInput';

export interface ImageElementPanelProps {
    data: IPageElementImage;
    onChangeData: (data: DeepPartial<IPageElementImage>) => void;
}

export const ImageElementPanel: FC<ImageElementPanelProps> = (props: ImageElementPanelProps) => {
    const { onChangeData, data } = props;
    const [, dispatch] = useContext(ExecutionDataContext);
    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <ResourceLookupField
                        label='Image'
                        type='ResourceImageContentLookup'
                        selectedResourceId={data.resourceId}
                        onSelectResource={(resource) => {
                            if (resource) {
                                onChangeData({ resourceId: resource.id });
                                dispatch({
                                    type: 'addResource',
                                    payload: {
                                        resourceId: resource.id,
                                        data: {
                                            id: resource.id,
                                            blobPath: resource.blobPath,
                                            data: resource.data,
                                            type: resource.resourceType,
                                        },
                                    },
                                });
                            } else {
                                onChangeData({ resourceId: undefined });
                            }
                        }}
                    />
                    <TextField
                        label='Tool tip'
                        value={data.toolTip}
                        onChange={(_, val) => {
                            onChangeData({ toolTip: val });
                        }}
                    ></TextField>
                    <DimensionsInput
                        label='Dimensions'
                        dimension={data.style?.dimensions}
                        onChange={(dimensions) =>
                            onChangeData({
                                style: {
                                    dimensions,
                                },
                            })
                        }
                    />
                </PivotItem>
                <PivotItem headerText='Style'>
                    <ImageEffectPanel data={data} onChangeData={(d) => onChangeData(d)} />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
