import { Icon, Label } from '@fluentui/react';
import React, { CSSProperties, FC, useContext, useMemo } from 'react';
import { Dimensions, EnumVideoProvider, IPageElementVideo, PageElementImageStyle } from '../../../../../Services/SakuraApiClient';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';

export interface ElementVideoEditionProps {
    data: IPageElementVideo;
}

export const ElementVideoEdition: FC<ElementVideoEditionProps> = (props: ElementVideoEditionProps) => {
    const { data } = props;
    const [state] = useContext(ExecutionDataContext);

    const resourceVideo = useMemo(() => {
        return state.resources[data.resource?.id]?.data.content.video;
    }, [state, data]);
    return (
        <div className='designerVideoPlayer'>
            {resourceVideo ? (
                <>
                    <Label>Video player</Label>
                    <img height={75} width={75} src={resourceVideo.provider === EnumVideoProvider.Vimeo ? '/images/logo/square/vimeo.svg' : '/images/logo/square/youtube.svg'} />
                    <Label>{resourceVideo.videoId ?? ''}</Label>
                </>
            ) : (
                <Icon iconName='MyMoviesTV' style={{ fontSize: 'x-large' }} />
            )}
        </div>
    );
};
