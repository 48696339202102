import { Icon } from '@fluentui/react';
import { FC, useContext } from 'react';
import { NavMenu } from '../../../common/components/NavMenu';
import { FormationDataContext } from './FormationContext/FormationContextProvider';

export interface FormationNavigationMenuProps {
    onDoubleClickItem?: (id: string | undefined) => void;
}
export const FormationNavigationMenu: FC<FormationNavigationMenuProps> = (props: FormationNavigationMenuProps) => {
    const formationContext = useContext(FormationDataContext);
    const { onDoubleClickItem } = props;

    return (
        <>
            <NavMenu
                selectedItemId={formationContext.selectedContentPath}
                items={formationContext.menu.items}
                customPart={(_, data) => {
                    if (data.accountDataResource?.completedOn) {
                        return <Icon iconName='SkypeCircleCheck' />;
                    }
                    return <Icon iconName='CircleRing' />;
                    return null;
                }}
                onExpanded={(_, data, isExpanded) => {
                    formationContext.menu.expandItem(data.resourcePath!, isExpanded);
                }}
                onSelectedItem={(id) => {
                    if (id) {
                        formationContext.navigateTo(id);
                    }
                }}
                onDoubleClickItem={onDoubleClickItem}
            />
        </>
    );
};
