import { CSSProperties } from 'react';
import { Property } from 'csstype';
import { fontStyleToCss } from '../../../../../common/styles/FontStyles';
import { IBaseStyle, baseStyleBuilder } from '../baseStyleBuilder';
import { IFontStyle, IPageElementButtonStyle, IPageElementTextStyle } from '../../../../../Services/SakuraApiClient';
import { IStyle } from '@fluentui/react';

export function textStyleBuilder(style: IPageElementTextStyle): CSSProperties {
    return {
        ...fontStyleToCss(style?.font),
        textAlign: style?.textAlignement?.toString().toLowerCase() as Property.TextAlign | undefined,
        ...baseStyleBuilder(style),
        flex: 1,
    };
}
export function textStyleBuilderFontOnly(style: IPageElementTextStyle): CSSProperties {
    return {
        ...fontStyleToCss(style?.font),
        textAlign: style?.textAlignement?.toString().toLowerCase() as Property.TextAlign | undefined,
    };
}
export function textStyleBuilderContainerOnly(style: IPageElementTextStyle): CSSProperties {
    return {
        textAlign: style?.textAlignement?.toString().toLowerCase() as Property.TextAlign | undefined,
        ...baseStyleBuilder(style),
        flex: 1,
    };
}
export function buttonStyleBuilderFontOnly(style: { font: IFontStyle | undefined }): CSSProperties {
    return {
        ...fontStyleToCss(style?.font),
    };
}
export function buttonStyleBuilderContainerOnly(style: IBaseStyle): CSSProperties {
    return {
        ...baseStyleBuilder(style),
        flex: 1,
    };
}

export function toIStyleLabel(style: CSSProperties): IStyle {
    return {
        fontSize: style?.fontSize,
        fontWeight: style?.fontWeight as string,
        fontVariant: style?.fontVariant,
        fontFamily: style?.fontFamily,
        lineHeight: style?.lineHeight,
        fontStretch: style?.fontStretch,
        fontStyle: style?.fontStyle,
        font: style?.font,
        color: style?.color as string,
        textDecoration: style?.textDecoration as string,
        textAlign: style?.textAlign,
        opacity: style?.opacity,
    };
}

export function toIStyleContainer(style: CSSProperties): IStyle {
    return {
        textAlign: style.textAlign as string,
        opacity: style?.opacity as string | number,
        border: style?.border as string,
        borderRadius: style?.borderRadius as string,
        padding: style?.padding as string,
        margin: style?.margin as string,
        boxShadow: style?.boxShadow as string,
        alignSelf: style?.alignSelf,
        flexGrow: style?.flexGrow,
    };
}
