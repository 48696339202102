import { Pivot, PivotItem, Separator, TextField, Toggle } from '@fluentui/react';
import React, { FC, useContext, useMemo } from 'react';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { PanelCommand } from '../../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ICategoryResultDescription, IResultDescriptionBase } from '../../../../Services/SakuraApiClient';
import { Category } from './Category';
import { QuestionnaryDataContext } from './DataContext/QuestionnaryDataContextProvider';
import { ResultDescriptionBase } from './ResultDescriptionBase';

export interface CategoriesProps {
    resultDescription: DeepPartial<IResultDescriptionBase> | undefined;
    onChangeResultDescription: (resultDescription: DeepPartial<IResultDescriptionBase> | undefined) => void;
    showScoringDetails: boolean | undefined;
    onChangeShowScoringDetails: (showScoringDetails: boolean | undefined) => void;
    selectedCategory: string | undefined;
    onSelectCategory: (selectedCategory: string | undefined) => void;
    items: DeepPartial<ICategoryResultDescription>[] | undefined;
    onUpdate: (items: DeepPartial<ICategoryResultDescription>[] | undefined) => void;
    hasResponse: boolean;
}
export const Categories: FC<CategoriesProps> = (props: CategoriesProps) => {
    const { items, onUpdate, selectedCategory, onSelectCategory, showScoringDetails, onChangeShowScoringDetails, hasResponse, resultDescription, onChangeResultDescription } = props;

    return (
        <>
            <Toggle
                label={'Affichage des résultats'}
                onText='Afficher le score dans chaque categorie'
                offText={'Afficher uniquement la catégorie gagnante'}
                checked={showScoringDetails}
                onChange={(_, v) => onChangeShowScoringDetails(v)}
            />
            <Separator />
            <ResultDescriptionBase item={resultDescription} onUpdate={onChangeResultDescription} />
            <Separator />
            <Pivot
                linkFormat='tabs'
                selectedKey={selectedCategory}
                onLinkClick={(item) => {
                    onSelectCategory(item?.props.itemKey);
                }}
            >
                {(items ?? []).map((category, index) => {
                    return (
                        <PivotItem key={category.id} headerText={category?.name ?? 'Sans Nom'} itemKey={category.id}>
                            <Category
                                hasResponse={hasResponse}
                                item={category}
                                onUpdate={(item) => {
                                    if (item) {
                                        onUpdate(ArrayOperation.updateByIndex(items ?? [], index, item));
                                    }
                                }}
                            />
                        </PivotItem>
                    );
                })}
            </Pivot>
        </>
    );
};
