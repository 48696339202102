import { RoleEnum } from '../../../Services/SakuraApiClient';
import { AppDispatch, store } from '../../store';
import { createScopeDataForDefinition } from '../DynamiqueData/helpers';
import { enableAutoRefreshIntelisense, loadScope } from '../DynamiqueData/reducer';
import { SetProfileAction } from './action';
import { setProfile } from './reducer';
import { updateProfileToDynamicData } from './utils';

export function updateProfile(dispatch: AppDispatch, payload: SetProfileAction) {
    if (payload.profile?.userRole === RoleEnum.Admin || payload.profile?.userRole === RoleEnum.OrganisationAdmin) {
        if (store.getState().dynamicData.autoRefreshIntelisense === false) {
            dispatch(enableAutoRefreshIntelisense({ enable: true }));
        }
    }
    dispatch(setProfile(payload));
    updateProfileToDynamicData(dispatch, payload.profile);
    if (payload.dynVariableNames && payload.dynVariableNames.length > 0) {
        dispatch(loadScope({ scope: 'data', content: createScopeDataForDefinition(payload.profile?.accountData.data, payload.dynVariableNames) }));
    } else {
        if (payload.profile?.accountData.data && payload.profile.userRole !== RoleEnum.Admin && payload.profile.userRole !== RoleEnum.OrganisationAdmin) {
            dispatch(loadScope({ scope: 'data', content: payload.profile?.accountData.data }));
        }
    }
}
