import { IDropdownOption } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { ApiCall } from '../../../Redux/Reducers/DataSet/ApiCall/apiCall';
import { DataSetName } from '../../../Redux/Reducers/DataSet/DataSetName';
import { ITransactionLog } from '../../../Services/SakuraApiClient';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { Amount } from '../../../common/components/Amount/Amount';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';

interface NavState {
    refresh?: boolean;
}

const coinOptions: IDropdownOption[] = [
    {
        key: 'D',
        text: 'Débit',
    },
    {
        key: 'C',
        text: 'Crédit',
    },
];

export interface TransactionListProps {
    dataSource: ApiCall;
    datasetName: DataSetName;
    title: string;
    icon: string;
    currency: string;
}
export const TransactionList: FC<TransactionListProps> = (props: TransactionListProps) => {
    const { dataSource, title, icon, currency, datasetName } = props;
    const nav = useNav();

    const navState = nav.current.state as INavigationState<NavState>;
    const [messageView, setMessageView] = useState<string>();
    useEffect(() => {
        if (navState?.state?.refresh) {
            dataSource.refresh();
        }
    }, [navState?.state?.refresh]);

    const columns = [
        {
            key: 'compte',
            name: 'Compte',
            minWidth: 250,
            maxWidth: 250,
            allowSorting: true,
            isResizable: true,
            fieldName: 'signInEmail',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'email',
                fieldNames: [
                    {
                        fieldName: 'signInEmail',
                        displayName: 'email du compte',
                    },
                ],
            },
        },
        {
            key: 'firstname',
            name: 'Prénom',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'firstname',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'prénom',
                fieldNames: [
                    {
                        fieldName: 'firstname',
                        displayName: 'Prénom',
                    },
                ],
            },
        },
        {
            key: 'lastname',
            name: 'Nom',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'lastname',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'lastname',
                        displayName: 'Nom',
                    },
                ],
            },
        },
        {
            key: 'coin',
            name: 'Montant',
            fieldName: 'amount',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'Status',
                options: coinOptions,
                fieldNames: [
                    {
                        fieldName: 'amount',
                        displayName: 'Status',
                    },
                ],
            },
            onRender: (item: ITransactionLog) => {
                return <Amount variation amount={item.amount} />;
            },
        },
        {
            key: 'PreviousBalance',
            name: "Solde avant l'opération",
            fieldName: 'previousBalance',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: ITransactionLog) => {
                return <Amount amount={item.previousBalance} />;
            },
        },
        {
            key: 'CreatedOn',
            name: 'Créer le',
            fieldName: 'createdOn',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Date,
                placeholder: 'Date',
                fieldNames: [
                    {
                        fieldName: 'createdOn_0',
                        displayName: 'Depuis',
                    },
                    {
                        fieldName: 'createdOn_1',
                        displayName: "Jusqu'a",
                    },
                ],
            },
            onRender: (item: ITransactionLog) => {
                return <span>{item.createdOn.toLocaleString()}</span>;
            },
        },
    ];
    return (
        <Panel
            title={title}
            icon={icon}
            commands={[
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        dataSource.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid datasetName={datasetName} columns={columns} />
        </Panel>
    );
};
