import { ActionButton, Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { setThumbnailSections, thumbnailSectionsSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch, useAppSelector } from '../../../../Redux/hook';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { AskDialog } from '../../../../common/components/Dialog/AskDialog/AskDialog';

export interface SectionSettingLookupProps {
    label: string;
    required?: boolean;
    value: string;
    onChange: (section: string) => void;
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 175 },
};
export const SectionSettingLookup: FC<SectionSettingLookupProps> = (props: SectionSettingLookupProps) => {
    const { label, value, onChange, required } = props;
    const sections = useAppSelector(thumbnailSectionsSelector);
    const appDispatch = useAppDispatch();
    const [showAddSection, setShowAddSection] = useState<boolean>();
    const options = useMemo<IDropdownOption[]>(() => {
        return (
            sections?.map((o) => ({
                key: o,
                label: o,
                text: o,
            })) ?? []
        );
    }, [sections]);
    return (
        <>
            <Dropdown
                required={required}
                label={label}
                title={label}
                styles={dropdownStyles}
                options={options}
                selectedKey={value}
                onChange={(_, option) => {
                    onChange(option?.key as string);
                }}
                onRenderList={(props, df) => {
                    if (df) {
                        return (
                            <div>
                                <ActionButton
                                    iconProps={{ iconName: 'AddTo' }}
                                    onClick={() => {
                                        setShowAddSection(true);
                                    }}
                                >
                                    {'Créer une nouvelle section'}
                                </ActionButton>
                                {df(props)}
                            </div>
                        );
                    }
                    return <div></div>;
                }}
            />
            {showAddSection ? (
                <AskDialog
                    id='onCreateItem'
                    onClose={(itemName) => {
                        if (itemName) {
                            const sectionSetting = [...(sections ?? [])];
                            if (sectionSetting.indexOf(itemName) === -1) {
                                sectionSetting.push(itemName);

                                performApiCall(async (client, canceltoken) => {
                                    await client.setSetting(
                                        'SectionSetting',
                                        {
                                            sections: sectionSetting,
                                        },
                                        canceltoken,
                                    );
                                    appDispatch(
                                        setThumbnailSections({
                                            sections: sectionSetting,
                                        }),
                                    );
                                }, appDispatch);
                            }
                        }
                        setShowAddSection(false);
                    }}
                    show
                    title={'Ajouter une section'}
                    icon={{ iconName: 'ThumbnailView' }}
                    question={'Quel est le nom de la section'}
                />
            ) : null}
        </>
    );
};
