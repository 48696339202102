import { PrimaryButton } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { ResourcePublicLinkAccessGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { FilterValue } from '../../../Redux/Reducers/DataSet/state';
import { IResourcePublicAccessTrackingExtended } from '../../../Services/SakuraApiClient';
import { ColumnInfo, FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { displayDateAndTime } from '../../../common/helpers/DateTimeHelper';
import { DatasetGrid } from '../../Common/DatasetGrid';

export interface SocialNetworkLinkTrackingListProps {
    contextId: string;
    resourceId: number;
}
export const ResourcePublicLinkAccessList: FC<SocialNetworkLinkTrackingListProps> = (props: SocialNetworkLinkTrackingListProps) => {
    const { resourceId, contextId } = props;

    const columns = useMemo<ColumnInfo[]>(() => {
        return [
            {
                key: 'visitedOn',
                name: 'Visité le',
                fieldName: 'visitedOn',
                minWidth: 150,
                maxWidth: 150,
                allowSorting: true,
                isResizable: true,
                onRender: (item: IResourcePublicAccessTrackingExtended) => {
                    return <span>{displayDateAndTime(item.visitedOn)}</span>;
                },
            },
            {
                key: 'name',
                name: 'Via lien Réseau sociaux',
                fieldName: 'linkShortName',
                minWidth: 150,
                maxWidth: 150,
                allowSorting: true,
                isResizable: true,
                filterOption: {
                    type: FilterType.Text,
                    placeholder: 'nom court (SLUG)',
                    fieldNames: [
                        {
                            fieldName: 'linkShortName',
                            displayName: 'Nom court (SLUG)',
                        },
                    ],
                },
                onRender: (item: IResourcePublicAccessTrackingExtended) => {
                    return <span title={item.linkDescription}>{item.linkId ? `${item.linkName} (${item.linkShortName})` : 'Non'}</span>;
                },
            },
        ];
    }, []);

    const defaultParameter = useMemo<Record<string, FilterValue>>(() => ({ resourceId }), [resourceId]);

    return (
        <div>
            <PrimaryButton iconProps={{ iconName: 'Refresh' }} text='Actualiser' onClick={() => ResourcePublicLinkAccessGrid.refresh()} />
            <DatasetGrid
                datasetName={'ResourcePublicLinkAccessGrid'}
                contextId={contextId}
                parameters={defaultParameter}
                columns={columns}
                getKey={(item: IResourcePublicAccessTrackingExtended) => item.resourceId ?? ''}
            />
        </div>
    );
};
