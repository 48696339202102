import clsx from 'clsx';
import React, { FC } from 'react';
import { ResourceViewer } from '../../../../common/components/ResourcePackage/ResourceViewer';
import { SvgButton, Visual } from '../../../../common/components/Svg/SvgButton';
import { DeepPartial } from '../../../../common/helpers/ObjectAndArray';
import { IImageEffect } from '../../../../Services/SakuraApiClient';
import { useDynamicData } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { useBracketResolver } from '../../../../common/components/ExpressionField/Hook/useBracketResolver';

export interface QuestionChoiceAnswerProps {
    answerText: string | undefined;
    selected: boolean;
    hasAnswerPic: boolean;
    index: number;
    imageEffect?: DeepPartial<IImageEffect>;
    onSelect: (selected: boolean) => void;
    imageResourceId?: number;
    uncheckedIcon: Visual;
    checkedIcon: Visual;
}
const size = {
    width: 48,
    height: 48,
};

export const QuestionChoiceAnswer: FC<QuestionChoiceAnswerProps> = (props: QuestionChoiceAnswerProps) => {
    const { answerText, hasAnswerPic, index, onSelect, selected, imageEffect, imageResourceId, uncheckedIcon, checkedIcon } = props;

    const dynData = useDynamicData();
    const resolvedAnswer = useBracketResolver(answerText, dynData);
    return (
        <div
            role='button'
            className={clsx(hasAnswerPic ? 'itemPic' : 'item', selected ? 'selected' : '', imageEffect ? 'borderLess' : undefined)}
            style={{ animationDelay: `${(100 * index).toString()}ms` }}
            onClick={imageEffect && imageResourceId ? undefined : () => onSelect(!selected)}
        >
            {imageResourceId ? (
                <>
                    <div className='answerPic'>
                        <ResourceViewer resourceId={imageResourceId} effect={imageEffect} selected={imageEffect ? selected : undefined} onSelect={imageEffect ? onSelect : undefined} />
                    </div>
                    {resolvedAnswer && resolvedAnswer !== '' ? (
                        <div>
                            <p>{resolvedAnswer}</p>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    <SvgButton state={selected} visualFalse={uncheckedIcon} visualTrue={checkedIcon} width={size.width} height={size.height} />
                    <div>
                        <p>{resolvedAnswer}</p>
                    </div>
                </>
            )}
        </div>
    );
};
