import { ActionButton, IIconProps, PrimaryButton } from '@fluentui/react';
import React, { FC } from 'react';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { ST, triggerDownload, triggerDownloadBlob } from '../../Hooks/StorageResolver';

export interface DownloadLinkProps {
    blobPath: string;
    blobPathKind: 'Value' | 'ProtectedValue';
    display: 'Action' | 'Button';
    text: string;
    downloadName?: string;
    iconProps?: IIconProps;
    buttonStyle?: React.CSSProperties;
    disabled?: boolean;
}
export const DownloadLink: FC<DownloadLinkProps> = (props: DownloadLinkProps) => {
    const { blobPath, blobPathKind, display, iconProps, text, downloadName, buttonStyle, disabled } = props;
    const storage = useAppSelector(storageEndpointsSelector);
    const download = () => {
        if (blobPath.startsWith('Public')) {
            const url = ST(storage, blobPath);
            if (url) {
                triggerDownload(url, downloadName);
            }
        } else {
            if (blobPathKind === 'Value') {
                triggerDownloadBlob(blobPath);
            } else {
                triggerDownload('/api/Storage/P/access?blobPath=' + encodeURIComponent(blobPath), downloadName);
            }
        }
    };
    return (
        <>
            {display === 'Button' && <PrimaryButton disabled={disabled} iconProps={iconProps} style={buttonStyle} text={text} onClick={download} />}
            {display === 'Action' && <ActionButton disabled={disabled} iconProps={iconProps} text={text} onClick={download} />}
        </>
    );
};
