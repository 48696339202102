import { ActionButton, Checkbox, PrimaryButton, TextField } from '@fluentui/react';
import { FC, useState } from 'react';
import { required, useForm, validEmail } from '../../../common/Hooks/Form';

import { useLinks } from '../../../Redux/Reducers/System/reducer';
import { LoadingSpinner } from '../../../common/components/loadingSpinner';
import { openNewTab } from '../../../common/helpers/uri';
import './ProspectSubscriptionForm.scss';

export interface ProspectSubscriptionFormProps {
    inscriptionLabel: string;
    buttonText: string;
    askName?: boolean;
    onSubscribe: (firstname: string, lastname: string, email: string) => Promise<void>;
}
interface subscriptionForm {
    firstName?: string;
    lastName?: string;
    email?: string;
    acceptCondition?: boolean;
}

export const ProspectSubscriptionForm: FC<ProspectSubscriptionFormProps> = (props: ProspectSubscriptionFormProps) => {
    const { inscriptionLabel, onSubscribe, buttonText, askName } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const links = useLinks();
    const form = useForm<subscriptionForm>(
        {
            initialState: {},
            validators: {
                fields: {
                    firstName: { displayName: 'Le prénom', validators: [required] },
                    lastName: askName ? { displayName: 'Le nom', validators: [required] } : undefined,
                    email: { displayName: "L'email", validators: [required, validEmail] },
                },
            },
        },
        false,
    );
    const valid = () => {
        const error = form.validate();
        if (!error) {
            const perform = async () => {
                setLoading(true);
                await onSubscribe(form.state.firstName ?? '', form.state.lastName ?? '', form.state.email ?? '');
                setLoading(false);
            };
            perform();
        }
    };
    return (
        <div className='prospectForm'>
            <h2>{inscriptionLabel}</h2>

            {askName ? <TextField placeholder='Nom' value={form.state.lastName} errorMessage={form.fieldErrors.lastName} onChange={(_, lastName) => form.update({ lastName })} /> : null}
            <TextField placeholder='Prénom' value={form.state.firstName} errorMessage={form.fieldErrors.firstName} onChange={(_, firstName) => form.update({ firstName })} />
            <TextField placeholder='Email' value={form.state.email} errorMessage={form.fieldErrors.email} onChange={(_, email) => form.update({ email })} />
            <Checkbox
                checked={form.state.acceptCondition ?? false}
                label="J'accepte de recevoir les e-mails de sakura-coaching et confirme avoir pris connaissance de la politique de confidentialité et mentions légales."
                onChange={(_, acceptCondition) => form.update({ acceptCondition })}
            />
            <ActionButton
                style={{ alignSelf: 'center' }}
                onClick={() => {
                    if (links?.privacyPolicy) {
                        openNewTab(links.privacyPolicy);
                    }
                }}
            >
                Politique de confidentialité et mentions légales
            </ActionButton>
            {loading ? (
                <div className='buttonLoading'>
                    <span>{buttonText}</span>
                    <LoadingSpinner messageLabel='' />
                </div>
            ) : (
                <PrimaryButton className='sakuraButton' disabled={form.state.acceptCondition !== true} text={buttonText} onClick={valid}></PrimaryButton>
            )}
        </div>
    );
};
