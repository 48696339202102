import { FC, useMemo } from 'react';
import { mobileViewInContextSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { EnumVideoProvider, IAccountDataResource, IResource } from '../../../Services/SakuraApiClient';
import { VideoViewer } from '../../../common/components/VideoViewer/VideoViewer';
import './videoView.scss';

export interface VideoViewProps {
    resource: IResource;
    accountResourceData: IAccountDataResource;
}

export const VideoView: FC<VideoViewProps> = (props: VideoViewProps) => {
    const { resource } = props;
    const resourceVideo = useMemo(() => {
        return resource.data.content.video;
    }, [resource]);
    const isMobile = useAppSelector(mobileViewInContextSelector);
    const size = useMemo(() => {
        return isMobile ? { height: 150, width: 330 } : { height: 300, width: 730 };
    }, [isMobile]);
    return (
        <div className='videoView'>
            <div className='videoBox'>
                {isMobile ? (
                    <VideoViewer key={'mobile'} height={size.height} width={size.width} provider={resourceVideo.provider ?? EnumVideoProvider.Youtube} videoId={resourceVideo.videoId ?? ''} />
                ) : (
                    <VideoViewer key={'desktop'} height={size.height} width={size.width} provider={resourceVideo.provider ?? EnumVideoProvider.Youtube} videoId={resourceVideo.videoId ?? ''} />
                )}
            </div>
        </div>
    );
};
