import { FC, useMemo, useState } from 'react';
import { IResourceTile } from '../../../../../../../Services/SakuraApiClient';

import { ResourceTileFavoriteGrid, ResourceTileGrid } from '../../../../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { useAppDispatch } from '../../../../../../../Redux/hook';
import { performApiCall } from '../../../../../../../common/Hooks/useApiCall';
import { CircleButton } from '../../../../../../../common/components/CircleButton';
import './ThumbnailItemDetails.scss';
import { resourceTypeToActions } from './resourceActionMap';
export interface ThumbnailItemResourceProps {
    data: IResourceTile;
    onOpen: () => Promise<void>;
}
export const ThumbnailItemResource: FC<ThumbnailItemResourceProps> = (props: ThumbnailItemResourceProps) => {
    const { data, onOpen } = props;
    const appDispatch = useAppDispatch();
    const [isLoadingOperationList, setIsLoadingOperationList] = useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

    const action = useMemo(() => {
        return resourceTypeToActions[data.type];
    }, [data]);
    return (
        <>
            <div className='commandBar'>
                <CircleButton
                    toolTip={action.label}
                    iconName={action.icon}
                    size='medium'
                    isLoading={isLoadingAction}
                    onClick={async () => {
                        setIsLoadingAction(true);
                        await onOpen();
                        setIsLoadingAction(false);
                    }}
                />
                {data.inList ? (
                    <CircleButton
                        toolTip={'Retirer de ma liste'}
                        iconName={'Cancel'}
                        size='medium'
                        isLoading={isLoadingOperationList}
                        onClick={() => {
                            setIsLoadingOperationList(true);
                            performApiCall(async (client, ct) => {
                                try {
                                    await client.deleteResourceFromFavoriteList(data.id, ct);
                                    ResourceTileGrid.PatchData<IResourceTile>((item) => {
                                        if (item.id === data.id) {
                                            return { ...item, inList: false };
                                        }
                                        return item;
                                    });
                                    ResourceTileFavoriteGrid.refresh();
                                } finally {
                                    setIsLoadingOperationList(false);
                                }
                            }, appDispatch);
                        }}
                    />
                ) : (
                    <CircleButton
                        toolTip={'Ajouter à ma liste'}
                        iconName={'Add'}
                        size='medium'
                        isLoading={isLoadingOperationList}
                        onClick={() => {
                            setIsLoadingOperationList(true);
                            performApiCall(async (client, ct) => {
                                try {
                                    await client.addResourceToFavoriteList(data.id, ct);
                                    ResourceTileGrid.PatchData<IResourceTile>((item) => {
                                        if (item.id === data.id) {
                                            return { ...item, inList: true };
                                        }
                                        return item;
                                    });
                                    ResourceTileFavoriteGrid.refresh();
                                } finally {
                                    setIsLoadingOperationList(false);
                                }
                            }, appDispatch);
                        }}
                    />
                )}
            </div>
            {/* <PrimaryButton text={action.label} iconProps={{ iconName: action.icon }} /> */}
            <h3>{data.uxData?.name ?? data.name ?? ''}</h3>
            <span>{data.uxData?.description ?? ''}</span>
        </>
    );
};
