import { Icon } from '@fluentui/react';

export interface INavMenuEntry<T> {
    name: string | undefined;
    icon: string | undefined;
    id: string | undefined;
    data: T;
    isExpanded?: boolean;
    children: INavMenuEntry<T>[] | undefined;
}

interface NavMenuProps<T> {
    items: INavMenuEntry<T>[] | undefined;
    selectedItemId: string | undefined;
    onSelectedItem: (id: string | undefined, data: T) => void;
    onDoubleClickItem?: (id: string | undefined) => void;
    notRoot?: boolean;
    customPart?: ((id: string, data: T) => ReactNode) | undefined;
    onExpanded: (id: string, data: T, isExpanded: boolean) => void;
}

import clsx from 'clsx';
import { ReactNode } from 'react';
import { AccordionIcon } from '../AccordionIcon/AccordionIcon';
import './NavMenu.scss';

export const NavMenu = <T extends object>(props: NavMenuProps<T>) => {
    const { items, selectedItemId, onSelectedItem, notRoot, customPart, onExpanded, onDoubleClickItem } = props;

    return items && items.length > 0 ? (
        <div className={clsx('sakura-navMenu', notRoot ? '' : 'root')}>
            {items?.map((item) => {
                const hasChildren = item?.children && item.children.length > 0;
                return (
                    <div key={item.id} className={clsx('sakura-navMenuItem', notRoot ? 'notRoot' : '')}>
                        <div
                            className={clsx('sakura-navMenu-header', item.id === selectedItemId ? 'selected' : '')}
                            onClick={(ev) => {
                                if (!onDoubleClickItem) {
                                    ev.stopPropagation();
                                }
                                const isSelected = item.id === selectedItemId;
                                if (!isSelected) {
                                    onSelectedItem(item.id, item.data);
                                }
                                if (item.children && item.children.length > 0 && onExpanded) {
                                    if (!isSelected) {
                                        onExpanded(item.id!, item.data, true);
                                    } else {
                                        onExpanded(item.id!, item.data, !item.isExpanded);
                                    }
                                }
                            }}
                            onDoubleClick={(ev) => {
                                debugger;
                                ev.stopPropagation();
                                const isSelected = item.id === selectedItemId;
                                if (!isSelected) {
                                    onSelectedItem(item.id, item.data);
                                }
                                if (onDoubleClickItem) {
                                    onDoubleClickItem(item.id);
                                }
                            }}
                        >
                            <div className='sakura-navMenu-header-title'>
                                {hasChildren ? (
                                    <AccordionIcon
                                        open={item.isExpanded ?? false}
                                        onClick={() => {
                                            onExpanded(item.id!, item.data, !item.isExpanded);
                                        }}
                                    />
                                ) : (
                                    <Icon iconName={item.icon} />
                                )}
                                <span>{item.name}</span>
                            </div>
                            {customPart ? customPart(item.id ?? '', item.data) : null}
                        </div>
                        {item.isExpanded && item.children ? (
                            <NavMenu notRoot={true} selectedItemId={selectedItemId} items={item.children} onSelectedItem={onSelectedItem} customPart={customPart} onExpanded={onExpanded} />
                        ) : null}
                    </div>
                );
            })}
        </div>
    ) : null;
};
