import { FC, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AccountPage } from '../Pages/Account/AccountPage';
import { AccountDetail } from '../Pages/Admin/Accounts/AccountDetail';
import { AccountsList } from '../Pages/Admin/Accounts/AccountsList';
import { BadgesList } from '../Pages/Admin/Badges/BadgesList';
import { AccountActivityLogsList } from '../Pages/Admin/Logs/AccountActivityLogsList';
import { CoinTransactionList } from '../Pages/Admin/Logs/CoinTransactionList';
import { DiamondTransactionList } from '../Pages/Admin/Logs/DiamondTransactionList';
import { ExternalSystemLogsList } from '../Pages/Admin/Logs/ExternalSystemLogsList';
import { OfferDetail } from '../Pages/Admin/Offers/OfferDetail';
import { OffersList } from '../Pages/Admin/Offers/OffersList';
import { ProductDetail } from '../Pages/Admin/Products/ProductDetail';
import { ProductsList } from '../Pages/Admin/Products/ProductsList';
import { ResourceAudioList } from '../Pages/Admin/Resources/Audio/ResourceAudioList';
import { ResourceDocumentDetailsPage } from '../Pages/Admin/Resources/Document/ResourceDocumentDetailsPage';
import { ResourceDocumentList } from '../Pages/Admin/Resources/Document/ResourceDocumentList';
import { ResourceFormationDetails } from '../Pages/Admin/Resources/Formation/ResourceFormationDetails';
import { ResourceFormationList } from '../Pages/Admin/Resources/Formation/ResourceFormationList';
import { ResourceGameContentList } from '../Pages/Admin/Resources/Game/ResourceGameContentList';
import { ResourceImageList } from '../Pages/Admin/Resources/Image/ResourceImageList';
import { ResourcePageEditor } from '../Pages/Admin/Resources/Page/ResourcePageEditor';
import { ResourcePageList } from '../Pages/Admin/Resources/Page/ResourcePageList';
import { ResourceQuestionnaryDetails } from '../Pages/Admin/Resources/Questionnary/ResourceQuestionnaryDetails';
import { ResourceQuestionnaryList } from '../Pages/Admin/Resources/Questionnary/ResourceQuestionnaryList';
import { ResourceVideoList } from '../Pages/Admin/Resources/Video/ResourceVideoList';
import { ResourceWorkflowList } from '../Pages/Admin/Resources/Workflow/ResourceWorkflowList';
import { SocialNetworkLinksList } from '../Pages/Admin/SocialNetworkLinks/SocialNetworkLinksList';
import { BuildinPageName } from '../Pages/Admin/System/MenuSetting/menuSetting';
import { SystemPage } from '../Pages/Admin/System/SystemPage';
import { QuestionnaryResponseListView } from '../Pages/DataCollection/QuestionnaryResponseListView';
import { NoPageConfigured } from '../Pages/NoPageConfigured';
import { ResourceRouteView } from '../Pages/ResourceRouteView';
import { UserRouteView } from '../Pages/UserRouteView';
import { SimplePublic } from '../PagesPublic/SimplePublic';
import { ViewQuestionnaryResult } from '../PagesShared/ViewQuestionnaryResult';
import { ViewResource } from '../PagesShared/ViewResource';
import { buildUserMenuRoutes } from '../Redux/Reducers/Menu/buildUserMenu';
import { applicationMenuSelector } from '../Redux/Reducers/Menu/reducer';
import { userMenuSelector, userOrganizationSelector, userRoleSelector } from '../Redux/Reducers/User/reducer';
import { useAppSelector } from '../Redux/hook';
import { LoginCallback, LogoutCallback, authService } from '../Services/Authentication';
import { AuthenticationBarrier } from '../Services/Authentication/AuthenticationBarrier';
import { ProspectAccess } from '../Services/Authentication/ProspectAccess';
import { SilentLoginCallback } from '../Services/Authentication/SilentLoginCallback';
import { RoleEnum } from '../Services/SakuraApiClient';
import { ErrorPage } from '../common/ErrorPage';
import { InvalidLinkPage } from '../common/InvalidLinkPage';
import { Layout } from '../common/Layout/Layout';
import { LoadingScreen } from '../common/LoadingScreen';
import { LogoutPage } from '../common/LogoutPage';
import { WebRedirect } from './WebRedirect';

export const GlobalRoutes: FC = () => {
    const userRole = useAppSelector(userRoleSelector);
    const userMenu = useAppSelector(userMenuSelector);
    const applicationMenu = useAppSelector(applicationMenuSelector);
    const userOrga = useAppSelector(userOrganizationSelector);

    const { userMenuRoutes, firstRoute } = useMemo(() => {
        if (userMenu) {
            const { routes, firstRoute } = buildUserMenuRoutes(userMenu);
            if (routes.length > 0) return { userMenuRoutes: routes, firstRoute: `/${firstRoute}` };
        }
        return { userMenuRoutes: [], firstRoute: undefined };
    }, [userMenu]);
    return (
        <Routes>
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/invalid' element={<InvalidLinkPage />} />
            <Route path='/login' element={<LoginCallback authService={authService}></LoginCallback>} />
            <Route path='/silentLogin' element={<SilentLoginCallback authService={authService}></SilentLoginCallback>} />
            <Route
                path='/logout/:organization'
                element={
                    <LogoutCallback authService={authService}>
                        <LogoutPage />
                    </LogoutCallback>
                }
            />
            <Route
                path='/logout'
                element={
                    <LogoutCallback authService={authService}>
                        <LogoutPage />
                    </LogoutCallback>
                }
            />
            <Route path='/pub' element={<SimplePublic />} />
            <Route path='/web/*' element={<WebRedirect />} />
            <Route path='/shared/view/*' element={<ViewResource />} />
            <Route path='/shared/results/*' element={<ViewQuestionnaryResult />} />
            <Route path='/access/guest' element={<ProspectAccess />} />
            <Route path='/:organization/*'>
                <Route
                    element={
                        <AuthenticationBarrier authService={authService} renderLoadingScreen={() => <LoadingScreen text='Bienvenue &#x1F603;' />}>
                            <Outlet />
                        </AuthenticationBarrier>
                    }
                >
                    <Route
                        element={
                            <Layout menu={applicationMenu}>
                                <Outlet />
                            </Layout>
                        }
                    >
                        {firstRoute ? <Route index element={<Navigate to={`${userOrga}/app${firstRoute}`} replace />} /> : <Route index element={<NoPageConfigured />} />}
                        {userMenuRoutes.map((menu) => (
                            <Route key={menu.routePath} path={menu.routePath} element={<UserRouteView data={menu} />} />
                        ))}
                        {/* Build-in pages */}
                        <Route path={BuildinPageName.Account} element={<AccountPage />} />
                        <Route path={'data/:type/:id'} element={<ResourceRouteView />} />
                        {/* End of Build-in pages */}
                        {userRole === RoleEnum.Admin && (
                            <>
                                <Route path='datacollection/questionnaryResult' element={<QuestionnaryResponseListView />} />
                                <Route path='admin'>
                                    <Route path='settings/offers/create' element={<OfferDetail />} />
                                    <Route path='settings/offers/edit/:id' element={<OfferDetail />} />
                                    <Route path='settings/offers' element={<OffersList />} />
                                    <Route path='settings/products' element={<ProductsList />} />
                                    <Route path='settings/products/create' element={<ProductDetail />} />
                                    <Route path='settings/products/edit/:id' element={<ProductDetail />} />
                                    <Route path='settings/resources/formation' element={<ResourceFormationList />} />
                                    <Route path='settings/resources/formation/:id' element={<ResourceFormationDetails />} />
                                    <Route path='settings/resources/page' element={<ResourcePageList />} />
                                    <Route path='settings/resources/page/create' element={<ResourcePageEditor />} />
                                    <Route path='settings/resources/page/:id' element={<ResourcePageEditor />} />
                                    <Route path='settings/resources/Questionnary' element={<ResourceQuestionnaryList />} />
                                    <Route path='settings/resources/Questionnary/create' element={<ResourceQuestionnaryDetails />} />
                                    <Route path='settings/resources/Questionnary/:id' element={<ResourceQuestionnaryDetails />} />
                                    <Route path='settings/resources/document' element={<ResourceDocumentList />} />
                                    <Route path='settings/resources/document/:id' element={<ResourceDocumentDetailsPage />} />
                                    <Route path='settings/resources/image' element={<ResourceImageList />} />
                                    <Route path='settings/resources/audio' element={<ResourceAudioList />} />
                                    <Route path='settings/resources/video' element={<ResourceVideoList />} />
                                    <Route path='settings/resources/gamecontent' element={<ResourceGameContentList />} />
                                    <Route path='settings/workflow' element={<ResourceWorkflowList />} />

                                    <Route path='settings/badges' element={<BadgesList />} />
                                    <Route path='settings/socialNetworkLinks' element={<SocialNetworkLinksList />} />
                                    <Route path='accounts' element={<AccountsList />} />
                                    <Route path='accounts/create' element={<AccountDetail />} />
                                    <Route path='accounts/edit/:id' element={<AccountDetail />} />
                                    <Route path='system' element={<SystemPage />} />
                                    <Route path='logs/externalSystem' element={<ExternalSystemLogsList />} />
                                    <Route path='logs/coinTransaction' element={<CoinTransactionList />} />
                                    <Route path='logs/diamondTransaction' element={<DiamondTransactionList />} />
                                    <Route path='logs/account' element={<AccountActivityLogsList />} />
                                </Route>
                            </>
                        )}
                        <Route path='*' element={<Navigate to={`/${userOrga}${firstRoute}`} replace />} />
                    </Route>
                </Route>
            </Route>
            <Route path='*' element={<Navigate to='/app' replace />} />
        </Routes>
    );
};
