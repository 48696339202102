export type ValueTyping = number | string | boolean | Date | undefined | null | Record<string, unknown>;
export type ValueType = 'number' | 'string' | 'boolean' | 'Date' | 'undefined' | 'null';

export class Value {
    private static readonly DatePattern = /^(0?[1-9]|[12][0-9]|3[01])(?:\/|-)(0[1-9]|1[0-2])(?:\/|-)(\d{4})$/;

    valueObject: ValueTyping;
    type: string;

    asBoolean(): boolean {
        if (this.valueObject === null || this.valueObject === undefined) {
            return false;
        }

        if (this.type !== 'boolean') {
            const val = Value.Transtype(this, 'boolean').valueObject as boolean;
            return val !== null ? val : false;
        }

        return this.valueObject as boolean;
    }
    public getValue<T>(): T {
        return this.valueObject as T;
    }
    private static ParseDate(dateStr: string | null): Date | null {
        if (dateStr !== null && dateStr !== undefined) {
            const match = dateStr.match(Value.DatePattern);
            if (match) {
                const day = parseInt(match[1], 10);
                const month = parseInt(match[2], 10);
                const year = parseInt(match[3], 10);
                return new Date(year, month - 1, day);
            }
        }
        return null;
    }

    private static ParseNumber(str: string | null): number | null {
        if (str !== null && str !== undefined) {
            const potentialNumber = str.replace(',', '.');
            if (!potentialNumber.includes('.')) {
                const intValue = parseInt(potentialNumber, 10);
                if (!isNaN(intValue)) {
                    return intValue;
                }
            }
            const doubleValue = parseFloat(potentialNumber);
            if (!isNaN(doubleValue)) {
                return doubleValue;
            }
        }
        return null;
    }

    private static ParseBoolean(str: string | null): boolean | null {
        if (str !== null && str !== undefined) {
            const lowerStr = str.toLowerCase();
            if (['true', 'vrai', 'yes', 'oui', 'ok'].includes(lowerStr)) {
                return true;
            }
            if (['false', 'faux', 'no', 'non', 'ko'].includes(lowerStr)) {
                return false;
            }
        }
        return null;
    }

    private static DisplayDate(date: Date | null): string {
        if (date !== null && date !== undefined) {
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().padStart(4, '0')}`;
        }
        return '';
    }

    static CreateValue(value: ValueTyping): Value {
        if (value !== null && value !== undefined) {
            if (typeof value === 'boolean') {
                return new Value({ type: 'boolean', valueObject: value });
            }
            if (value instanceof Date) {
                return new Value({ type: 'date', valueObject: value });
            }
            if (typeof value === 'number') {
                return new Value({ type: 'number', valueObject: value });
            }
            return new Value({ type: 'string', valueObject: value.toString() });
        }

        if (value === null) {
            return new Value({ type: 'null', valueObject: null });
        }

        return new Value({ type: 'undefined', valueObject: null });
    }

    static ParseValue(valueString: string): Value {
        if (!valueString) {
            return new Value({ type: 'undefined', valueObject: null });
        }

        if ((valueString.startsWith("'") && valueString.endsWith("'")) || (valueString.startsWith('"') && valueString.endsWith('"'))) {
            return new Value({ type: 'string', valueObject: valueString.substring(1, valueString.length - 1) });
        }

        const boolValue = Value.ParseBoolean(valueString);
        if (boolValue !== null) {
            return new Value({ type: 'boolean', valueObject: boolValue });
        }

        const dateValue = Value.ParseDate(valueString);
        if (dateValue !== null) {
            return new Value({ type: 'date', valueObject: dateValue });
        }

        const numberValue = Value.ParseNumber(valueString);
        if (numberValue !== null) {
            return new Value({ type: 'number', valueObject: numberValue });
        }

        return new Value({ type: 'undefined', valueObject: null });
    }

    static ValueOperator(valueLeft: Value, op: string, valueRight: Value): Value {
        if (valueLeft !== null && valueRight !== null) {
            if (valueLeft.type === valueRight.type) {
                return Value.ValueOperation(valueLeft, op, valueRight);
            }

            const valueLeftAdapt = Value.Transtype(valueLeft, valueRight.type);
            return Value.ValueOperation(valueLeftAdapt, op, valueRight);
        }

        return new Value({ type: 'null', valueObject: null });
    }

    static ValueCompare(valueLeft: Value, op: string, valueRight: Value): boolean {
        if (valueLeft !== null && valueRight !== null) {
            if (valueLeft.type === valueRight.type) {
                return Value.ValueComparator(valueLeft, op, valueRight);
            }

            const valueLeftAdapt = Value.Transtype(valueLeft, valueRight.type);
            return Value.ValueComparator(valueLeftAdapt, op, valueRight);
        }

        return false;
    }

    public static Transtype(value: Value, targettype: string): Value {
        if (value.type === targettype) {
            return value;
        }
        switch (targettype) {
            case 'date':
                switch (value.type) {
                    case 'boolean':
                    case 'number':
                        return new Value({ type: 'date', valueObject: new Date(value.valueObject as number) });
                    case 'string':
                        return new Value({ type: 'date', valueObject: Value.ParseDate(value.valueObject as string) });
                    case 'null':
                    case 'undefined':
                        return new Value({ type: 'date', valueObject: new Date(0) });
                }
                break;
            case 'boolean':
                switch (value.type) {
                    case 'date':
                        return new Value({ type: 'boolean', valueObject: false });
                    case 'number':
                        const val = value?.valueObject;
                        if (val !== null) {
                            return new Value({ type: 'boolean', valueObject: val ? true : false });
                        }
                        return new Value({ type: 'boolean', valueObject: false });
                    case 'string':
                        return new Value({ type: 'boolean', valueObject: Value.ParseBoolean(value.valueObject as string) });
                    case 'null':
                    case 'undefined':
                        return new Value({ type: 'boolean', valueObject: false });
                }
                break;
            case 'number':
                switch (value.type) {
                    case 'date':
                    case 'boolean':
                        return new Value({ type: 'number', valueObject: value.valueObject !== null ? (value.valueObject ? 1 : 0) : 0 });
                    case 'string':
                        return new Value({ type: 'number', valueObject: Value.ParseNumber(value.valueObject as string) });
                    case 'null':
                    case 'undefined':
                        return new Value({ type: 'number', valueObject: 0 });
                }
                break;
            case 'string':
                switch (value.type) {
                    case 'number':
                    case 'boolean':
                        return new Value({ type: 'string', valueObject: value.valueObject?.toString() });
                    case 'date':
                        return new Value({ type: 'string', valueObject: Value.DisplayDate(value.valueObject as Date) });
                    case 'null':
                    case 'undefined':
                        return new Value({ type: 'string', valueObject: '' });
                }
                break;
        }

        return new Value({ type: 'undefined', valueObject: null });
    }

    private static CompareString(left: string | null, right: string | null): number {
        if (left === right) {
            return 0;
        }

        if (left !== null && right !== null) {
            return left.toLowerCase().localeCompare(right.toLowerCase());
        }

        return -1;
    }

    private static ValueComparator(valueLeft: Value, op: string, valueRight: Value): boolean {
        switch (op) {
            case '=':
                if (valueLeft.type === 'number') {
                    return valueLeft.valueObject === valueRight.valueObject;
                }
                if (valueLeft.type === 'date') {
                    const left = valueLeft.valueObject as Date | null;
                    const right = valueRight.valueObject as Date | null;
                    return left === right;
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) === 0;
                }
                if (valueLeft.type === 'boolean') {
                    return valueLeft.valueObject === valueRight.valueObject;
                }
                return valueLeft.valueObject === valueRight.valueObject;
            case '!=':
                if (valueLeft.type === 'number') {
                    return valueLeft.valueObject !== valueRight.valueObject;
                }
                if (valueLeft.type === 'date') {
                    const left = valueLeft.valueObject as Date | null;
                    const right = valueRight.valueObject as Date | null;
                    return left !== right;
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) !== 0;
                }
                if (valueLeft.type === 'boolean') {
                    return valueLeft.valueObject !== valueRight.valueObject;
                }
                return valueLeft.valueObject !== valueRight.valueObject;
            case '>':
                if (valueLeft.type === 'number') {
                    return (valueLeft.valueObject as number) > (valueRight.valueObject as number);
                }
                if (valueLeft.type === 'date') {
                    return (valueLeft.valueObject as Date) > (valueRight.valueObject as Date);
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) > 0;
                }
                return false;
            case '>=':
                if (valueLeft.type === 'number') {
                    return (valueLeft.valueObject as number) >= (valueRight.valueObject as number);
                }
                if (valueLeft.type === 'date') {
                    return (valueLeft.valueObject as Date) >= (valueRight.valueObject as Date);
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) >= 0;
                }
                return false;
            case '<':
                if (valueLeft.type === 'number') {
                    return (valueLeft.valueObject as number) < (valueRight.valueObject as number);
                }
                if (valueLeft.type === 'date') {
                    return (valueLeft.valueObject as Date) < (valueRight.valueObject as Date);
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) < 0;
                }
                return false;
            case '<=':
                if (valueLeft.type === 'number') {
                    return (valueLeft.valueObject as number) <= (valueRight.valueObject as number);
                }
                if (valueLeft.type === 'date') {
                    return (valueLeft.valueObject as Date) <= (valueRight.valueObject as Date);
                }
                if (valueLeft.type === 'string') {
                    return Value.CompareString(valueLeft.valueObject as string | null, valueRight.valueObject as string | null) <= 0;
                }
                return false;
        }

        return false;
    }

    private static NumericOperation(valueLeft: ValueTyping | null, valueRight: ValueTyping | null, operation: (a: number, b: number) => number | null): Value {
        if (valueLeft !== null && valueRight !== null) {
            const result = operation(valueLeft as number, valueRight as number);
            if (result !== null) {
                return new Value({ type: 'number', valueObject: result });
            }
        }

        return new Value({ type: 'null', valueObject: null });
    }

    private static ValueOperation(valueLeft: Value, op: string, valueRight: Value): Value {
        switch (op) {
            case '+':
                if (valueLeft.type === 'string') {
                    return new Value({ type: 'string', valueObject: `${valueLeft.valueObject}${valueRight.valueObject}` });
                }
                if (valueLeft.type === 'number') {
                    return Value.NumericOperation(valueLeft.valueObject, valueRight.valueObject, (a, b) => a + b);
                }
                break;
            case '-':
                if (valueLeft.type === 'number') {
                    return Value.NumericOperation(valueLeft.valueObject, valueRight.valueObject, (a, b) => a - b);
                }
                break;
            case '*':
                if (valueLeft.type === 'number') {
                    return Value.NumericOperation(valueLeft.valueObject, valueRight.valueObject, (a, b) => a * b);
                }
                break;
            case '/':
                if (valueLeft.type === 'number') {
                    return Value.NumericOperation(valueLeft.valueObject, valueRight.valueObject, (a, b) => a / b);
                }
                break;
            case '%':
                if (valueLeft.type === 'number') {
                    return Value.NumericOperation(valueLeft.valueObject, valueRight.valueObject, (a, b) => a % b);
                }
                break;
        }

        return new Value({ type: 'null', valueObject: null });
    }

    constructor({ valueObject, type }: { valueObject: ValueTyping; type?: string }) {
        this.valueObject = valueObject;
        this.type = type || '';
    }
}
