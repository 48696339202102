import { Toggle } from '@fluentui/react';
import { FC } from 'react';
import { IPageElementInput } from '../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../common/components/ExpressionField/ExpressionField';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';

export interface InputElementPanelProps {
    data: IPageElementInput;
    onChangeData: (data: DeepPartial<IPageElementInput>) => void;
}

export const InputElementPanel: FC<InputElementPanelProps> = (props: InputElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <ExpressionField Kind={ExpressionKind.TextWithExpression} label='Nom du champ' value={data.label} onChange={(label) => onChangeData({ label })} showExpandButton />
            <ExpressionField Kind={ExpressionKind.Condition} label='Désactivé si' value={data.disableIf} onChange={(disableIf) => onChangeData({ disableIf })} showExpandButton />
            <ExpressionField
                Kind={ExpressionKind.VariableNameOnly}
                label='Stocker la valeur saisie dans'
                value={data.assignValueTo}
                onChange={(assignValueTo) => onChangeData({ assignValueTo })}
                showExpandButton
            />
            <Toggle label={'Marquer comme'} checked={data.mandatory} onText={'Obligatoire'} offText={'Facultatif'} onChange={(_, mandatory) => onChangeData({ mandatory })} />
        </>
    );
};
