import React, { FC, useMemo, useState } from 'react';
import { ColorPalette, IColorPalette } from '../../../Services/SakuraApiClient';
import { DeepPartial } from '../../Hooks/Form/useForm';
import { ColorPaletteView, getIndexesForId } from './ColorPaletteView';
import { AskDialog } from '../Dialog/AskDialog/AskDialog';
import { ActionButton, ColorPicker, Label, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { getGradiantDataFromString, getStringFromGradiantData, isGradiantColor } from './gradiant';
import { NumberField } from '../NumberField/NumberField';
import './ColorPaletteEditor.scss';

export interface ColorPaletteEditorProps {
    palette: DeepPartial<IColorPalette> | undefined;
    onChange: (palette: DeepPartial<IColorPalette> | undefined) => void;
}

export const ColorPaletteEditor: FC<ColorPaletteEditorProps> = (props: ColorPaletteEditorProps) => {
    const { palette, onChange } = props;
    const [selectedColorId, setSelectedColorId] = useState<string | undefined>(undefined);
    const [askCreateGroup, setAskCreateGroup] = useState<boolean>();
    const [askRenameGroup, setAskRenameGroup] = useState<number>();

    const [colorType, setColorType] = useState<'ColorGradiant' | 'ColorUnit'>();

    const selectedColor = useMemo(() => {
        return findColorById(palette as IColorPalette, selectedColorId);
    }, [palette, selectedColorId]);

    const editPalette = (currentPalette: DeepPartial<IColorPalette> | undefined, currentId: string | undefined, change: (data: string) => string | undefined) => {
        const indexes = getIndexesForId(currentId);
        if (indexes && currentPalette?.entries) {
            const copy = { ...currentPalette };
            copy.entries = [...currentPalette?.entries];
            copy.entries[indexes[0]].colors = [...(currentPalette.entries[indexes[0]].colors ?? [])];
            const colors = copy.entries[indexes[0]].colors;
            if (colors) {
                const val = change(colors[indexes[1]]);
                if (val) {
                    colors[indexes[1]] = val;
                }
            }
            onChange(copy);
        }
    };
    return (
        <div>
            {askCreateGroup ? (
                <AskDialog
                    id='onCreateGroup'
                    onClose={(groupName) => {
                        if (groupName) {
                            const copy = { ...palette };
                            copy.entries = copy.entries ?? [];
                            copy.entries.push({
                                entryName: groupName,
                                colors: [],
                            });
                            onChange(copy);
                        }
                        setAskCreateGroup(false);
                    }}
                    show
                    title='Ajouter un groupe'
                    icon={{ iconName: 'ColorSolid' }}
                    question='Quel est le nom du groupe'
                />
            ) : null}
            {askRenameGroup !== undefined ? (
                <AskDialog
                    id='onRenameGroup'
                    onClose={(groupName) => {
                        if (groupName) {
                            const copy = { ...palette };
                            copy.entries = copy.entries ?? [];
                            copy.entries[askRenameGroup] = {
                                colors: copy.entries[askRenameGroup]?.colors,
                                entryName: groupName,
                            };
                            onChange(copy);
                        }
                        setAskRenameGroup(undefined);
                    }}
                    show
                    title='Renommer un groupe'
                    defaultAnswer={palette?.entries ? palette?.entries[askRenameGroup].entryName : undefined}
                    icon={{ iconName: 'ColorSolid' }}
                    question='Quel est le nouveau nom du groupe'
                />
            ) : null}
            <div className='DivFlexHorizontal'>
                <Pivot>
                    <PivotItem className='colorPaletteEditorPivot' headerText='Palettes' itemIcon={'Color'}>
                        <ActionButton
                            text='Ajouter un groupe'
                            iconProps={{ iconName: 'AddTo' }}
                            onClick={() => {
                                setAskCreateGroup(true);
                            }}
                        />
                        <ColorPaletteView
                            selectedId={selectedColorId}
                            palette={palette as IColorPalette}
                            onSelected={(id) => {
                                setSelectedColorId(id);
                                const indexes = getIndexesForId(id);
                                if (indexes && palette?.entries) {
                                    const colors = palette.entries[indexes[0]].colors;
                                    if (colors) {
                                        if (decodeColorValue(colors[indexes[1]])?.gradiant !== undefined) {
                                            setColorType('ColorGradiant');
                                        } else {
                                            setColorType('ColorUnit');
                                        }
                                    }
                                }
                            }}
                            onAddColor={(id) => {
                                setSelectedColorId(id);
                                const indexes = getIndexesForId(id);
                                if (indexes && palette?.entries) {
                                    const copy = { ...palette };
                                    copy.entries = [...palette?.entries];
                                    copy.entries[indexes[0]].colors = [...(palette.entries[indexes[0]].colors ?? [])];
                                    copy.entries[indexes[0]].colors?.push('#000000');
                                    onChange(copy);
                                }
                            }}
                            onRenameGroup={(index) => {
                                setAskRenameGroup(index);
                            }}
                        />
                    </PivotItem>
                </Pivot>
                {selectedColorId ? (
                    <Pivot
                        selectedKey={colorType ?? 'ColorUnit'}
                        onLinkClick={(item) => {
                            if (selectedColorId) {
                                setColorType(item?.props.itemKey as 'ColorUnit' | 'ColorGradiant');
                                editPalette(palette, selectedColorId, (data) => {
                                    const info = decodeColorValue(data);
                                    if (info?.gradiant === undefined && item?.props.itemKey === 'ColorGradiant') {
                                        const value = getStringFromGradiantData({ deg: 0, color1: '#000000', color2: '#000000' });
                                        return encodeColorValue(info?.name, value);
                                    }
                                    return data;
                                });
                            }
                        }}
                    >
                        <PivotItem className='colorPaletteEditorPivot' itemKey={'ColorUnit'} headerText='Couleur unie'>
                            <TextField
                                label='Nom de la couleur (optionel)'
                                value={selectedColor?.name}
                                onChange={(_, name) => {
                                    editPalette(palette, selectedColorId, (data) => {
                                        const info = decodeColorValue(data);
                                        return encodeColorValue(name, info?.value);
                                    });
                                }}
                            ></TextField>
                            <ColorPicker
                                color={selectedColor?.value ?? '#000000'}
                                onChange={(_, color) => {
                                    if (selectedColorId) {
                                        editPalette(palette, selectedColorId, (data) => {
                                            const info = decodeColorValue(data);
                                            return encodeColorValue(info?.name, color.str);
                                        });
                                    }
                                }}
                                alphaType={'transparency'}
                                showPreview
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </PivotItem>
                        <PivotItem className='colorPaletteEditorPivot' itemKey={'ColorGradiant'} headerText='Dégrader de couleur'>
                            <div className='DivFlexHorizontalCenter'>
                                <TextField
                                    label='Nom de la couleur (optionel)'
                                    value={selectedColor?.name}
                                    onChange={(_, name) => {
                                        editPalette(palette, selectedColorId, (data) => {
                                            const info = decodeColorValue(data);
                                            return encodeColorValue(name, info?.value);
                                        });
                                    }}
                                ></TextField>
                                <NumberField
                                    label='Angle (en dégréé)'
                                    value={selectedColor?.gradiant?.deg ?? 0}
                                    onChange={(deg) => {
                                        editPalette(palette, selectedColorId, (data) => {
                                            const info = decodeColorValue(data);
                                            if (info?.gradiant) {
                                                const gradiant = info?.gradiant;
                                                const value = getStringFromGradiantData({ ...gradiant, deg: deg ?? 0 });
                                                return encodeColorValue(info?.name, value);
                                            }
                                            return data;
                                        });
                                    }}
                                ></NumberField>
                            </div>
                            <div className='DivFlexHorizontalCenter'>
                                <ColorPicker
                                    color={selectedColor?.gradiant?.color1 ?? '#000000'}
                                    onChange={(_, color) => {
                                        if (selectedColorId) {
                                            editPalette(palette, selectedColorId, (data) => {
                                                const info = decodeColorValue(data);
                                                if (info?.gradiant) {
                                                    const value = getStringFromGradiantData({ ...info?.gradiant, color1: color.str ?? '#000000' });
                                                    return encodeColorValue(info?.name, value);
                                                }
                                                return data;
                                            });
                                        }
                                    }}
                                    alphaType={'transparency'}
                                    showPreview
                                    strings={{
                                        hueAriaLabel: 'Hue',
                                    }}
                                />
                                <ColorPicker
                                    color={selectedColor?.gradiant?.color2 ?? '#000000'}
                                    onChange={(_, color) => {
                                        if (selectedColorId) {
                                            editPalette(palette, selectedColorId, (data) => {
                                                const info = decodeColorValue(data);
                                                if (info?.gradiant) {
                                                    const value = getStringFromGradiantData({ ...info?.gradiant, color2: color.str ?? '#000000' });
                                                    return encodeColorValue(info?.name, value);
                                                }
                                                return data;
                                            });
                                        }
                                    }}
                                    alphaType={'transparency'}
                                    showPreview
                                    strings={{
                                        hueAriaLabel: 'Hue',
                                    }}
                                />
                                <div style={{ alignSelf: 'flex-start', padding: 16, justifyContent: 'center' }}>
                                    <div style={{ background: selectedColor?.value, height: 220, width: 266 }}></div>
                                    <Label style={{ fontStyle: 'italic' }}>{'Aperçu'}</Label>
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                ) : null}
            </div>
        </div>
    );
};

function decodeColorValue(val: string) {
    if (val) {
        const split = val.split('|');
        const gradiant = isGradiantColor(split[0]) ? getGradiantDataFromString(split[0]) : undefined;

        return { name: split[1], value: split[0], gradiant };
    }
    return undefined;
}
function encodeColorValue(name: string | undefined, value: string | undefined) {
    if (name) {
        return `${value}|${name}`;
    }
    return value;
}
function findColorById(palettes: IColorPalette | undefined, selectedColorId: string | undefined) {
    if (palettes?.entries && selectedColorId) {
        const indexes = getIndexesForId(selectedColorId);
        if (indexes !== undefined && palettes.entries[indexes[0]]?.colors !== undefined) {
            const colors = palettes.entries[indexes[0]].colors;
            if (colors) {
                const val = colors[indexes[1]];
                return decodeColorValue(val);
            }
        }
    }
    return undefined;
}
