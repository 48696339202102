export function arrayBufferToBase64(buffer: ArrayBuffer | ArrayBufferLike | null) {
    let binary = '';
    if (buffer) {
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    return '';
}
