import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const accountId = newState.filters['accountId'] as number;
    const accountName = newState.filters['accountName'] as string;
    const questionnary = newState.filters['questionnary'] as string;
    const questionnaryId = newState.filters['questionnaryId'] as number;
    return await client.listQuestionnaryResults(newState.pageIndex, newState.pageSize, accountName, accountId, questionnary, questionnaryId, cancelToken);
};

export const QuestionnaryResponsesGrid = new ApiCall('QuestionnaryResponsesGrid', query);
