import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { Editor, EditorState } from 'draft-js';
import React, { FC, useMemo } from 'react';
import { fontStylesSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../Redux/hook';
import { listOfFontFamily } from '../../../styles/FontStyles';
import { clearPresetFontStyle, fontStylePrefix, getEditorPresetStyle, setEditorStyle } from './utils';

export interface RichEditFontButtonProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    refEditor: React.RefObject<Editor>;
}

const defaultFont = `${fontStylePrefix}Poppins`;
export function buildFontFamilyDropdown(names: string[], fontStylePrefix?: string): IDropdownOption[] {
    return names
        .map((n) => {
            const name = n.split(',')[0].replaceAll("'", '');
            return {
                key: `${fontStylePrefix}${name}`,
                text: name,
                data: n,
            };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
}
export const listFonts: IDropdownOption[] = buildFontFamilyDropdown(listOfFontFamily, fontStylePrefix);
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 175 },
};

export const RichEditFontButton: FC<RichEditFontButtonProps> = (props: RichEditFontButtonProps) => {
    const { editorState, onChangeState, refEditor } = props;

    const fontStyles = useAppSelector(fontStylesSelector);

    const selectedFontSize = useMemo(() => {
        let ffStyle = editorState
            .getCurrentInlineStyle()
            .filter((s) => s !== undefined && s.startsWith(fontStylePrefix))
            ?.last();
        if (!ffStyle) {
            const presetStyle = getEditorPresetStyle(editorState, fontStyles);
            if (presetStyle?.fontProperties.family) {
                const name = presetStyle?.fontProperties.family.split(',')[0].replaceAll("'", '');
                ffStyle = `${fontStylePrefix}${name}`;
            }
        }
        return ffStyle;
    }, [editorState, fontStyles]);

    return (
        <Dropdown
            className='ddlsmallsize'
            options={listFonts}
            styles={dropdownStyles}
            selectedKey={selectedFontSize ?? defaultFont}
            onChange={(_, selectedKey) => {
                let newState = editorState;

                const newvalue = selectedKey?.key;
                if (newvalue) {
                    newState = clearPresetFontStyle(refEditor.current, newState, fontStyles);
                    newState = setEditorStyle(refEditor.current, newState, fontStylePrefix, undefined, newvalue as string);
                    onChangeState(newState);
                }
            }}
        />
    );
};
