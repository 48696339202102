import { Icon } from '@fluentui/react';
import { useCarousel } from 'nuka-carousel';
import { FC, useEffect, useState } from 'react';
import './CustomArrows.scss';

export interface CustomArrowsProps {
    onMouseEnter: () => void;
    onNavigate: (direction: 'backward' | 'forward') => void;
}
export const CustomArrows: FC<CustomArrowsProps> = (props: CustomArrowsProps) => {
    const { onMouseEnter, onNavigate } = props;
    const { currentPage, totalPages, wrapMode, goBack, goForward } = useCarousel();
    const [blockingDiv, setBlockingDiv] = useState<boolean>(false);
    const allowWrap = wrapMode === 'wrap';
    const enablePrevNavButton = allowWrap || currentPage > 0;
    const enableNextNavButton = allowWrap || currentPage < totalPages - 1;

    useEffect(() => {
        if (blockingDiv) {
            const timeoutRef = setTimeout(() => {
                setBlockingDiv(false);
            }, 500);
            return () => {
                clearTimeout(timeoutRef);
            };
        }
        return undefined;
    }, [blockingDiv]);
    return (
        <>
            {enablePrevNavButton ? (
                <div
                    className='sk-thumbnail-nav-button sk-thumbnail-nav-prev'
                    onClick={(ev) => {
                        goBack();
                        setBlockingDiv(true);
                        onNavigate('backward');
                        ev.stopPropagation();
                    }}
                    onMouseOver={(ev) => ev.stopPropagation()}
                    onMouseEnter={(ev) => {
                        ev.stopPropagation();
                        onMouseEnter();
                    }}
                    onMouseLeave={(ev) => ev.stopPropagation()}
                >
                    <Icon iconName={'ChevronLeftSmall'} />
                </div>
            ) : blockingDiv ? (
                <div
                    className='sk-thumbnail-blockingDiv-left'
                    onClick={(ev) => ev.stopPropagation()}
                    onMouseOver={(ev) => ev.stopPropagation()}
                    onMouseEnter={(ev) => ev.stopPropagation()}
                    onMouseLeave={(ev) => ev.stopPropagation()}
                />
            ) : null}
            {enableNextNavButton ? (
                <div
                    className='sk-thumbnail-nav-button sk-thumbnail-nav-next'
                    onClick={(ev) => {
                        goForward();
                        setBlockingDiv(true);
                        onNavigate('forward');
                        ev.stopPropagation();
                    }}
                    onMouseOver={(ev) => ev.stopPropagation()}
                    onMouseEnter={(ev) => {
                        ev.stopPropagation();
                        onMouseEnter();
                    }}
                    onMouseLeave={(ev) => ev.stopPropagation()}
                >
                    <Icon iconName={'ChevronRightSmall'} />
                </div>
            ) : blockingDiv ? (
                <div
                    className='sk-thumbnail-blockingDiv-right'
                    onClick={(ev) => ev.stopPropagation()}
                    onMouseOver={(ev) => ev.stopPropagation()}
                    onMouseEnter={(ev) => ev.stopPropagation()}
                    onMouseLeave={(ev) => ev.stopPropagation()}
                />
            ) : null}
        </>
    );
};
