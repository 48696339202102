import { ProgressIndicator } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { ImageViewer } from '../../../common/components/ImageViewer/ImageViewer';
import { ST } from '../../../common/Hooks/StorageResolver';
import { useAppSelector } from '../../../Redux/hook';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { IResourceHierarchyView } from '../../../Services/SakuraApiClient';

export interface FormationHeaderProps {
    formationHierarchyView: IResourceHierarchyView;
    isMobile?: boolean;
}
export const FormationHeader: FC<FormationHeaderProps> = (props: FormationHeaderProps) => {
    const { formationHierarchyView, isMobile } = props;

    const storage = useAppSelector(storageEndpointsSelector);

    const thumbnailPath = useMemo(() => {
        if (formationHierarchyView.resource?.data?.content?.formation?.pictureId && formationHierarchyView.innerResourcePackages) {
            return formationHierarchyView.innerResourcePackages[formationHierarchyView.resource.data.content.formation.pictureId]?.blobPath;
        }
        return undefined;
    }, [formationHierarchyView]);
    const formationTitle = useMemo(() => {
        return formationHierarchyView.resource?.data?.content?.formation.title ?? formationHierarchyView.resource?.name;
    }, [formationHierarchyView.resource?.name, formationHierarchyView.resource?.data?.content?.formation.title]);
    return (
        <div className='sakura-formation-header '>
            {formationHierarchyView.resource.data.content.formation.showTitle ? <h3>{formationTitle}</h3> : null}
            {thumbnailPath ? <ImageViewer url={ST(storage, thumbnailPath, isMobile)} width={isMobile ? undefined : '250px'} height={isMobile ? '100px' : undefined} /> : null}
            <ProgressIndicator label='Votre progression' percentComplete={0.25} description={'1 module sur 10'} barHeight={5} className='progress' />
        </div>
    );
};
