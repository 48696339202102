import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { ContactListLookup } from '../../Redux/Reducers/DataSet/ApiCall/Brevo';
import { useAppDispatch } from '../../Redux/hook';
import { IContactList } from '../../Services/SakuraApiClient';
import { performApiCall } from '../Hooks/useApiCall';
import { DatasetLookupField } from './DatasetLookupField';

export interface ContactListLookupFieldProps {
    contactListId: number | undefined;
    onSelectContactList: (contactList: IContactList | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
    errorMessage?: string;
}
export const ContactListLookupField: FC<ContactListLookupFieldProps> = (props: ContactListLookupFieldProps) => {
    const { label, contactListId, onSelectContactList, readOnly, required, contextId, errorMessage } = props;

    const [contactList, setContactList] = useState<IContactList>();

    const appDispatch = useAppDispatch();

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (contactListId) {
            const listId = ContactListLookup.lastResult?.result?.find((r) => r.id === contactListId);
            if (listId == undefined) {
                performApiCall(
                    async (client, cancelToken) => {
                        if (contactListId) {
                            const result = await client.getContactList(contactListId, cancelToken);

                            setContactList(result);
                        }
                    },
                    appDispatch,
                    {
                        contextId: 'Default',
                        cancelToken: source.token,
                    },
                );
            } else {
                setContactList(listId);
            }
        } else {
            setContactList(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [contactListId]);
    return (
        <DatasetLookupField
            label={label}
            errorMessage={errorMessage}
            apiCall={ContactListLookup}
            accessKeyProp={(i) => i.id.toString()}
            accessTextProp={(i) => i.name ?? ''}
            selectedItem={contactList}
            onSelectItem={(item) => onSelectContactList(item)}
            readOnly={readOnly}
            required={required}
            contextId={contextId}
            disableTextSearch={true}
        />
    );
};
