import { Dropdown, IDropdownOption, Label, TextField } from '@fluentui/react';
import { FC } from 'react';
import { EnumClickAction, IClickable, IResourceRef } from '../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../common/components/ExpressionField/ExpressionField';
import { WorkflowConfigurator } from '../../../../Admin/Resources/Questionnary/WorkflowConfigurator';
import { ProductReferencePicker } from './ProductReferencePicker';

export interface ConfigureClickActionProps {
    label: string;
    data: IClickable | undefined;
    onChange: (data: Partial<IClickable> | undefined) => void;
}
const clickActionList: IDropdownOption[] = [
    {
        key: EnumClickAction.Link,
        text: 'Lien web',
    },
    {
        key: EnumClickAction.DownloadDocument,
        text: 'Télécharger un produit',
    },
    {
        key: EnumClickAction.ExecuteWorkflow,
        text: 'Executer un workflow',
    },
    {
        key: EnumClickAction.AssignVariable,
        text: 'Assigner une variable',
    },
    {
        key: EnumClickAction.NavigateNext,
        text: 'Aller à la page suivante',
    },
    {
        key: EnumClickAction.NavigatePrev,
        text: 'Aller à la page précédante',
    },
    {
        key: EnumClickAction.NavigateTo,
        text: 'Aller à la page spécifié',
    },
];

export const ConfigureClickAction: FC<ConfigureClickActionProps> = (props: ConfigureClickActionProps) => {
    const { label, data, onChange } = props;
    return (
        <>
            <Label>{label}</Label>
            <Dropdown
                options={clickActionList}
                selectedKey={data?.action}
                onChange={(_, opt) => {
                    onChange({ action: opt?.key as EnumClickAction });
                }}
            />
            {data?.action === EnumClickAction.DownloadDocument ? (
                <ProductReferencePicker
                    label='Séléctionner un produit'
                    resourceRef={data.documentResource}
                    onChange={(documentResource) => onChange({ documentResource: documentResource as IResourceRef })}
                />
            ) : null}
            {data?.action === EnumClickAction.ExecuteWorkflow ? <WorkflowConfigurator workflow={data?.workflow} onChangeWorkflow={(workflow) => onChange({ workflow })} /> : null}
            {data?.action === EnumClickAction.Link ? <TextField label='Url' value={data.href} onChange={(_, href) => onChange({ href })} /> : null}
            {data?.action === EnumClickAction.AssignVariable ? (
                <ExpressionField Kind={ExpressionKind.Assignment} label="Expression d'affectation" value={data.assignExpression} onChange={(assignExpression) => onChange({ assignExpression })} />
            ) : null}
        </>
    );
};
