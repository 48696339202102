import { CancelToken } from 'axios';
import { EnumSystem, ResourceTypeEnum, SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const queryActivityLogExternalService = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listActivityLogExternalSystem(
        newState.pageIndex,
        newState.pageSize,
        newState.filters['systemSrc'] as EnumSystem,
        newState.filters['systemDst'] as EnumSystem,
        newState.filters['eventType'] as string | undefined,
        newState.filters['jsonData'] as string | undefined,
        newState.filters['urlData'] as string | undefined,
        newState.filters['status'] as string | undefined,
        newState.filters['createdOn_0'] as Date | undefined,
        newState.filters['createdOn_1'] as Date | undefined,
        cancelToken,
    );
};

const queryCoinTransactionLogService = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const coinFilter = newState.filters['coin'];
    const isCredit = coinFilter !== undefined ? (coinFilter === 'C' ? true : false) : undefined;
    return await client.listCoinTransactionLog(
        newState.pageIndex,
        newState.pageSize,
        newState.filters['signInEmail'] as string | undefined,
        newState.filters['firstname'] as string | undefined,
        newState.filters['lastname'] as string | undefined,
        isCredit,
        newState.filters['createdOn_0'] as Date | undefined,
        newState.filters['createdOn_1'] as Date | undefined,
        cancelToken,
    );
};

const queryDiamondTransactionLogService = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const coinFilter = newState.filters['coin'];
    const isCredit = coinFilter !== undefined ? (coinFilter === 'C' ? true : false) : undefined;
    return await client.listDiamondTransactionLog(
        newState.pageIndex,
        newState.pageSize,
        newState.filters['signInEmail'] as string | undefined,
        newState.filters['firstname'] as string | undefined,
        newState.filters['lastname'] as string | undefined,
        isCredit,
        newState.filters['createdOn_0'] as Date | undefined,
        newState.filters['createdOn_1'] as Date | undefined,
        cancelToken,
    );
};

const queryAccountActivityLogService = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listAccountActivityLog(
        newState.pageIndex,
        newState.pageSize,
        newState.filters['signInEmail'] as string | undefined,
        newState.filters['firstname'] as string | undefined,
        newState.filters['lastname'] as string | undefined,
        newState.filters['eventName'] as string | undefined,
        newState.filters['eventMessage'] as string | undefined,
        newState.filters['resourceName'] as string | undefined,
        newState.filters['resourceType'] as ResourceTypeEnum | undefined,
        newState.filters['createdOn_0'] as Date | undefined,
        newState.filters['createdOn_1'] as Date | undefined,
        cancelToken,
    );
};
export const ActivityLogExternalServiceGrid = new ApiCall('ActivityLogExternalServiceGrid', queryActivityLogExternalService);
export const CoinTransactionLogGrid = new ApiCall('CoinTransactionLogGrid', queryCoinTransactionLogService);
export const DiamondTransactionLogGrid = new ApiCall('DiamondTransactionLogGrid', queryDiamondTransactionLogService);
export const AccountActivityLogGrid = new ApiCall('AccountActivityLogGrid', queryAccountActivityLogService);
