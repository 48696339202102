import { useEffect, useRef } from 'react';

export function useTimeout(callback?: () => void, delay?: number): void {
    if (delay) {
        const savedCallback = useRef<() => void>();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            if (delay) {
                const tick = () => {
                    savedCallback.current?.();
                };
                const id = setTimeout(tick, delay);

                return () => clearTimeout(id);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                return () => {};
            }
        }, [delay]);
    }
}

export function useInterval(callback?: () => void, delay?: number): void {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay) {
            const id = setInterval(tick, delay);

            return () => clearInterval(id);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
    }, [delay]);
}
