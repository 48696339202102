import { FC } from 'react';
import {
    PageElement,
    PageElementAudio,
    PageElementButton,
    PageElementCheckField,
    PageElementContainer,
    PageElementContainerStyle,
    PageElementCustomComponent,
    PageElementDatetimeField,
    PageElementImage,
    PageElementLabel,
    PageElementLink,
    PageElementNumberField,
    PageElementPDF,
    PageElementQuestionnary,
    PageElementRichText,
    PageElementSeparator,
    PageElementTextField,
    PageElementType,
    PageElementVideo,
} from '../../../Services/SakuraApiClient';
import { ElementAudioView } from './Elements/Audio/ElementAudioView';
import { ElementButtonView } from './Elements/Button/ElementButtonView';
import { ElementContainerEdition } from './Elements/Container/ElementContainerEdition';
import { ElementCustomComponent } from './Elements/CustomComponent/ElementCustomComponent';
import { ElementImageView } from './Elements/Image/ElementImageView';
import { ElementCheckField } from './Elements/Input/ElementCheckField';
import { ElementDatetimeField } from './Elements/Input/ElementDatetimeField';
import { ElementNumberField } from './Elements/Input/ElementNumberField';
import { ElementTextField } from './Elements/Input/ElementTextField';
import { ElementLabelView } from './Elements/Label/ElementLabelView';
import { ElementLinkView } from './Elements/Link/ElementLinkView';
import { ElementPdfViewerView } from './Elements/PdfViewer/ElementPdfViewerView';
import { ElementQuestionnaryEdition } from './Elements/Questionnary/ElementQuestionnaryEdition';
import { ElementRichTextEdition } from './Elements/RichText/ElementRichTextEdition';
import { ElementSeparatorView } from './Elements/Separator/ElementSeparatorView';
import { ElementVideoEdition } from './Elements/Video/ElementVideoEdition';

export interface PageElementEditionBaseProps {
    type: PageElementType;
    data: PageElement;
    onChangeData: (data: PageElement) => void;
    isSelected: boolean;
    parentContainerStyle?: PageElementContainerStyle;
}

export const PageElementEditionBase: FC<PageElementEditionBaseProps> = (props: PageElementEditionBaseProps) => {
    const { type, data, onChangeData, isSelected, parentContainerStyle } = props;

    switch (type) {
        case PageElementType.Container:
            return <ElementContainerEdition data={data as PageElementContainer} onChangeData={onChangeData} parentContainerStyle={parentContainerStyle} />;
        case PageElementType.CheckField:
            return <ElementCheckField data={data as PageElementCheckField} />;
        case PageElementType.TextField:
            return <ElementTextField data={data as PageElementTextField} />;
        case PageElementType.NumberField:
            return <ElementNumberField data={data as PageElementNumberField} />;
        case PageElementType.DatetimeField:
            return <ElementDatetimeField data={data as PageElementDatetimeField} />;
        case PageElementType.Label:
            return <ElementLabelView data={data as PageElementLabel} />;
        case PageElementType.Image:
            return <ElementImageView data={data as PageElementImage} />;
        case PageElementType.Audio:
            return <ElementAudioView data={data as PageElementAudio} />;
        case PageElementType.Video:
            return <ElementVideoEdition data={data as PageElementVideo} />;
        case PageElementType.Button:
            return <ElementButtonView data={data as PageElementButton} clickDisabled />;
        case PageElementType.Link:
            return <ElementLinkView data={data as PageElementLink} clickDisabled />;
        case PageElementType.RichText:
            return <ElementRichTextEdition data={data as PageElementRichText} onChangeData={onChangeData} isSelected={isSelected} />;
        case PageElementType.Questionnary:
            return <ElementQuestionnaryEdition data={data as PageElementQuestionnary} />;
        case PageElementType.PDF:
            return <ElementPdfViewerView data={data as PageElementPDF} />;
        case PageElementType.Separator:
            return <ElementSeparatorView data={data as PageElementSeparator} direction={parentContainerStyle?.direction} editMode />;
        case PageElementType.CustomComponent:
            return <ElementCustomComponent data={data as PageElementCustomComponent} editMode />;
        default:
            return <></>;
    }
};
