import { TextField } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';

export interface CssBoxShadowProps {
    label: string;
    value: string | undefined;
    onChangeValue: (value: string | undefined) => void;
}
export const CssBoxShadow: FC<CssBoxShadowProps> = (props: CssBoxShadowProps) => {
    const { value, onChangeValue, label } = props;
    const [boxShadowValue, setBoxShadowValue] = useState<string | undefined>(value);
    useEffect(() => {
        setBoxShadowValue(value);
    }, [value]);
    return (
        <TextField
            label={label}
            value={boxShadowValue}
            onBlur={() => {
                onChangeValue(boxShadowValue);
            }}
            onChange={(_, value) => setBoxShadowValue(value)}
        ></TextField>
    );
};
