import { EditorCommand, getDefaultKeyBinding } from 'draft-js';

export const IntelisenseCmd = 'intelisense';
export const BracketExpressionCmd = 'bracket-expression';
export const CommitSelectionCmd = 'CommitSelection';
export const CmdArrowUp = 'cmd-ArrowUp';
export const CmdArrowDown = 'cmd-ArrowDown';
export const CmdEnter = 'cmd-Enter';
export const CmdTab = 'cmd-Tab';
export function keyCmdIntelisense(
    e: React.KeyboardEvent<Record<string, unknown>>,
    intelisenseOpen: boolean,
    shouldHandleEnter: boolean,
    enabledBracketExpression: boolean | undefined,
): EditorCommand | null {
    if (e.ctrlKey && e.key === ' ') {
        return IntelisenseCmd;
    }
    if (e.ctrlKey && e.key === 'e' && enabledBracketExpression) {
        return BracketExpressionCmd;
    }
    if (intelisenseOpen) {
        if ((e.shiftKey && e.key === '.') || e.key === '.' || e.key === '(') {
            return CommitSelectionCmd;
        }
        if (e.key === 'ArrowUp') {
            return CmdArrowUp;
        }
        if (e.key === 'ArrowDown') {
            return CmdArrowDown;
        }
        if (e.key === 'Tab') {
            return CmdTab;
        }
    }
    if (shouldHandleEnter && e.key === 'Enter') {
        return CmdEnter;
    }
    return getDefaultKeyBinding(e);
}
