import { IDropdownOption } from '@fluentui/react';

export interface ResolutionDevice {
    width: number;
    height: number;
}

export const deviceResolutions: IDropdownOption<ResolutionDevice>[] = [
    {
        key: 'Samsung Galaxy S8+',
        text: 'Samsung Galaxy S8+',
        data: {
            width: 360,
            height: 740,
        },
    },
    {
        key: 'Iphone SE',
        text: 'Iphone SE',
        data: {
            width: 375,
            height: 667,
        },
    },
    {
        key: 'Iphone X',
        text: 'Iphone X',
        data: {
            width: 375,
            height: 812,
        },
    },
    {
        key: 'Iphone 12 Pro',
        text: 'Iphone 12 Pro',
        data: {
            width: 390,
            height: 844,
        },
    },
    {
        key: 'Iphone XR',
        text: 'Iphone XR',
        data: {
            width: 414,
            height: 896,
        },
    },
    {
        key: 'Iphone 6/7/8 Plus',
        text: 'Iphone 6/7/8 Plus',
        data: {
            width: 414,
            height: 736,
        },
    },

    {
        key: 'Pixel 7',
        text: 'Pixel 7',
        data: {
            width: 412,
            height: 915,
        },
    },
    {
        key: 'Samsung Galaxy S20 Ultra',
        text: 'Samsung Galaxy S20 Ultra',
        data: {
            width: 412,
            height: 915,
        },
    },
    {
        key: 'Iphone 14 Pro Max',
        text: 'Iphone 14 Pro Max',
        data: {
            width: 430,
            height: 932,
        },
    },

    {
        key: 'IPad Mini',
        text: 'IPad Mini',
        data: {
            width: 768,
            height: 1024,
        },
    },
    {
        key: 'IPad Air',
        text: 'IPad Air',
        data: {
            width: 820,
            height: 1180,
        },
    },
    {
        key: 'Surface Pro 7',
        text: 'Surface Pro 7',
        data: {
            width: 912,
            height: 1368,
        },
    },
    {
        key: 'PC 1600x700',
        text: 'PC 1600x700',
        data: {
            width: 1600,
            height: 700,
        },
    },
    {
        key: 'PC 1920x1080',
        text: 'PC 1920x1080',
        data: {
            width: 1920,
            height: 1080,
        },
    },
    {
        key: 'PC 1920x1440',
        text: 'PC 1920x1440',
        data: {
            width: 1920,
            height: 1440,
        },
    },
    {
        key: 'PC 2560x1440',
        text: 'PC 2560x1440',
        data: {
            width: 2560,
            height: 1440,
        },
    },
];
