import { ITooltipHostStyles, Icon, Label, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FC } from 'react';
import './CircleButton.scss';

export interface CircleButtonProps {
    iconName: string;
    toolTip: string;
    onClick: () => void;
    size?: 'small' | 'medium' | 'big';
    isLoading?: boolean;
}
const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const CircleButton: FC<CircleButtonProps> = (props: CircleButtonProps) => {
    const { iconName, onClick, size, toolTip, isLoading } = props;
    const buttonId = useId('targetButton');
    const tooltipId = useId('tooltip');
    return (
        <>
            <TooltipHost
                content={toolTip}
                tooltipProps={{ onRenderContent: () => <Label>{toolTip}</Label> }}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}
            >
                <div id={buttonId} aria-describedby={tooltipId} className={clsx('circleButton', size ?? 'small', isLoading ? 'loadingAnim' : '')} onClick={onClick}>
                    <Icon iconName={isLoading ? 'SyncOccurence' : iconName} />
                </div>
            </TooltipHost>
        </>
    );
};
