import { TextField } from '@fluentui/react';
import { FC } from 'react';
export interface QuestionFreeTextProps {
    answer: string | undefined;
    onAnswer: (answer: string | undefined) => void;
}

export const QuestionFreeText: FC<QuestionFreeTextProps> = (props: QuestionFreeTextProps) => {
    const { answer, onAnswer } = props;
    return <TextField multiline className='freeText' value={answer ?? ''} onChange={(_, answer) => onAnswer(answer)} />;
};
