import { FormError } from '../../../../common/Hooks/Form/useForm';
import { DeepPartialWithArrayFunc, ObjectOperation } from '../../../../common/helpers/ObjectAndArray';
import { MenuSettingEntry } from './menuSetting';

export function getParentPath(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const firstPart = index !== -1 ? path.substring(0, index) : path;
        return firstPart;
    }
    return undefined;
}
export function getIdPart(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const lastPart = path.substring(index + 1);
        return lastPart;
    }
    return undefined;
}
export function getItemByPath(path: string, item: MenuSettingEntry, errors: FormError<MenuSettingEntry> | undefined): [MenuSettingEntry | undefined, FormError<MenuSettingEntry> | undefined] {
    const index = path.indexOf('/');

    const firstPart = index !== -1 ? path.substring(0, index) : path;
    const remainPart = index !== -1 ? path.substring(index + 1) : undefined;
    if (item.id?.endsWith(firstPart) && remainPart) {
        return getItemByPath(remainPart, item, errors);
    }

    const childIndex = item.children?.findIndex((c) => c.id && c.id.endsWith(firstPart));
    const child = item?.children === undefined || childIndex === -1 || childIndex === undefined ? undefined : item?.children[childIndex];
    const childErrors = errors?.children?.find((c, index) => index === childIndex);
    if (child && remainPart) {
        return getItemByPath(remainPart, child, childErrors);
    }
    return [child, childErrors];
}

export function updateAtIdPath(idPath: string, value: DeepPartialWithArrayFunc<MenuSettingEntry>): DeepPartialWithArrayFunc<MenuSettingEntry> {
    const parts = idPath.split('/');
    if (parts.length == 1) {
        return value;
    }
    return updateArrayAtIdPath(idPath, (currentArray, index) => {
        currentArray[index] = ObjectOperation.merge(currentArray[index], value);
        return undefined;
    });
}

export function updateArrayAtIdPath(
    idPath: string,
    callback: (array: DeepPartialWithArrayFunc<MenuSettingEntry>[], index: number) => DeepPartialWithArrayFunc<MenuSettingEntry>[] | undefined,
): DeepPartialWithArrayFunc<MenuSettingEntry> {
    let updater: DeepPartialWithArrayFunc<MenuSettingEntry> = {};
    const parts = idPath.split('/');
    if (parts.length == 1) {
        return {};
    }

    updater = {
        children: (array) => {
            let newArray = [...array];
            newArray = updateArrayAtIdPathInternal(newArray, parts, callback);

            return newArray;
        },
    };
    return updater;
}

export function updateIdPathOnChildren(children: MenuSettingEntry[] | undefined, parentPath: string | undefined) {
    if (children) {
        const copyChildren = [...children];
        for (let i = 0; i < copyChildren.length; i++) {
            const id = getIdPart(copyChildren[i].id);
            copyChildren[i].id = `${parentPath}/${id}`;
            const newChildren = updateIdPathOnChildren(copyChildren[i].children, copyChildren[i].id);
            if (newChildren) {
                copyChildren[i].children = newChildren;
            }
        }
        return copyChildren;
    }
    return undefined;
}

export function updateArrayAtMultipleIdPath(
    updates: {
        idPath: string;
        callback: (array: DeepPartialWithArrayFunc<MenuSettingEntry>[], index: number) => DeepPartialWithArrayFunc<MenuSettingEntry>[] | undefined;
    }[],
): DeepPartialWithArrayFunc<MenuSettingEntry> {
    let updater: DeepPartialWithArrayFunc<MenuSettingEntry> = {};

    updater = {
        children: (array) => {
            let newArray = [...array];
            for (let i = 0; i < updates.length; i++) {
                const parts = updates[i].idPath.split('/');
                if (parts.length == 1) {
                    continue;
                }
                newArray = updateArrayAtIdPathInternal(newArray, parts, updates[i].callback);
            }
            return newArray;
        },
    };
    return updater;
}

function updateArrayAtIdPathInternal(
    array: DeepPartialWithArrayFunc<MenuSettingEntry>[],
    parts: string[],
    callback: (array: DeepPartialWithArrayFunc<MenuSettingEntry>[], index: number) => DeepPartialWithArrayFunc<MenuSettingEntry>[] | undefined,
) {
    let rootArray = array;
    let currentArray = rootArray;
    let previousArray = undefined;
    let previousIndex = undefined;
    for (let i = 1; i < parts.length; i++) {
        const index = currentArray.findIndex((res) => res.id?.endsWith(parts[i]));
        if (index === -1) {
            break;
        }
        if (i === parts.length - 1) {
            const updatedArray = callback(currentArray, index);
            if (updatedArray && previousArray && previousIndex !== undefined) {
                previousArray[previousIndex] = ObjectOperation.merge(previousArray[previousIndex], {
                    children: updatedArray,
                });
            } else if (updatedArray) {
                rootArray = updatedArray as DeepPartialWithArrayFunc<MenuSettingEntry>[];
            }
            break;
        } else {
            if (currentArray[index].children) {
                currentArray[index] = ObjectOperation.merge(currentArray[index], {
                    children: [...(currentArray[index].children as unknown as MenuSettingEntry[])],
                });
                previousArray = currentArray;
                previousIndex = index;
                currentArray = currentArray[index].children as unknown as MenuSettingEntry[];
            }
        }
    }
    return rootArray;
}
