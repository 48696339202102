import { Dropdown, Icon, IDropdownOption, Panel, TextField } from '@fluentui/react';
import React, { FC, useContext } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ResourceLookupField } from '../../../../../common/LookupFields/ResourceLookupField';
import { EnumBlockDirection, IPageElementContainer, IPageElementImage, IPageElementLabel, IPageElementVideo } from '../../../../../Services/SakuraApiClient';
import { ExecutionDataContext } from '../../../Page/ExecutionContext/ExecutionDataContextProvider';
import { IEditorDataContextProvider } from '../../EditorContext/EditorDataContextProvider';

export interface VideoElementPanelProps {
    data: IPageElementVideo;
    onChangeData: (data: DeepPartial<IPageElementVideo>) => void;
}

export const VideoElementPanel: FC<VideoElementPanelProps> = (props: VideoElementPanelProps) => {
    const { onChangeData, data } = props;
    const [, dispatch] = useContext(ExecutionDataContext);
    return (
        <>
            <ResourceLookupField
                label='Video'
                type='ResourceVideoLookup'
                selectedResourceId={data.resource?.id}
                onSelectResource={(resource) => {
                    if (resource) {
                        onChangeData({ resource: { id: resource.id } });
                        dispatch({
                            type: 'addResource',
                            payload: {
                                resourceId: resource.id,
                                data: {
                                    id: resource.id,
                                    blobPath: resource.blobPath,
                                    data: resource.data,
                                    type: resource.resourceType,
                                },
                            },
                        });
                    } else {
                        onChangeData({ resource: undefined });
                    }
                }}
            />
        </>
    );
};
