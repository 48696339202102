export interface Rectangle {
    left: number;
    top: number;
    width: number;
    height: number;
}
export function Scale(rect: Rectangle, scaleX: number, scaleY: number): Rectangle {
    // Appliquez la transformation de mise à l'échelle

    const scaledWidth = rect.width * scaleX;
    const scaledHeight = rect.height * scaleY;

    // Calculez les décalages pour maintenir l'origine (0.5, 0.5)
    const offsetX = (scaledWidth - rect.width) / 2;
    const offsetY = (scaledHeight - rect.height) / 2;

    // Déplacez le rectangle pour maintenir l'origine (0.5, 0.5)
    return {
        left: rect.left - offsetX,
        top: rect.top - offsetY,
        width: scaledWidth,
        height: scaledHeight,
    };
}
