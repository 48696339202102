import { IFunction } from '../IFunction';
import { Value } from '../../Value';
import { IntelisenseInfo } from '../../../../../../Redux/Reducers/DynamiqueData/state';

export class UpperFunction implements IFunction {
    public get name(): string {
        return 'majuscule';
    }
    public get intelisenseInfo(): IntelisenseInfo {
        return {
            type: 'func',
            fullName: this.name,
            description: 'Renvoi la chaine de caracteres value en majuscule.',
            parameters: [
                {
                    name: 'value',
                    type: 'Chaine',
                    description: 'une chaine de caractere',
                },
            ],
        };
    }
    public checkArguments(parameters: Value[]): string | null {
        if (parameters.length !== 1) {
            return 'Cette fonction prend un seul argument';
        }
        return null;
    }

    public invoke(parameters: Value[]): Value {
        const value = parameters[0];
        const val = Value.Transtype(value, 'string');
        if (val.valueObject !== null && typeof val.valueObject === 'string') {
            val.valueObject = (val.valueObject as string).toUpperCase();
        }
        return val;
    }
}
