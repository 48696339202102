import React, { FC, useMemo } from 'react';
import { useDataSetSelector } from '../../../../Redux/Reducers/DataSet/reducer';
import { IResource } from '../../../../Services/SakuraApiClient';
import { useDrag } from 'react-dnd';
import { Icon, Label } from '@fluentui/react';

export interface PageBlockItemProps {
    item: IResource;
}
export const PageBlockItem: FC<PageBlockItemProps> = (props: PageBlockItemProps) => {
    const { item } = props;
    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: 'ELEMENT_LIB_ITEM',
        item: item.data.content.pageBlock,
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    return (
        <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className='elementToolBox' ref={drag}>
                <Icon iconName={item.icon} />
                <Label>{item.name}</Label>
            </div>
        </div>
    );
};
