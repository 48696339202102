import { Reducer } from 'react';
import { IQuestionnaryDataContextState, CategoryData } from './IQuestionnaryDataContextState';

export type QuestionnaryDataContextAction = { type: 'upsertCategory'; payload: { category: CategoryData } } | { type: 'removeCategory'; payload: { categoryId: string } };

export type QuestionnaryDataContextReducerType = Reducer<IQuestionnaryDataContextState, QuestionnaryDataContextAction>;
export const QuestionnaryDataContextReducer = (state: IQuestionnaryDataContextState, action: QuestionnaryDataContextAction): IQuestionnaryDataContextState => {
    switch (action.type) {
        case 'upsertCategory':
            const index = state.categories.findIndex((i) => i.id === action.payload.category.id);
            if (index !== -1) {
                const categories = [...state.categories];
                categories[index] = action.payload.category;
                return { ...state, categories };
            }
            return { ...state, categories: [...state.categories, { ...action.payload.category }] };
        case 'removeCategory':
            return { ...state, categories: state.categories.filter((c) => c.id !== action.payload.categoryId) };
    }
    return state;
};
