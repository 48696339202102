import { ExpressionItem } from './ExpressionItem';
import { Value, ValueTyping } from './Value';

export class ExpressionVariable extends ExpressionItem {
    variableName: string[];

    constructor(text: string, variableResolver?: (variableName: string[]) => ValueTyping | undefined) {
        super(text, variableResolver);

        this.variableName = text.split('.');
    }

    public resolve(): ExpressionItem {
        if (this.next) {
            return this.next.resolve();
        }
        return this;
    }

    public getValue(): Value {
        if (this.variableResolver) {
            const result = this.variableResolver(this.variableName);
            return Value.CreateValue(result);
        }
        throw new Error('variableResolver is not defined.');
    }

    public canReturnValue(): boolean {
        return this.variableResolver !== null;
    }
}
