import React, { FC, useRef, useState, useEffect, ComponentType } from 'react';
import { IOverflowSetItemProps, OverflowSet, ProgressIndicator } from '@fluentui/react';
import { useResizeObserver } from '@asyarb/use-resize-observer';

import './OverflowCommands.scss';
import { DefaultCommandButton } from './DefaultCommandButton';
import { renderOverflowButton } from './CommandOverflowButton';

export interface OverflowCommandsProps {
    commandButtons?: IOverflowSetItemProps[];
    commandButtonComponent?: ComponentType<IOverflowSetItemProps>;
}

export const OverflowCommands: FC<OverflowCommandsProps> = (props: OverflowCommandsProps) => {
    const { commandButtons, commandButtonComponent } = props;
    const actionDivRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);
    const [commandActions, setCommandActions] = useState<{
        buttons: IOverflowSetItemProps[];
        overflowButtons: IOverflowSetItemProps[];
    }>({
        buttons: [],
        overflowButtons: [],
    });

    useResizeObserver({
        ref: actionDivRef,
        callback: (entry) => setWidth(entry.contentRect.width),
    });

    useEffect(() => {
        const visibleButtons = Math.floor(width / 150);

        if (commandButtons && commandButtons.length > 0) {
            setCommandActions({
                buttons: commandButtons.slice(0, visibleButtons),
                overflowButtons: commandButtons.slice(visibleButtons, commandButtons.length).map((item) => {
                    if (item.menuProps) {
                        return {
                            ...item,
                            subMenuProps: {
                                items: [...item.menuProps.items],
                            },
                            iconProps: {
                                iconName: item.icon,
                            },
                        };
                    } else {
                        return {
                            ...item,
                            iconProps: {
                                iconName: item.icon,
                            },
                        };
                    }
                }),
            });
        } else {
            setCommandActions({
                buttons: [],
                overflowButtons: [],
            });
        }
    }, [width, commandButtons]);

    const CommandButtonComponent = commandButtonComponent ?? DefaultCommandButton;

    return (
        <>
            <div className='overflow-commands-div' ref={actionDivRef}>
                <OverflowSet
                    items={commandActions.buttons}
                    overflowItems={commandActions.overflowButtons}
                    onRenderItem={(itemProps) => <CommandButtonComponent {...itemProps} />}
                    onRenderOverflowButton={renderOverflowButton}
                />
            </div>
        </>
    );
};
