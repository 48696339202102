export function displayError(err: unknown) {
    const error = err as Error & { status: number; detail: string };
    if (error.message) {
        return error.message;
    }
    if (error.status == 404) {
        return 'Données introuvable.';
    }
    if (error.status == 500) {
        return 'Une erreur inattendu est survenu.';
    }
    if (error.status == 401) {
        return 'Accés non authorisé pour réaliser cette opération.';
    }
    if (error.status == 400 && !error.detail) {
        return "La requete formulé n'est pas correcte";
    }
    if (error.status == 409) {
        return "Cet objet est référencé par d'autre.";
    }
    if (error.detail) {
        return error.detail;
    }
    return `Api call return HTTP/${error.status}`;
}
