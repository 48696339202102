import { Spinner } from '@fluentui/react';
import { FC, Suspense, lazy } from 'react';
import { IPageElementPDF } from '../../../../../Services/SakuraApiClient';

export interface ElementPdfViewerViewProps {
    data: IPageElementPDF;
}

const PdfViewer = lazy(() => import('./PdfViewer'));

export const ElementPdfViewerView: FC<ElementPdfViewerViewProps> = ({ data }) => {
    return (
        <Suspense fallback={<Spinner label='Chargement...' />}>
            <PdfViewer data={data} />
        </Suspense>
    );
};
