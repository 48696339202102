import React, { FC, useEffect } from 'react';
import { ApiCall } from '../../Redux/Reducers/DataSet/ApiCall/apiCall';
import { useDataSetSelector } from '../../Redux/Reducers/DataSet/reducer';
import { SearchComboBox } from '../components/SearchComboBox';
import { FilterDefinition, searchCriteriaToObject, SearchParameter } from '../components/SearchComboBox/SearchParameters';
import { ISelectableOption } from '@fluentui/react';

export interface DatasetLookupFieldProps<TDataItem> {
    viewMode?: 'List' | 'Grid';
    selectedItem: TDataItem | undefined;
    onSelectItem: (item: TDataItem | undefined) => void;
    accessKeyProp: (di: TDataItem) => string;
    accessTextProp: (di: TDataItem) => string;
    onCreateNewItem?: () => void;
    apiCall: ApiCall;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
    filters?: FilterDefinition[];
    onRenderItem?: (item: TDataItem, comboBoxProps?: ISelectableOption, defaultRender?: (dfProps?: ISelectableOption) => JSX.Element | null) => JSX.Element | null;
    onRenderLabel?: (item: TDataItem) => JSX.Element | null;
    refreshButton?: boolean;
    errorMessage?: string;
    disableTextSearch?: boolean;
}
export const DatasetLookupField = <T,>(props: DatasetLookupFieldProps<T>) => {
    const {
        viewMode,
        label,
        apiCall,
        selectedItem,
        onSelectItem,
        onCreateNewItem,
        accessKeyProp,
        accessTextProp,
        readOnly,
        required,
        contextId,
        filters,
        onRenderItem,
        onRenderLabel,
        errorMessage,
        disableTextSearch,
    } = props;

    const dataset = useDataSetSelector(apiCall.datasetName);
    useEffect(() => {
        apiCall.init(contextId);
    }, []);
    return (
        <SearchComboBox
            items={dataset.data as T[]}
            viewMode={viewMode ?? 'List'}
            paginationSetting={{
                nbItemPerPage: dataset.pageSize,
                nbPageShown: 5,
                currentPage: dataset.pageIndex,
                totalPage: dataset.totalPages,
            }}
            errorMessage={errorMessage}
            totalCount={dataset.totalCount}
            label={label}
            accessKeyProp={accessKeyProp}
            accessTextProp={accessTextProp}
            selectedItem={selectedItem}
            onSelectItem={(item) => onSelectItem(item as T)}
            onRenderItem={onRenderItem}
            onRenderLabel={onRenderLabel}
            filters={filters}
            onSearch={async (text, filters, pageIndex) => {
                const criteria = searchCriteriaToObject(filters);
                await apiCall.update({ pageIndex }, { mode: 'Full', change: { ...criteria, name: disableTextSearch ? undefined : text } }, contextId);
            }}
            onCreateNewItem={onCreateNewItem}
            readOnly={readOnly}
            required={required}
        />
    );
};
