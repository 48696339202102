import React, { FC, useMemo, useState } from 'react';
import { CustomDialog } from '../../../../common/components/Dialog/CustomDialog';
import { DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import { IResource, IResourceHierarchy } from '../../../../Services/SakuraApiClient';
import { Icon, Label } from '@fluentui/react';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { ResourceLookUp } from '../../../../Redux/Reducers/DataSet/DataSetName';

export interface NewOrLinkResourceprops {
    type: ResourceLookUp;
    newPhrase: string;
    linkPhrase: string;
    selectedResource: IResource | undefined;
    action: 'new' | 'link';
    onSelect: (action: 'new' | 'link', resource: IResource | undefined) => void;
}
import './NewOrLinkResource.scss';

export const NewOrLinkResource: FC<NewOrLinkResourceprops> = (props: NewOrLinkResourceprops) => {
    const { type, newPhrase, linkPhrase, onSelect, selectedResource, action } = props;

    return (
        <div className='NewOrLinkResource'>
            <div className={action === 'new' ? 'selected' : ''} tabIndex={0} onClick={() => onSelect('new', undefined)}>
                <Icon iconName='CheckMark' style={{ opacity: action === 'new' ? 1 : 0 }} />
                <Label>{newPhrase}</Label>
            </div>
            <div className={action === 'link' ? 'selected' : ''} onClick={() => onSelect('link', selectedResource)}>
                <Icon iconName='CheckMark' style={{ opacity: action === 'link' ? 1 : 0 }} />
                <ResourceLookupField
                    label={linkPhrase}
                    selectedResourceId={selectedResource?.id}
                    onSelectResource={(resource: IResource | undefined) => {
                        onSelect('link', resource);
                    }}
                    removeItemCreate
                    type={type}
                />
            </div>
        </div>
    );
};
