import clsx from 'clsx';
import { FC, PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { useState2 } from '../../../../common/Hooks/useState2';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';

import './MenuHierarchyItemDnd.scss';
import { MenuSettingEntry } from './menuSetting';

type DropPosition = 'Start' | 'End' | 'Middle';

export interface MenuHierarchyItemDndProps {
    entry: DeepPartial<MenuSettingEntry>;
    orientation?: 'Vertical' | 'Horizontal';
    noDrag?: boolean;
    onDropItem?: (item: DeepPartial<MenuSettingEntry>, destination: DeepPartial<MenuSettingEntry>, position: DropPosition) => void;
}
export const MenuHierarchyItemDnd: FC<PropsWithChildren<MenuHierarchyItemDndProps>> = (props: PropsWithChildren<MenuHierarchyItemDndProps>) => {
    const { entry, children, orientation, noDrag, onDropItem } = props;

    const [dragOver, setDragOver, dragOverRef] = useState2<DropPosition | undefined>();
    const selectorId = useRef(generateUniqueId('menu_entry_selector'));
    const htmlDivRef = useRef<HTMLDivElement>();

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            // The type (or types) to accept - strings or symbols
            accept: ['MENU_ENTRY_ITEM'],
            drop: (item: DeepPartial<MenuSettingEntry>, monitor) => {
                if (monitor.didDrop()) {
                    return monitor.getDropResult();
                }
                if (dragOverRef.current && onDropItem) {
                    console.log('Drop');
                    onDropItem(item, entry, dragOverRef.current);
                }
                return undefined;
            },
            canDrop: (item, monitor) => {
                if (monitor.getItemType() == 'MENU_ENTRY_ITEM') {
                    if (item.id && entry.id?.startsWith(item.id)) {
                        return false;
                    }
                    return true;
                }
                return false;
            },
            hover: (item, monitor) => {
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(selectorId.current) as HTMLDivElement;
                }
                const offset = monitor.getClientOffset();
                if (offset) {
                    if (orientation === 'Horizontal') {
                        const posX = offset.x - htmlDivRef.current.offsetLeft;
                        if (posX < htmlDivRef.current.clientWidth / 4) {
                            setDragOver('Start');
                        } else if (posX > (htmlDivRef.current.clientWidth / 4) * 3) {
                            setDragOver('End');
                        } else {
                            setDragOver('Middle');
                        }
                    } else {
                        const posY = offset.y - htmlDivRef.current.offsetTop;
                        if (posY < htmlDivRef.current.clientHeight / 4) {
                            setDragOver('Start');
                        } else if (posY > (htmlDivRef.current.clientHeight / 4) * 3) {
                            setDragOver('End');
                        } else {
                            setDragOver('Middle');
                        }
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        [entry, onDropItem],
    );
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: 'MENU_ENTRY_ITEM',
            item: entry,

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end(draggedItem, monitor) {
                //
            },
        }),
        [entry],
    );
    return (
        <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div ref={noDrag ? undefined : drag}>
                <div
                    id={selectorId.current}
                    ref={onDropItem ? drop : undefined}
                    className={clsx('menuHierarchyItemDnd', isOver ? `dragHover${orientation}${dragOver}` : '', isOver && canDrop === false ? 'noDrop' : '')}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
