import React, { FC, useEffect, useState } from 'react';
import { IOffer, Offer } from '../../../Services/SakuraApiClient';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { OfferGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Offer';
import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../Redux/hook';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { IconEnabled } from '../../../common/components/Icons/IconEnabled';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';

const offerIcon = 'ProductCatalog';
interface NavState {
    refresh?: boolean;
}
export const OffersList: FC = () => {
    const [selectedOffer, setSelectedOffer] = useState<IOffer | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const appDispatch = useAppDispatch();
    const nav = useNav();
    const navState = nav.current.state as INavigationState<NavState>;
    const onSelect = (offer: IOffer | undefined) => {
        setSelectedOffer(offer);
    };
    useEffect(() => {
        if (navState?.state?.refresh) {
            OfferGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const columns = [
        // {
        //     key: 'code',
        //     name: 'Code',
        //     fieldName: 'code',
        //     minWidth: 60,
        //     maxWidth: 60,
        //     allowSorting: true,
        //     isResizable: true,
        // },
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 300,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'entrer un nom',
                fieldNames: [
                    {
                        fieldName: 'name',
                        displayName: 'Nom',
                    },
                ],
            },
        },
        {
            key: 'Price',
            name: 'Prix',
            fieldName: 'price',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: Offer) => {
                return <span>{item.price} €</span>;
            },
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 400,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Publicité',
            name: 'Publicité',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: Offer) => {
                const nb = item.data?.marketing?.length ?? 0;
                return <span>{`${nb} publicité${nb > 1 ? 's' : ''}`}</span>;
            },
        },
        {
            key: 'CurrentOffer',
            name: 'Offre actuelle',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: false,
            isResizable: true,
            onRender: (item: Offer) => {
                return <IconEnabled enabled={item.isCurrentOffer} />;
            },
        },
    ];
    return (
        <Panel
            title='Liste des offres'
            icon={offerIcon}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        nav.navigate(screenPaths.offerCreate);
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedOffer,
                    onClick: () => {
                        if (selectedOffer?.id) {
                            nav.navigate(screenPaths.offerUpdate(selectedOffer.id), selectedOffer);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedOffer,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        OfferGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid
                datasetName={'OfferGrid'}
                columns={columns}
                getKey={(item) => item.id ?? ''}
                onSelect={onSelect}
                onRowDoubleClick={(item) => {
                    nav.navigate(screenPaths.offerUpdate(item.id), item);
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: offerIcon }}
                show={showDeleteDialog}
                title="Suppression de l'offre"
                subText="Etes-vous sur de vouloir supprimer l'offre?"
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedOffer?.id) {
                                    await client.deleteOffer(selectedOffer.id);
                                    setShowDeleteDialog(false);
                                    OfferGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
        </Panel>
    );
};
