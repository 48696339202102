import { Icon } from '@fluentui/react';
import { CSSProperties, FC, useContext, useMemo, useState } from 'react';
import { storageEndpointsSelector } from '../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../Redux/hook';
import { IPageElementImage, IPageElementImageStyle } from '../../../../../Services/SakuraApiClient';
import { imageEffectPredefined } from '../../../../../common/components/ImageViewer/ImageEffectPreDefined';
import { ResourceImageViewer } from '../../../../../common/components/ImageViewer/ResourceImageViewer';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';
import { baseStyleBuilder } from '../baseStyleBuilder';

export interface ElementImageViewProps {
    data: IPageElementImage;
}

function styleBuilder(style: IPageElementImageStyle): CSSProperties {
    return {
        ...baseStyleBuilder(style),
        maxHeight: style?.dimensions?.maxHeight,
        maxWidth: style?.dimensions?.maxWidth,
        minHeight: style?.dimensions?.minHeight,
        minWidth: style?.dimensions?.minWidth,
        height: style?.dimensions?.height,
        width: style?.dimensions?.width,
    };
}
export const ElementImageView: FC<ElementImageViewProps> = (props: ElementImageViewProps) => {
    const { data } = props;
    const storage = useAppSelector(storageEndpointsSelector);
    const [selected, setSelected] = useState<boolean>();
    const style = useMemo(() => {
        return styleBuilder(data.style);
    }, [data.style]);

    const [state] = useContext(ExecutionDataContext);
    const onSelect = (value: boolean) => {
        setSelected(value);
    };
    const imageEffect = useMemo(() => {
        if (data.style?.effect?.preDefined) {
            return { ...imageEffectPredefined[data.style?.effect.preDefined], shape: data.style?.effect.shape };
        }
        return data.style?.effect;
    }, [data.style?.effect]);
    return (
        <>
            {state.resources[data.resourceId]?.blobPath ? (
                <ResourceImageViewer
                    style={style}
                    blobPath={state.resources[data.resourceId].blobPath ?? ''}
                    effect={imageEffect}
                    selected={selected}
                    onSelect={data.style?.effect?.preDefined ? onSelect : undefined}
                ></ResourceImageViewer>
            ) : (
                <Icon iconName='PictureCenter' style={{ fontSize: 'x-large' }} />
            )}
        </>
    );

    // return <>{url ? <img title={data.toolTip} style={style} src={url}></img> : <Icon iconName='PictureCenter' style={{ fontSize: 'x-large' }} />}</>;
};
