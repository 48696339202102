import { AxiosRequestConfig } from 'axios';
import { notifyTokenExpired } from '../Redux/Reducers/System/reducer';
import { store } from '../Redux/store';
import { getTimeZone } from '../common/helpers/DateTimeHelper';
import { authService } from './Authentication/Security';

/* eslint-disable @typescript-eslint/no-explicit-any */
export async function interceptRequestHeader_Security(request: AxiosRequestConfig<any>): Promise<void> {
    const user = await authService.getUser();
    if (request.headers) {
        if (user && user.access_token) {
            request.headers.Authorization = `Bearer ${user.access_token}`;
        } else {
            store.dispatch(notifyTokenExpired());
        }
    }
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function interceptRequestHeader_ClientTime(request: AxiosRequestConfig<any>): void {
    if (request.headers) {
        request.headers['c-tz'] = getTimeZone();
    }
}
