import { Dropdown, Icon, IconButton, IDropdownOption, IDropdownProps, Label, Stack } from '@fluentui/react';
import React, { FC } from 'react';
import { DropDownWithPictureWithPic } from '../../../common/components/DropDownWithPicture';
import { GetResourceTypeOption } from '../../../common/components/Icons/ResourceTypeIcon';
import { ResourceTypeEnum } from '../../../Services/SakuraApiClient';

export interface ResourceTypeDropDownProps {
    disabled?: boolean;
    selectedResourceType: ResourceTypeEnum | undefined;
    onSelectResourceType: (type: ResourceTypeEnum | undefined) => void;
}

const resourceTypeOptions: IDropdownOption[] = [
    GetResourceTypeOption(ResourceTypeEnum.Formation),
    GetResourceTypeOption(ResourceTypeEnum.Document),
    GetResourceTypeOption(ResourceTypeEnum.Audio),
    GetResourceTypeOption(ResourceTypeEnum.Video),
    GetResourceTypeOption(ResourceTypeEnum.Questionnary),
    GetResourceTypeOption(ResourceTypeEnum.GameContent),
];

export const ResourceTypeDropDown: FC<ResourceTypeDropDownProps> = (props: ResourceTypeDropDownProps) => {
    const { selectedResourceType, onSelectResourceType, disabled } = props;
    return (
        <DropDownWithPictureWithPic
            placeholder='Sélectionner un type de resource'
            disabled={disabled}
            options={resourceTypeOptions}
            selectedKey={selectedResourceType}
            onSelectKey={(key) => onSelectResourceType(key as ResourceTypeEnum)}
        />
    );
};
