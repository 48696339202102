import { FC } from 'react';
import { PinterestShareButton } from 'react-share';
import { SocialNetworkLogo } from '../SocialNetworkLogo';
import { SharedButtonProps } from './type';

export const PinterestSB: FC<SharedButtonProps> = (props: SharedButtonProps) => {
    const { url, media } = props;
    return url ? (
        <PinterestShareButton url={url} media={media ?? ''}>
            <div className='pinterest_share_b' title='Partager sur pinterest'>
                <SocialNetworkLogo socialNetwork='pinterest' white size='small' />
                <span>{'Partager'}</span>
            </div>
        </PinterestShareButton>
    ) : null;
};
