import React, { FC, PropsWithChildren, useContext } from 'react';
import { IResourceDataPage } from '../../../Services/SakuraApiClient';
import { EditorDataContext, EditorDataContextProvider } from './EditorContext/EditorDataContextProvider';
import { PageEdition } from './PageEdition';
import { PageEditorElementPanel } from './ElementPanel/PageEditorElementPanel';
import { PageEditorToolBox } from './ToolBox/PageEditorToolBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const PageEditorStudioLayer: FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return <div className='pageEditorStudio'>{children}</div>;
};
