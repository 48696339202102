import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listOffers(newState.pageIndex, newState.pageSize, newState.filters['name'] as string, cancelToken);
};
export const OfferGrid = new ApiCall('OfferGrid', query);
export const OfferLookup = new ApiCall('OfferLookup', query);
