import React, { FC } from 'react';
import { RichTextViewer } from '../../../../../common/components/RichText/RichTextViewer';
import { IPageElementRichTextExtended } from './IPageElementRichTextExtended';

export interface ElementParagraphViewProps {
    data: IPageElementRichTextExtended;
}

export const ElementRichTextView: FC<ElementParagraphViewProps> = (props: ElementParagraphViewProps) => {
    const { data } = props;

    return <RichTextViewer content={data.content} textAlignment={data.textAlignment} />;
};
