import { DataSetName, customDefaultState, datasetCollections } from './DataSetName';

export type FilterValue = Date | string | number | null | undefined;

export interface DataSetInfoChange {
    pageIndex: number;
    pageSize: number;
}
export interface DataSetInfo extends DataSetInfoChange {
    totalCount: number;
    totalPages: number;
    data: unknown[];
    filters: Record<string, FilterValue>;
}

export interface DataSetState {
    data: Record<DataSetName, DataSetInfo>;
}

export const defaultDataSetState = {
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    data: [],
    filters: {},
};
export const initialState: DataSetState = {
    data: getInitialState(Object.keys(datasetCollections)),
};

function getInitialState(datasetNames: string[]) {
    const data: Record<string, DataSetInfo> = {};
    datasetNames.forEach((f) => (data[f] = customDefaultState[f] ? customDefaultState[f] : defaultDataSetState));
    data['ResourcePageBlockGrid'] = { ...data['ResourcePageBlockGrid'], pageSize: 1000 }; //Not page 1000 is enought

    data['ResourceTileGrid'] = { ...data['ResourceTileGrid'], pageSize: 1000 }; //Not page 1000 is enought
    data['ProductTileGrid'] = { ...data['ProductTileGrid'], pageSize: 1000 }; //Not page 1000 is enought
    return data;
}
