import React, { FC, useMemo, useState } from 'react';
import { ColorPalette, IColorPalette, IFontStyleEntry, IFontStyles } from '../../../Services/SakuraApiClient';
import { DeepPartial, DeepPartialWithArrayFunc } from '../../Hooks/Form/useForm';

import { Label } from '@fluentui/react';
import { FontConfiguration, FontStyleData } from './FontConfiguration';
import { fontStyleToCss } from '../../styles/FontStyles';

export interface FontStyleEntryItemProps {
    entry: IFontStyleEntry;
    onChange: (entry: DeepPartialWithArrayFunc<IFontStyleEntry>) => void;
}

export const FontStyleEntryItem: FC<FontStyleEntryItemProps> = (props: FontStyleEntryItemProps) => {
    const { entry, onChange } = props;

    const style = useMemo(() => {
        return fontStyleToCss({
            presetName: undefined,
            color: entry.color,
            font: entry.font,
            strike: entry.strike ?? false,
            underline: entry.underline ?? false,
        });
    }, [entry]);

    return (
        <div>
            <FontConfiguration
                font={entry as FontStyleData}
                onChangeFont={(data) => {
                    onChange(data);
                }}
            />
            <div className='preview'>
                <span>
                    <span style={style}>{"Ceci est un text d'exemple."}</span>
                    <br />
                    <span style={style}>{'a b c d e f g h i j k l m n o p q r s t u v w x y z'}</span>
                    <br />
                    <span style={style}>{'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z'}</span>
                    <br />
                    <span style={style}>{'0 1 2 3 4 5 6 7 8 9'}</span>
                    <br />
                    <span style={style}>{'? ! , ; . < > ( ) = + - * €'}</span>
                </span>
            </div>
        </div>
    );
};
