import { Editor, EditorState, RichUtils } from 'draft-js';
import React, { FC, useMemo } from 'react';
import { SelectableIcon } from '../../Icons/SelectableIcon';
import { toogleStyleOnEditorState } from '../state';
import { FontStylePicker } from '../../FontStyles/FontStylePicker';
import { PresetStylePrefix } from '../../../styles/FontStyles';
import { IFontStyleEntry } from '../../../../Services/SakuraApiClient';
import { clearCustom, fontSizeStylePrefix, setEditorStyle } from './utils';

export interface RichEditFontstyleButtonProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;

    refEditor: React.RefObject<Editor>;
}

export const RichEditFontstyleButton: FC<RichEditFontstyleButtonProps> = (props: RichEditFontstyleButtonProps) => {
    const { editorState, onChangeState, refEditor } = props;
    const selectedFontStyle = useMemo(() => {
        const value = editorState
            .getCurrentInlineStyle()
            .filter((s) => s !== undefined && s.startsWith(PresetStylePrefix))
            ?.last();
        if (value) {
            return value.substring(fontSizeStylePrefix.length).replaceAll('_', ' ');
        }
        return undefined;
    }, [editorState]);
    return (
        <FontStylePicker
            smallDropdown
            selectedFontStyleName={selectedFontStyle ?? ''}
            onSelect={(fontStyleName, data: IFontStyleEntry) => {
                let newState = editorState;
                const value = `${PresetStylePrefix}${fontStyleName.replaceAll(' ', '_')}`;
                newState = setEditorStyle(refEditor.current, editorState, PresetStylePrefix, undefined, value);
                newState = clearCustom(refEditor.current, newState);
                onChangeState(newState);
            }}
        />
    );
};
