import { ActionButton, Label, TextField } from '@fluentui/react';
import { CSSProperties, DragEventHandler, FC, MouseEventHandler, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { syntaxHighlightDecoratorTemplate } from '../RichText/Decorators/SyntaxHighlight';
import { RichTextEditor } from '../RichText/RichTextEditor';
import { loadEditorStateFromstring } from '../RichText/state';
import { Expression } from './Expression';

import clsx from 'clsx';
import { dynamicDataResolver } from '../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { declareVariables, useDynamicDataIntelisense } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { ExpressionVariable } from './Expression/ExpressionVariable';
import './expressionField.scss';

export enum ExpressionKind {
    Condition,
    Assignment,
    VariableNameOnly,
    TextWithExpression,
}
export interface ExpressionFieldProps {
    label: string;
    value: string | undefined;
    Kind: ExpressionKind;
    onChange: (value: string | undefined) => void;
    multilines?: boolean;
    maxVisibleLines?: number;
    minVisibleLines?: number;
    required?: boolean;
    errorMessage?: string;
    onDragStart?: DragEventHandler<HTMLDivElement> | undefined;
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    onDrag?: DragEventHandler<HTMLDivElement> | undefined;
    className?: string;
    showExpandButton?: boolean;
    readonly?: boolean;
}

export const ExpressionField: FC<ExpressionFieldProps> = (props: ExpressionFieldProps) => {
    const { label, value, onChange, multilines, maxVisibleLines, minVisibleLines, required, errorMessage, className, onClick, onDrag, onDragStart, Kind, showExpandButton, readonly } = props;

    const [editorState, setEditorState] = useState(() => loadEditorStateFromstring(value, syntaxHighlightDecoratorTemplate));
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<string | undefined>(errorMessage);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setErrMessage(errorMessage);
    }, [errorMessage]);
    useEffect(() => {
        setEditorState(loadEditorStateFromstring(value, syntaxHighlightDecoratorTemplate));
    }, [value]);
    const triggerOnChange = () => {
        const text = editorState.getCurrentContent().getPlainText();

        if (Kind === ExpressionKind.TextWithExpression) {
            const expressions = Expression.getExpressionInBrackets(text);
            const variables: ExpressionVariable[] = [];
            expressions.forEach((exp) => {
                variables.push(...exp.getListOfVariableUsed());
            });
            if (variables.length > 0) {
                dispatch(declareVariables({ variables }));
            }
        } else {
            const text = editorState.getCurrentContent().getPlainText();
            if (!errorMessage) {
                const expression = new Expression(text, dynamicDataResolver);
                const variables = expression.getListOfVariableUsed();
                dispatch(declareVariables({ variables }));
            }
        }
        // if (!errorMessage) {
        //     const expression = new Expression(text, dynamicDataResolver());
        //     const variables = expression.getListOfVariableUsed();
        //     dispatch(declareVariables({ variables }));
        // }
        onChange(text);
        setIsSelected(false);
    };
    const intelisense = useDynamicDataIntelisense();
    const isSingleLine = useMemo(() => {
        if (showEditDialog) {
            return false;
        }
        return !multilines;
    }, [showEditDialog, multilines]);

    const divStyle = useMemo(() => {
        if (maxVisibleLines || !isSingleLine) {
            return {
                overflowY: 'auto',
                maxHeight: maxVisibleLines ? maxVisibleLines * 28 : undefined,
                minHeight: minVisibleLines ? minVisibleLines * 28 : 28,
            } as CSSProperties | undefined;
        }
        return {
            minHeight: 28,
            maxHeight: 28,
        } as CSSProperties;
    }, [maxVisibleLines, isSingleLine]);

    const divRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const parentDiv = divRef.current?.getElementsByClassName('public-DraftEditor-content');
        if (parentDiv && parentDiv.length > 0) {
            if (parentDiv[0].children.length > 0) {
                parentDiv[0].children[0].setAttribute('style', `overflow-y: auto;${divStyle?.maxHeight ? `max-height: ${divStyle?.maxHeight}px;` : ''}min-height: ${divStyle?.minHeight}px;`);
            }
        }
    }, [divStyle]);

    return (
        <>
            <div
                className={clsx(
                    'expressionField',
                    className,
                    isSelected ? 'isSelected' : undefined,
                    errorMessage ? 'invalid' : undefined,
                    !isSingleLine ? '' : 'singleLine',
                    showEditDialog ? 'fullscreen sakuraDialog' : '',
                )}
                onClick={onClick}
                onDrag={onDrag}
                onDragStart={onDragStart}
            >
                <div className='headerExpressionField'>
                    <Label>{label}</Label>
                    {required ? <Label style={{ color: 'rgb(164, 38, 44)', paddingLeft: 4, paddingRight: 12 }}>{'*'}</Label> : null}
                </div>
                <div className={clsx('bodyExpressionField', readonly ? 'ro' : '')}>
                    <div className={clsx('contentExpressionField', readonly ? 'ro' : '')} ref={divRef}>
                        <RichTextEditor
                            showPanel={false}
                            showFocus={false}
                            readonly={readonly}
                            textAlignment='left'
                            editorState={editorState}
                            onChangeState={(state) => {
                                setEditorState(state);
                                const text = state.getCurrentContent().getPlainText();
                                if (Kind !== ExpressionKind.TextWithExpression) {
                                    const error = Expression.validate(text, Kind === ExpressionKind.Assignment);
                                    setErrMessage(error);
                                } else {
                                    const error = Expression.validateAllExpressionInBrackets(text);
                                    setErrMessage(error);
                                }
                            }}
                            onBlur={triggerOnChange}
                            onFocus={() => {
                                setIsSelected(true);
                            }}
                            singleLine={isSingleLine}
                            intelisenseDataSource={intelisense}
                            enabledBracketExpression={Kind === ExpressionKind.TextWithExpression}
                            disableReadOnlyData={Kind === ExpressionKind.VariableNameOnly}
                        />
                    </div>
                    {showExpandButton ? (
                        <>
                            {' '}
                            {showEditDialog ? (
                                <ActionButton
                                    iconProps={{ iconName: 'ChromeClose' }}
                                    onClick={() => {
                                        setShowEditDialog(false);
                                    }}
                                />
                            ) : (
                                <ActionButton
                                    iconProps={{ iconName: 'windowEdit' }}
                                    onClick={() => {
                                        setShowEditDialog(true);
                                    }}
                                />
                            )}
                        </>
                    ) : null}
                </div>

                {errMessage ? <span>{errMessage}</span> : null}
            </div>
            {showExpandButton && showEditDialog ? <TextField label={label} disabled value='edition plein ecran' /> : null}
        </>
    );
};
