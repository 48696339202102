import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { QuestionnaryDataContext } from './DataContext/QuestionnaryDataContextProvider';

export interface CategorySelectorProps {
    label?: string;
    errorMessage?: string;
    categoryId: string | undefined;
    onChange: (category: string | undefined) => void;
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
};
export const CategorySelector: FC<CategorySelectorProps> = (props: CategorySelectorProps) => {
    const { label, errorMessage, categoryId, onChange } = props;

    const context = useContext(QuestionnaryDataContext);

    const options = useMemo<IDropdownOption[]>(() => {
        return context.categories.map((c) => ({ key: c.id, text: c.name }));
    }, [context.categories]);

    return (
        <Dropdown
            label={label}
            errorMessage={errorMessage}
            options={options}
            placeholder={'choisi une category'}
            selectedKey={categoryId}
            styles={dropdownStyles}
            onChange={(_, val) => {
                onChange(val?.key as string | undefined);
            }}
        />
    );
};
