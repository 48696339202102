import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { EnumVideoProvider } from '../../../Services/SakuraApiClient';

import Youtube from 'react-player/youtube';
import Vimeo from '@u-wave/react-vimeo';
import { VimeoVideoPlayer } from './Vimeo/VimeoVideoPlayer';

export interface VideoViewerProps {
    videoId: string;
    provider: EnumVideoProvider;
    width?: number;
    height?: number;
}

export const VideoViewer: FC<VideoViewerProps> = (props: VideoViewerProps) => {
    const { provider, videoId, width, height } = props;
    const refVimeo = useRef<Vimeo>(null);
    return (
        <>
            {provider === EnumVideoProvider.Youtube && (
                <Youtube
                    url={videoId}
                    width={width}
                    height={height}
                    config={{
                        playerVars: {
                            showinfo: 0,
                            modestbranding: 1,
                        },
                    }}
                    controls
                    onProgress={(stateProgress) => {
                        console.log('YOUTUBE played = ' + stateProgress.played);
                        console.log('YOUTUBE played sec = ' + stateProgress.playedSeconds);
                    }}
                />
            )}
            {provider === EnumVideoProvider.Vimeo && (
                <>
                    {/* <iframe src={`https://player.vimeo.com/video/875369478?progress_bar=0#t=20`} width='640' height='360' frameBorder='0' allowFullScreen allow='autoplay; encrypted-media'></iframe> */}

                    <VimeoVideoPlayer videoUrl={videoId} width={width ?? 800} height={height ?? 450} />
                    {/* <Vimeo
                        start={20}
                        showTitle={false}
                        showByline={false}
                        showPortrait={false}
                        video={videoId}
                        width={width ?? 800}
                        height={height ?? 600}
                        onTimeUpdate={(ev) => {
                            console.log('VIMEO percent = ' + ev.percent);
                            console.log('VIMEO played sec = ' + ev.seconds);
                            console.log('VIMEO duration sec = ' + ev.duration);
                        }}
                    /> */}
                </>
            )}
        </>
    );
};
