import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { useInterval } from '../../helpers/timeUtils';
import { useEffect } from 'react';

export interface ParagraphAnimatedProps {
    speed: number;
    onUpdate?: () => void;
    onEnd?: () => void;
}
export const ParagraphAnimated: FC<PropsWithChildren<ParagraphAnimatedProps>> = (props: PropsWithChildren<ParagraphAnimatedProps>) => {
    const { speed, children, onUpdate, onEnd } = props;
    const pRef = useRef<HTMLParagraphElement | null>(null);
    const [stateSpeed, setStateSpeed] = useState<number | undefined>(speed);
    const originalText = useRef<string[]>();
    const letterIndex = useRef<number>(0);
    useEffect(() => {
        setStateSpeed(speed);
    }, [speed]);
    useEffect(() => {
        if (pRef.current) {
            if (pRef.current.textContent) {
                originalText.current = pRef.current.textContent.split('');
                letterIndex.current = 0;
                pRef.current.textContent = '';
            }
        }
    }, [children]);

    useInterval(() => {
        if (originalText.current && pRef.current) {
            if (letterIndex.current < originalText.current.length) {
                pRef.current.textContent += originalText.current[letterIndex.current];
                letterIndex.current++;
                if (onUpdate) {
                    onUpdate();
                }
            } else {
                if (onEnd) {
                    onEnd();
                    setStateSpeed(undefined);
                }
            }
        }
    }, speed);

    return <p ref={pRef}>{children}</p>;
};
