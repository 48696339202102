import { FC, useRef } from 'react';
import { Expander } from '../../../common/components/Expander';
import { IResourceHierarchyView } from '../../../Services/SakuraApiClient';
import { FormationHeader } from './FormationHeader';
import { FormationNavigationMenu } from './FormationNavigationMenu';

export interface FormationNavigationProps {
    formationHierarchyView: IResourceHierarchyView;
}

export const FormationNavigation: FC<FormationNavigationProps> = (props: FormationNavigationProps) => {
    const { formationHierarchyView } = props;
    const targetDiv = useRef<HTMLDivElement>(null); // Référence pour la div à redimensionner

    return (
        <div className='toc'>
            <div ref={targetDiv} className='content'>
                <FormationHeader formationHierarchyView={formationHierarchyView} />
                <FormationNavigationMenu />
            </div>
            <Expander targetDiv={targetDiv} direction='vertical' min={300} max={1000} />
        </div>
    );
};
