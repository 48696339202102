import React, { FC } from 'react';
import { SvgViewer } from './SvgViewer';
import './SvgButton.scss';

export interface Visual {
    content: string;
    color?: string;
}
export interface SvgButtonProps {
    state: boolean;
    onChangeState?: (state: boolean) => void;
    visualFalse: Visual;
    visualTrue: Visual;
    width: number;
    height: number;
}

export const SvgButton: FC<SvgButtonProps> = (props: SvgButtonProps) => {
    const { state, onChangeState, visualFalse, visualTrue, width, height } = props;
    return (
        <div className='svgButton' style={{ width, height }}>
            <div
                style={{ display: state ? undefined : 'none', pointerEvents: state ? undefined : 'none' }}
                onClick={() => {
                    if (onChangeState) {
                        onChangeState(false);
                    }
                }}
            >
                <SvgViewer content={visualTrue?.content} color={visualTrue?.color} height={height} width={width} />
            </div>
            <div
                style={{ display: state ? 'none' : undefined, pointerEvents: state ? 'none' : undefined }}
                onClick={() => {
                    if (onChangeState) {
                        onChangeState(true);
                    }
                }}
            >
                <SvgViewer content={visualFalse?.content} color={visualFalse?.color} height={height} width={width} />
            </div>
        </div>
    );
};
