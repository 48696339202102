import { FC } from 'react';
import { IProductTile, IResourceTile } from '../../../../../../../Services/SakuraApiClient';

import { DataType } from '../ContentSourceType';
import './ThumbnailItemDetails.scss';
import { ThumbnailItemProduct } from './ThumbnailItemProduct';
import { ThumbnailItemResource } from './ThumbnailItemResource';
export interface ThumbnailItemDetailsProps {
    type: DataType;
    data: IProductTile | IResourceTile;
    onOpen: () => Promise<void>;
}
export const ThumbnailItemDetails: FC<ThumbnailItemDetailsProps> = (props: ThumbnailItemDetailsProps) => {
    const { data, type, onOpen } = props;

    return (
        <div className='thumbnailItemDetails'>
            {type === 'Resource' ? <ThumbnailItemResource data={data as IResourceTile} onOpen={onOpen} /> : <ThumbnailItemProduct data={data as IProductTile} onOpen={onOpen} />}
        </div>
    );
};
