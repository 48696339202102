import { FC } from 'react';
import { IProduct, ProductUpdateModel } from '../../../Services/SakuraApiClient';

import { ActionButton, TextField } from '@fluentui/react';
import { refreshAllProductTiles } from '../../../Redux/Reducers/DataSet/helper';
import { useAppDispatch } from '../../../Redux/hook';
import { useForm } from '../../../common/Hooks/Form';
import * as validators from '../../../common/Hooks/Form/validators';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { SectionSettingLookup } from '../System/SectionSetting/SectionSettingLookup';

export interface ProductUXDataChangeProps {
    product: IProduct;
    onClose: (product: IProduct | undefined) => void;
}

export const ProductUXDataChange: FC<ProductUXDataChangeProps> = (props: ProductUXDataChangeProps) => {
    const { product, onClose } = props;
    const contextId = `EditUxData`;

    const form = useForm<IProduct>(
        {
            initialState: product,
            validators: {
                fields: {
                    uxData: {
                        name: { displayName: 'Le nom', validators: [validators.required] },
                        section: { displayName: 'La section', validators: [validators.required] },
                    },
                },
            },
        },
        product !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (prd: IProduct | undefined) => {
        form.clearErrors();
        onClose(prd);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state) {
                        const data = new ProductUpdateModel();
                        data.init(form.state);
                        const savedProduct = await client.updateProduct(product.id, data);
                        form.commit();
                        refreshAllProductTiles();
                        close(savedProduct);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: 'PreviewLink' }}
            mode={'Edit'}
            title={'Mettre à jour les informations de la tuile (NetFlix)'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField required label='Nom' value={form.state.uxData?.name ?? ''} onChange={(_, v) => form.update({ uxData: { name: v } })} />
                <SectionSettingLookup required label='Section' value={form.state.uxData?.section ?? ''} onChange={(v) => form.update({ uxData: { section: v } })} />
                <TextField label='Description' value={form.state.uxData?.description ?? ''} onChange={(_, v) => form.update({ uxData: { description: v } })} />
                <ResourceLookupField
                    type='ResourceImageVignetteLookup'
                    label='Vignette'
                    selectedResourceId={form.state.thumbnailResourceId}
                    onSelectResource={(resource) => form.update({ thumbnailResourceId: resource?.id })}
                />
                <ResourceLookupField
                    label='Page de vente'
                    selectedResourceId={form.state.uxData?.detailPageResourceId}
                    onSelectResource={(resource) => form.update({ uxData: { detailPageResourceId: resource?.id } })}
                    removeItemCreate
                    type={'ResourcePageVenteLookup'}
                />
                <ActionButton
                    title='En effacant ces informations, la tuile ne sera plus visible.'
                    text='Effacer les informations'
                    iconProps={{ iconName: 'Delete' }}
                    onClick={() => {
                        performApiCall(
                            async (client) => {
                                await client.deleteProductUxData(product.id);
                                close(undefined);
                            },
                            appDispatch,
                            { contextId },
                        );
                    }}
                />
            </div>
        </EditDialog>
    );
};
