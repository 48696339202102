import { CompoundButton, DirectionalHint, TeachingBubble } from '@fluentui/react';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mobileViewSelector } from '../../Redux/Reducers/System/reducer';
import { userOrganizationSelector, userProfileSelector } from '../../Redux/Reducers/User/reducer';
import { useAppSelector } from '../../Redux/hook';
import { useNav } from '../Hooks/useNav';
import { SakuraPersona } from '../components/Persona/SakuraPersona';

import { updateProfile } from '../../Redux/Reducers/User/actionChain';
import './LayoutUserProfile.scss';

export const LayoutUserProfile: FC = () => {
    const isMobileView = useAppSelector(mobileViewSelector);
    const userProfile = useAppSelector(userProfileSelector);
    const organization = useAppSelector(userOrganizationSelector);
    const dispatch = useDispatch();
    const [menuVisible, setMenuVisible] = useState(false);
    const nav = useNav();

    const onDisconnect = () => {
        updateProfile(dispatch, { profile: undefined, experienceRequiredToLevelUp: 0, organization: '' });

        nav.navigateGlobal(`/logout/${organization}`);
    };

    return (
        <div className='sakura-layout-profile'>
            <SakuraPersona isMobileView={isMobileView} userProfile={userProfile} onclick={() => setMenuVisible(!menuVisible)} />
            {menuVisible && (
                <TeachingBubble
                    calloutProps={{
                        directionalHint: DirectionalHint.bottomLeftEdge,
                        calloutWidth: 250,
                        className: 'profileMenuBubble',
                    }}
                    target={`#personaButton`}
                    styles={{ bodyContent: { marginBottom: 0 } }}
                    hasCloseButton={true}
                    closeButtonAriaLabel='Close'
                    onDismiss={() => setMenuVisible(false)}
                    headline='Mon compte'
                >
                    <div className='profileMenu'>
                        <CompoundButton
                            primary
                            iconProps={{ iconName: 'EditContact' }}
                            text='Profile'
                            secondaryText='Modifier mon profile'
                            onClick={() => {
                                setMenuVisible(false);
                                nav.navigate(`account`);
                            }}
                        />
                        <CompoundButton primary iconProps={{ iconName: 'OutOfOffice' }} text='Déconnection' secondaryText='Fermer ma session' onClick={() => onDisconnect()} />
                    </div>
                </TeachingBubble>
            )}
        </div>
    );
};
