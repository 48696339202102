import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const offerId = newState.filters['offerId'];
    return await client.listProducts(offerId as number, newState.pageIndex, newState.pageSize, newState.filters['name'] as string, cancelToken);
};

export const ProductGrid = new ApiCall('ProductGrid', query);
export const ProductsByOffer = new ApiCall('ProductsByOffer', query);
export const ProductLookup = new ApiCall('ProductLookup', query);

export const ProductTileGrid = new ApiCall('ProductTileGrid', async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listProductTile(newState.pageIndex, newState.pageSize, cancelToken);
});

export const ProductTileWhishlistGrid = new ApiCall('ProductTileWhishlistGrid', async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    return await client.listProductTileInWhishList(newState.pageIndex, newState.pageSize, cancelToken);
});
