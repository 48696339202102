import React, { FC, useState } from 'react';
import { useMessageSelector } from '../../Redux/Reducers/System/reducer';
import { useApiCall } from '../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../Redux/hook';
import { LoadingScreen } from '../../common/LoadingScreen';
import { InvalidLinkPage } from '../../common/InvalidLinkPage';
import { AuthenticationBarrier } from './AuthenticationBarrier';
import { authService } from './index';

export const ProspectAccess: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [guestCode, setGuestCode] = useState<string | undefined>(undefined);
    const error = useMessageSelector();
    const dispatch = useAppDispatch();
    useApiCall(
        async (client, cancelToken) => {
            const values = await client.getProtectedValueOfQueryString(window.location.href, cancelToken);
            const prospectId = values['u'];
            setGuestCode(prospectId.protected);
            setIsLoading(false);
        },
        [],
        { anonymous: true },
    );
    return (
        <>
            {error && <InvalidLinkPage />}
            {isLoading ? (
                <LoadingScreen text='Bienvenue &#x1F603;' />
            ) : (
                <AuthenticationBarrier guestCode={guestCode} authService={authService} renderLoadingScreen={() => <LoadingScreen text='Bienvenue &#x1F603;' />} />
            )}
        </>
    );
};
