import { useCallback, useState } from 'react';

export type VisiblityAnimatedState = 'showing' | 'visible' | 'hiding' | 'hide';
export function visiblityAnimatedStatetoBoolean(state: VisiblityAnimatedState) {
    if (state === 'showing' || state === 'visible') {
        return true;
    }
    return false;
}
export function useAnimationState(animationDuration: number, visible: boolean): [VisiblityAnimatedState, (visible: boolean) => void] {
    const [state, setInternalState] = useState<VisiblityAnimatedState>(visible ? 'visible' : 'hide');

    const setState = useCallback(
        (visible: boolean) => {
            if (visible) {
                setInternalState('showing');
                setTimeout(() => {
                    setInternalState('visible');
                }, animationDuration);
            } else {
                setInternalState('hiding');
                setTimeout(() => {
                    setInternalState('hide');
                }, animationDuration);
            }
        },
        [animationDuration, setInternalState],
    );

    return [state, setState];
}
