import { IAccount, IBadge, IUserMenuEntry } from '../../../Services/SakuraApiClient';

export interface UserState {
    userProfile?: IAccount;
    menu: IUserMenuEntry[] | undefined;
    experienceRequiredToLevelUp: number;
    hasLevelUp: boolean;
    acquiredExperience: number | undefined;
    acquiredCoin: ValueChange | undefined;
    acquiredDiamond: ValueChange | undefined;
    organization: string;
    rootPath: string;
    displayBadge: IBadge[];
}

export interface ValueChange {
    total: number;
    variation: number;
}

export const initialState: UserState = {
    userProfile: undefined,
    menu: undefined,
    experienceRequiredToLevelUp: 0,
    hasLevelUp: false,
    acquiredExperience: undefined,
    acquiredCoin: undefined,
    acquiredDiamond: undefined,
    organization: '',
    rootPath: '/app',
    displayBadge: [],
};
