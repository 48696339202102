import clsx from 'clsx';
import { FC, PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useState2 } from '../../Hooks/useState2';
import { getParent } from '../../helpers/Element';
import { generateUniqueId } from '../../helpers/uniqueId';
import './PivotItemDraggable.scss';

export interface PivotItemDraggableProps {
    index: number;
    disabled?: boolean;
    onDrop: (currentIndex: number, targetIndex: number) => void;
}
interface ItemData {
    index: number;
}
export type DropAt = 'Before' | 'After';

export const PivotItemDraggable: FC<PropsWithChildren<PivotItemDraggableProps>> = (props: PropsWithChildren<PivotItemDraggableProps>) => {
    const { index, children, onDrop, disabled } = props;
    const pivotItemId = useRef(generateUniqueId('pivot_'));
    const htmlDivRef = useRef<HTMLDivElement>();

    const [dragOver, setDragOver, dragOverRef] = useState2<DropAt | undefined>();
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: 'PIVOT_ITEM',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end(draggedItem, monitor) {
                if (monitor.didDrop()) {
                }
            },
        }),
        [index],
    );
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: ['PIVOT_ITEM'],
            drop: (item: unknown, monitor) => {
                if (monitor.didDrop()) {
                    return monitor.getDropResult();
                }
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(pivotItemId.current) as HTMLDivElement;
                }
                const itemIndex = (item as ItemData).index;
                let targetIndex = itemIndex;
                if (itemIndex > index) {
                    targetIndex = dragOverRef.current === 'Before' ? index : index + 1;
                } else {
                    targetIndex = dragOverRef.current === 'Before' ? index - 1 : index;
                }
                onDrop(itemIndex, targetIndex);
                return monitor.getDropResult();
            },
            hover: (item, monitor) => {
                if ((item as ItemData).index === index) {
                    return;
                }
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(pivotItemId.current) as HTMLDivElement;
                }
                const offset = monitor.getSourceClientOffset();
                if (offset) {
                    const button = getParent(htmlDivRef.current, (e) => e.nodeName === 'BUTTON');
                    const span = getParent(htmlDivRef.current, (e) => e.nodeName === 'SPAN') ?? (htmlDivRef.current as HTMLElement);
                    const tablist = getParent(htmlDivRef.current, (e: HTMLElement) => e.attributes.getNamedItem('role')?.value === 'tablist');
                    const newOffset = offset.x - (tablist?.offsetLeft ?? 0);

                    if (button) {
                        setDragOver(newOffset - button.offsetLeft < span.clientWidth / 2 ? 'Before' : 'After');
                    }
                }
            },
            canDrop: (item, monitor) => {
                if (monitor.getItemType() == 'PIVOT_ITEM') {
                    if ((item as ItemData).index !== index) {
                        return true;
                    }
                }
                return false;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        [index],
    );
    return (
        <div ref={disabled ? undefined : dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div ref={disabled ? undefined : drag}>
                <div id={pivotItemId.current} ref={disabled ? undefined : drop} className={clsx('pivotItemDraggable', isOver && dragOver ? `dragHover${dragOver}` : '')}>
                    {children}
                </div>
            </div>
        </div>
    );
};
