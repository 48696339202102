import { FC } from 'react';

import { useDynamicData } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { IQuestionnaryResultScore, IResultDescription } from '../../../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';
import { ParagraphAnimated } from '../../../../../common/components/ParagraphAnimated.tsx';
import { ResourceViewer } from '../../../../../common/components/ResourcePackage/ResourceViewer';

export interface ShowExamResultProps {
    score?: IQuestionnaryResultScore;
    scoreDesc: IResultDescription;
    immediate?: boolean;
}

export const ShowExamResult: FC<ShowExamResultProps> = (props: ShowExamResultProps) => {
    const { score, scoreDesc, immediate } = props;
    const dynData = useDynamicData();
    const resolvedTitle = useBracketResolver(scoreDesc?.title, dynData);
    const resolvedDescription = useBracketResolver(scoreDesc?.description, dynData);
    return scoreDesc ? (
        <>
            <h2>{resolvedTitle}</h2>
            <div className='description'>
                {scoreDesc.imageResourceId ? (
                    <div className='pic'>
                        <ResourceViewer resourceId={scoreDesc.imageResourceId} />
                    </div>
                ) : (
                    <></>
                )}
                {score ? (
                    <>
                        <h2>{`${score.percent} %`}</h2>
                    </>
                ) : null}
                {resolvedDescription ? <div>{immediate ? <p>{resolvedDescription}</p> : <ParagraphAnimated speed={25}>{resolvedDescription}</ParagraphAnimated>}</div> : <></>}
            </div>
        </>
    ) : (
        <></>
    );
};
