import { EnumBlockDirection, EnumDisplayMode, PageElementContainer, PageElementContainerStyle, PageElementType, ResourceDataPage } from '../../../../Services/SakuraApiClient';

export function buildNewPage(): ResourceDataPage {
    const page = new ResourceDataPage();
    page.title = 'Nouvelle Page';
    const container = new PageElementContainer();
    container.type = PageElementType.Container;
    container.items = [];
    container.style = new PageElementContainerStyle();
    container.style.display = EnumDisplayMode.Flex;
    container.style.direction = EnumBlockDirection.Column;
    page.page = container;
    const containerMobile = new PageElementContainer();
    containerMobile.type = PageElementType.Container;
    containerMobile.items = [];
    containerMobile.style = new PageElementContainerStyle();
    containerMobile.style.display = EnumDisplayMode.Flex;
    containerMobile.style.direction = EnumBlockDirection.Column;
    page.mobilePage = containerMobile;
    return page;
}
