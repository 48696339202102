import { FC, PropsWithChildren, useState } from 'react';
import './PwaRoot.scss';

interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}
interface PwaRootProps {
    applicationName: string;
    applicationLogo: string;
}
export const PwaRoot: FC<PropsWithChildren<PwaRootProps>> = (props: PropsWithChildren<PwaRootProps>) => {
    const [installpromptEvent, setInstallprompt] = useState<BeforeInstallPromptEvent>();
    const { children, applicationLogo, applicationName } = props;

    /*
    async function showNotification() {
        try
        {
           
            if (Notification){
            const result = await Notification.requestPermission();
            
            if (result === 'granted') {
                const noti = new Notification('Hello!', {
                    body: 'It’s me.',
                    icon: 'mario.png'
                });
                noti.onclick = () => alert('clicked');
            }
        }
        }catch(error){
            
            console.log(error);
        }
    };
*/
    // useEffect(() => {
    //     if (isMobile) {

    //         console.log('register listener for event beforeinstallprompt');
    //         window.addEventListener('beforeinstallprompt', (e) => {
    //             e.preventDefault();
    //             setInstallprompt(e);
    //             console.log('beforeinstallprompt');
    //         });
    //     }
    // }, []);

    const onDownloadClick = () => {
        if (installpromptEvent) {
            installpromptEvent.prompt();
            setInstallprompt(undefined);
        }
    };

    return (
        <>
            {/* {installpromptEvent ? (
                <div className='promptInstall'>
                    <div>
                        <img src={applicationLogo} alt='Application logo' />
                        <h1>{applicationName}</h1>
                        <Button onClick={onDownloadClick} text='Get the App' iconProps={{ iconName: 'download' }} />
                    </div>
                </div>
            ) : (
                children
            )} */}
            {children}
        </>
    );
};
