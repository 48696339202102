import { useEffect } from 'react';

export function useOnClickScreen(callBack: () => void) {
    useEffect(() => {
        document.addEventListener('click', callBack);
        return () => {
            document.removeEventListener('click', callBack);
        };
    }, [callBack]);
}
