import { FC, Suspense, lazy, useMemo, useState } from 'react';
import { IResultDescription } from '../../../../../Services/SakuraApiClient';

import { ShowCategory } from './ShowCategory';

const PolarChart = lazy(() => import('../../../Charts/PolarChart'));

export interface ShowCategoriesGraphicProps {
    categoryDesc: Record<string, IResultDescription> | undefined;
    scoringByCategory: Record<string, number> | undefined;
}
const backgroundColor = ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)'];
export const ShowCategoriesGraphic: FC<ShowCategoriesGraphicProps> = (props: ShowCategoriesGraphicProps) => {
    const { categoryDesc, scoringByCategory } = props;
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [selectedcategory, setSelectedcategory] = useState<IResultDescription>();
    const data = useMemo(() => {
        if (categoryDesc && scoringByCategory) {
            const labels: string[] = [];
            const data: number[] = [];
            const categories: IResultDescription[] = [];
            Object.keys(categoryDesc).forEach((key) => {
                labels.push(categoryDesc[key].title ?? '');
                categories.push(categoryDesc[key]);
                data.push(scoringByCategory[key]);
            });
            return {
                labels,
                data,
                categories,
            };
        }
        return undefined;
    }, [categoryDesc, scoringByCategory]);
    return data ? (
        <>
            <div className='resultChartView'>
                <Suspense fallback={<></>}>
                    <PolarChart
                        colors={backgroundColor}
                        labels={data.labels}
                        valueLabel='Points'
                        values={data.data}
                        selectedIndex={selectedIndex}
                        onSelect={(label, value, index) => {
                            setSelectedIndex(index);
                            if (categoryDesc) {
                                setSelectedcategory(data.categories[index]);
                            }
                        }}
                        unSelect={() => {
                            setSelectedIndex(undefined);
                            setSelectedcategory(undefined);
                        }}
                    />
                </Suspense>
            </div>
            <ShowCategory category={selectedcategory} />
        </>
    ) : (
        <></>
    );
};
