import { AuthenticationService, ISecurityConfig } from './AuthService';

const isLocalDev = window.location.href.startsWith('https://localhost');
const localAuthority = isLocalDev;
const config: ISecurityConfig = {
    authority: localAuthority ? 'https://localhost:40443' : 'https://connexion.my.sakura-coaching.fr',
    client_id: 'SakuraPortal',
    clientRoot: isLocalDev ? 'https://localhost:50443/' : 'https://my.sakura-coaching.fr/',
    scope: 'openid profile SakuraApi',
};
export const authService = new AuthenticationService(config);
