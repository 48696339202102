import { Label, TextField, Toggle } from '@fluentui/react';
import { FC } from 'react';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { parseBlobPath } from '../../../../common/Hooks/StorageResolver';
import { useNav } from '../../../../common/Hooks/useNav';
import { useNavigationEntity } from '../../../../common/Hooks/useNavigationEntity';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { DownloadLink } from '../../../../common/components/DownloadLink/DownloadLink';
import { Panel } from '../../../../common/components/Panel/Panel';

export const ResourceDocumentDetailsPage: FC = () => {
    const [id, resource] = useNavigationEntity((id, client) => client.getResource(id as number));
    return id && !resource ? <></> : <ResourceDocumentDetailsPageContent resource={resource}></ResourceDocumentDetailsPageContent>;
};
interface ResourceDocumentDetailsPageContentProps {
    resource: IResource | undefined;
}

export const ResourceDocumentDetailsPageContent: FC<ResourceDocumentDetailsPageContentProps> = (props: ResourceDocumentDetailsPageContentProps) => {
    const { resource } = props;
    const nav = useNav();
    return (
        <>
            <Panel
                title={'Document information (lecture seule)'}
                backButtonCommand={() => {
                    nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Document));
                }}
            >
                <TextField label='Nom' readOnly value={resource?.name}></TextField>
                <TextField label='Category' readOnly value={resource?.category}></TextField>
                <TextField label='Description' readOnly value={resource?.description}></TextField>
                <ResourceLookupField type='ResourceImageVignetteLookup' label='Vignette' readOnly selectedResourceId={resource?.thumbnailResourceId} onSelectResource={() => {}} />

                <Toggle label='Actif' offText='Non' onText='Oui' checked={resource?.enabled} />
                <Label>{'Lien de téléchargement:'}</Label>
                {resource?.blobPath ? (
                    <DownloadLink blobPath={resource.blobPath} text={parseBlobPath(resource.blobPath)?.name ?? ''} blobPathKind='Value' downloadName={resource.name} display='Action' />
                ) : (
                    <span>Pas de lien</span>
                )}
            </Panel>
        </>
    );
};
