import { IFunction } from './IFunction';
import { LowerFunction } from './Litteral/LowerFunction';
import { UpperFunction } from './Litteral/UpperFunction';
import { ReplaceFunction } from './Litteral/ReplaceFunction';
import { IfFunction } from './Conditional/IfFunction';
import { Value } from '../Value';
import { IntelisenseInfo } from '../../../../../Redux/Reducers/DynamiqueData/state';

export class FunctionLibrary {
    private static library: { [key: string]: IFunction } = {};

    static init() {
        FunctionLibrary.registerFunctions(new LowerFunction(), new UpperFunction(), new ReplaceFunction(), new IfFunction());
    }

    private static registerFunctions(...functions: IFunction[]) {
        for (const func of functions) {
            FunctionLibrary.library[func.name.toLowerCase()] = func;
        }
    }

    static invoke(functionName: string, parameters: Value[]): Value {
        if (!functionName) {
            throw new Error('functionName cannot be null or undefined.');
        }

        const lowerFunctionName = functionName.toLowerCase();
        const func = FunctionLibrary.library[lowerFunctionName];

        if (func) {
            const error = func.checkArguments(parameters);
            if (!error) {
                return func.invoke(parameters);
            } else {
                throw new Error(error);
            }
        }

        throw new Error(`Function '${functionName}' not found`);
    }

    static getIntelisense(): IntelisenseInfo[] {
        return Object.entries(FunctionLibrary.library).map((func) => {
            return func[1].intelisenseInfo;
        });
    }
}

// Initialisation de la bibliothèque des fonctions
FunctionLibrary.init();
