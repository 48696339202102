import { EntityState, IFormationData, IResource, IResourceData, IResourceHierarchy, IUxJsonData, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { DeepPartial, DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import { ObjectOperation } from '../../../../common/helpers/ObjectAndArray';
import {
    checkModuleContainsResource,
    deleteCascade,
    getIdPart,
    getItemById,
    getItemByPath,
    getParentPath,
    reAssignOrderIndex,
    updateArrayAtMultipleResourcePath,
    updateArrayAtResourcePath,
    updateAtResourcePath,
    updateResourcePathOnChildren,
    updateSharedResource,
} from './Utils';

export interface PropertyUpdate {
    resourcePart?: {
        name?: string;
        reusable?: boolean | undefined;
        thumbnailResourceId?: number;
    };
    title?: string | undefined;
    icon?: string | undefined;

    formation?: Partial<IFormationData>;
    resourceData?: Partial<IResourceData>;
    uxData?: Partial<IUxJsonData>;
}
export type ResourceHierarchyAction =
    | { type: 'addItem'; payload: { resourcePath: string; item: DeepPartialWithArrayFunc<IResourceHierarchy> } }
    | { type: 'updateProperties'; payload: { resourcePath: string; propertyChanged: PropertyUpdate } }
    | { type: 'updateItemResourcePart'; payload: { resourcePath: string; resource: DeepPartialWithArrayFunc<IResource> | undefined } }
    | { type: 'MoveItem'; payload: { item: DeepPartialWithArrayFunc<IResourceHierarchy>; destination: DeepPartialWithArrayFunc<IResourceHierarchy>; position: 'before' | 'after' | 'inside' } }
    | { type: 'deleteItem'; payload: { resourcePath: string } };

export function formResourceHierarchySlicer(state: DeepPartial<IResourceHierarchy>, action: ResourceHierarchyAction) {
    let newState: DeepPartialWithArrayFunc<IResourceHierarchy> = state;
    let error: string | undefined = undefined;
    switch (action.type) {
        case 'addItem':
            if (action.payload.item.resource?.resourceType === ResourceTypeEnum.Module && action.payload.item.resource.id !== undefined) {
                if (getItemById(state, action.payload.item.resource.id?.toString() ?? '')) {
                    error = 'Ce module est déjà utilisé dans cette formation.';
                    break;
                }
            }
            if (action.payload.item.resource?.resourceType === ResourceTypeEnum.Page && action.payload.item.resource.id !== undefined) {
                if (checkModuleContainsResource(action.payload.resourcePath, action.payload.item.resource.id, state as IResourceHierarchy)) {
                    error = 'Cette page est déjà utilisé dans ce module.';
                    break;
                }
            }
            newState = updateAtResourcePath(action.payload.resourcePath, {
                children: (array) => {
                    const newArray = [...array];
                    newArray.push({ ...action.payload.item, linkState: EntityState.Create });
                    return newArray;
                },
            });
            break;
        case 'updateProperties':
            const item = getItemByPath(action.payload.resourcePath, state as IResourceHierarchy);
            if (item) {
                const resourceState = item.resourceState !== EntityState.Create ? EntityState.Update : item.resourceState;

                if (item.resource.resourceType === ResourceTypeEnum.Formation) {
                    newState = updateAtResourcePath(action.payload.resourcePath, {
                        resourceState,
                        resource: {
                            ...action.payload.propertyChanged.resourcePart,
                            uxData: { ...action.payload.propertyChanged.uxData },
                            data: {
                                ...action.payload.propertyChanged.resourceData,
                                content: {
                                    formation: { ...action.payload.propertyChanged?.formation },
                                },
                            },
                        },
                    });
                } else {
                    const linkState = item.linkState !== EntityState.Create ? EntityState.Update : item.linkState;

                    const resourcePart = {
                        ...action.payload.propertyChanged.resourcePart,
                        data: {
                            ...action.payload.propertyChanged.resourceData,
                        },
                    };
                    const linkPart = { overrideName: action.payload.propertyChanged.title, overrideIcon: action.payload.propertyChanged.icon };

                    newState = updateAtResourcePath(action.payload.resourcePath, {
                        resourceState,
                        linkState,
                        resource: resourcePart,
                        ...linkPart,
                    });
                }
            }
            break;
        case 'updateItemResourcePart':
            {
                const item = getItemByPath(action.payload.resourcePath, state as IResourceHierarchy);
                if (item) {
                    const resourceState = item.resourceState !== EntityState.Create ? EntityState.Update : item.resourceState;
                    if (item.resource.reusable && item.resource.resourceType !== ResourceTypeEnum.Formation) {
                        newState = updateSharedResource(action.payload.resourcePath, {
                            resourceState,
                            resource: action.payload.resource,
                        });
                    } else {
                        newState = updateAtResourcePath(action.payload.resourcePath, {
                            resourceState,
                            resource: action.payload.resource,
                        });
                    }
                }
            }
            break;
        case 'MoveItem':
            if (action.payload.item.resourcePath && action.payload.destination.resourcePath) {
                const srcParent = getParentPath(action.payload.item.resourcePath);
                const dstParent = getParentPath(action.payload.destination.resourcePath);

                if (srcParent === dstParent && action.payload.position !== 'inside') {
                    //la source et la destination on le même parent. on reordonne les elements
                    newState = updateArrayAtResourcePath(action.payload.item.resourcePath, (array, index) => {
                        const srcIndex = array.findIndex((e) => e.resourcePath === action.payload.item.resourcePath);
                        const src = array[srcIndex];
                        let newArray = array.filter((a) => a.resourcePath !== action.payload.item.resourcePath);
                        const destIndex = newArray.findIndex((e) => e.resourcePath === action.payload.destination.resourcePath);

                        const insertIndex = action.payload.position === 'before' ? destIndex : destIndex + 1;

                        if (srcIndex === insertIndex) {
                            return array; //Nothing to change
                        }
                        if (insertIndex >= newArray.length) {
                            newArray.push(src as DeepPartialWithArrayFunc<IResourceHierarchy>);
                        } else {
                            newArray.splice(insertIndex, 0, src as DeepPartialWithArrayFunc<IResourceHierarchy>);
                        }

                        newArray = reAssignOrderIndex(newArray);

                        return newArray;
                    });
                } else {
                    const src = getItemByPath(action.payload.item.resourcePath, state as IResourceHierarchy);
                    if (src) {
                        newState = updateArrayAtMultipleResourcePath([
                            {
                                resourcePath: action.payload.item.resourcePath,
                                callback: (array, index) => {
                                    //remove the source of this place
                                    if (src.linkState === EntityState.Create) {
                                        // si l'item n'existe pas encore sur le serveur on le supprime réelement de la liste
                                        if (index !== -1) {
                                            return array.filter((a) => a.resourcePath !== src.resourcePath);
                                        }
                                    } else {
                                        array[index] = ObjectOperation.merge(array[index], {
                                            linkState: EntityState.Delete,
                                        });
                                    }
                                    return array;
                                },
                            },
                            {
                                resourcePath: action.payload.destination.resourcePath,
                                callback: (array, index) => {
                                    //add the source to this place

                                    const destIndex = array.findIndex((e) => e.resourcePath === action.payload.destination.resourcePath);
                                    const srcId = getIdPart(src.resourcePath);
                                    let parentPath = dstParent;
                                    let arrayUpdated = array;
                                    let insertIndex;
                                    if (action.payload.position === 'inside') {
                                        parentPath = array[destIndex].resourcePath;
                                        arrayUpdated = array[destIndex].children ? [...(array[destIndex].children as DeepPartialWithArrayFunc<IResourceHierarchy>[])] : [];
                                        insertIndex = arrayUpdated.length;
                                    } else {
                                        insertIndex = action.payload.position === 'before' ? destIndex : destIndex + 1;
                                    }

                                    const newSrcResourcePath = `${parentPath}/${srcId}`;

                                    const indexOfPrevious = arrayUpdated.findIndex((e) => e.resourcePath === newSrcResourcePath && e.linkState === EntityState.Delete);
                                    //update resourcePath on children too
                                    const copySrc: IResourceHierarchy = {
                                        ...src,
                                        children: updateResourcePathOnChildren(src.children, newSrcResourcePath),
                                        resourcePath: newSrcResourcePath,
                                        parentId: undefined,
                                        linkState: indexOfPrevious === -1 ? EntityState.Create : EntityState.Update,
                                    };

                                    if (insertIndex >= arrayUpdated.length) {
                                        arrayUpdated.push(copySrc);
                                    } else {
                                        arrayUpdated.splice(insertIndex, 0, copySrc);
                                    }
                                    if (indexOfPrevious !== -1) {
                                        arrayUpdated = arrayUpdated.filter((e) => !(e.resourcePath === newSrcResourcePath && e.linkState === EntityState.Delete));
                                    }

                                    arrayUpdated = reAssignOrderIndex(arrayUpdated);

                                    if (action.payload.position === 'inside') {
                                        array = [...array];
                                        array[index] = ObjectOperation.merge(array[index], {
                                            children: arrayUpdated,
                                        });
                                        return array;
                                    } else {
                                        return arrayUpdated;
                                    }
                                },
                            },
                        ]);
                    }
                }
            }
            break;
        case 'deleteItem':
            {
                const item = getItemByPath(action.payload.resourcePath, state as IResourceHierarchy);
                if (item?.linkState === EntityState.Create) {
                    // si l'item n'existe pas encore sur le serveur on le supprime réelement de la liste

                    newState = updateArrayAtResourcePath(action.payload.resourcePath, (array, index) => {
                        if (index !== -1) {
                            return array.filter((a) => a.resourcePath !== action.payload.resourcePath);
                        }
                        return undefined;
                    });
                } else {
                    //si l'item existe sur le server on le flag deleted
                    newState = updateArrayAtResourcePath(action.payload.resourcePath, (array, index) => {
                        const itemToDelete = array[index];
                        const resourceState = itemToDelete.resource?.reusable ? itemToDelete.resourceState : EntityState.Delete;
                        array[index] = ObjectOperation.merge(itemToDelete, {
                            linkState: EntityState.Delete,
                            resourceState,
                            children: resourceState === EntityState.Delete ? deleteCascade(itemToDelete.children as IResourceHierarchy[]) : itemToDelete.children,
                        });
                        return undefined;
                    });
                }
            }
            break;
    }
    return { newState, error };
}
