import { FC } from 'react';

import { PrimaryButton } from '@fluentui/react';
import './InvalidResource.scss';
export const InvalidResource: FC = () => {
    return (
        <div className='invalidResource'>
            <div>
                <img src='/images/SakuraSurprise.png' alt='Sakura coaching Invalid link' tabIndex={-1} width={250} />
            </div>
            <span className='title'>
                <span className='title subtext'>
                    Ooohh!!!
                    <br />
                </span>
                CE LIEN EST<span className='title subtext'>INVALIDE</span>
            </span>
            <PrimaryButton
                className='sakuraButton'
                text="Retour à l'accueil"
                iconProps={{ iconName: 'SkypeCircleArrow' }}
                onClick={() => {
                    window.location.replace('/');
                }}
            />
        </div>
    );
};
