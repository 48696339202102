import { FC } from 'react';
import { UserRoute } from '../Redux/Reducers/Menu/buildUserMenu';
import { MenuEntryType } from '../Services/SakuraApiClient';
import { InvalidResource } from './InvalidResource';
import { ResourcePage } from './ResourcePage';

export interface UserRouteViewProps {
    data: UserRoute;
}
export const UserRouteView: FC<UserRouteViewProps> = (props: UserRouteViewProps) => {
    const { data } = props;

    switch (data.type) {
        case MenuEntryType.Page:
            {
                if (data.pageId) {
                    return <ResourcePage resourceId={data.pageId} resourceConsumerPackage={data.page} fallback={<InvalidResource />} />;
                }
            }
            break;
        case MenuEntryType.ViewResource:
            {
                if (data.resourceId) {
                    return <ResourcePage resourceId={data.resourceId} fallback={<InvalidResource />} />;
                }
            }
            break;
        default:
            break;
    }

    return <span>{'Invalid user route'}</span>;
};
