import { Spinner, SpinnerSize, initializeIcons, loadTheme } from '@fluentui/react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import vhCheck from 'vh-check';
import palette from './common/styles/_variables.module.scss';

import { PwaRoot } from './PwaRoot';
import { GlobalRoutes } from './Routing/GlobalRoutes';
import './common/common.scss';
import { isInAppMode } from './common/helpers/IsInAppMode';

if (!isInAppMode()) {
    vhCheck();
}

initializeIcons();
loadTheme({
    palette,
    defaultFontStyle: {
        fontFamily: 'Poppins',
        fontWeight: 'Regular',
    },
});
function App(): JSX.Element {
    return (
        <Suspense
            fallback={
                <div className='app-loading'>
                    <Spinner size={SpinnerSize.large} />
                </div>
            }
        >
            <PwaRoot applicationName='Sakura-Coaching' applicationLogo='Logo_application.svg'>
                <BrowserRouter>
                    <GlobalRoutes />
                </BrowserRouter>
            </PwaRoot>
        </Suspense>
    );
}

export default App;
