import { Icon } from '@fluentui/react';
import React, { CSSProperties, FC, useContext, useMemo } from 'react';

import { VideoViewer } from '../../../../../common/components/VideoViewer/VideoViewer';
import { Dimensions, EnumVideoProvider, IPageElementVideo, PageElementImageStyle } from '../../../../../Services/SakuraApiClient';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';

export interface ElementVideoViewProps {
    data: IPageElementVideo;
}

export const ElementVideoView: FC<ElementVideoViewProps> = (props: ElementVideoViewProps) => {
    const { data } = props;
    const [state] = useContext(ExecutionDataContext);

    const resourceVideo = useMemo(() => {
        return state.resources[data.resource?.id]?.data.content.video;
    }, [state, data]);
    return (
        <>
            {resourceVideo ? (
                <VideoViewer
                    provider={resourceVideo.provider ?? EnumVideoProvider.Youtube}
                    videoId={resourceVideo.videoId ?? ''}
                    height={data.style?.dimensions?.height}
                    width={data.style?.dimensions?.width}
                />
            ) : (
                <Icon iconName='MyMoviesTV' style={{ fontSize: 'x-large' }} />
            )}
        </>
    );
};
