import { EditorState } from 'draft-js';
import { FC, useEffect, useRef, useState } from 'react';
import { declareVariables, useDynamicDataIntelisense } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../../../Redux/hook';
import { PageElementRichText, PageElementType } from '../../../../../Services/SakuraApiClient';
import { useRefLink } from '../../../../../common/Hooks/useRefLink';
import { useState2 } from '../../../../../common/Hooks/useState2';
import { Expression } from '../../../../../common/components/ExpressionField/Expression';
import { ExpressionVariable } from '../../../../../common/components/ExpressionField/Expression/ExpressionVariable';
import { syntaxHighlightDecoratorTemplate } from '../../../../../common/components/RichText/Decorators/SyntaxHighlight';
import { RichTextEditor } from '../../../../../common/components/RichText/RichTextEditor';
import { loadEditorState, saveEditorState } from '../../../../../common/components/RichText/state';
import { disableElementClipBoard, enableElementClipBoard } from '../../../PageEditor/ElementSelector';
import { createNewElement } from '../Container/createNewElement';
import { IPageElementRichTextExtended } from './IPageElementRichTextExtended';

export interface ElementRichTextEditionProps {
    data: PageElementRichText;
    onChangeData: (data: PageElementRichText) => void;
    isSelected: boolean;
}

export const ElementRichTextEdition: FC<ElementRichTextEditionProps> = (props: ElementRichTextEditionProps) => {
    const { data, onChangeData, isSelected } = props;
    const content = (data as IPageElementRichTextExtended).content;
    const [editorState, setEditorState] = useState(() => loadEditorState(content, syntaxHighlightDecoratorTemplate));
    const updatedStateRef = useRef<EditorState | undefined>(editorState);
    const dataRef = useRef<PageElementRichText>(data);
    const hasChangedData = useRef<boolean>(false);
    const [textAlignement, setTextAlignement, textAlignementRef] = useState2<'left' | 'center' | 'right' | undefined>(data.textAlignment as 'left' | 'center' | 'right' | undefined);
    const dispatch = useAppDispatch();
    const onChangeDataRef = useRefLink(onChangeData);
    useEffect(() => {
        dataRef.current = data;
        hasChangedData.current = false;
    }, [data]);
    useEffect(() => {
        const state = loadEditorState(content, syntaxHighlightDecoratorTemplate);
        setEditorState(state);
        updatedStateRef.current = state;
    }, [content]);
    useEffect(() => {
        if (!isSelected && updatedStateRef.current && hasChangedData.current) {
            const newContent = saveEditorState(updatedStateRef.current);
            //   if (JSON.stringify(newContent) !== JSON.stringify(content)) {
            const newData = createNewElement(PageElementType.RichText, undefined) as PageElementRichText;
            if (newData) {
                newData.init({ type: PageElementType.RichText, visibleIf: dataRef.current.visibleIf, content: newContent, textAlignment: textAlignementRef.current });
                onChangeDataRef.current(newData);
            }
            // }
        }
    }, [isSelected]);
    const intelisense = useDynamicDataIntelisense();
    return (
        <RichTextEditor
            showPanel={isSelected}
            showFocus
            editorState={editorState}
            textAlignment={textAlignement}
            onChangeTextAlignment={(ta) => {
                setTextAlignement(ta);
                const newData = createNewElement(PageElementType.RichText) as PageElementRichText;
                const newContent = updatedStateRef.current ? saveEditorState(updatedStateRef.current) : undefined;
                if (newData) {
                    newData.init({ type: PageElementType.RichText, content: newContent, textAlignment: ta });
                    onChangeData(newData);
                }
            }}
            onFocus={() => {
                disableElementClipBoard();
            }}
            onBlur={() => {
                enableElementClipBoard();
            }}
            onChangeState={(state) => {
                setEditorState(state);
                const text = state.getCurrentContent().getPlainText();
                const expressions = Expression.getExpressionInBrackets(text);
                const variables: ExpressionVariable[] = [];
                expressions.forEach((exp) => {
                    variables.push(...exp.getListOfVariableUsed());
                });
                if (variables.length > 0) {
                    dispatch(declareVariables({ variables }));
                }
                updatedStateRef.current = state;
                hasChangedData.current = true;
            }}
            intelisenseDataSource={intelisense}
            enabledBracketExpression
        />
    );
};
