import { IDropdownOption } from '@fluentui/react';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ExamModeEnum, IResourceDataQuestionnary, QuestionnaryKind, QuestionTypeEnum } from '../../../../Services/SakuraApiClient';

export function getExamModes(mode: ExamModeEnum): string {
    switch (mode) {
        case ExamModeEnum.QuestionCount:
            return 'Par question repondu correctement';
        case ExamModeEnum.Scoring:
            return 'Par points';
    }
    return '<TBD>';
}
export const ExamModes: IDropdownOption[] = [
    {
        key: ExamModeEnum.QuestionCount,
        text: getExamModes(ExamModeEnum.QuestionCount),
    },
    {
        key: ExamModeEnum.Scoring,
        text: getExamModes(ExamModeEnum.Scoring),
    },
];

function getPositivePoints(points: number | undefined) {
    if (points && points > 0) {
        return points;
    }
    return 0;
}
export function getTotalPoints(questionnary: DeepPartial<IResourceDataQuestionnary> | undefined) {
    let totalPoints = 0;
    if (questionnary?.kind === QuestionnaryKind.Exam) {
        questionnary.questions?.forEach((q) => {
            totalPoints += getPositivePoints(q.whenCorrect?.points);
            totalPoints += getPositivePoints(q.whenNotCorrect?.points);
            if (q.type === QuestionTypeEnum.YesNo) {
                totalPoints += getPositivePoints(q.yes?.points);
                totalPoints += getPositivePoints(q.no?.points);
            } else if (q.type === QuestionTypeEnum.Choice || q.type === QuestionTypeEnum.MultiChoice) {
                q.answers?.forEach((a) => {
                    totalPoints += getPositivePoints(a.points);
                });
            }
        });
    }
    return totalPoints;
}
