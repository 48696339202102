import { IQuestionnaryQuestion, IQuestionnarySubmissionItem } from '../../../Services/SakuraApiClient';
import { ValueTyping } from '../../../common/components/ExpressionField/Expression';

export function getDynVariableChange(question: IQuestionnaryQuestion, answer: Partial<IQuestionnarySubmissionItem>) {
    const changes: Record<string, ValueTyping & Record<string, ValueTyping | Record<string, ValueTyping>>> = {};

    if (question?.id) {
        changes[question?.id] = getDynVariable(question, answer);
    }
    return changes;
}

function getDynVariable(question: IQuestionnaryQuestion, answer: Partial<IQuestionnarySubmissionItem>) {
    const data: Record<string, string | boolean | Record<string, boolean | string>> = {};

    if (question?.id) {
        if (answer.freeText !== undefined) {
            data.reponse = answer.freeText;
        }
        if (answer.answerYes !== undefined) {
            data.reponse = answer.answerYes;
        }

        const choix = answer.answers ? answer.answers.join(',') : '';
        data.choix = choix;
        const choixTexte: string[] = [];
        question.answers?.forEach((a) => {
            const indexAnswer = answer.answers?.findIndex((item) => item === a.id);
            if (question?.id && a?.id) {
                if (indexAnswer !== -1) {
                    choixTexte.push(a.text ?? '');
                }
                data[a.id] = {
                    selectionner: indexAnswer !== -1,
                };
            }
        });
        if (question.answers && question.answers.length > 0) {
            data.choixTexte = choixTexte.join(',') ?? '';
        }
    }
    return data;
}

export function getDynVariableQuestionnary(questions: IQuestionnaryQuestion[] | undefined, answers: IQuestionnarySubmissionItem[]) {
    const changes: Record<string, ValueTyping & Record<string, ValueTyping | Record<string, ValueTyping>>> = {};

    if (questions) {
        for (let i = 0; i < questions?.length; i++) {
            const questionId = questions[i].id;
            if (questionId) {
                const answer = answers.find((a) => a.questionId === questionId);
                if (answer) {
                    changes[questionId] = getDynVariable(questions[i], answer);
                }
            }
        }
    }
    return changes;
}
