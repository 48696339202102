import React, { FC, useState } from 'react';
import { useDataSetSelector } from '../../../../Redux/Reducers/DataSet/reducer';
import { IResource, PageElement, PageElementContainer, Resource, ResourceContent, ResourceData, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { ChoiceGroup, IChoiceGroupOption, Pivot, PivotItem, TextField } from '@fluentui/react';
import * as validators from '../../../../common/Hooks/Form/validators';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { useForm } from '../../../../common/Hooks/Form';
import { FluentIconSelector } from '../../../../common/components/Icons/FluentIconSelector';
import { FluentIconSelectorDialog } from '../../../../common/components/Icons/FluentIconSelectorDialog';
import { useAppDispatch } from '../../../../Redux/hook';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { datasetCollections } from '../../../../Redux/Reducers/DataSet/DataSetName';
import { setPopupMessage } from '../../../../Redux/Reducers/System/reducer';
import { PopupMessage } from '../../../../Redux/Reducers/System/state';
import { ResourceList } from '../../../Admin/Resources/ResourceList';
import { TemplateList } from './TemplateList';

export interface PageBlockAddDialogProps {
    page: IResource;
    onClose: () => void;
}
const confirmationSaveTemplatePopup: PopupMessage = { icon: 'SkypeCircleCheck', title: 'Sauvegarde de modéle', subTitle: 'Modéle sauvegardé avec succés.', timeout: 1000 };

export const SaveTemplateDialog: FC<PageBlockAddDialogProps> = (props: PageBlockAddDialogProps) => {
    const { page, onClose } = props;

    const appDispatch = useAppDispatch();
    const [template, setTemplate] = useState<IResource>();
    const [choice, setChoice] = useState<'new' | 'update' | string>('new');
    const form = useForm<IResource>(
        {
            initialState: page,
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        false,
        'createTemplate',
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose();
    };
    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client, ct) => {
                    if (template && choice === 'update') {
                        const resourceUpdated = new Resource();
                        resourceUpdated.init(form.state);
                        resourceUpdated.resourceType = ResourceTypeEnum.PageTemplate;
                        await client.updateResource(template.id, resourceUpdated, ct);

                        appDispatch(setPopupMessage({ message: confirmationSaveTemplatePopup }));
                    } else {
                        const data = new Resource();
                        data.init(form.state);
                        data.resourceType = ResourceTypeEnum.PageTemplate;
                        const response = await client.createResource(data);
                        appDispatch(setPopupMessage({ message: confirmationSaveTemplatePopup }));
                    }
                    datasetCollections['ResourcePageTemplateGrid'].refresh();
                    onClose();
                },
                appDispatch,
                { contextId: 'createTemplate' },
            );
        }
    };
    return (
        <EditDialog
            id={'createTemplate'}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.PageBlock) }}
            mode={choice === 'new' ? 'Create' : 'Edit'}
            title={'Sauvegarder en tant que Modéle'}
            show={true}
            onSubmit={onSubmit}
            canSubmit={() => {
                return choice === 'new' || (choice === 'update' && template !== undefined);
            }}
            onClose={() => close(undefined)}
        >
            <Pivot
                onLinkClick={(item) => {
                    if (item?.props.itemKey) {
                        setChoice(item?.props.itemKey);
                    }
                }}
            >
                <PivotItem headerText='Nouveau' itemIcon='AddTo' itemKey={'new'}>
                    <span>{'Spécifier le nom du nouveau modéle'}</span>
                    <TextField label='Nom' disabled={choice === 'update'} errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                </PivotItem>
                <PivotItem headerText='Remplacer' itemIcon='Edit' itemKey={'update'}>
                    <span>{'Séléctionner un modéle dans la liste pour remplacer son contenu par la page actuelle.'}</span>
                    <TemplateList contextId='createTemplate' datasetName={'ResourcePageTemplateGrid'} onSelect={(selection) => setTemplate(selection)} />
                </PivotItem>
            </Pivot>
        </EditDialog>
    );
};
