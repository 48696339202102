import { useMemo, useReducer } from 'react';
import { setDirtyForm } from '../../../Redux/Reducers/System/reducer';
import { store } from '../../../Redux/store';
import { isFunction } from '../../helpers/JavascriptType';
import { jsonParse } from '../../helpers/Json';
import { ArrayOperation, ObjectOperation, isPrimitive } from '../../helpers/ObjectAndArray';

export type FormFieldValue = string | number | Date | boolean | undefined;
export type DeepPartial<T> = {
    [Property in keyof Partial<T>]: T[Property] extends FormFieldValue
        ? T[Property]
        : T[Property] extends Array<infer TElement> | undefined
          ? DeepPartial<TElement>[] | undefined
          : DeepPartial<T[Property]> | undefined;
};
export type DeepPartialWithArrayFunc<T> = {
    [Property in keyof Partial<T>]: T[Property] extends FormFieldValue
        ? T[Property]
        : T[Property] extends Array<infer TElement> | undefined
          ? DeepPartialWithArrayFunc<TElement>[] | ((array: DeepPartialWithArrayFunc<TElement>[]) => DeepPartialWithArrayFunc<TElement>[] | undefined) | undefined
          : DeepPartialWithArrayFunc<T[Property]> | undefined;
};

export type FormError<T> = {
    [Property in keyof Partial<T>]: T[Property] extends FormFieldValue ? string | undefined : FormError<T[Property]> | undefined;
};

export type FormValidationModelMethod<TModel> = (model: DeepPartial<TModel>) => string[] | undefined;
export type FormValidationFieldMethod<TModel> = (model: DeepPartial<TModel> | undefined, value: FormFieldValue, displayName: string) => string | undefined;
export type FieldValidator<TModel> = {
    displayName?: string | undefined;
    validators: FormValidationFieldMethod<TModel>[];
};
export const ReursiveValidation = {};
export interface ArrayValidator<TModel> {
    _array?: FieldValidator<TModel>;
}

export type FieldsValidator<TModel> = {
    [Property in keyof TModel]: TModel[Property] extends FormFieldValue
        ? FieldValidator<TModel> | undefined
        : TModel[Property] extends Array<infer TElement> | undefined
          ? FieldsValidator<Partial<TElement>> | ArrayValidator<TModel> | undefined
          : FieldsValidator<Partial<TModel[Property]>> | undefined;
};

export interface FormValidator<TModel> {
    fields?: FieldsValidator<Partial<TModel>>;
    global?: FormValidationModelMethod<TModel>;
}
export interface FormState<TModel> {
    initialState: DeepPartial<TModel>;
    state: DeepPartial<TModel>;
    fieldErrors: FormError<Partial<TModel>>;
    errors: string[] | undefined;
    validators?: FormValidator<TModel>;
    isModified: boolean;
    enableChangeTracking: boolean;
    contextId: string;
}
export type UpdateFieldFormAction<TModel> = { type: 'updateField'; value: DeepPartialWithArrayFunc<TModel> };
export type ValidateFormAction<TModel> = {
    type: 'updateError';
    fieldErrors: FormError<Partial<TModel>>;
    errors: string[] | undefined;
};
export type ClearErrorsFormAction = {
    type: 'clearError';
};
export type CommitFormAction<TModel> = {
    type: 'commit';
    serverSideReponse: DeepPartial<TModel> | undefined;
};
export type RollbackFormAction = {
    type: 'rollback';
};
export type EnableChangeTrackingAction = {
    type: 'enableChangeTracking';
    enable: boolean;
};
export type FormAction<TModel> = UpdateFieldFormAction<TModel> | ValidateFormAction<TModel> | ClearErrorsFormAction | CommitFormAction<TModel> | RollbackFormAction | EnableChangeTrackingAction;

type FormReducer<TModel> = (state: FormState<TModel>, action: FormAction<TModel>) => FormState<TModel>;

function formReducer<TModel>(state: FormState<TModel>, action: FormAction<TModel>): FormState<TModel> {
    switch (action.type) {
        case 'updateField':
            const fieldErrors = state.fieldErrors;
            const newState = ObjectOperation.merge(state.state, action.value);
            const isModified = state.enableChangeTracking ? JSON.stringify(newState) !== JSON.stringify(state.initialState) : false;

            if (isModified !== state.isModified) {
                store.dispatch(setDirtyForm({ dirtyForm: isModified, contextId: state.contextId }));
            }
            if (clearFields(state.validators?.fields, state.state, action.value, fieldErrors)) {
                return {
                    ...state,
                    isModified,
                    state: newState,
                    fieldErrors: ObjectOperation.merge(state.fieldErrors, fieldErrors),
                };
            }
            return { ...state, isModified, state: newState };
        case 'updateError':
            return { ...state, fieldErrors: action.fieldErrors, errors: action.errors };
        case 'clearError':
            return { ...state, fieldErrors: {} as FormError<Partial<TModel>>, errors: undefined };
        case 'commit':
            if (action.serverSideReponse) {
                return {
                    ...state,
                    isModified: false,
                    fieldErrors: {} as FormError<Partial<TModel>>,
                    errors: undefined,
                    initialState: { ...action.serverSideReponse },
                    state: { ...action.serverSideReponse },
                };
            } else {
                return { ...state, isModified: false, fieldErrors: {} as FormError<Partial<TModel>>, errors: undefined, initialState: { ...state.state } };
            }
        case 'rollback':
            return { ...state, isModified: false, fieldErrors: {} as FormError<Partial<TModel>>, errors: undefined, state: state.initialState };
        case 'enableChangeTracking': {
            const isModified = action.enable ? JSON.stringify(state.state) !== JSON.stringify(state.initialState) : false;
            return { ...state, isModified, enableChangeTracking: action.enable };
        }
    }
}
export interface Form<T> {
    initialState: DeepPartial<T>;
    validators?: FormValidator<T>;
}
export interface EntityForm<T> {
    initialState: DeepPartial<T>;
    state: DeepPartial<T>;
    isModified: boolean;
    fieldErrors: FormError<Partial<T>>;
    globalErrors: string[] | undefined;
    update: (value: DeepPartialWithArrayFunc<T>) => void;
    validate: () => boolean;
    clearErrors: () => void;
    commit: (serverSideReponse?: DeepPartial<T>) => void;
    rollback: () => void;
    enableChangeTracking: (enable: boolean) => void;
}
export function useForm<T>(initializerArg: Form<T>, enableChangeTracking: boolean, contextId?: string | undefined, serializationIdentifier?: string): EntityForm<T> {
    const [state, dispatch] = useReducer<FormReducer<T>, Form<T>>(formReducer, initializerArg, (arg) => {
        if (serializationIdentifier) {
            const json = localStorage.getItem(`form_${serializationIdentifier}`);
            if (json) {
                const existingState = jsonParse(json) as FormState<T>;
                return {
                    ...existingState,
                    validators: arg.validators,
                };
            }
        }
        return {
            contextId: contextId ?? 'default',
            initialState: arg.initialState,
            state: arg.initialState,
            validators: arg.validators,
            errors: undefined,
            isModified: false,
            enableChangeTracking,
            fieldErrors: {} as FormError<Partial<T>>,
        };
    });
    return useMemo(() => {
        if (serializationIdentifier) {
            if (state.isModified) {
                localStorage.setItem(`form_${serializationIdentifier}`, JSON.stringify(state));
            } else {
                localStorage.removeItem(`form_${serializationIdentifier}`);
            }
        }
        return {
            initialState: state.initialState,
            state: state.state,
            isModified: state.isModified,
            fieldErrors: state.fieldErrors,
            globalErrors: state.errors,
            update: (value: DeepPartialWithArrayFunc<T>) => {
                dispatch({ type: 'updateField', value });
            },
            validate: () => {
                const [fieldErrors, errors, hasErrors] = validateForm<T>(state);
                dispatch({ type: 'updateError', fieldErrors, errors });
                return hasErrors;
            },
            clearErrors: () => {
                dispatch({ type: 'clearError' });
            },
            commit: (serverSideReponse?: DeepPartial<T>) => {
                store.dispatch(setDirtyForm({ contextId: contextId ?? 'default', dirtyForm: false }));
                dispatch({ type: 'commit', serverSideReponse });
            },
            rollback: () => {
                store.dispatch(setDirtyForm({ contextId: contextId ?? 'default', dirtyForm: false }));
                dispatch({ type: 'rollback' });
            },
            enableChangeTracking: (enable: boolean) => {
                dispatch({ type: 'enableChangeTracking', enable });
            },
        };
    }, [state, dispatch]);
}

function clearFields<TModel>(
    validators: FieldsValidator<Partial<TModel>> | undefined,
    currentValues: DeepPartial<TModel> | undefined,
    values: DeepPartialWithArrayFunc<TModel> | undefined,
    fieldErrors: FormError<Partial<TModel>>,
) {
    let hasClear = false;

    if (values) {
        for (const [key, value] of ObjectOperation.entries(values)) {
            const validator = validators ? (validators as Record<string, FieldValidator<TModel> | undefined>)[key] : undefined;
            if (isPrimitive(value)) {
                if (validator?.validators) {
                    let hasError = false;
                    for (let i = 0; i < validator.validators.length; i++) {
                        const errorMessage = validator.validators[i](currentValues, value as FormFieldValue, validator.displayName ?? '');
                        if (errorMessage) {
                            hasError = true;
                            break;
                        }
                    }
                    if (!hasError) {
                        if ((fieldErrors as Record<string, FormFieldValue>)[key]) {
                            (fieldErrors as Record<string, FormFieldValue>)[key] = undefined;
                            hasClear = true;
                        }
                    }
                }
            } else {
                const existingValue = currentValues ? (currentValues as Record<string, unknown>)[key] : undefined;
                let val;
                if (isFunction(value)) {
                    const func = value as (array: object | undefined) => object | undefined;
                    val = func(existingValue ?? []);
                } else {
                    val = value;
                }

                const isValueAnArray = Array.isArray(val);

                if (isValueAnArray) {
                    const array = (val as []) ?? [];
                    (fieldErrors as unknown as Record<string, Array<FormError<Partial<TModel>>>>)[key] = ArrayOperation.EnsureArraySize(
                        (fieldErrors as unknown as Record<string, Array<FormError<Partial<TModel>>>>)[key],
                        array.length,
                    );
                    array.forEach((arrayVal, index) => {
                        if (
                            clearFields(
                                validator as FieldsValidator<Partial<TModel>>,
                                ((existingValue as []) ?? [])[index] as DeepPartial<TModel>,
                                arrayVal as DeepPartialWithArrayFunc<TModel>,
                                ((fieldErrors as Record<string, FormError<Partial<TModel>>>)[key] as [])[index],
                            )
                        ) {
                            hasClear = true;
                        }
                    });
                } else {
                    const fieldError = (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key];
                    if (fieldError === undefined) {
                        (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key] = {} as FormError<Partial<TModel>>;
                    }
                    if (existingValue) {
                        if (
                            clearFields(
                                validator as FieldsValidator<Partial<TModel>>,
                                existingValue as DeepPartial<TModel>,
                                val as DeepPartialWithArrayFunc<TModel>,
                                (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key],
                            )
                        ) {
                            hasClear = true;
                        }
                    }
                }
            }
        }
    }
    return hasClear;
}

function validateFields<TModel>(validators: FieldsValidator<Partial<TModel>> | undefined, values: DeepPartial<TModel> | undefined, fieldErrors: FormError<Partial<TModel>>) {
    let isValid = true;

    if (validators) {
        for (const [key, entryValidator] of ObjectOperation.entries(validators)) {
            if (entryValidator) {
                let validator = validators ? (validators as Record<string, FieldValidator<Partial<TModel>> | undefined>)[key] : undefined;
                const value = values ? (values as Record<string, FormFieldValue>)[key] : undefined;
                if (validator?.validators) {
                    let thisIsValid = true;
                    for (let i = 0; i < validator.validators.length; i++) {
                        const errorMessage = validator.validators[i](values, value, validator.displayName ?? '');

                        if (errorMessage) {
                            (fieldErrors as Record<string, FormFieldValue>)[key] = ((fieldErrors as Record<string, FormFieldValue>)[key] ?? '') + errorMessage;
                            isValid = false;
                            thisIsValid = false;
                        }
                    }
                    if (thisIsValid) {
                        (fieldErrors as Record<string, FormFieldValue>)[key] = undefined;
                    }
                } else {
                    const fieldError = (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key];
                    const isValueAnArray = Array.isArray(value);
                    if (fieldError === undefined) {
                        (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key] = (isValueAnArray ? [] : {}) as FormError<Partial<TModel>>;
                    }
                    const arrayValidator = (validator as ArrayValidator<TModel>)._array;

                    if (arrayValidator) {
                        if (arrayValidator?.validators) {
                            for (let i = 0; i < arrayValidator.validators.length; i++) {
                                const errorMessage = arrayValidator.validators[i](values, value, arrayValidator.displayName ?? '');
                                (fieldErrors as Record<string, FormFieldValue>)[key] = errorMessage;
                                if (errorMessage) {
                                    isValid = false;
                                }
                            }
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (value === undefined || (value as any).length === 0) {
                            continue;
                        }
                    }
                    if (validator === ReursiveValidation) {
                        validator = validators as FieldValidator<Partial<TModel>>;
                    }
                    if (isValueAnArray) {
                        const array = value as DeepPartial<TModel>[];
                        (fieldErrors as unknown as Record<string, Array<FormError<Partial<TModel>>>>)[key] = ArrayOperation.createWithEmptyObjects(array.length);
                        array.forEach((val, index) => {
                            if (!validateFields(validator as FieldsValidator<Partial<TModel>>, val, (fieldErrors as unknown as Record<string, Array<FormError<Partial<TModel>>>>)[key][index])) {
                                isValid = false;
                            }
                        });
                    } else {
                        if (!validateFields(validator as FieldsValidator<Partial<TModel>>, value as DeepPartial<TModel>, (fieldErrors as Record<string, FormError<Partial<TModel>>>)[key])) {
                            isValid = false;
                        }
                    }
                }
            }
        }
    }
    return isValid;
}

// function validateFields<TModel>(validators: FieldsValidator<Partial<TModel>> | undefined, values: DeepPartial<TModel>, fieldErrors: Record<string, FormFieldValue>, mode: 'Validate' | 'Clear') {
//     let hasChangeFieldErrors = false;
//     if (validators) {
//         for (const [key, value] of Object.entries<FieldValidator | FieldsValidator<Partial<TModel>>>(validators)) {
//         }
//         validators;
//         for (const [key, value] of Object.entries<FormValidationFieldMethod[]>(validators)) {
//             const fieldValue = values ? (values as Record<string, FormFieldValue>)[key] : undefined;
//             if (value) {
//                 for (let i = 0; i < 0; i++) {
//                     const errorMsg = value[i](fieldValue);
//                     if (errorMsg && mode == 'Validate') {
//                         fieldErrors[key] = errorMsg;
//                         hasChangeFieldErrors = true;
//                         break;
//                     }
//                     if (mode == 'Clear' && errorMsg === undefined) {
//                         fieldErrors[key] = undefined;
//                         hasChangeFieldErrors = true;
//                     }
//                 }
//             }
//         }
//     }
//     return hasChangeFieldErrors;
// }
function validateForm<TModel>(state: FormState<TModel>): [FormError<Partial<TModel>>, string[] | undefined, boolean] {
    const fieldErrors = {} as FormError<Partial<TModel>>;
    let hasErrors = false;
    let errors = undefined;
    if (state.validators) {
        hasErrors = !validateFields(state.validators.fields, state.state, fieldErrors);
        if (state.validators.global) {
            errors = state.validators.global(state.state);
            if (!hasErrors && errors && errors.length > 0) {
                hasErrors = true;
            }
        }
    }

    return [fieldErrors as FormError<Partial<TModel>>, errors, hasErrors];
}
