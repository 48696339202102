import { ResourceTypeEnum } from '../../../../../../../Services/SakuraApiClient';

export const resourceTypeToActions: Record<ResourceTypeEnum, { icon: string; label: string }> = {
    Document: {
        icon: 'Download',
        label: 'Télécharger',
    },
    Audio: {
        icon: 'Play',
        label: 'Ecouter',
    },
    Image: {
        icon: 'Download',
        label: 'Télécharger',
    },
    Questionnary: {
        icon: 'EditSolid12',
        label: 'Répondre',
    },
    Formation: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    Module: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    Page: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    PageBlock: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    PageTemplate: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    Video: {
        icon: 'Play',
        label: 'Voir',
    },
    Character: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    CharacterSpeech: {
        icon: 'Play',
        label: 'Ouvrir',
    },
    GameContent: {
        icon: 'Download',
        label: 'Télécharger',
    },
    Workflow: {
        icon: 'Download',
        label: 'Obtenir',
    },
};
