import { ActionButton, PrimaryButton } from '@fluentui/react';
import { Howl } from 'howler';
import { FC, useMemo, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { ST, getAccessLink } from '../../Hooks/StorageResolver';

export interface AudioPlayerProps {
    blobPath?: string;
    blobPathKind: 'Value' | 'ProtectedValue';
    url?: string;
    file?: File;
    display: 'Action' | 'Button';
    disabled?: boolean;
}

export const AudioPlayer: FC<AudioPlayerProps> = (props: AudioPlayerProps) => {
    const { blobPath, url, file, blobPathKind, display, disabled } = props;
    const [playingState, setPlayingState] = useState<'stop' | 'playing' | 'pause'>('stop');
    const [audioUrl, setAudioUrl] = useState<string>();
    const storage = useAppSelector(storageEndpointsSelector);
    const audio = useRef<Howl>();
    const audioPlay = useRef<{ id?: number; position?: number }>({});

    const resolveUrl = async () => {
        let usableUrl = url;

        if (blobPath) {
            if (blobPath.startsWith('Public')) {
                usableUrl = ST(storage, blobPath);
            } else {
                usableUrl = await getAccessLink(blobPath, blobPathKind);
            }
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (ev) => {
                usableUrl = ev?.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
        return usableUrl;
    };
    const play = () => {
        const resolve = async () => {
            if (!audio.current) {
                const audioUrl = await resolveUrl();
                if (audioUrl) {
                    audio.current = new Howl({
                        src: [audioUrl],
                        html5: true,
                    });
                }
            }

            if (audio.current) {
                const playId = audio.current.play();
                audioPlay.current.id = playId;
                audio.current.once('play', () => {
                    if (audio.current && audioPlay.current.position) {
                        audio.current.seek(audioPlay.current.position ?? 0, audioPlay.current.id);
                    }
                });

                setPlayingState('playing');
                audio.current.on('end', () => {
                    audioPlay.current = {};
                    setPlayingState('stop');
                });
            }
        };
        resolve();
    };
    const pause = () => {
        if (audio.current) {
            audio.current.pause(audioPlay.current.id);
            audioPlay.current.position = audio.current.seek(audioPlay.current.id);
            if (audio.current.playing()) {
                setPlayingState('playing');
            } else {
                setPlayingState('pause');
            }
        }
    };
    const iconViewProps = useMemo(() => ({ iconName: playingState == 'playing' ? 'Pause' : 'Play' }), [playingState]);
    //const command = useMemo(() => (playingState === 'playing' ? pause : play), [playingState]);
    const command = () => {
        const resolve = async () => {
            const result = await resolveUrl();
            setAudioUrl(result);
        };
        resolve();
    };
    return (
        <>
            {!audioUrl && (
                <>
                    {display === 'Button' && <PrimaryButton disabled={disabled} iconProps={iconViewProps} text={'Ecouter'} onClick={command} />}
                    {display === 'Action' && <ActionButton disabled={disabled} iconProps={iconViewProps} text={'Ecouter'} onClick={command} />}
                </>
            )}
            {audioUrl && (
                <>
                    {display === 'Button' && <PrimaryButton disabled={disabled} iconProps={{ iconName: 'ChromeClose' }} onClick={() => setAudioUrl(undefined)} />}
                    {display === 'Action' && <ActionButton disabled={disabled} iconProps={{ iconName: 'ChromeClose' }} onClick={() => setAudioUrl(undefined)} />}
                    <ReactAudioPlayer
                        layout={'horizontal'}
                        autoPlay
                        src={audioUrl}
                        onPlay={(e) => console.log('onPlay')}
                        // other props here
                    />
                </>
            )}
        </>
    );
};
