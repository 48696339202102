import React, { FC, useMemo } from 'react';
import { IntelisenseInfo } from '../../../../Redux/Reducers/DynamiqueData/state';
import { Label } from '@fluentui/react';

export interface IntelisenseFunctionDetailProps {
    info: IntelisenseInfo;
    argumentIndex: number;
}
export const IntelisenseFunctionDetail: FC<IntelisenseFunctionDetailProps> = (props: IntelisenseFunctionDetailProps) => {
    const { info, argumentIndex } = props;
    const argumentInfo = useMemo(() => {
        if (info.parameters) {
            const argInfo = info.parameters[argumentIndex];
            return argInfo;
        }
        return undefined;
    }, [argumentIndex]);

    return (
        <div className='intelisense-function'>
            <div className='name'>
                <span className='highlight'>{info.fullName}</span>
                <span>{'('}</span>
                {info.parameters
                    ? info.parameters?.map((p, index) => {
                          return (
                              <span key={`arg_${p.name}${index}`} className={index === argumentIndex ? 'highlight' : ''}>{`${p.name}: ${p.type}${
                                  index !== (info.parameters?.length ?? 0) - 1 ? ', ' : ''
                              }`}</span>
                          );
                      })
                    : null}
                <span>{')'}</span>
            </div>
            <span className='description'>{info.description}</span>
            {argumentInfo ? (
                <>
                    <div className='name'>
                        <span className='highlight'>{`${argumentInfo.name}: `}</span>
                        <span className='description'>{argumentInfo.description}</span>
                    </div>
                </>
            ) : null}
        </div>
    );
};
