import { ResourceTypeEnum } from '../Services/SakuraApiClient';

export const screenPaths = {
    resourceView: (type: ResourceTypeEnum, resourceId: number) => `/data/${type}/${resourceId}`,
    offerList: '/admin/settings/offers',
    offerCreate: '/admin/settings/offers/create',
    offerUpdate: (offerId: number) => `/admin/settings/offers/edit/${offerId}`,
    productList: '/admin/settings/products',
    productCreate: '/admin/settings/products/create',
    productUpdate: (productId: number) => `/admin/settings/products/edit/${productId}`,
    resourceList: (type: ResourceTypeEnum) => `/admin/settings/resources/${type}`,
    resourceCreate: (type: ResourceTypeEnum) => `/admin/settings/resources/${type}/create`,
    resourceUpdate: (type: ResourceTypeEnum, resourceId: number) => `/admin/settings/resources/${type}/${resourceId}`,
    badgeList: '/admin/settings/badges',
    workflowList: '/admin/settings/workflow',
    accountList: '/admin/accounts',
    accountCreate: '/admin/accounts/create',
    accountUpdate: (userOid: string) => `/admin/accounts/edit/${userOid}`,
    socialNetworkLinksList: '/admin/settings/socialNetworkLinks',
    system: '/admin/system',
    externalSystemLog: '/admin/logs/externalSystem',
    coinTransactionLog: '/admin/logs/coinTransaction',
    diamondTransactionLog: '/admin/logs/diamondTransaction',
    accountLog: '/admin/logs/account',
    QuestionnaryResponseList: '/datacollection/questionnaryResult',
};
