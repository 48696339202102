import { useEffect } from 'react';

export type WheelDirection = 'UP' | 'DOWN';

export const useWheel = (onMouseWheel: ((direction: WheelDirection, delta: number) => void) | undefined): void => {
    useEffect(() => {
        if (onMouseWheel) {
            const callback = (event: WheelEvent) => {
                if (event.deltaY < 0) {
                    onMouseWheel('UP', event.deltaY);
                } else if (event.deltaY > 0) {
                    onMouseWheel('DOWN', event.deltaY);
                }
            };
            window.addEventListener('wheel', callback);

            return () => {
                window.removeEventListener('wheel', callback);
            };
        }
        return undefined;
    });
};
