import { ExpressionCombiner } from './ExpressionCombiner';
import { CombinerContext, ExpressionItem } from './ExpressionItem';
import { Value } from './Value';

export class ExpressionOperator extends ExpressionCombiner {
    private op: string;

    constructor(text: string) {
        super(text);
        this.op = text;
    }

    public resolve(): ExpressionItem {
        return this.combine((previousValue, nextValue) => {
            return Value.ValueOperator(previousValue, this.op, nextValue);
        }, CombinerContext.Operation);
    }

    public canReturnValue(): boolean {
        return false;
    }

    public getValue(): Value {
        throw new Error(`Cannot call GetValue of the operator ${this.op}`);
    }

    public isGreedy(context: string): boolean {
        if (context === CombinerContext.Operation) {
            if (this.op === '+' || this.op === '-') {
                return false;
            }
        }
        return true;
    }
}
