import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userProfileSelector } from '../../../Redux/Reducers/User/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IProtectedValueInfo, IQuestionnaryResult, QuestionnaryKind } from '../../../Services/SakuraApiClient';
import { parseBoolean } from '../../../common/helpers/boolean';
import { openInCurrentTab, openNewTab } from '../../../common/helpers/uri';
import { ProspectSubscriptionForm } from '../Subscription/ProspectSubscriptionForm';
import { QuestionnaryResult } from './SubmitQuestionnary/QuestionnaryResult';
import { saveQuestionnaryResponses } from './SubmitQuestionnary/saveQuestionnaryResponses';
import { clearPersistanceState } from './reducer/reducer';
import { IQuestionaryState } from './reducer/state';

export interface EndPageProps {
    questionnaryState: IQuestionaryState;
    showResult?: IProtectedValueInfo;
    onClose?: () => void;
    enablePersitance: boolean;
}

export const EndPage: FC<EndPageProps> = (props: EndPageProps) => {
    const { showResult, questionnaryState, onClose, enablePersitance } = props;
    const [questionnaryResult, setQuestionnaryResult] = useState<IQuestionnaryResult>();
    const appDispatch = useDispatch();

    const currentUserProfile = useAppSelector(userProfileSelector);

    const subscribe = async (firstName: string, lastName: string, email: string) => {
        await saveQuestionnaryResponses('Prospect', appDispatch, questionnaryState, showResult, email, firstName, lastName);
        clearPersistanceState(questionnaryState.resourceId);
        if (questionnaryState.questionnary.kind === QuestionnaryKind.Interview && questionnaryState.questionnary.interviewCalendarLink) {
            openInCurrentTab(questionnaryState.questionnary.interviewCalendarLink);
        } else {
            if (onClose) {
                if (questionnaryState.questionnary.kind === QuestionnaryKind.Interview && questionnaryState.questionnary.interviewCalendarLink) {
                    openNewTab(questionnaryState.questionnary.interviewCalendarLink);
                }
                onClose();
            }
        }
    };
    const displayResult = useMemo(() => {
        return parseBoolean(showResult?.value);
    }, [showResult?.value]);

    useEffect(() => {
        if (currentUserProfile) {
            const call = async () => {
                const result = await saveQuestionnaryResponses('User', appDispatch, questionnaryState, showResult, undefined, undefined, undefined, enablePersitance);
                setQuestionnaryResult(result);
                if (result) {
                    clearPersistanceState(questionnaryState.resourceId);
                }
            };
            call();
        } else if (displayResult) {
            const call = async () => {
                const result = await saveQuestionnaryResponses('anonymous', appDispatch, questionnaryState, showResult);
                setQuestionnaryResult(result);
                if (result) {
                    clearPersistanceState(questionnaryState.resourceId);
                }
            };
            call();
        }
    }, [currentUserProfile, displayResult]);

    return (
        <>
            {displayResult || currentUserProfile ? (
                <>{questionnaryResult ? <QuestionnaryResult result={questionnaryResult} questionnary={questionnaryState.questionnary} /> : <></>}</>
            ) : (
                <>
                    {questionnaryState.questionnary.kind === QuestionnaryKind.Interview ? (
                        <ProspectSubscriptionForm inscriptionLabel='Inscris-toi pour planifier un rendez-vous' askName buttonText='Fixer une date' onSubscribe={subscribe} />
                    ) : (
                        <ProspectSubscriptionForm inscriptionLabel='Inscris-toi pour recevoir tes résultats par e-mail' buttonText="Je m'inscris" onSubscribe={subscribe} />
                    )}
                </>
            )}
        </>
    );
};
