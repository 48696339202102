import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { IntelisenseInfo } from '../../../../Redux/Reducers/DynamiqueData/state';
import './Intelisense.scss';
import { IntelisenseFunctionDetail } from './IntelisenseFunctionDetail';

export interface IntelisenseEntry {
    name: string;
    info: IntelisenseInfo;
}
export interface TriggerRange {
    end: number;
    start: number;
    text: string;
}
export interface IntelisenseData {
    searchText?: string;
    position: { top: number; left: number };
    selectedIndex?: number;
    triggerRange: TriggerRange;
    functionInfo?: {
        info: IntelisenseInfo;
        argumentIndex: number;
    };
}
export interface IntelisenseProps {
    dataSource: IntelisenseEntry[];
    onSelect: (index: number) => void;
    intelisenseData: IntelisenseData;
}

const icons = {
    rovar: { icon: 'Product', classname: 'icon_rovar' },
    var: { icon: 'ProductRelease', classname: 'icon_var' },
    func: { icon: 'Variable', classname: 'icon_func' },
};

export const IntelisensePopup: FC<IntelisenseProps> = (props: IntelisenseProps) => {
    const { dataSource, intelisenseData, onSelect } = props;

    const { selectedIndex, position, functionInfo } = intelisenseData;
    useLayoutEffect(() => {
        if (selectedIndex !== undefined) {
            const elementDiv = document.getElementById(`Entry_${selectedIndex}`);
            if (elementDiv) {
                const rectElem = elementDiv.getBoundingClientRect();
                const container = elementDiv.parentElement?.parentElement as HTMLDivElement;
                const rectContainer = container.getBoundingClientRect();
                if (rectElem.bottom > rectContainer.bottom) elementDiv.scrollIntoView(false);
                if (rectElem.top < rectContainer.top) elementDiv.scrollIntoView();
            }
        }
    }, [selectedIndex]);
    const height = useMemo(() => {
        return 25 * Math.min(dataSource.length, 10);
    }, [dataSource]);
    const refParentCallout = useRef<HTMLDivElement>();
    const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    useEffect(() => {
        let extraOffset = { x: 0, y: 0 };
        if (!refParentCallout.current) {
            const parentElement = document.getElementsByClassName('sakuraCallout');
            if (parentElement && parentElement.length === 1) {
                refParentCallout.current = parentElement.item(0) as HTMLDivElement;
                refParentCallout.current = refParentCallout.current.children.item(2) as HTMLDivElement;
                extraOffset = { x: 0, y: 7 };
            } else {
                const parentElement = document.getElementsByClassName('sakuraDialog');
                if (parentElement && parentElement.length > 0) {
                    refParentCallout.current = parentElement.item(parentElement.length - 1) as HTMLDivElement;
                    if (refParentCallout.current.className.indexOf('fullscreen') !== -1) {
                        extraOffset = { x: -15, y: -45 };
                    }
                    refParentCallout.current = refParentCallout.current.children.item(1)?.children.item(0) as HTMLDivElement;
                }
            }
        }
        if (refParentCallout.current) {
            const rect = refParentCallout.current.getBoundingClientRect();
            setOffset({ x: rect.left + extraOffset.x, y: rect.top + extraOffset.y });
        }
    }, []);

    return (dataSource && dataSource.length > 0) || functionInfo ? (
        <>
            {functionInfo ? (
                <div style={{ top: position.top - offset.y, left: position.left - offset.x, height: 'auto' }} className={'intelisense overlay'}>
                    <IntelisenseFunctionDetail argumentIndex={functionInfo.argumentIndex} info={functionInfo.info} />
                </div>
            ) : (
                <div style={{ top: position.top - offset.y, left: position.left - offset.x, height }} className={'intelisense overlay overflow'}>
                    <div className='intelisense-list'>
                        {dataSource.map((result, index) => {
                            return (
                                <div
                                    id={`Entry_${index}`}
                                    key={`Entry_${index}`}
                                    className={clsx('intelisense-item', selectedIndex === index ? 'selected' : undefined)}
                                    onMouseDown={(ev) => {
                                        onSelect(index);
                                        ev.preventDefault();
                                        return false;
                                    }}
                                >
                                    <Icon iconName={icons[result.info.type].icon} className={icons[result.info.type].classname} />
                                    <span>{result.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    ) : (
        <></>
    );
};
