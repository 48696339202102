import { useMemo } from 'react';

export function useVariableName(fieldValue: string | undefined) {
    return useMemo(() => {
        if (fieldValue) {
            return fieldValue.split('.');
        }
        return undefined;
    }, [fieldValue]);
}
