import { IDropdownOption } from '@fluentui/react';
import { QuestionTypeEnum } from '../../../../Services/SakuraApiClient';

export function getQuestionType(type: QuestionTypeEnum): string {
    switch (type) {
        case QuestionTypeEnum.Choice:
            return 'Choix unique';
        case QuestionTypeEnum.MultiChoice:
            return 'Choix multiple';
        case QuestionTypeEnum.YesNo:
            return 'Oui / Non';
        case QuestionTypeEnum.FreeText:
            return 'Texte libre';
    }
    return '<TBD>';
}
export const QuestionTypes: IDropdownOption[] = [
    {
        key: QuestionTypeEnum.Choice,
        text: getQuestionType(QuestionTypeEnum.Choice),
    },
    {
        key: QuestionTypeEnum.MultiChoice,
        text: getQuestionType(QuestionTypeEnum.MultiChoice),
    },
    {
        key: QuestionTypeEnum.YesNo,
        text: getQuestionType(QuestionTypeEnum.YesNo),
    },
    {
        key: QuestionTypeEnum.FreeText,
        text: getQuestionType(QuestionTypeEnum.FreeText),
    },
];
