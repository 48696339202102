import React, { FC, useMemo } from 'react';
import { mobileViewInContextSelector, storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IImageEffect } from '../../../Services/SakuraApiClient';
import { ST } from '../../Hooks/StorageResolver';
import { DeepPartial } from '../../helpers/ObjectAndArray';
import { ImageViewer2 } from './ImageViewer2';

export interface ResourceImageViewerProps {
    blobPath: string;
    style?: React.CSSProperties | undefined;
    title?: string | undefined;
    effect?: DeepPartial<IImageEffect>;
    selected?: boolean;
    onSelect?: (selcted: boolean) => void;
}

export const ResourceImageViewer: FC<ResourceImageViewerProps> = (props: ResourceImageViewerProps) => {
    const { blobPath, style, title, effect, selected, onSelect } = props;
    const storage = useAppSelector(storageEndpointsSelector);
    const isMobile = useAppSelector(mobileViewInContextSelector);
    const url = useMemo(() => {
        return ST(storage, blobPath, isMobile);
    }, [storage, blobPath, isMobile]);

    return <ImageViewer2 effect={effect} selected={selected} onSelect={onSelect} className='riv' title={title} style={style} url={url}></ImageViewer2>;
};
