import { PrimaryButton } from '@fluentui/react';
import clsx from 'clsx';
import React, { CSSProperties, FC, ImgHTMLAttributes, ReactEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import './ImageViewer.scss';
import { IImageEffect } from '../../../Services/SakuraApiClient';
import { DeepPartial } from '../../helpers/ObjectAndArray';

export interface ImageViewer2Props {
    width?: string;
    height?: string;
    url?: string;
    file?: File;
    style?: CSSProperties | undefined;
    className?: string;
    title?: string;
    onLoad?: ReactEventHandler<HTMLImageElement>;
    effect?: DeepPartial<IImageEffect>;
    selected?: boolean;
    onSelect?: (selcted: boolean) => void;
}

export const ImageViewer2: FC<ImageViewer2Props> = (props: ImageViewer2Props) => {
    const { width, height, url, file, style, title, className, onLoad, effect, onSelect, selected } = props;
    const [imgUrl, setImgUrl] = useState<string | undefined>();
    const [state, setState] = useState<'base' | 'hover' | 'pressed'>('base');
    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (ev) => {
                setImgUrl(ev?.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);

    const effectProperties = useMemo(() => {
        if (!selected) {
            switch (state) {
                case 'base':
                    return effect?.base;
                case 'hover':
                    return effect?.onHover;
                case 'pressed':
                    return effect?.onPressed;
                case 'pressed':
                    return effect?.onPressed;
            }
        }
        return effect?.onSelected;
    }, [state, effect, selected]);

    /*
    const backdrop = useMemo(() => {
        if (effectProperties) {
            const values = effectProperties?.backdropFilter?.map((f) => {
                const unit = f.filterFunction === 'blur' ? 'px' : '%';
                return `${f.filterFunction}(${f.value}${unit})`;
            });
            if (values && values.length > 0) {
                return values.join(' ');
            }
        }
        return undefined;
    }, [effectProperties?.backdropFilter]);*/

    return (
        <div
            onMouseEnter={() => setState('hover')}
            onMouseLeave={() => setState('base')}
            onMouseDown={() => setState('pressed')}
            onTouchStart={() => setState('pressed')}
            onClick={onSelect ? () => onSelect(!(selected === undefined ? false : selected)) : undefined}
            title={title}
            className={clsx(className, 'imgbg', effect?.shape ? `img${effect.shape}` : undefined)}
            style={{
                width,
                height,
                cursor: onSelect ? 'pointer' : undefined,
                backgroundImage: `url("${imgUrl ?? url}")`,
                ...style,
                ...effectProperties,
                backdropFilter: undefined,
            }}
        >
            <div className={clsx(effect?.shape ? `img${effect.shape}` : undefined)} style={{ backdropFilter: effectProperties?.backdropFilter }}>
                <img onLoad={onLoad} src={imgUrl ?? url} style={{ width: width ?? '100%', height, visibility: 'hidden' }} />
            </div>
        </div>
    );
};
