import { IDropdownOption } from '@fluentui/react';
import { ProductTypeEnum } from '../../../Services/SakuraApiClient';

export function getProductTypeText(productType: ProductTypeEnum): string {
    switch (productType) {
        case ProductTypeEnum.ResourceAccess:
            return 'Resources';
        case ProductTypeEnum.ResourceSubscription:
            return 'Abonnement';
        case ProductTypeEnum.WorkflowAction:
            return 'Actions';
    }
    return '<TBD>';
}
export const ProductTypes: IDropdownOption[] = [
    {
        key: ProductTypeEnum.ResourceAccess,
        text: getProductTypeText(ProductTypeEnum.ResourceAccess),
    },
    {
        key: ProductTypeEnum.ResourceSubscription,
        text: getProductTypeText(ProductTypeEnum.ResourceSubscription),
    },
    {
        key: ProductTypeEnum.WorkflowAction,
        text: getProductTypeText(ProductTypeEnum.WorkflowAction),
    },
];
