import { FC, useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import { IAccountDataResource, IProtectedValueInfo, IQuestionnary } from '../../../Services/SakuraApiClient';
import { EndPage } from './EndPage';
import { StartPage } from './StartPage';
import { questionnaryReducer, questionnaryReducerInitializer } from './reducer/reducer';

export interface QuestionnaryProps {
    resourceId: number;
    questionnary: IQuestionnary;
    enablePersitance: boolean;
    showResult?: IProtectedValueInfo;
    accountDataResource: IAccountDataResource | undefined;
    onClosed?: () => void;
}

import { patchScope } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { ResourcePackageContextProvider } from '../../../common/components/ResourcePackage/ResourcePackageContextProvider';
import { QuestionPage } from './QuestionPage';
import { getDynVariableChange } from './getDynVariableChange';
import './questionnaryUI.scss';

export const QuestionnaryUI: FC<QuestionnaryProps> = (props: QuestionnaryProps) => {
    const { resourceId, questionnary, enablePersitance, showResult, onClosed, accountDataResource } = props;

    const [state, dispatch] = useReducer(questionnaryReducer, questionnaryReducerInitializer(resourceId, questionnary, enablePersitance, accountDataResource));
    const appDispatch = useAppDispatch();
    useEffect(() => {
        dispatch({ type: 'init', payload: { resourceId, questionnary, enablePersitance, accountDataResource } });
    }, [resourceId, questionnary]);

    const currentQuestion = useMemo(() => {
        if (state.questionnary.questions) {
            return state.questionnary.questions[state.navigate.current];
        }
        return undefined;
    }, [state.questionnary.questions, state.navigate.current]);
    const cutomFirstPage = useMemo(() => {
        if (questionnary.customStartPageId && questionnary.resourcePackage) {
            return questionnary.resourcePackage[questionnary.customStartPageId];
        }
        return undefined;
    }, [questionnary]);
    useLayoutEffect(() => {
        window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
    }, [currentQuestion]);
    return (
        <ResourcePackageContextProvider resourcePackages={questionnary.resourcePackage}>
            <div className='questionnaryRoot'>
                <div className='questionnaryUI'>
                    {state.status === 'NotStarted' ? (
                        <StartPage
                            title={state.questionnary.title ?? 'Questionnaire'}
                            subTitle={state.questionnary.subTitle}
                            buttonText={state.questionnary.beginButtonText ?? 'Commencer'}
                            imageResourceId={state.questionnary.imageResourceId}
                            cutomFirstPage={cutomFirstPage}
                            onStart={() => {
                                dispatch({ type: 'start' });
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {state.status === 'InProgress' && currentQuestion ? (
                        <QuestionPage
                            questionnaryTitle={state.questionnary.title ?? ''}
                            questionNumber={state.navigate.current + 1}
                            nbQuestions={state.questionnary.questions?.length ?? 0}
                            question={currentQuestion}
                            answer={state.answers[state.navigate.current]}
                            onAnswer={(answer) => {
                                dispatch({ type: 'answer', payload: { answer } });
                                appDispatch(patchScope({ scope: 'quiz', contentToMerge: getDynVariableChange(currentQuestion, answer) }));
                            }}
                            onBack={() => {
                                dispatch({ type: 'prev' });
                                window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
                            }}
                            onNext={() => {
                                dispatch({ type: 'next' });
                            }}
                            canGoPrev={state.navigate.canGoPrev}
                            canGoNext={state.navigate.canGoNext}
                        />
                    ) : (
                        <></>
                    )}
                    {state.status === 'Completed' ? <EndPage enablePersitance={enablePersitance} showResult={showResult} questionnaryState={state} onClose={onClosed} /> : <></>}
                </div>
            </div>
        </ResourcePackageContextProvider>
    );
};
