import { FC, PropsWithChildren } from 'react';
import { InitialiazerPublicLinks } from '../../InitialiazerPublicLinks';
import { ApplicationLogo } from '../Layout/ApplicationLogo';

export const LayoutPublic: FC<PropsWithChildren<unknown>> = (props: PropsWithChildren<unknown>) => {
    const { children } = props;
    return (
        <div id='layoutRoot' className='sakura-layout-public'>
            <div className='sakura-layout-header-public'>
                <div>
                    <ApplicationLogo />
                </div>
            </div>
            <InitialiazerPublicLinks>{children}</InitialiazerPublicLinks>
        </div>
    );
};
