import { FC } from 'react';
import { IEmailTemplate } from '../../../Services/SakuraApiClient';
import { EmailTemplateLookupField } from '../../../common/LookupFields/EmailTemplateLookupField';

export interface EmailTemplateSelectorProps {
    label: string;
    emailTemplate: IEmailTemplate | undefined;
    onSelectEmailTemplate: (template: IEmailTemplate | undefined) => void;
    errorMessage?: string;
}

export const EmailTemplateSelector: FC<EmailTemplateSelectorProps> = (props: EmailTemplateSelectorProps) => {
    const { label, emailTemplate, onSelectEmailTemplate, errorMessage } = props;
    return (
        <>
            <EmailTemplateLookupField label={label} emailTemplate={emailTemplate} onSelectEmailTemplate={onSelectEmailTemplate} errorMessage={errorMessage} />
        </>
    );
};
