import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC } from 'react';
import './AccordionIcon.scss';

export interface AccordionIconProps {
    open: boolean;
    onClick?: () => void;
}
export const AccordionIcon: FC<AccordionIconProps> = (props: AccordionIconProps) => {
    const { open, onClick } = props;
    return (
        <Icon
            className={clsx('accordion-icon', open ? 'open' : '')}
            iconName={'CaretRightSolid8'}
            onClick={
                onClick
                    ? (ev) => {
                          ev.stopPropagation();
                          onClick();
                      }
                    : undefined
            }
        />
    );
};
