import React, { FC, useEffect, useMemo, useState } from 'react';
import { IResource, Resource, ResourceTypeEnum } from '../../../Services/SakuraApiClient';

import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../Redux/hook';
import { FilePicker } from '../../../common/components/FilePicker';
import { Dropdown, IDropdownOption, TextField, Toggle } from '@fluentui/react';
import { Container } from '../../../common/Hooks/StorageResolver';
import { GetResourceTypeEntityTitle, GetResourceTypeFileExtensions, GetResourceTypeIcon } from '../../../common/components/Icons/ResourceTypeIcon';
import { useForm } from '../../../common/Hooks/Form';
import * as validators from '../../../common/Hooks/Form/validators';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';

export interface ResourceDialogProps {
    resource?: IResource;
    type: ResourceTypeEnum;
    show: boolean;

    showOptionEnabled?: boolean;
    showThumbnail?: boolean;
    categoryOptions?: IDropdownOption[];
    category?: string;
    onClose: (resource: IResource | undefined) => void;
}
const getNewResource = (category?: string, resourceType?: ResourceTypeEnum) => {
    const newResource = new Resource();
    newResource.init({ enabled: true, category, resourceType });
    return newResource;
};

interface FormModel {
    resource: IResource;
}

export const ResourceDialog: FC<ResourceDialogProps> = (props: ResourceDialogProps) => {
    const { resource, show, onClose, type, showOptionEnabled, showThumbnail, categoryOptions, category } = props;
    const contextId = `Edit${type.toString()}`;

    const form = useForm<FormModel>(
        {
            initialState: { resource: resource ?? getNewResource(category, type) },
            validators: {
                fields: {
                    resource: {
                        name: { displayName: 'Le nom', validators: [validators.required] },
                    },
                },
            },
        },
        resource !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose(res);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state.resource) {
                        const data = new Resource();
                        data.init(form.state.resource);

                        let savedResource;
                        if (resource) {
                            savedResource = await client.updateResource(resource.id, data);
                        } else {
                            data.enabled = true;
                            savedResource = await client.createResource(data);
                        }
                        form.commit();
                        close(savedResource);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: GetResourceTypeIcon(type) }}
            mode={resource ? 'Edit' : 'Create'}
            title={GetResourceTypeEntityTitle(type, resource ? 'edit' : 'new')}
            show={show}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.resource?.name} required value={form.state.resource?.name ?? ''} onChange={(_, v) => form.update({ resource: { name: v } })} />
                {categoryOptions ? (
                    <Dropdown
                        label='Category'
                        options={categoryOptions}
                        selectedKey={form.state.resource?.category}
                        onChange={(_, v) => form.update({ resource: { category: v?.key?.toString() } })}
                    ></Dropdown>
                ) : (
                    <TextField label='Category' value={form.state.resource?.category ?? ''} onChange={(_, v) => form.update({ resource: { category: v } })} />
                )}
                <TextField label='Description' value={form.state.resource?.description ?? ''} onChange={(_, v) => form.update({ resource: { description: v } })} />
                {showThumbnail && (
                    <ResourceLookupField
                        type='ResourceImageVignetteLookup'
                        label='Vignette'
                        selectedResourceId={form.state.resource?.thumbnailResourceId}
                        onSelectResource={(resource) => form.update({ resource: { thumbnailResourceId: resource?.id } })}
                    />
                )}
                {resource && showOptionEnabled && (
                    <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.resource?.enabled} onChange={(_, val) => form.update({ resource: { enabled: val } })} />
                )}
            </div>
        </EditDialog>
    );
};
