import { Carousel, SlideHandle } from 'nuka-carousel';
import { FC, useMemo, useRef } from 'react';

import { clearSelectedThumbnail, mobileViewInContextSelector, setSelectedThumbnail, useThumbnailInfo } from '../../../../../../Redux/Reducers/System/reducer';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/hook';
import { screenPaths } from '../../../../../../Routing/ScreenPaths';
import { IResourceTile, ResourceTypeEnum } from '../../../../../../Services/SakuraApiClient';
import { triggerDownloadBlob } from '../../../../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../../../../common/Hooks/useApiCall';
import { useNav } from '../../../../../../common/Hooks/useNav';
import { useOnClickScreen } from '../../../../../../common/Hooks/useOnClickScreen';
import { generateUniqueId } from '../../../../../../common/helpers/uniqueId';
import { CustomArrows } from './CustomArrows';
import { ThumbnailItem } from './ThumbnailItem';
import { ThumbnailItemMobile } from './ThumbnailItemMobile';
import { getTileSize } from './tileSize';
import { IDataTile } from './useThumbnailSource';

export interface ThumbnailCarouselProps {
    disableActions?: boolean;
    items: IDataTile[];
}

export const ThumbnailCarousel: FC<ThumbnailCarouselProps> = (props: ThumbnailCarouselProps) => {
    const { items, disableActions } = props;
    const carouselRef = useRef<SlideHandle>(null);
    const { selected } = useThumbnailInfo();
    const dispatch = useAppDispatch();
    const componentId = useRef<string>(generateUniqueId('RootPage/ThumbnailSection'));
    const isMobileView = useAppSelector(mobileViewInContextSelector);
    const nav = useNav();
    const titleSize = useMemo(() => {
        return getTileSize(isMobileView);
    }, [isMobileView]);

    useOnClickScreen(() => {
        dispatch(clearSelectedThumbnail());
    });
    const ThumbnailComponent = isMobileView ? ThumbnailItemMobile : ThumbnailItem;
    return (
        <div style={{ display: 'block', width: '-webkit-fill-available' }}>
            <Carousel
                keyboard
                ref={carouselRef}
                scrollDistance='screen'
                dots={false}
                swiping={true}
                showArrows={!isMobileView}
                arrows={
                    <CustomArrows
                        onMouseEnter={() => {
                            dispatch(clearSelectedThumbnail());
                        }}
                        onNavigate={() => {
                            dispatch(clearSelectedThumbnail());
                        }}
                    />
                }
            >
                {items.map((item, index) => {
                    const position = index === 0 ? 'first' : index === items.length - 1 ? 'last' : 'middle';
                    const selectionState = selected?.id === item.data.id && selected?.blockId === componentId.current ? { status: selected.status, origin: selected.origin } : undefined;

                    return (
                        <ThumbnailComponent
                            disableActions={disableActions}
                            index={index}
                            position={position}
                            key={`${componentId.current}_Title_${item.data.id}`}
                            width={titleSize.width}
                            height={titleSize.height}
                            item={item}
                            selection={selectionState}
                            onOpen={async () => {
                                if (item.type === 'Product') {
                                    //TODO ACHAT
                                } else {
                                    const resTile = item.data as IResourceTile;
                                    switch (resTile.type) {
                                        case ResourceTypeEnum.Document:
                                            await performApiCall(async (client, ct) => {
                                                const res = await client.getResourcePackage(resTile.id, ct);
                                                if (res) {
                                                    await triggerDownloadBlob(res.blobPath);
                                                }
                                            }, dispatch);
                                            break;
                                        default:
                                            nav.navigate(screenPaths.resourceView(resTile.type, resTile.id));
                                            break;
                                    }
                                }
                            }}
                            onSelect={(selection) => {
                                if (selection?.status === 'Selected' || selection?.status === 'UnSelecting') {
                                    dispatch(setSelectedThumbnail({ blockId: componentId.current, id: item.data.id, origin: selection.origin, status: selection.status }));
                                } else {
                                    dispatch(clearSelectedThumbnail());
                                }
                            }}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};
