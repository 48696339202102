import { DraftStyleMap } from 'draft-js';
import { listFontSizes } from './Panel/RichEditFontSizeButton';
import { listFonts } from './Panel/RichEditFontButton';
import { IColorPalette, IFontStyles } from '../../../Services/SakuraApiClient';
import { createDraftJsPalette } from '../../styles/ColorPalette';
import { createDraftJsFontStyles } from '../../styles/FontStyles';

export function createCustomStyleMap(colorPalette: IColorPalette, fontStyles: IFontStyles): DraftStyleMap {
    const entries: DraftStyleMap = {};
    listFontSizes.map((fs) => {
        entries[fs.key] = { fontSize: Number.parseInt(fs.text, 10) };
    });
    listFonts.map((f) => {
        entries[f.key] = { fontFamily: f.data };
    });
    const frontColor = createDraftJsPalette(colorPalette, 'f');
    const backColor = createDraftJsPalette(colorPalette, 'b');
    const fontStylesDraftJs = createDraftJsFontStyles(fontStyles);

    Object.keys(frontColor).forEach((key) => (entries[key] = frontColor[key]));
    Object.keys(backColor).forEach((key) => (entries[key] = backColor[key]));
    Object.keys(fontStylesDraftJs).forEach((key) => (entries[key.replaceAll(' ', '_')] = fontStylesDraftJs[key]));
    return entries;
}
