import { FC } from 'react';
import { IAccountDataResource, IResource } from '../../../Services/SakuraApiClient';

export interface AudioViewProps {
    resource: IResource;
    accountResourceData: IAccountDataResource;
}
export const AudioView: FC<AudioViewProps> = (props: AudioViewProps) => {
    const { resource } = props;

    return <span>AUDIO {resource.id}</span>;
};
