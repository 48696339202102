import { FC, useMemo } from 'react';
import { ExamModeEnum, IQuestionnaryResponsesDetail, IQuestionnaryResultItem, QuestionnaryKind, QuestionTypeEnum } from '../../Services/SakuraApiClient';

import { Icon, Label, PrimaryButton } from '@fluentui/react';
import { performApiCall } from '../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../Redux/hook';
import './QuestionnaryDetailsResponseView.scss';

export interface QuestionnaryDetailsResponseViewProps {
    data: IQuestionnaryResultItem;
}
export const QuestionnaryDetailsResponseView: FC<QuestionnaryDetailsResponseViewProps> = (props: QuestionnaryDetailsResponseViewProps) => {
    const { data } = props;

    const responseGroupByQuestion = useMemo(() => {
        const groupedElements = data.questionnaryResponsesDetails?.reduce(
            (acc, element) => {
                if (element.questionId) {
                    if (!acc[element.questionId]) {
                        acc[element.questionId] = [];
                    }
                    acc[element.questionId].push(element);
                }
                return acc;
            },
            {} as { [key: string]: IQuestionnaryResponsesDetail[] },
        );
        return groupedElements ? Object.keys(groupedElements).map((key) => ({ questionId: key, answers: groupedElements[key] })) : [];
    }, [data.questionnaryResponsesDetails]);

    const responses = useMemo(() => {
        return responseGroupByQuestion?.map((detail) => {
            const question = data.questionnaryResource.data.content.questionnary.questions?.find((q) => q.id === detail.questionId);

            const answers = detail.answers.map((answerItem) => {
                let answerText: string | undefined;
                const answer = question?.answers?.find((qa) => qa.id === answerItem.responseId);
                if (question?.type === QuestionTypeEnum.FreeText) {
                    answerText = answerItem.responseText;
                } else {
                    answerText = answer?.text;
                }
                return {
                    id: answerItem.id,
                    answerId: answer?.id,

                    hasCategory: answer?.categories?.length && answer?.categories?.length > 0 ? true : false,
                    hasPoints: answer?.points !== undefined,
                    category: answerItem.category,
                    answerText,
                    isCorrect: answerItem.isCorrect,
                    checked: answerItem.response,
                    score: answerItem.score,
                };
            });

            return { id: detail.questionId, question, questionText: question?.text, type: question?.type, answers };
        });
    }, [data, responseGroupByQuestion]);
    const kind = useMemo(() => {
        return data.questionnaryResource.data.content.questionnary.kind;
    }, [data.questionnaryResource.data.content.questionnary.kind]);

    const questionnary = useMemo(() => {
        return { ...data.questionnaryResource.data.content.questionnary, resourcePackage: {} };
    }, [data.questionnaryResource]);

    const dispatch = useAppDispatch();
    return (
        <div className='QuestionnaryDetailsResponseView'>
            <div className='list'>
                {responses?.map((item) => {
                    return (
                        <div key={item.id}>
                            <span>{`${item.id}. ${item.questionText}`}</span>
                            <div>
                                {item.answers.map((answer) => {
                                    const yesNoDetails = answer.checked ? item.question?.yes : item.question?.no;
                                    const isCorrect = item.type === QuestionTypeEnum.YesNo ? yesNoDetails?.isCorrect : answer.isCorrect;

                                    return (
                                        <div key={answer.id}>
                                            {kind === QuestionnaryKind.Exam ? (
                                                <>{isCorrect ? <Icon iconName='CheckMark' className='positive' /> : <Icon iconName='Cancel' className='negative' />}</>
                                            ) : null}
                                            {item.type === QuestionTypeEnum.YesNo ? (
                                                <span>{answer.checked ? 'Oui' : 'Non'}</span>
                                            ) : (
                                                <span title={answer.answerId ? `Id de réponse: ${answer.answerId}` : ''}>{answer.answerText}</span>
                                            )}
                                            {answer.hasCategory ? <span style={{ fontStyle: 'italic' }}>{`(Category: ${answer.category})`}</span> : null}
                                            {answer.hasPoints ? <span style={{ fontStyle: 'italic' }}>{`(Score: ${answer.score})`}</span> : null}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='resultDetails'>
                {questionnary.contactAttributeResultLink && data.account?.brevoId ? (
                    <div>
                        <PrimaryButton
                            onClick={() => {
                                performApiCall(async (client, cancel) => {
                                    await client.updateResultLinkOnContactAttribute(data.id, cancel);
                                }, dispatch);
                            }}
                        >{`Actualiser l'attribut contact '${questionnary.contactAttributeResultLink}'`}</PrimaryButton>
                    </div>
                ) : null}
                {questionnary.kind === QuestionnaryKind.Exam ? (
                    <>
                        <div className='field'>
                            <Label>{'Test de connaissance'}</Label>
                            <span>{data.resultDetails.examPassed ? 'Réussi' : 'Echoué'}</span>
                        </div>
                        {questionnary.examMode === ExamModeEnum.Scoring ? (
                            <>
                                <div className='field'>
                                    <Label>{'Points'}</Label>
                                    <span>
                                        {data.resultDetails.score?.points ?? 0}/{data.resultDetails.score?.totalPoints ?? 0}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='field'>
                                    <Label>{'Nombre de questions valide'}</Label>
                                    <span>
                                        {data.resultDetails.score?.nbQuestions ?? 0}/{data.resultDetails.score?.totalQuestions ?? 0}
                                    </span>
                                </div>
                            </>
                        )}
                        <div className='field'>
                            <Label>{'Pourcentage'}</Label>
                            <span>{`${data.resultDetails.score?.percent ?? 0}%`}</span>
                        </div>
                    </>
                ) : null}
                {questionnary.kind === QuestionnaryKind.Survey ? (
                    <>
                        <div className='field'>
                            <Label>{'Premiére Catégorie'}</Label>
                            <span>
                                {data.resultDetails.firstCategory && data.resultDetails.categoryDesc
                                    ? `${data.resultDetails.firstCategory}: ${data.resultDetails.categoryDesc[data.resultDetails.firstCategory].title ?? ''}`
                                    : ''}
                            </span>
                        </div>
                        <div className='field'>
                            <Label>{'Points par categories'}</Label>
                            <div className='DivFlexVerticalCenter'>
                                {data.resultDetails.scoringByCategory
                                    ? Object.keys(data.resultDetails.scoringByCategory).map((cat) => {
                                          return data.resultDetails.scoringByCategory ? <span key={cat}>{`${cat}: ${data.resultDetails.scoringByCategory[cat]}`}</span> : null;
                                      })
                                    : null}
                            </div>
                        </div>
                    </>
                ) : null}

                {/* //<QuestionnaryResult questionnary={questionnary} result={data.resultDetails} /> */}
            </div>
        </div>
    );
};
