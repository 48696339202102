import { Editor, EditorState, RichUtils } from 'draft-js';
import React, { FC } from 'react';
import { RichEditStyleButton } from './RichEditStyleButton';

import './RichTextEditorCommandPanel.scss';
import { RichEditFontSizeButton } from './RichEditFontSizeButton';
import { RichEditTextAlignmentButton } from './RichEditTextAlignmentButton';
import { RichEditColorButton } from './RichEditColorButton';
import { RichEditFontButton } from './RichEditFontButton';
import { Icon } from '@fluentui/react';
import { RichEditFontstyleButton } from './RichEditFontstyleButton';

export interface RichTextEditorCommandPanelProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    textAlignment?: 'left' | 'center' | 'right';
    onChangeTextAlignment: (textAlignment?: 'left' | 'center' | 'right') => void;
    refEditor: React.RefObject<Editor>;
}
export const RichTextEditorCommandPanel: FC<RichTextEditorCommandPanelProps> = (props: RichTextEditorCommandPanelProps) => {
    const { editorState, onChangeState, textAlignment, onChangeTextAlignment, refEditor } = props;
    return (
        <div className='richEditCommandPanel'>
            <Icon iconName='Waffle' title='Déplacer la zone de texte' style={{ alignSelf: 'center', marginInlineEnd: 10 }} />
            <RichEditStyleButton refEditor={refEditor} style='BOLD' iconName='Bold' title='Gras' editorState={editorState} onChangeState={onChangeState} />
            <RichEditStyleButton refEditor={refEditor} style='ITALIC' iconName='Italic' title='Italic' editorState={editorState} onChangeState={onChangeState} />
            <RichEditStyleButton refEditor={refEditor} style='UNDERLINE' iconName='Underline' title='Souligné' editorState={editorState} onChangeState={onChangeState} />
            <RichEditStyleButton refEditor={refEditor} style='STRIKETHROUGH' iconName='Strikethrough' title='Barré' editorState={editorState} onChangeState={onChangeState} />
            <RichEditFontButton refEditor={refEditor} editorState={editorState} onChangeState={onChangeState} />
            <RichEditFontSizeButton refEditor={refEditor} editorState={editorState} onChangeState={onChangeState} />
            <RichEditColorButton type='f' refEditor={refEditor} editorState={editorState} onChangeState={onChangeState} />
            <RichEditFontstyleButton refEditor={refEditor} editorState={editorState} onChangeState={onChangeState} />
            <RichEditColorButton type='b' refEditor={refEditor} editorState={editorState} onChangeState={onChangeState} />
            <RichEditTextAlignmentButton textAlignment={textAlignment} onChangeTextAlignment={onChangeTextAlignment} />
        </div>
    );
};
