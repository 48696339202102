import { Dropdown, IDropdownOption } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { IEmailTemplate } from '../../../Services/SakuraApiClient';

export interface EmailTemplateParameterSelectorProps {
    label: string;
    emailTemplate: IEmailTemplate | undefined;
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    errorMessage?: string;
}

export const EmailTemplateParameterSelector: FC<EmailTemplateParameterSelectorProps> = (props: EmailTemplateParameterSelectorProps) => {
    const { label, emailTemplate, value, onChange, errorMessage } = props;

    const options = useMemo<IDropdownOption[]>(() => {
        if (emailTemplate) {
            return (emailTemplate.parameterNames ?? []).map<IDropdownOption>((p) => ({ key: p, text: p }));
        }
        return [];
    }, [emailTemplate]);
    return (
        <>
            <Dropdown label={label} disabled={!emailTemplate} options={options} selectedKey={value} onChange={(_, val) => onChange(val?.key as string | undefined)} errorMessage={errorMessage} />
        </>
    );
};
