import { FC, useEffect, useState } from 'react';
import { DataSetName, datasetCollections } from '../../../../Redux/Reducers/DataSet/DataSetName';
import { IResource, ResourcePackageFull } from '../../../../Services/SakuraApiClient';
import { useApiCall } from '../../../../common/Hooks/useApiCall';
import { getResourcesInfo } from '../../Page/ResourcePageContent';

import { SearchBox } from '@fluentui/react';
import axios from 'axios';
import { useDataSetSelector } from '../../../../Redux/Reducers/DataSet/reducer';
import { Scaler } from '../../../../common/components/Scaler';
import { PageTemplateItem } from './PageTemplateItem';
import { PreviewTemplate } from './PreviewTemplate';
import './TemplateList.scss';

export interface TemplateListProps {
    datasetName: DataSetName;
    selection?: IResource | undefined;
    onSelect: (resource: IResource | undefined, resourcePackageFull: ResourcePackageFull | undefined) => void;
    contextId: string;
}

export const TemplateList: FC<TemplateListProps> = (props: TemplateListProps) => {
    const { onSelect, selection, datasetName, contextId } = props;
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [template, setTemplate] = useState<IResource>();
    const [resourcePackageFull, setResourcePackageFull] = useState<ResourcePackageFull>();
    const dataset = useDataSetSelector('ResourcePageTemplateGrid');
    const onfilter = (name: string | undefined) => {
        const perform = async () => {
            await datasetCollections['ResourcePageTemplateGrid'].update({ pageIndex: 1 }, { mode: 'Full', change: { name } });
        };

        perform();
    };
    useEffect(() => {
        setTemplate(selection);
    }, [selection]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        datasetCollections['ResourcePageTemplateGrid'].init('default', { mode: 'Full', change: { name: undefined } }, undefined, source.token);
        return () => {
            console.log('throw cancel api call for dataset');
            source.cancel();
        };
    }, []);
    useApiCall(
        async (client, ct) => {
            if (template) {
                const pkg = await client.getResourcePackageFull(template?.id as number, ct);
                if (pkg) {
                    setResourcePackageFull(pkg);
                }
            } else {
                setResourcePackageFull(undefined);
            }
        },
        [template],
        {
            contextId,
        },
    );
    useEffect(() => {
        onSelect(template, resourcePackageFull);
    }, [template, resourcePackageFull]);
    return (
        <div className='templateListDialog'>
            <div className='grid'>
                <SearchBox
                    placeholder='Rechercher'
                    underlined={true}
                    value={searchValue}
                    onChange={(_, val) => setSearchValue(val)}
                    onSearch={(val) => onfilter(val)}
                    onClear={() => {
                        onfilter(undefined);
                    }}
                />
                <div className='templateList'>
                    {dataset.data.map((item) => {
                        const resource = item as IResource;
                        return (
                            <PageTemplateItem
                                key={`templateItem${resource.id}`}
                                item={resource}
                                isSelected={template?.id === resource.id}
                                onSelect={() => {
                                    {
                                        setTemplate(resource);
                                    }
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div className='preview'>
                <Scaler width={500} height={300} renderWidth={800} renderheight={600}>
                    {resourcePackageFull ? (
                        <PreviewTemplate
                            resource={resourcePackageFull?.resource}
                            parentResourcePathId={undefined}
                            innerResources={getResourcesInfo(resourcePackageFull?.resourcePackage?.innerResourcePackages ?? {})}
                            resourceAccountData={resourcePackageFull?.accountResourceData}
                        />
                    ) : null}
                </Scaler>
            </div>
        </div>
    );
};
