import { CSSProperties } from 'react';
import { EnumBlockDirection, EnumDisplayMode, EnumJustifyContent, PageElementContainerStyle } from '../../../../../Services/SakuraApiClient';

function enumJustifyContentToCss(val: EnumJustifyContent) {
    if (val) {
        if (val === EnumJustifyContent.SpaceAround) {
            return 'space-around';
        }
        if (val === EnumJustifyContent.SpaceBetween) {
            return 'space-between';
        }
        if (val === EnumJustifyContent.SpaceEvenly) {
            return 'space-evenly';
        }
        //'space-evenly' | 'stretch';
        return val.toString();
    }
    return undefined;
}
export function styleBuilder(
    style: PageElementContainerStyle,
    parentContainerStyle: PageElementContainerStyle | undefined,
    backgroundImageUrl: string | undefined,
    windowWidth: number,
    editMode: boolean = false,
): CSSProperties {
    if (style) {
        let minWidth = undefined;
        if (parentContainerStyle?.flexWrap && !editMode) {
            const defaultWidth = Math.min(windowWidth - 35, 600);
            minWidth = parentContainerStyle.minChildrenWidth ?? defaultWidth;
        }
        return {
            display: style.display?.toString().toLowerCase(),
            flexDirection: style.direction === EnumBlockDirection.Row ? 'row' : 'column',
            gridTemplateColumns: style.display === EnumDisplayMode.Grid ? style.gridTemplates : 'unset',
            alignContent: enumJustifyContentToCss(style.alignContent),
            alignItems: style.alignItems,
            alignSelf: style.alignSelf,
            justifyContent: enumJustifyContentToCss(style.justifyContent),
            gap: style.gap,
            flex: style.flexGrow ?? 1,
            flexWrap: editMode ? 'nowrap' : style.flexWrap ? 'wrap' : 'nowrap',
            background: style.background,
            border: style.border,
            margin: style.margin,
            padding: style.padding,
            borderRadius: style.borderRadius,
            backgroundImage: backgroundImageUrl ? `url('${backgroundImageUrl}')` : undefined,
            boxShadow: style.boxShadow,
            maxHeight: style?.dimensions?.maxHeight,
            maxWidth: style?.dimensions?.maxWidth,
            minHeight: style?.dimensions?.minHeight,
            minWidth: style?.dimensions?.minWidth ?? minWidth,
            height: style?.dimensions?.height,
            width: style?.dimensions?.width,
        };
    }
    return {};
}

export function styleBuilderForParentEdition(style: PageElementContainerStyle): CSSProperties {
    if (style) {
        return {
            display: style?.display?.toString()?.toLowerCase(),
            flexDirection: 'column',
            alignSelf: style?.alignSelf,
            flex: style?.flexGrow ?? 1,
        };
    }
    return {};
}
