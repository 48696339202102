import React, { FC } from 'react';
import { SelectableIcon } from '../../Icons/SelectableIcon';

export interface RichEditTextAlignmentButtonProps {
    textAlignment?: 'left' | 'center' | 'right';
    onChangeTextAlignment: (textAlignment?: 'left' | 'center' | 'right') => void;
}
export const RichEditTextAlignmentButton: FC<RichEditTextAlignmentButtonProps> = (props: RichEditTextAlignmentButtonProps) => {
    const { textAlignment, onChangeTextAlignment } = props;
    return (
        <>
            <SelectableIcon
                iconName={'AlignLeft'}
                title={'Aligné à gauche'}
                selected={textAlignment === 'left' || textAlignment === undefined}
                onChange={() => {
                    onChangeTextAlignment('left');
                }}
            />
            <SelectableIcon
                iconName={'AlignCenter'}
                title={'Aligné au centre'}
                selected={textAlignment === 'center'}
                onChange={() => {
                    onChangeTextAlignment('center');
                }}
            />
            <SelectableIcon
                iconName={'AlignRight'}
                title={'Aligné à droite'}
                selected={textAlignment === 'right'}
                onChange={() => {
                    onChangeTextAlignment('right');
                }}
            />
        </>
    );
};
