import { RawDraftContentState, RawDraftInlineStyleRange } from 'draft-js';
import { dynamicDataResolver } from '../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { Expression } from '../ExpressionField/Expression';
import { BracketItemResolved } from '../ExpressionField/Expression/Expression';

function applyShift(inlineStyleRange: Array<RawDraftInlineStyleRange>, items: BracketItemResolved[]): Array<RawDraftInlineStyleRange> {
    return inlineStyleRange.map((inlineStyle) => {
        let totalShift = 0;
        for (let i = 0; i < items.length; i++) {
            if (items[i].beginIndex <= inlineStyle.offset) {
                totalShift += items[i].shift;
            }
        }
        return {
            ...inlineStyle,
            offset: inlineStyle.offset + totalShift,
        };
    });
}
export function draftjsBracketResolver(content: RawDraftContentState | undefined): RawDraftContentState | undefined {
    if (content) {
        const newBlocks = content.blocks.map((b) => {
            const result = Expression.resolveExpressionsInBrackets(b.text, dynamicDataResolver);
            if (result.items.length > 0) {
                return {
                    ...b,
                    text: result.newContent,
                    inlineStyleRanges: applyShift(b.inlineStyleRanges, result.items),
                };
            }
            return b;
        });
        return {
            ...content,
            blocks: newBlocks,
        };
    }
    return undefined;
}
