import { ValueTyping } from '../../../common/components/ExpressionField/Expression';
import { store } from '../../store';
import { DataScope, DynamicData } from './state';

// export function dynamicDataResolver(data: DynamicData | undefined = undefined) {
//     const dynData = data ?? store.getState().dynamicData.data;
//     return (variableName: string[]) => {
//         if (variableName.length > 1) {
//             const currentScope = variableName[0] as DataScope;
//             const dataScope = dynData[currentScope];
//             if (dataScope) {
//                 let result: Record<string, unknown> = dataScope;
//                 for (let index = 1; index < variableName.length; index++) {
//                     result = result[variableName[index]] as Record<string, unknown>;
//                     if (!result) {
//                         break;
//                     }
//                 }
//                 return result as Record<string, ValueTyping>;
//             }
//         }
//         return undefined;
//     };
// }

export function dynamicDataResolverOnData(dynData: DynamicData, variableName: string[]) {
    if (variableName.length > 1) {
        const currentScope = variableName[0] as DataScope;
        const dataScope = dynData[currentScope];
        if (dataScope) {
            let result: Record<string, unknown> = dataScope;
            for (let index = 1; index < variableName.length; index++) {
                result = result[variableName[index]] as Record<string, unknown>;
                if (!result) {
                    break;
                }
            }
            return result as Record<string, ValueTyping>;
        }
    }
    return undefined;
}

export function dynamicDataResolver(variableName: string[]) {
    const dynData = store.getState().dynamicData.data;
    if (variableName.length > 1) {
        const currentScope = variableName[0] as DataScope;
        const dataScope = dynData[currentScope];
        if (dataScope) {
            let result: Record<string, unknown> = dataScope;
            for (let index = 1; index < variableName.length; index++) {
                result = result[variableName[index]] as Record<string, unknown>;
                if (!result) {
                    break;
                }
            }
            return result as Record<string, ValueTyping>;
        }
    }
    return undefined;
}
