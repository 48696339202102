import { FC } from 'react';
import { WhatsappShareButton } from 'react-share';
import { SocialNetworkLogo } from '../SocialNetworkLogo';
import { SharedButtonProps } from './type';

export const WhatsAppSB: FC<SharedButtonProps> = (props: SharedButtonProps) => {
    const { url, hashTag } = props;
    return url ? (
        <WhatsappShareButton url={url}>
            <div className='whatsapp_share_b' title='Partager via whats app'>
                <SocialNetworkLogo socialNetwork='whatsapp' white size='small' />
                <span>{'Partager'}</span>
            </div>
        </WhatsappShareButton>
    ) : null;
};
