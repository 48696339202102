import { Editor, RawDraftContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDynamicData } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { colorsPaletteSelector, fontStylesSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { createCustomStyleMap } from './customStyleMap';
import { draftjsBracketResolver } from './draftjsBracketResolver';
import { loadEditorState } from './state';

export interface RichTextViewerProps {
    content?: RawDraftContentState;
    textAlignment?: 'left' | 'center' | 'right';
}

export const RichTextViewer: FC<RichTextViewerProps> = (props: RichTextViewerProps) => {
    const { content, textAlignment } = props;
    const [editorState, setEditorState] = useState(() => loadEditorState(content));
    const colors = useAppSelector(colorsPaletteSelector);
    const fontStyles = useAppSelector(fontStylesSelector);
    const dynData = useDynamicData();
    useEffect(() => {
        const updatedContent = draftjsBracketResolver(content);
        setEditorState(loadEditorState(updatedContent));
    }, [content, dynData]);
    const customStyleMap = useMemo(() => {
        return createCustomStyleMap(colors, fontStyles);
    }, [colors]);
    return <Editor readOnly customStyleMap={customStyleMap} editorState={editorState} onChange={setEditorState} textAlignment={textAlignment} />;
};
