import clsx from 'clsx';
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { mobileViewInContextSelector, storageEndpointsSelector, useWindowWidth } from '../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../Redux/hook';
import { EnumBlockDirection, IPageElement, IPageElementContainer, PageElement, PageElementContainer, PageElementContainerStyle, PageElementType } from '../../../../../Services/SakuraApiClient';
import { ST } from '../../../../../common/Hooks/StorageResolver';
import { ArrayOperation } from '../../../../../common/helpers/ObjectAndArray';
import { generateUniqueId } from '../../../../../common/helpers/uniqueId';
import { EditorDataContext } from '../../../PageEditor/EditorContext/EditorDataContextProvider';
import { DropPosition } from '../../../PageEditor/ElementSelector';
import { PageElementEdition } from '../../../PageEditor/PageElementEdition';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';
import { CustomComponentName } from '../CustomComponent/type';
import { createNewElement } from './createNewElement';
import { styleBuilder } from './styleBuilder';

export interface ElementContainerEditionProps {
    data: PageElementContainer;
    parentContainerStyle?: PageElementContainerStyle;
    onChangeData: (data: PageElementContainer) => void;
}
interface DeleteInfo {
    index: number;
    position: DropPosition;
    containerItemsCount: number;
}
interface IItemElementType {
    elementType: PageElementType;
    elementSubType: CustomComponentName | undefined;
}
interface IItemElement {
    data: PageElement;
    parentContainerId?: string;
}

export const ElementContainerEdition: FC<ElementContainerEditionProps> = (props: ElementContainerEditionProps) => {
    const { data, onChangeData, parentContainerStyle } = props;
    const dataRef = useRef<PageElementContainer>(data);
    const containerId = useRef(generateUniqueId('container_'));
    const indexToDelete = useRef<DeleteInfo>();
    const editorContext = useContext(EditorDataContext);

    const [state] = useContext(ExecutionDataContext);
    const isMobile = useAppSelector(mobileViewInContextSelector);
    const storage = useAppSelector(storageEndpointsSelector);
    const windowWidth = useWindowWidth();
    const onDataChange = useCallback(
        (newData: PageElementContainer) => {
            dataRef.current = newData;
            onChangeData(newData);
        },
        [onChangeData],
    );

    useEffect(() => {
        dataRef.current = data;
        if (indexToDelete.current) {
            const newData = new PageElementContainer();
            const existingData = dataRef.current;
            newData.init({ ...existingData });
            const index =
                indexToDelete.current.position === 'Start' && indexToDelete.current.containerItemsCount !== dataRef.current.items?.length
                    ? indexToDelete.current.index + 1
                    : indexToDelete.current.index;
            newData.items = ArrayOperation.removeByIndex(existingData.items ?? [], index);
            editorContext.unSelect();
            onDataChange(newData);
            indexToDelete.current = undefined;
        }
    }, [data, onDataChange]);

    const style = useMemo(() => {
        return styleBuilder(
            data.style,
            parentContainerStyle,
            data.style?.backgroundImageResourceId ? ST(storage, state.resources[data.style?.backgroundImageResourceId]?.blobPath, isMobile) : undefined,
            windowWidth,
            true,
        );
    }, [data.style, parentContainerStyle, storage, state, isMobile, windowWidth]);

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            // The type (or types) to accept - strings or symbols
            accept: ['ELEMENT_TYPE', 'ELEMENT', 'ELEMENT_LIB_ITEM'],
            drop: (item: IItemElementType | IItemElement | IPageElementContainer, monitor) => {
                if (monitor.didDrop()) {
                    return monitor.getDropResult();
                }
                if (monitor.getItemType() == 'ELEMENT_TYPE') {
                    const newData = new PageElementContainer();
                    newData.init(dataRef.current);
                    const pageElement = createNewElement((item as IItemElementType).elementType, (item as IItemElementType).elementSubType);
                    if (pageElement) {
                        newData.items = [...(dataRef.current.items ?? []), pageElement];
                    }
                    if (pageElement) {
                        editorContext.assignedPlanSelection(pageElement);
                    }
                    onDataChange(newData);
                }
                if (monitor.getItemType() == 'ELEMENT_LIB_ITEM') {
                    const itemElement = item as IPageElementContainer;
                    const newData = new PageElementContainer();
                    newData.init(dataRef.current);
                    const pageElement = createNewElement(PageElementType.Container, undefined, itemElement);
                    if (pageElement) {
                        newData.items = [...(dataRef.current.items ?? []), pageElement];
                    }
                    if (pageElement) {
                        editorContext.assignedPlanSelection(pageElement);
                    }
                    onDataChange(newData);
                }
                if (monitor.getItemType() == 'ELEMENT') {
                    const itemElement = item as IItemElement;

                    if (itemElement.data.type === PageElementType.Container) {
                        if (itemElement.data === dataRef.current) {
                            return undefined;
                        }
                        if (ArrayOperation.isChild((itemElement.data as PageElementContainer).items ?? [], dataRef.current, 'items')) {
                            return undefined;
                        }
                    }
                    const newData = new PageElementContainer();
                    const existingData = dataRef.current;
                    newData.init({ ...existingData });
                    const pageElement = createNewElement(itemElement.data.type, undefined, itemElement.data);
                    const indexOfExisting = existingData.items?.indexOf(itemElement.data);
                    let hasDeleteItem = false;
                    if (indexOfExisting === -1 && newData.items) {
                        if (existingData.items) {
                            const result = ArrayOperation.removeDeep(existingData.items, itemElement.data, 'items');
                            hasDeleteItem = result.hasDeleted;
                            newData.items = result.array;
                        }
                    }
                    if (pageElement && newData.items) {
                        newData.items.push(pageElement);
                    }

                    if (indexOfExisting !== undefined && indexOfExisting !== -1 && newData.items) {
                        //in same container
                        newData.items = ArrayOperation.removeByIndex(newData.items, indexOfExisting);
                        hasDeleteItem = true;
                    }
                    if (pageElement) {
                        editorContext.assignedPlanSelection(pageElement);
                    }
                    onDataChange(newData);
                    return { position: 'End', hasDeleteItem };
                }
                return undefined;
            },
            canDrop: (item, monitor) => {
                if (monitor.getItemType() == 'ELEMENT') {
                    const itemElement = item as IItemElement;

                    if (itemElement.data.type === PageElementType.Container) {
                        if (itemElement.data === data) {
                            return false;
                        }
                        const isChild = ArrayOperation.isChild((itemElement.data as PageElementContainer).items ?? [], dataRef.current, 'items');
                        return !isChild;
                    }
                }
                return true;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        [onDataChange, editorContext],
    );

    return (
        <div ref={drop} id={containerId.current} style={style} className={clsx('containerEdition', isOver ? `dragHover` : '', isOver && canDrop === false ? 'noDrop' : '')}>
            {(data.items ?? []).map((item, index) => {
                return item ? (
                    <PageElementEdition
                        parentContainerId={containerId.current}
                        key={`item_${index}`}
                        data={item}
                        parentContainerStyle={data.style}
                        orientation={data.style?.direction === EnumBlockDirection.Row ? 'Horizontal' : 'Vertical'}
                        onChangeData={(childData: PageElement) => {
                            const newData = new PageElementContainer();
                            const existingData = dataRef.current;
                            newData.init({ ...existingData });
                            newData.items = ArrayOperation.updateByIndex(existingData.items ?? [], index, childData, 'merge');
                            onDataChange(newData);
                        }}
                        onCopyPaste={(itemCopied: IPageElement, to: IPageElement) => {
                            const pageElement = createNewElement(itemCopied.type, undefined, itemCopied);
                            const newData = new PageElementContainer();
                            const existingData = dataRef.current;
                            newData.init({ ...existingData });

                            if (to.type === PageElementType.Container) {
                                //Copy inside the container
                                if (pageElement && newData.items) {
                                    const toContainer = to as IPageElementContainer;
                                    newData.items = ArrayOperation.updateByIndex(
                                        newData.items ?? [],
                                        index,
                                        {
                                            items: [...(toContainer.items ?? []), itemCopied],
                                        } as IPageElementContainer,
                                        'merge',
                                    );
                                }
                            } else {
                                const index = existingData.items?.findIndex((item) => item === to);
                                if (pageElement && newData.items && index !== undefined) {
                                    newData.items.splice(index + 1, 0, pageElement);
                                }
                                // copy after the element
                            }
                            onDataChange(newData);
                            return newData;
                        }}
                        onDropItem={(position, elementType, elementSubType) => {
                            const insertAt = position === 'Start' ? index : index + 1;
                            const newData = new PageElementContainer();
                            const existingData = dataRef.current;
                            newData.init({ ...existingData });
                            const pageElement = createNewElement(elementType, elementSubType);
                            if (pageElement && newData.items) {
                                newData.items.splice(insertAt, 0, pageElement);
                            }
                            if (pageElement) {
                                editorContext.assignedPlanSelection(pageElement);
                            }
                            onDataChange(newData);
                            return newData;
                        }}
                        onDropLibraryItem={(position, libraryItem) => {
                            const insertAt = position === 'Start' ? index : index + 1;
                            const newData = new PageElementContainer();
                            const existingData = dataRef.current;
                            newData.init({ ...existingData });
                            const pageElement = createNewElement(PageElementType.Container, undefined, libraryItem);
                            if (pageElement && newData.items) {
                                newData.items.splice(insertAt, 0, pageElement);
                            }
                            if (pageElement) {
                                editorContext.assignedPlanSelection(pageElement);
                            }
                            onDataChange(newData);
                            return newData;
                        }}
                        onMoveItem={(position, element) => {
                            const insertAt = position === 'Start' ? index : index + 1;
                            const newData = new PageElementContainer();
                            const existingData = dataRef.current;
                            newData.init({ ...existingData });
                            const pageElement = createNewElement(element.type, undefined, element);
                            const indexOfExisting = existingData.items?.indexOf(element);
                            let hasDeleteItem = false;
                            if (indexOfExisting === -1 && newData.items) {
                                if (existingData.items) {
                                    const result = ArrayOperation.removeDeep(existingData.items, element, 'items');
                                    hasDeleteItem = result.hasDeleted;
                                    newData.items = result.array;
                                }
                            }
                            if (pageElement && newData.items) {
                                newData.items.splice(insertAt, 0, pageElement);
                            }

                            if (indexOfExisting !== undefined && indexOfExisting !== -1 && newData.items) {
                                //in same container
                                const insertedBefore = insertAt < indexOfExisting;
                                newData.items = ArrayOperation.removeByIndex(newData.items, indexOfExisting + (insertedBefore ? 1 : 0));
                                hasDeleteItem = true;
                            }
                            if (pageElement) {
                                editorContext.assignedPlanSelection(pageElement);
                            }
                            onDataChange(newData);
                            return hasDeleteItem;
                        }}
                        onRemoveItem={(immediate, dropPosition) => {
                            if (immediate) {
                                const newData = new PageElementContainer();
                                const existingData = dataRef.current;
                                newData.init({ ...existingData });
                                newData.items = ArrayOperation.removeByIndex(existingData.items ?? [], index);
                                editorContext.unSelect();
                                onDataChange(newData);
                                return newData;
                            } else {
                                if (dropPosition) {
                                    indexToDelete.current = { index, position: dropPosition, containerItemsCount: dataRef.current.items?.length ?? 0 };
                                }
                            }
                            return undefined;
                        }}
                    />
                ) : null;
            })}
        </div>
    );
};
