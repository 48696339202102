//note that we don't use export default here
//create context here, use context in others

import { createContext, FC, PropsWithChildren, useMemo, useReducer } from 'react';
import { jsonParse } from '../../../../common/helpers/Json';

import { IAccountDataResource, IResourceHierarchyView } from '../../../../Services/SakuraApiClient';
import { INavMenuEntry } from '../../../../common/components/NavMenu';
import { FormationContent } from '../helper';
import { FormationContextReducer, FormationContextReducerType } from './FormationContextReducer';
import { IFormationContextState } from './IFormationContextState';
import { buildFormationUserData } from './helper';

const emptyContext: IFormationContextState = { serializationIdentifier: undefined, menu: [], userData: { userDataIndex: {}, userDatas: [] }, selectedContentPath: undefined };
export interface MenuContext {
    items: INavMenuEntry<IResourceHierarchyView>[];
    expandItem: (resourcePath: string, isExpanded: boolean) => void;
}
//this DataContext will be shared by all the components
export interface FormationContextValue {
    menu: MenuContext;
    currentUserData: IAccountDataResource | undefined;
    selectedContentPath: string | undefined;
    selectedContent: IResourceHierarchyView | undefined;
    navigateTo: (path: string) => void;
    goBack: () => void;
    goNext: () => void;
}
const formationContextDefaultValue = {
    menu: {
        items: [],
        expandItem: () => {
            /**/
        },
    },
    currentUserData: undefined,
    selectedContentPath: undefined,
    selectedContent: undefined,
    navigateTo: (_path: string) => {
        /**/
    },
    goBack: () => {
        /**/
    },
    goNext: () => {
        /**/
    },
};

export const FormationDataContext = createContext<FormationContextValue>(formationContextDefaultValue);

export interface FormationContextProviderProps {
    formationContent: FormationContent;
    serializationIdentifier: string | undefined;
}

export const FormationContextProvider: FC<PropsWithChildren<FormationContextProviderProps>> = (props: PropsWithChildren<FormationContextProviderProps>) => {
    const { children, serializationIdentifier, formationContent } = props;

    const [state, dispatch] = useReducer<FormationContextReducerType, IFormationContextState>(
        FormationContextReducer,
        { ...emptyContext, serializationIdentifier, menu: formationContent.menu, userData: buildFormationUserData(formationContent.userData) },
        (arg) => {
            arg.selectedContentPath = arg.userData.userDatas.length > 0 ? arg.userData.userDatas[0].resourcePath : undefined;
            if (serializationIdentifier) {
                const json = localStorage.getItem(`ExecData_${serializationIdentifier}`);
                if (json) {
                    const existingState = jsonParse(json) as IFormationContextState;
                    return existingState;
                }
            }
            return { ...arg, serializationIdentifier };
        },
    );

    const value: FormationContextValue = useMemo(() => {
        let currentUserData: IAccountDataResource | undefined = undefined;
        if (state.selectedContentPath) {
            const index = state.userData.userDataIndex[state.selectedContentPath];
            currentUserData = state.userData.userDatas[index]?.data;
        }
        state.selectedContentPath;
        return {
            menu: {
                items: state.menu,
                expandItem: (path, isExpanded) => {
                    dispatch({ type: 'expandItem', payload: { path, isExpanded } });
                },
            },
            currentUserData,
            selectedContentPath: state.selectedContentPath,
            selectedContent: state.selectedContentPath ? formationContent.content[state.selectedContentPath] : undefined,
            navigateTo: (path: string) => {
                dispatch({ type: 'selectFormationItem', payload: { path } });
            },
            goBack: () => {
                dispatch({ type: 'movePrev' });
            },
            goNext: () => {
                dispatch({ type: 'moveNext' });
            },
        };
    }, [state, formationContent.content, dispatch]);

    return <FormationDataContext.Provider value={value}>{children}</FormationDataContext.Provider>;
};

export function deletePersistantExecutionDataContext(identifier: string) {
    localStorage.removeItem(`ExecData_${identifier}`);
}
