import { ActionButton, Label, PrimaryButton } from '@fluentui/react';
import { FC, useState } from 'react';

import { AskDialog } from '../Dialog/AskDialog/AskDialog';
import './DataListEdit.scss';

export interface DataListEditProps {
    titleAdd: string;
    label: string;
    question: string;
    iconName: string;
    data: string[];
    onChange: (data: string[]) => void;
}
export const DataListEdit: FC<DataListEditProps> = (props: DataListEditProps) => {
    const { data, onChange, titleAdd, label, question, iconName } = props;
    const [askCreateSection, setAskCreateSection] = useState<boolean>();
    return (
        <>
            {askCreateSection ? (
                <AskDialog
                    id='onCreateItem'
                    onClose={(itemName) => {
                        if (data.findIndex((v) => v == itemName) === -1) {
                            if (itemName) {
                                const copy = [...data];
                                copy.push(itemName);
                                onChange(copy);
                            }
                        }
                        setAskCreateSection(false);
                    }}
                    show
                    title={titleAdd}
                    icon={{ iconName }}
                    question={question}
                />
            ) : null}
            <PrimaryButton
                text={titleAdd}
                onClick={() => {
                    setAskCreateSection(true);
                }}
            />
            <Label>{label}</Label>
            <div className='dataListEdit'>
                {data.map((item, index) => {
                    return (
                        <div key={`item${item}`} className='dataListEditItem'>
                            <Label>{item}</Label>
                            <ActionButton
                                iconProps={{ iconName: 'Delete' }}
                                style={{ color: 'red' }}
                                onClick={() => {
                                    onChange(data.filter((_, i) => i !== index));
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
