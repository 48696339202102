import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { Editor, EditorState } from 'draft-js';
import React, { FC, useMemo } from 'react';
import { fontStylesSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../Redux/hook';
import { clearPresetFontStyle, fontSizeStylePrefix, getEditorPresetStyle, setEditorStyle } from './utils';

export interface RichEditFontSizeButtonProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    refEditor: React.RefObject<Editor>;
}

const defaultFontSize = `${fontSizeStylePrefix}16px`;
function generateFontSize(length: number) {
    return Array.from({ length }, (_, i) => ({
        key: `${fontSizeStylePrefix}${i}px`,
        text: `${i}px`,
    }));
}

export const listFontSizes: IDropdownOption[] = generateFontSize(72);

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 80 },
};
export const RichEditFontSizeButton: FC<RichEditFontSizeButtonProps> = (props: RichEditFontSizeButtonProps) => {
    const { editorState, onChangeState, refEditor } = props;

    const fontStyles = useAppSelector(fontStylesSelector);

    const selectedFontSize = useMemo(() => {
        let fsStyle = editorState
            .getCurrentInlineStyle()
            .filter((s) => s !== undefined && s.startsWith(fontSizeStylePrefix))
            ?.last();
        if (!fsStyle) {
            const presetStyle = getEditorPresetStyle(editorState, fontStyles);
            if (presetStyle?.fontProperties.size) {
                fsStyle = `${fontSizeStylePrefix}${presetStyle?.fontProperties.size}`;
            }
        }
        return fsStyle;
    }, [editorState, fontStyles]);

    return (
        <Dropdown
            className='ddlsmallsize'
            styles={dropdownStyles}
            options={listFontSizes}
            selectedKey={selectedFontSize ?? defaultFontSize}
            onChange={(_, selectedKey) => {
                let newState = editorState;

                const newvalue = selectedKey?.key;
                if (newvalue) {
                    newState = clearPresetFontStyle(refEditor.current, newState, fontStyles);
                    newState = setEditorStyle(refEditor.current, newState, fontSizeStylePrefix, undefined, newvalue as string);
                    onChangeState(newState);
                }
            }}
        />
    );
};
