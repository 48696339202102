//note that we don't use export default here
//create context here, use context in others

import React, { createContext, FC, PropsWithChildren, useMemo, useReducer, useState } from 'react';
import { IEditorDataContextState, ViewType } from './IEditorDataContextState';
import { EditorDataContextAction, EditorDataContextReducer, EditorDataContextReducerType } from './EditorDataContextReducer';
import { PageElement } from '../../../../Services/SakuraApiClient';

const emptyContext: IEditorDataContextState = { selectedElement: undefined, deleteSelectedElement: undefined, updateSelectedElement: undefined, viewType: 'Desktop' };
//this DataContext will be shared by all the components
export const EditorDataContext = createContext<IEditorDataContextProvider>({
    selectedElement: undefined,
    viewType: 'Desktop',
    select: (_) => {
        alert('Not Configured');
    },
    unSelect: () => {
        alert('Not Configured');
    },
    updateSelected: (_) => {
        alert('Not Configured');
    },
    deleteSelected: () => {
        alert('Not Configured');
    },
    assignedPlanSelection: () => {
        alert('Not Configured');
    },
    changeViewType: (_) => {
        alert('Not Configured');
    },
});

export interface IEditorDataContextProvider {
    selectedUIComponentId?: string | undefined;
    viewType: ViewType;
    selectedElement?: PageElement | undefined;
    planSelection?: PageElement | undefined;
    select: (uiComponentId: string, element: PageElement, updater: (data: PageElement) => PageElement, deleter: () => void) => void;
    unSelect: () => void;
    updateSelected: (element: PageElement) => void;
    deleteSelected: () => void;
    assignedPlanSelection: (element: PageElement) => void;
    changeViewType: (viewType: ViewType) => void;
}

export const EditorDataContextProvider: FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;
    const [state, dispatch] = useReducer<EditorDataContextReducerType, IEditorDataContextState>(EditorDataContextReducer, emptyContext, () => emptyContext);

    const value = useMemo<IEditorDataContextProvider>(() => {
        return {
            selectedUIComponentId: state.uiComponentId,
            viewType: state.viewType,
            selectedElement: state.selectedElement,
            planSelection: state.planSelection,
            select: (uiComponentId, element, updater, deleter) => {
                dispatch({ type: 'select', payload: { uiComponentId, element, updater, deleter } });
            },
            unSelect: () => {
                dispatch({ type: 'unSelect' });
            },
            updateSelected: (element) => {
                if (state.updateSelectedElement) {
                    state.updateSelectedElement(element);
                }
            },
            deleteSelected: () => {
                if (state.deleteSelectedElement) {
                    state.deleteSelectedElement();
                    dispatch({ type: 'unSelect' });
                }
            },
            assignedPlanSelection: (element) => {
                dispatch({ type: 'planSelection', payload: { element } });
            },
            changeViewType: (type) => {
                dispatch({ type: 'changeView', payload: { type } });
            },
        };
    }, [state, dispatch]);
    return <EditorDataContext.Provider value={value}>{children}</EditorDataContext.Provider>;
};
