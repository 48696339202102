import { Icon } from '@fluentui/react';
import React, { FC, PropsWithChildren } from 'react';

import './ToolBoxPanel.scss';

export interface ToolBoxPanelProps {
    icon: string;
    title: string;
    width?: number;
}
export const ToolBoxPanel: FC<PropsWithChildren<ToolBoxPanelProps>> = (props: PropsWithChildren<ToolBoxPanelProps>) => {
    const { icon, title, children, width } = props;
    return (
        <div className='sakuraToolBox' style={{ width }}>
            <div className='sakuraToolBoxHeader'>
                <Icon iconName={icon} className='icon'></Icon>
                <span>{title}</span>
            </div>
            <div className='sakuraToolBoxContent'>{children}</div>
        </div>
    );
};
