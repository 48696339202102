import { Editor, EditorState } from 'draft-js';
import React, { FC, useMemo } from 'react';
import { fontStylesSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../Redux/hook';
import { getIdFromCssVar } from '../../../styles/common';
import { ColorPalettePicker } from '../../Color/ColorPalettePicker';
import { clearPresetFontStyle, getEditorPresetStyle, setEditorStyle } from './utils';

export interface RichEditColorButtonProps {
    editorState: EditorState;
    onChangeState: (editorState: EditorState) => void;
    refEditor: React.RefObject<Editor>;
    type: 'f' | 'b';
}

export const RichEditColorButton: FC<RichEditColorButtonProps> = (props: RichEditColorButtonProps) => {
    const { editorState, onChangeState, refEditor, type } = props;

    const fontStyles = useAppSelector(fontStylesSelector);

    const selectedColor = useMemo(() => {
        const styles = editorState.getCurrentInlineStyle().filter((s) => s !== undefined && s.startsWith('--C') && s.endsWith(type));
        let colorStyle = styles?.last();

        if (!colorStyle && type === 'f') {
            const presetStyle = getEditorPresetStyle(editorState, fontStyles);
            if (presetStyle?.color) {
                colorStyle = `${getIdFromCssVar(presetStyle?.color)}-${type}`;
            }
        }
        if (colorStyle) {
            return colorStyle.substring(0, colorStyle.length - 2);
        }
        return undefined;
    }, [editorState, type, fontStyles]);
    console.log(selectedColor);
    return (
        <ColorPalettePicker
            type={type}
            colorId={selectedColor}
            onChange={(val) => {
                const value = `${val}-${type}`;

                let newState = editorState;

                if (value) {
                    if (type === 'f') {
                        newState = clearPresetFontStyle(refEditor.current, newState, fontStyles);
                    }
                    newState = setEditorStyle(refEditor.current, newState, '--C', type, value);
                    onChangeState(newState);
                }
            }}
        />
    );
};
