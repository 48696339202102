import { IDropdownOption, Link } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { AccountGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Account';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { useRootPathSelector } from '../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { AccountUpdateByAdminPayload, IAccount, ResourceTypeEnum, RoleEnum, SubscriptionContextEnum } from '../../../Services/SakuraApiClient';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { IconEnabled } from '../../../common/components/Icons/IconEnabled';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { rolesLabels } from './AccountDetail';

const roleOptions: IDropdownOption[] = [
    {
        key: RoleEnum.Admin,
        text: rolesLabels[RoleEnum.Admin.toString()],
    },
    {
        key: RoleEnum.OrganisationAdmin,
        text: rolesLabels[RoleEnum.OrganisationAdmin.toString()],
    },
    {
        key: RoleEnum.PremiumMember,
        text: rolesLabels[RoleEnum.PremiumMember.toString()],
    },
    {
        key: RoleEnum.Member,
        text: rolesLabels[RoleEnum.Member.toString()],
    },
    {
        key: RoleEnum.Prospect,
        text: rolesLabels[RoleEnum.Prospect.toString()],
    },
];
interface NavState {
    refresh?: boolean;
}

export const AccountsList: FC = () => {
    const [selectedAccount, setSelectedAccount] = useState<IAccount | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const nav = useNav();
    const rootPath = useAppSelector(useRootPathSelector);
    const navState = nav.current.state as INavigationState<NavState>;
    useEffect(() => {
        if (navState?.state?.refresh) {
            AccountGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const blockUser = (userId: string, enabled: boolean) => {
        performApiCall(async (client, _cancelToken) => {
            const payload = new AccountUpdateByAdminPayload();
            payload.init({ enabled });
            const accountModified = await client.updateAccount(userId, payload);

            if (accountModified && AccountGrid.lastResult) {
                AccountGrid.refresh(undefined, _cancelToken);
                // setAccounts({
                //     ...accounts,
                //     result: ArrayOperation.update(accounts?.result ?? [], (acc) => acc.id == accountModified.id, accountModified),
                // });
            }
        }, dispatch);
    };
    const deleteUser = (userId: string) => {
        performApiCall(
            async (client, _cancelToken) => {
                await client.deleteAccount(userId);
                AccountGrid.refresh(undefined, _cancelToken);
                // if (accounts) {
                //     setAccounts({
                //         ...accounts,
                //         result: ArrayOperation.remove(accounts?.result ?? [], (acc) => acc.oid === userId),
                //     });
                // }
                setShowDeleteDialog(false);
            },
            dispatch,
            { contextId: 'ConfirmationDialog' },
        );
    };
    const syncBrevo = () => {
        performApiCall(async (client, _cancelToken) => {
            await client.synchronizeSakuraData();
            AccountGrid.refresh();
        }, dispatch);
    };

    const columns = [
        {
            key: 'Actif',
            name: '',
            minWidth: 15,
            maxWidth: 15,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                return <IconEnabled enabled={item.enabled} />;
            },
        },
        {
            key: 'Pseudo',
            name: 'Pseudo',
            fieldName: 'pseudo',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Nom',
            name: 'Nom',
            fieldName: 'lastname',
            minWidth: 60,
            maxWidth: 60,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'lastname',
                        displayName: 'nom',
                    },
                ],
            },
        },
        {
            key: 'Prénom',
            name: 'Prénom',
            fieldName: 'firstname',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Role',
            name: 'Rôle',
            fieldName: 'userRole',
            minWidth: 100,
            maxWidth: 130,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'Choisir un rôle',
                options: roleOptions,
                fieldNames: [
                    {
                        fieldName: 'userRole',
                        displayName: 'rôle',
                    },
                ],
            },
            onRender: (item: IAccount) => {
                return <span>{rolesLabels[item.userRole.toString()]}</span>;
            },
        },
        {
            key: 'login',
            name: 'Email de connexion',
            fieldName: 'signInEmail',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Email',
                fieldNames: [
                    {
                        fieldName: 'signInEmail',
                        displayName: 'Email',
                    },
                ],
            },
        },
        {
            key: 'contact',
            name: 'Email de contact',
            fieldName: 'contactEmail',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'facebookId',
            name: 'Email Facebook',
            fieldName: 'facebookId',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'lastLoginOn',
            name: 'Dernier connexion le',
            fieldName: 'lastLoginOn',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                return <span>{item.lastLoginOn?.toLocaleDateString() ?? '-'}</span>;
            },
        },
        {
            key: 'Coin',
            name: 'Coin',
            fieldName: 'sakuraCoin',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Diamant',
            name: 'Diamant',
            fieldName: 'sakuraDiamond',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Level',
            name: 'Level',
            fieldName: 'level',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'XP',
            name: 'XP',
            fieldName: 'experience',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'Brevo',
            name: 'Identifiant Brevo',
            fieldName: 'sendingBlueId',
            minWidth: 120,
            maxWidth: 120,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                return <span>{item.sendingBlueId ?? 'Aucun'}</span>;
            },
        },
        {
            key: 'CreatedOn',
            name: 'Créer le',
            fieldName: 'createdOn',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                return <span>{item.createdOn.toLocaleDateString()}</span>;
            },
        },
        {
            key: 'updatedOn',
            name: 'Modifié le',
            fieldName: 'updatedOn',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                return <span>{item.updatedOn?.toLocaleDateString()}</span>;
            },
        },
        {
            key: 'prospectSubscriptionContextEnum',
            name: 'Origin',
            fieldName: 'prospectSubscriptionContextEnum',
            minWidth: 125,
            maxWidth: 125,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccount) => {
                switch (item.prospectSubscriptionContextEnum) {
                    case SubscriptionContextEnum.Optin:
                        return <span>{'System IO Optin'}</span>;
                    case SubscriptionContextEnum.DownloadFile:
                        return (
                            <Link href={`${rootPath}${screenPaths.resourceUpdate(ResourceTypeEnum.Document, item.subscriptionContextResourceId!)}`} target='_blank'>
                                {'Téléchargement Doc'}
                            </Link>
                        );
                    case SubscriptionContextEnum.Questionnary:
                        return (
                            <Link href={`${rootPath}${screenPaths.resourceUpdate(ResourceTypeEnum.Questionnary, item.subscriptionContextResourceId!)}`} target='_blank'>
                                {'Questionnaire'}
                            </Link>
                        );
                    case SubscriptionContextEnum.Synchro:
                        return <span>{'Synchro Brevo'}</span>;
                }
                return <span>{'inconnu'}</span>;
            },
        },
    ];
    return (
        <Panel
            title='Liste des comptes'
            icon='People'
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        nav.navigate(screenPaths.accountCreate);
                    },
                },
                selectedAccount?.enabled
                    ? {
                          icon: 'BlockContact',
                          text: 'Désactiver',
                          disabled: !selectedAccount,
                          onClick: () => {
                              if (selectedAccount?.oid) {
                                  blockUser(selectedAccount?.oid, false);
                              }
                          },
                      }
                    : {
                          icon: 'Contact',
                          text: selectedAccount ? 'Activer' : 'Activer/Désactiver',
                          disabled: !selectedAccount,
                          onClick: () => {
                              if (selectedAccount?.oid) {
                                  blockUser(selectedAccount?.oid.toString(), true);
                              }
                          },
                      },
                {
                    icon: 'FollowUser',
                    text: 'Convertir en compte membre',
                    disabled: !selectedAccount || selectedAccount.userRole !== RoleEnum.Prospect,
                    onClick: () => {
                        //todo
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedAccount,

                    onClick: () => {
                        if (selectedAccount?.oid) {
                            nav.navigate(screenPaths.accountUpdate(selectedAccount.oid), selectedAccount);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedAccount,
                    onClick: () => {
                        if (selectedAccount?.oid) {
                            setShowDeleteDialog(true);
                        }
                    },
                },
                {
                    icon: 'Sync',
                    text: 'Syncro Brevo',
                    onClick: () => {
                        syncBrevo();
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        AccountGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid
                datasetName={'AccountGrid'}
                columns={columns}
                getKey={(item) => item.id ?? ''}
                onRowDoubleClick={(item: IAccount) => {
                    if (item?.oid) {
                        nav.navigate(screenPaths.accountUpdate(item.oid), item);
                    }
                }}
                onSelect={(selected: IAccount | undefined) => setSelectedAccount(selected)}
            />

            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: 'People' }}
                show={showDeleteDialog}
                title='Suppression du compte'
                subText='Etes-vous sur de vouloir supprimer le compte?'
                onReply={(reply) => {
                    if (reply) {
                        if (selectedAccount?.oid) {
                            deleteUser(selectedAccount?.oid);
                        }
                    } else {
                        setShowDeleteDialog(false);
                        dispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
        </Panel>
    );
};
