import { IAccount } from '../../../Services/SakuraApiClient';
import { ValueTyping } from '../../../common/components/ExpressionField/Expression';
import { AppDispatch } from '../../store';
import { clearScope, loadScope } from '../DynamiqueData/reducer';

export function updateProfileToDynamicData(dispatch: AppDispatch, accountProfile: IAccount | undefined) {
    if (accountProfile) {
        dispatch(loadScope({ scope: 'user', content: exportProfileToDynamicData(accountProfile) }));
    } else {
        dispatch(clearScope({ scope: 'user' }));
    }
}
export function exportProfileToDynamicData(accountProfile: IAccount): Record<string, ValueTyping> {
    const data: Record<string, ValueTyping> = {
        prenom: accountProfile.firstname,
        nom: accountProfile.lastname,
        pseudo: accountProfile.pseudo,
        nomaffiche: accountProfile.displayName,
        email_login: accountProfile.signInEmail,
        role: accountProfile.userRole,
        niveau: accountProfile.level,
        experience: accountProfile.experience,
        petale: accountProfile.sakuraCoin,
        diamant: accountProfile.sakuraDiamond,
        facebookId: accountProfile.facebookId,
        nb_questionnaire: getAccountData(accountProfile, 'Nb_Questionnaire'),
        nb_questionnaire_reussie: getAccountData(accountProfile, 'Nb_Questionnaire_Success'),
    };

    return data;
}
function getAccountData(accountProfile: IAccount, name: string) {
    if (accountProfile.accountData.variables && accountProfile.accountData.variables[name]) {
        return accountProfile.accountData.variables[name];
    }
    return undefined;
}
