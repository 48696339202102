import { PrimaryButton } from '@fluentui/react';
import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormError } from '../../../../common/Hooks/Form/useForm';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';
import { MenuHierarchyItem } from './MenuHierarchyItem';
import { MenuHierarchyAction } from './formMenuHierarchySlicer';
import { MenuSettingEntry } from './menuSetting';

export interface MenuHierarchyProps {
    entries: MenuSettingEntry[];
    errors: FormError<MenuSettingEntry> | undefined;
    updateForm: (action: MenuHierarchyAction) => void;
    selectedId: string | undefined;
    onSelect: (selectedId: string | undefined) => void;
}

export const MenuHierarchy: FC<MenuHierarchyProps> = (props: MenuHierarchyProps) => {
    const { entries, updateForm, selectedId, onSelect, errors } = props;
    return (
        <div className='MenuHierarchy'>
            <PrimaryButton
                text='Ajouter un element'
                iconProps={{ iconName: 'AddTO' }}
                onClick={() => {
                    const id = generateUniqueId('root/');
                    updateForm({
                        type: 'addItem',
                        payload: {
                            idPath: 'root',
                            item: {
                                id,
                                children: [],
                            },
                        },
                    });
                }}
            />
            <div className='MenuHierarchyContent'>
                <DndProvider backend={HTML5Backend}>
                    {(entries ?? []).map((child, index) => {
                        return (
                            <MenuHierarchyItem
                                root={true}
                                selectedId={selectedId}
                                errors={errors?.children ? errors.children[index] : undefined}
                                onSelect={onSelect}
                                key={child.id}
                                item={child}
                                updateForm={updateForm}
                            />
                        );
                    })}
                </DndProvider>
            </div>
        </div>
    );
};
