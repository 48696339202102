export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function emptyAsUndefined(str: string | null | undefined) {
    if (str === '' || str === null) {
        return undefined;
    }
    return str;
}
export function isNullOrEmpty(str: string | null | undefined) {
    if (str) {
        return str === '';
    }
    return true;
}

export function removeDiatrics(str: string) {
    return str.replace(/[^A-Za-z0-9]/g, function (x) {
        return characters[x] || x;
    });
}
const characters: Record<string, string> = {
    à: 'a',
    â: 'a',
    é: 'e',
    è: 'e',
    ê: 'e',
    î: 'i',
    ô: 'o',
    û: 'u',
};
