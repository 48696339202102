import { useMemo } from 'react';
import { To, useLocation, useNavigate, Location } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Redux/hook';
import { setPreventLostData, useDirtyFormSelector } from '../../Redux/Reducers/System/reducer';
import { useRootPathSelector } from '../../Redux/Reducers/User/reducer';
import { store } from '../../Redux/store';

export interface INav {
    current: Location;
    from: Location | undefined;
    navigateGlobal: (to: To, state?: unknown | undefined) => void;
    navigate: (to: To, state?: unknown | undefined) => void;
    goBack: () => void;
}
export interface INavigationState<T> {
    state: T;
    origin: Location | undefined;
}

export function useNav(): INav {
    const appDispatch = useAppDispatch();
    const rootPath = useAppSelector(useRootPathSelector);
    const navigate = useNavigate();
    const location = useLocation();

    return useMemo<INav>(
        () => ({
            current: location,
            from: (location.state as INavigationState<unknown>)?.origin,
            navigateGlobal: (to, state) => {
                const funcNav = () => navigate(to, { state: { state, origin: location } });

                if (store.getState().system.dirtyForm['default']) {
                    appDispatch(setPreventLostData({ callback: funcNav, contextId: 'default' }));
                } else {
                    funcNav();
                }
            },
            navigate: (to, state) => {
                const funcNav = () => {
                    if (to === '') {
                        navigate(rootPath, { state: { state, origin: location } });
                    } else {
                        if (to.toString().startsWith('/')) {
                            navigate(`${rootPath}${to}`, { state: { state, origin: location } });
                        } else {
                            navigate(`${rootPath}/${to}`, { state: { state, origin: location } });
                        }
                    }
                };
                if (store.getState().system.dirtyForm['default']) {
                    appDispatch(setPreventLostData({ callback: funcNav, contextId: 'default' }));
                } else {
                    funcNav();
                }
            },
            goBack: () => {
                const funcNav = () => navigate(-1);
                if (store.getState().system.dirtyForm['default']) {
                    appDispatch(setPreventLostData({ callback: funcNav, contextId: 'default' }));
                } else {
                    funcNav();
                }
            },
        }),
        [navigate, location, rootPath],
    );
}
