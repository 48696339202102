import { PrimaryButton, ProgressIndicator } from '@fluentui/react';
import BigNumber from 'bignumber.js';
import { FC, useMemo } from 'react';
import { ResourceViewer } from '../../../common/components/ResourcePackage/ResourceViewer';

import clsx from 'clsx';
import { useDynamicData } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { IQuestionnaryQuestion, IQuestionnarySubmissionItem, QuestionTypeEnum } from '../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../common/components/ExpressionField/Hook/useBracketResolver';
import { imageEffectPredefined } from '../../../common/components/ImageViewer/ImageEffectPreDefined';
import { QuestionChoice } from './QuestionType/QuestionChoice';
import { QuestionFreeText } from './QuestionType/QuestionFreeText';
import { QuestionYesNo } from './QuestionType/QuestionYesNo';

export interface QuestionPageProps {
    questionnaryTitle: string;
    questionNumber: number;
    nbQuestions: number;
    question: IQuestionnaryQuestion;
    answer: IQuestionnarySubmissionItem | undefined;
    onAnswer: (answer: Partial<IQuestionnarySubmissionItem>) => void;
    onNext: () => void;
    onBack: () => void;
    canGoPrev: boolean;
    canGoNext: boolean;
}
export const QuestionPage: FC<QuestionPageProps> = (props: QuestionPageProps) => {
    const { question, onAnswer, onNext, onBack, canGoNext, canGoPrev, nbQuestions, questionNumber, answer, questionnaryTitle } = props;

    const percentage = useMemo(() => {
        return new BigNumber(questionNumber).dividedBy(nbQuestions).toNumber();
    }, [questionNumber, nbQuestions]);

    const imageEffect = useMemo(() => {
        if (question.imageEffect?.preDefined) {
            return { ...imageEffectPredefined[question.imageEffect.preDefined], shape: question.imageEffect?.shape };
        }
        return undefined;
    }, [question.imageEffect]);
    const dynData = useDynamicData();
    const resolvedQuestionnaryTitle = useBracketResolver(questionnaryTitle, dynData);
    const resolvedQuestionText = useBracketResolver(question.text, dynData);

    return (
        <div className='questionPage'>
            <div className='header'>
                <span>{resolvedQuestionnaryTitle}</span>
                <ProgressIndicator
                    styles={{ progressBar: { borderRadius: 10 }, progressTrack: { borderRadius: 10 }, itemName: { textAlign: 'center', fontStyle: 'italic' } }}
                    barHeight={10}
                    description={`Question ${questionNumber} sur ${nbQuestions}`}
                    percentComplete={percentage}
                />
            </div>
            <div className={clsx('content')}>
                <h2>{resolvedQuestionText}</h2>
                {question.imageResourceId ? (
                    <div className='questionPicture'>
                        <ResourceViewer resourceId={question.imageResourceId} />
                    </div>
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.YesNo ? <QuestionYesNo answer={answer?.answerYes} onAnswer={(answerYes) => onAnswer({ answerYes })} /> : <></>}
                {question.type === QuestionTypeEnum.Choice ? (
                    <QuestionChoice
                        imageEffect={imageEffect}
                        questionId={question.id ?? ''}
                        answerList={question.answers ?? []}
                        answer={answer?.answers}
                        onAnswer={(answers) => onAnswer({ answers })}
                    />
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.MultiChoice ? (
                    <QuestionChoice
                        multiple
                        imageEffect={imageEffect}
                        questionId={question.id ?? ''}
                        answerList={question.answers ?? []}
                        answer={answer?.answers}
                        onAnswer={(answers) => onAnswer({ answers })}
                    />
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.FreeText ? <QuestionFreeText answer={answer?.freeText} onAnswer={(freeText) => onAnswer({ freeText })} /> : <></>}
            </div>
            <div className='footer'>
                <PrimaryButton className='sakuraButton' iconProps={{ iconName: 'ChevronLeftSmall' }} text={'Précédent'} onClick={() => onBack()} />
                <PrimaryButton
                    className='sakuraButton'
                    disabled={!canGoNext}
                    menuIconProps={{ iconName: 'ChevronRightSmall' }}
                    text={questionNumber !== nbQuestions ? 'Suivant' : 'Terminer'}
                    onClick={() => onNext()}
                />
            </div>
        </div>
    );
};
