import { ContentState, DraftDecoratorType, Editor, EditorState, RawDraftContentState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';

export function loadEditorState(content: RawDraftContentState | undefined, decorator?: DraftDecoratorType): EditorState {
    if (content) {
        const contentState = convertFromRaw(content);
        return EditorState.createWithContent(contentState, decorator);
    }
    return EditorState.createEmpty(decorator);
}
export function loadEditorStateFromstring(content: string | undefined, decorator?: DraftDecoratorType): EditorState {
    if (content) {
        return EditorState.createWithContent(ContentState.createFromText(content), decorator);
    }
    return EditorState.createEmpty(decorator);
}

export function saveEditorState(state: EditorState): RawDraftContentState {
    return convertToRaw(state.getCurrentContent());
}

export function updateEditorStateWithSelection(state: EditorState, change: (state: EditorState) => EditorState): EditorState {
    const editorStateFocused = EditorState.forceSelection(state, state.getSelection());
    return change(editorStateFocused);
}
export function toogleStyleOnEditorState(editor: Editor | null, state: EditorState, style: string): EditorState {
    if (editor) {
        editor.focus();
    }
    const editorStateFocused = EditorState.forceSelection(state, state.getSelection());
    if (editorStateFocused) {
        return RichUtils.toggleInlineStyle(editorStateFocused, style);
    } else {
        return RichUtils.toggleInlineStyle(state, style);
    }
}

export function toogleBlockStyleOnEditorState(state: EditorState, blockstyle: string): EditorState {
    return RichUtils.toggleBlockType(state, blockstyle);
}
