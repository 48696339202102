import { FC, useContext } from 'react';
import { IResourceHierarchyView } from '../../../Services/SakuraApiClient';
import { PageView } from '../Page/PageView';
import { getResourcesInfo } from '../Page/ResourcePageContent';
import { FormationDataContext } from './FormationContext/FormationContextProvider';
import { getParentPath } from './helper';

export interface FormationContentPageProps {
    formationHierarchyView: IResourceHierarchyView;
}
export const FormationContentPage: FC<FormationContentPageProps> = (props: FormationContentPageProps) => {
    const { formationHierarchyView } = props;
    const context = useContext(FormationDataContext);
    return (
        <>
            {context.selectedContent ? (
                <PageView
                    key={context.selectedContent.resourcePath}
                    pageResourceId={context.selectedContent.resource.id}
                    parentResourcePathId={getParentPath(context.selectedContent.resourcePath)}
                    resourceAccountData={context.selectedContent.accountDataResource}
                    pageData={context.selectedContent.resource.data.content.page}
                    resourceData={context.selectedContent.resource.data}
                    resources={getResourcesInfo(context.selectedContent.innerResourcePackages ?? {})}
                />
            ) : null}
        </>
    );
};
