import React, { CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import { IPageElementQuestionnary, Questionnary } from '../../../../../Services/SakuraApiClient';
import { ElementQuestionnaryView } from './ElementQuestionnaryView';

export interface ElementQuestionnaryEditionProps {
    data: IPageElementQuestionnary;
}

export const ElementQuestionnaryEdition: FC<ElementQuestionnaryEditionProps> = (props: ElementQuestionnaryEditionProps) => {
    const { data } = props;

    return (
        <div
            style={{ display: 'flex', flex: 1, pointerEvents: 'none' }}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <ElementQuestionnaryView data={data} />
        </div>
    );
};
