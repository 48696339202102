import { IDropdownOption } from '@fluentui/react';

export type SearchComboBoxFilterType = 'text' | 'boolean' | 'date' | 'list';

export interface FilterDefinition {
    name: string;
    default?: boolean;
    prefix?: string;
    type: SearchComboBoxFilterType;
    options?: IDropdownOption[];
    errorMessage?: string;
}
export interface SearchCriteria {
    name: string;
    value: string | undefined;
    definition: FilterDefinition;
}

export function searchCriteriaToObject(criteria: SearchCriteria[]) {
    const obj = {} as Record<string, string | undefined>;
    criteria.map((c) => {
        obj[c.name] = c.value;
    });
    return obj;
}
export class SearchParameter {
    public defaultParameterName: string | undefined;

    public primaryText: string;

    public criterias: SearchCriteria[];

    constructor(parameterNames: FilterDefinition[], searchString: string | undefined, rtl: boolean) {
        this.primaryText = '';
        this.criterias = parameterNames.map((p) => {
            return { name: p.name, value: undefined, definition: { ...p } };
        });

        this.defaultParameterName = parameterNames.find((p) => p.default === true)?.name;
        const parameterNamesToSearch = parameterNames.filter((p) => p.default !== true).map((c) => c.name);
        const regExExpression = rtl ? `((${parameterNamesToSearch.join('|')}):)` : `(\\b(${parameterNamesToSearch.join('|')}):)`;

        const regEx = new RegExp(regExExpression, 'gi');
        let position = 0;
        let criteriaName = '';

        if (searchString) {
            let match;
            do {
                match = regEx.exec(searchString);
                if (match) {
                    if (criteriaName === '') {
                        if (match.index > 0) {
                            this.primaryText = searchString.substring(0, match.index).trim();
                        }
                    } else {
                        // eslint-disable-next-line no-loop-func
                        const criteria = this.criterias.find((c) => c.name === criteriaName);
                        if (criteria) {
                            criteria.value = searchString.substring(position, match.index).trim();
                        }
                    }
                    position = match.index + match[0].length;
                    [, , criteriaName] = match;
                    // eslint-disable-next-line no-loop-func
                    const fixedCriterianame = parameterNames.find((c) => c.name.toLowerCase() === criteriaName.toLowerCase());
                    if (fixedCriterianame) {
                        criteriaName = fixedCriterianame.name;
                    }
                }
            } while (match);

            if (criteriaName) {
                // eslint-disable-next-line no-loop-func
                const criteria = this.criterias.find((c) => c.name === criteriaName);
                if (criteria) {
                    criteria.value = searchString.substring(position).trim();
                }
            }
            if (this.primaryText === '' && this.criterias.find((c) => c.value !== undefined) === undefined) {
                this.primaryText = searchString;
            }
        }

        if (this.defaultParameterName) {
            const criteria = this.criterias.find((c) => c.name === this.defaultParameterName);
            if (criteria) {
                criteria.value = this.primaryText;
            }
        }
    }

    public get hasCritieras(): boolean {
        return this.criterias.filter((c) => c.name !== this.defaultParameterName).findIndex((c) => c.value && c.value.trim() !== '') !== -1;
    }

    public toString() {
        if (this.defaultParameterName) {
            const criteria = this.criterias.find((c) => c.name === this.defaultParameterName);
            return `${criteria?.value ?? ''} ${this.criterias
                .filter((c) => c.value && c.name !== this.defaultParameterName)
                .map((c) => `${c.name}: ${c.value}`)
                .join(' ')}`.trim();
        }
        return `${this.primaryText} ${this.criterias
            .filter((c) => c.value)
            .map((c) => `${c.name}: ${c.value}`)
            .join(' ')}`.trim();
    }
}
