import { FC, useState } from 'react';
import { IProtectedValueInfo, OfferMarketingInfo } from '../Services/SakuraApiClient';
import { useApiCall } from '../common/Hooks/useApiCall';
import { ClickablePicture } from '../common/components/ClickablePicture/ClickablePicture';

import './ViewMarketingOfferBasic.scss';

export interface MarketingOffer {
    showCurrentOffers?: IProtectedValueInfo;
    offerId1?: IProtectedValueInfo;
    offerId2?: IProtectedValueInfo;
}
export interface ViewMarketingOfferBasicProps {
    offer: MarketingOffer;
}

export const ViewMarketingOfferBasic: FC<ViewMarketingOfferBasicProps> = (props: ViewMarketingOfferBasicProps) => {
    const { offer } = props;
    const [offers, setOffers] = useState<OfferMarketingInfo[]>();

    useApiCall(
        async (client) => {
            if (offer) {
                const values = await client.getMarkingOffers(offer.showCurrentOffers?.protected, offer.offerId1?.protected, offer.offerId2?.protected);
                setOffers(values);
            }
        },
        [offer],
        { anonymous: true },
    );

    return (
        <>
            {offers ? (
                <>
                    {offers.map((o, index) => {
                        return <ClickablePicture key={`offer${index}`} src={o.imageUrl} href={o.link} className='commercialOffer' />;
                    })}
                </>
            ) : (
                <></>
            )}
        </>
    );
};
