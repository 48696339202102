/* ----------*
* Utils
------------*/

import { EditorState } from 'draft-js';
import { TriggerRange } from './IntelisensePopup';
import { Intelisense } from '../../../../Redux/Reducers/DynamiqueData/state';

export function getSelectionRange() {
    const selection = window.getSelection();
    if (selection) {
        if (selection.rangeCount === 0) return null;
        return selection.getRangeAt(0);
    }
    return undefined;
}

function indexOfBracketStart(text: string, position: number) {
    if (position > 1) {
        for (let index = position; index >= 0; index--) {
            if (text[index] === '{') {
                if (index > 0) {
                    if (text[index - 1] === '{') {
                        return index + 1;
                    }
                }
            }
            if (text[index] === '}' && index != position) {
                return -1;
            }
        }
    }
    return -1;
}
function indexOfBracketEnd(text: string, position: number) {
    if (position < text.length - 1) {
        for (let index = position; index < text.length; index++) {
            if (text[index] === '}') {
                if (index < text.length - 1) {
                    if (text[index + 1] === '}') {
                        return index - 1;
                    }
                }
            }
            if (text[index] === '{' && index != position) {
                return -1;
            }
        }
    }
    return -1;
}
function indexOfStartFunction(text: string, position: number) {
    if (position > 1) {
        let parentheseCounter = 0;
        for (let index = position - 1; index >= 0; index--) {
            if (text[index] === '(') {
                if (parentheseCounter === 0) {
                    return index;
                } else {
                    parentheseCounter--;
                }
            }
            if (text[index] === ')') {
                parentheseCounter++;
            }
        }
    }
    return -1;
}
function indexWhenMatch(text: string, position: number, reg: RegExp) {
    if (position > 1) {
        for (let index = position; index >= 0; index--) {
            if (text[index] && text[index].match(reg)) {
                return index;
            }
        }
    }
    return -1;
}
function skipFunction(text: string, position: number) {
    if (position > 1) {
        let parentheseCounter = 0;
        for (let index = position - 1; index < text.length; index++) {
            if (text[index] === '(') {
                parentheseCounter++;
            }
            if (text[index] === ')') {
                parentheseCounter--;
                if (parentheseCounter === 0) {
                    return index;
                }
            }
        }
    }
    return text.length - 1;
}
function findArgumentIndex(text: string, startIndex: number, focusIndex: number) {
    let argumentIndex = 0;

    if (startIndex <= text.length - 1) {
        for (let index = startIndex; index < text.length; index++) {
            if (text[index] === '(') {
                index = skipFunction(text, index);
            }
            if (index >= focusIndex) {
                break;
            }
            if (text[index] === ',') {
                argumentIndex++;
            }
        }
    }
    return argumentIndex;
}
function selectionIsInVariableName(contentText: string, focusIndex: number) {
    if (contentText && contentText.length > 0) {
        let beginIndex = 0;
        for (let index = Math.max(focusIndex - 1, 0); index >= 0; index--) {
            if (contentText[index] && !contentText[index].match(/[a-zA-Z_\.0-9]/)) {
                beginIndex = index + 1;
                break;
            }
        }
        let endIndex = contentText.length;

        if (focusIndex < contentText.length) {
            for (let index = focusIndex; index < contentText.length; index++) {
                if (contentText[index] && !contentText[index].match(/[a-zA-Z_\.0-9]/)) {
                    endIndex = index;
                    break;
                }
            }
        }
        //  const variableName = contentText.substring(beginIndex, endIndex);
        // if (variableName.match(/[a-zA-Z]+\.\w*/)) {
        //     return { beginIndex, endIndex };
        // }

        const bi = beginIndex === 0 ? 0 : beginIndex - 1;
        const ei = endIndex === contentText.length ? endIndex - 1 : endIndex;
        if ((bi > 0 && contentText[bi] === "'") || (ei <= contentText.length - 1 && contentText[ei] === "'")) {
            return { beginIndex, endIndex, insideLitteral: true };
        }
        return { beginIndex, endIndex };
    }
    return undefined;
}

export function calculatePreviousBlockLength(currentBlockIndex: number, contentText: string) {
    let count = 0;
    if (currentBlockIndex) {
        const nbLines = contentText.split('\n');
        for (let i = 0; i < currentBlockIndex; i++) {
            count += nbLines[i].length + 1;
        }
    }
    return count;
}
export interface SelectionContext {
    text: string;
    blockOffset: number;
    focusOffset: number;
    contentText: string;
}
export function getSelectionContext(editorState: EditorState): SelectionContext {
    const content = editorState.getCurrentContent();
    const contentText = content.getPlainText();
    const selection = editorState.getSelection();
    const currentBlockKey = selection.getStartKey();
    const currentBlockIndex = editorState
        .getCurrentContent()
        .getBlockMap()
        .keySeq()
        .findIndex((k) => k === currentBlockKey);
    const blockOffset = calculatePreviousBlockLength(currentBlockIndex, contentText);
    const focusOffset = selection.getFocusOffset() + blockOffset;

    const text = contentText.substring(0, focusOffset);
    return { text, blockOffset, focusOffset, contentText };
}
export function checkInsideExpressionBracket(selectionContext: SelectionContext) {
    const startIndex = indexOfBracketStart(selectionContext.contentText, selectionContext.focusOffset);
    if (startIndex !== -1) {
        return indexOfBracketEnd(selectionContext.contentText, selectionContext.focusOffset) !== -1;
    }
    return false;
}
function findFunction(contentText: string, blockOffset: number) {
    let funcName: string | undefined = undefined;
    let argumentIndex = 0;

    const firstParentheseIndex = indexOfStartFunction(contentText, blockOffset);
    if (firstParentheseIndex !== -1) {
        const startIndex = indexWhenMatch(contentText, firstParentheseIndex - 1, /[\s,\{]/);

        if (firstParentheseIndex - 1 !== startIndex) {
            funcName = contentText.substring(startIndex + 1, firstParentheseIndex);
            argumentIndex = findArgumentIndex(contentText, firstParentheseIndex + 1, blockOffset);
        }
    }
    return {
        funcName,
        argumentIndex,
    };
}
export function checkInsideFunction(selectionContext: SelectionContext, intelisense: Intelisense) {
    const { focusOffset, contentText } = selectionContext;

    if (selectionContext.contentText && selectionContext.contentText.length > 0) {
        const { funcName, argumentIndex } = findFunction(contentText, focusOffset);
        if (funcName) {
            const info = intelisense[funcName];
            if (info) {
                return {
                    triggerRange: {
                        end: focusOffset,
                        start: focusOffset,
                        text: info.fullName,
                    },
                    info: {
                        info,
                        argumentIndex,
                    },
                };
            }
        }
    }
    return undefined;
}
export function getTriggerRange(selectionContext: SelectionContext, shouldTrigger: boolean) {
    const { text, blockOffset, focusOffset, contentText } = selectionContext;

    const varNamePosition = selectionIsInVariableName(contentText, focusOffset);
    if (varNamePosition?.insideLitteral) {
        return undefined;
    }
    if (varNamePosition) {
        const newText = contentText.slice(varNamePosition.beginIndex, varNamePosition.endIndex);
        if ((newText.length > 1 && newText.indexOf('.') !== -1) || shouldTrigger) {
            return {
                end: varNamePosition.endIndex - blockOffset,
                start: varNamePosition.beginIndex - blockOffset,
                text: newText,
            };
        }
    }
    if (shouldTrigger) {
        return {
            end: blockOffset,
            start: blockOffset,
            text: '',
        };
    }
    return undefined;
}

export function getInsertRange(prefix: string, editorState: EditorState) {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const anchorKey = selection.getAnchorKey();
    const end = selection.getAnchorOffset();
    const block = content.getBlockForKey(anchorKey);
    const text = block.getText();
    const start = text.substring(0, end).lastIndexOf(prefix);

    return {
        start,
        end,
    };
}

let CaretCoordinates = {
    left: 0,
    top: 0,
};

export function getCaretCoordinates() {
    const range = getSelectionRange();
    if (range) {
        let { left, top } = range.getBoundingClientRect();
        if (left === 0 && top === 0) {
            const coord = (range.commonAncestorContainer as unknown as Range).getBoundingClientRect();
            left = coord.left;
            top = coord.top;
        }
        CaretCoordinates = { left, top };
    }
    return CaretCoordinates;
}

export function filterEntries(dataSource: Intelisense, searchText: string | undefined) {
    let results: Intelisense | undefined = dataSource;
    let localFilter = null;
    if (searchText) {
        const filterParts = searchText.split('.');
        let dsLevel: Intelisense | undefined = dataSource;
        filterParts.forEach((varName, index) => {
            if (index !== filterParts.length - 1) {
                dsLevel = dsLevel ? dsLevel[varName]?.items : undefined;
                results = dsLevel;
            } else if (dsLevel) {
                localFilter = varName;
            }
        });
    }
    return { results, localFilter };
}
