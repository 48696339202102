import { ContentBlock, ContentState, DraftDecorator } from 'draft-js';
import React, { FC, PropsWithChildren } from 'react';

export const IntelisenseDecoratorComponent: FC<PropsWithChildren<unknown>> = (props: PropsWithChildren<unknown>) => {
    const { children } = props;
    return <span className='label intelisense'>{children}</span>;
};

export const findIntelisenseEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'INTELISENSE';
    }, callback);
};

export const intelisenseDecorator: DraftDecorator = {
    strategy: findIntelisenseEntities,
    component: IntelisenseDecoratorComponent,
};
