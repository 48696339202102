import { Value, ValueTyping } from './Value';

export enum CombinerContext {
    Logical = 'Logical',
    Operation = 'Operation',
    Comparator = 'Comparator',
    Parenthese = 'Parenthese',
}

export class ExpressionItem {
    text: string;
    protected variableResolver?: (variableName: string[]) => ValueTyping | undefined;

    previous?: ExpressionItem;
    next?: ExpressionItem;

    constructor(text: string, variableResolver?: (variableName: string[]) => ValueTyping | undefined) {
        this.text = text;
        this.variableResolver = variableResolver;
    }

    public resolve(): ExpressionItem {
        // Implement the logic for resolving the expression
        throw new Error('Method not implemented.');
    }

    public getValue(): Value {
        // Implement the logic for getting the value
        throw new Error('Method not implemented.');
    }

    public canReturnValue(): boolean {
        // Implement the logic for determining if a value can be returned
        throw new Error('Method not implemented.');
    }

    public isGreedy(context: CombinerContext): boolean {
        // Implement the logic for determining if the expression is greedy
        return true;
    }

    public attachTo(nextItem: ExpressionItem): void {
        this.next = nextItem;
        nextItem.previous = this;
    }
}
