import { FC, useEffect, useState } from 'react';
import { EmailTemplateLookup } from '../../../Redux/Reducers/DataSet/ApiCall/Brevo';
import { useApiCall } from '../../../common/Hooks/useApiCall';
import { LoadingSpinner } from '../../../common/components/loadingSpinner';
import { menuSetting } from './MenuSetting/menuSetting';
import { SystemEdit, SystemState } from './SystemEdit';

// eslint-disable-next-line
function template(data: any) {
    if (data) {
        const template = EmailTemplateLookup.lastResult?.result?.find((v) => v.id == data.id);
        return { ...data, id: data.id, template };
    }
    return undefined;
}

export const SystemPage: FC = () => {
    const [settings, setSettings] = useState<SystemState | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        const perform = async () => {
            await EmailTemplateLookup.init();
            setLoaded(true);
        };
        perform();
    }, []);

    useApiCall(
        async (client, ctoken) => {
            const settings = await client.getSettings(ctoken);
            if (loaded) {
                setSettings({
                    templateEmailResetPassword: template(settings['templateEmailResetPassword']),
                    templateEmailInviteFirstConnection: template(settings['templateEmailInviteFirstConnection']),
                    templateEmailCompleteFirstConnection: template(settings['templateEmailCompleteFirstConnection']),
                    experienceMap: settings['experienceMap'],
                    links: settings['links'],
                    colorPalette: settings['colorPalette'],
                    fontStyles: settings['fontStyles'],
                    userVariables: settings['userVariables'],
                    sectionSetting: settings['sectionSetting'],
                    menuSetting: menuSetting(settings['menuSetting']),
                } as unknown as SystemState); //on by pass le typing exceptionnellement
            }
        },
        [loaded],
    );
    return <>{settings ? <SystemEdit settings={settings} /> : <LoadingSpinner messageLabel={'Chargement du paramétrage'} />}</>;
};
