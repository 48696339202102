import { CombinerContext, ExpressionItem } from './ExpressionItem';
import { ExpressionComparator } from './ExpressionComparator';
import { ExpressionLogic } from './ExpressionLogic';
import { ExpressionOperator } from './ExpressionOperator';
import { ExpressionVariable } from './ExpressionVariable';

import { ExpressionItemPatternRegEx, grpIndexComparator, grpIndexFunction, grpIndexLogic, grpIndexOperator, grpIndexValue, grpIndexVar } from './ExpressionItemPatternRegEx';
import { ExpressionValue } from './ExpressionValue';
import { Value, ValueTyping } from './Value';
import { ExpressionFunction } from './ExpressionFunction';

export class ExpressionBlock extends ExpressionItem {
    static LogicalOperators: string[] = ['et', 'ou', 'and', 'or', '||', '&&'];
    static ComparatorOperators: string[] = ['<>', '>=', '<=', '!=', '>', '<'];
    static Operators: string[] = ['+', '-', '*', '/', '%'];

    public items: ExpressionItem[];

    constructor(text: string, variableResolver?: (variableName: string[]) => ValueTyping | undefined) {
        super(text, variableResolver);
        this.items = [];

        let blockContent = text;

        if (text.startsWith('(') && text.endsWith(')')) {
            blockContent = text.substring(1, text.length - 1);
            const subblocks = ExpressionBlock.extractBlocks(blockContent);
            if (subblocks.length > 1) {
                let previousBlock: ExpressionBlock | null = null;
                for (const subblock of subblocks) {
                    const subBlockItem = new ExpressionBlock(subblock, variableResolver);
                    if (previousBlock !== null) {
                        previousBlock.attachTo(subBlockItem);
                        previousBlock.items[previousBlock.items.length - 1].attachTo(subBlockItem);
                    }
                    previousBlock = subBlockItem;
                    this.items.push(subBlockItem);
                }
                return;
            }
        }

        let previous: ExpressionItem | undefined = undefined;
        let match;
        const regEx = new RegExp(ExpressionItemPatternRegEx);
        do {
            match = regEx.exec(blockContent);
            if (match) {
                const item = ExpressionBlock.createItem(match, variableResolver);
                if (previous) {
                    previous.next = item;
                }
                item.previous = previous;
                previous = item;
                this.items.push(item);
            }
        } while (match);
    }
    public static extractBlocks(input: string): string[] {
        const blocks: string[] = [];
        let currentBlock = '';
        const stack: string[] = [];
        let previousChar = ' ';
        let insideFunction = false;
        let parentheseCount = 0;

        for (const c of input) {
            if (c === '(') {
                parentheseCount++;
                if (!insideFunction) {
                    if (/[a-zA-Z]/.test(previousChar)) {
                        insideFunction = true;
                        parentheseCount = 1;
                    } else {
                        if (stack.length === 0) {
                            if (currentBlock.trim() !== '') {
                                blocks.push(currentBlock.trim());
                            }
                            currentBlock = '';
                        }
                        stack.push(c);
                    }
                }
                currentBlock += c;
            } else if (c === ')') {
                parentheseCount--;
                if (insideFunction) {
                    if (parentheseCount === 0) {
                        insideFunction = false;
                    }
                    currentBlock += c;
                } else {
                    stack.pop();
                    currentBlock += c;
                    if (stack.length === 0) {
                        if (currentBlock.trim() !== '') {
                            blocks.push(currentBlock.trim());
                        }
                        currentBlock = '';
                    }
                }
            } else {
                currentBlock += c;
            }
            previousChar = c;
        }

        if (currentBlock.trim() !== '') {
            blocks.push(currentBlock.trim());
        }

        return blocks;
    }

    private static isInArray(value: string | null, array: string[]): string | null {
        if (value !== null && value !== undefined) {
            if (array.includes(value)) {
                return value;
            }
        }
        return null;
    }

    private static createItem(match: RegExpMatchArray, variableResolver?: (variableName: string[]) => ValueTyping | undefined): ExpressionItem {
        const entries = Array.from(match.values());
        let logic = entries[grpIndexLogic];
        const variable = entries[grpIndexVar];
        const value = entries[grpIndexValue];
        let comparator = entries[grpIndexComparator];
        let op = entries[grpIndexOperator];
        const func = entries[grpIndexFunction];

        if (value !== undefined && value !== null) {
            const logicFromValue = ExpressionBlock.isInArray(value, ExpressionBlock.LogicalOperators);
            if (logicFromValue === null) {
                const comparatorFromValue = ExpressionBlock.isInArray(value, ExpressionBlock.ComparatorOperators);
                if (comparatorFromValue === null) {
                    const opFromValue = ExpressionBlock.isInArray(value, ExpressionBlock.Operators);
                    if (opFromValue !== null) {
                        op = opFromValue;
                    }
                } else {
                    comparator = comparatorFromValue;
                }
            } else {
                logic = logicFromValue;
            }
        }

        if (logic !== undefined && logic !== null) {
            return new ExpressionLogic(logic);
        }
        if (variable !== undefined && variable !== null) {
            return new ExpressionVariable(variable, variableResolver);
        }
        if (comparator !== undefined && comparator !== null) {
            return new ExpressionComparator(comparator);
        }
        if (op !== undefined && op !== null) {
            return new ExpressionOperator(op);
        }
        if (value !== undefined && value !== null) {
            return new ExpressionValue(value);
        }
        if (func !== undefined && func !== null) {
            return ExpressionBlock.createFunctionExpression(func, variableResolver);
        }
        throw new Error(`Cannot create item for text ${match[0]}`);
    }
    private static createFunctionExpression(functionString: string, variableResolver?: (variableName: string[]) => ValueTyping | undefined): ExpressionFunction {
        const indexOfParenthesis = functionString.indexOf('(');

        if (indexOfParenthesis === -1) {
            throw new Error('Cannot create expression function because ( is not found.');
        }

        const functionName = functionString.substring(0, indexOfParenthesis);
        let parameters = functionString.substring(indexOfParenthesis + 1);
        parameters = parameters.substring(0, parameters.length - 1);
        const argsBlock: ExpressionItem[] = [];

        if (parameters.length > 0) {
            const args = parameters.split(',');

            for (const arg of args) {
                argsBlock.push(new ExpressionBlock(arg.trim(), variableResolver));
            }
        }

        return new ExpressionFunction(functionString, functionName, argsBlock);
    }
    public resolve(): ExpressionItem {
        if (this.items.length === 0) {
            return new ExpressionValue('null');
        } else {
            if (this.previous !== null) {
                this.items[0].previous = this.previous;
            }
            if (this.items[0].next === null) {
                this.items[0].next = this.next;
            }
            const blockResult = this.items[0].resolve();
            return blockResult;
        }
    }

    public isGreedy(context: CombinerContext): boolean {
        return false;
    }

    public getValue(): Value {
        throw new Error(`Cannot call getValue of the ExpressionBlock ${this.text}.`);
    }

    public canReturnValue(): boolean {
        return false;
    }
}
