import { FC } from 'react';

import { useLinks } from '../../../Redux/Reducers/System/reducer';
import { SocialNetworkButton } from './SocialNetworkButton';
import './SocialNetworkLinksBlock.scss';

export const SocialNetworkLinksBlock: FC = () => {
    const links = useLinks();

    return (
        <div className='socialNetworkLinksBlock'>
            <span>{'Rejoins-moi sur les réseaux !'}</span>
            <p>{'Un véritable soutient grâce aux membres. Une communauté composée de futures entrepreneuses du web bienveillantes.'}</p>
            <div className='links'>
                {links ? (
                    <>
                        <SocialNetworkButton links={links} socialNetwork={'facebook'} />
                        <SocialNetworkButton links={links} socialNetwork={'instagram'} />
                        <SocialNetworkButton links={links} socialNetwork={'threads'} />
                        <SocialNetworkButton links={links} socialNetwork={'pinterest'} />
                        <SocialNetworkButton links={links} socialNetwork={'youtube'} />
                        <SocialNetworkButton links={links} socialNetwork={'tiktok'} />
                        <SocialNetworkButton links={links} socialNetwork={'x'} />
                    </>
                ) : null}
            </div>
        </div>
    );
};
